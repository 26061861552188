import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'components/TrustradiusUICore';
import {
  getLinkedInAdAccounts,
  getLinkedInLicensedProductsCategories,
} from 'store/apis/limaApi';
import { CreateSegmentForm } from './CreateSegmentForm';
import styles from './LimaCreateSegmentBody.module.scss';

export function LimaCreateSegmentBody({ vendorId }) {
  const [adAccounts, setAdAccounts] = useState([]);
  const [licensedProducts, setLicensedProducts] = useState([]);
  const [licensedCategories, setLicensedCategories] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ heading: '', body: '' });

  useEffect(() => {
    async function getAdAccounts() {
      try {
        const result = await getLinkedInAdAccounts();
        setAdAccounts(prepareDataForSelect(result.data.adAccounts, true));
      } catch (err) {
        setShowToast(true);
        setToastMessage({
          heading: 'Something went wrong while loading your ad accounts',
          body: 'Please try reload page or contact us support@trustradius.com',
        });
        console.error(
          (err.response && err.response.data && err.response.data.message) ||
            err,
        );
      }
    }

    getAdAccounts();
  }, []);

  useEffect(() => {
    async function getLicensedProdAndCats() {
      try {
        const result = await getLinkedInLicensedProductsCategories({
          vendor: vendorId,
        });
        const { products, categories } = result.data;

        setLicensedProducts(prepareDataForSelect(products));
        setLicensedCategories(prepareDataForSelect(categories));
      } catch (err) {
        setShowToast(true);
        setToastMessage({
          heading:
            'Something went wrong while loading licensed products and categories',
          body: 'Please try reload page or contact us support@trustradius.com',
        });
        console.error(
          (err.response && err.response.data && err.response.data.message) ||
            err,
        );
      }
    }

    getLicensedProdAndCats();
  }, [vendorId]);

  function prepareDataForSelect(data, isAccounts = false) {
    if (data && data.length) {
      return data.map((item) => {
        return {
          label: isAccounts ? `${item.name} (ID: ${item.id})` : item.name,
          value: item,
        };
      });
    }

    return [];
  }

  return (
    <>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        closeButton
        autohide
        heading={<span>{toastMessage.heading}</span>}
        className={styles.toast}
      >
        <span>{toastMessage.body}</span>
      </Toast>
      <CreateSegmentForm
        vendorId={vendorId}
        adAccounts={adAccounts}
        licensedProducts={licensedProducts}
        licensedCategories={licensedCategories}
      />
    </>
  );
}

LimaCreateSegmentBody.propTypes = {
  vendorId: PropTypes.string.isRequired,
};
