import { Button, Text, Input } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import { CustomFields } from 'pages/WebhookConfigurationPage/CustomFields';
import { LeadFormFields } from 'pages/WebhookConfigurationPage/LeadFormFields';
import styles from './WebhookConfigurationPage.module.scss';

export function SalesForcePage({
  endpoint,
  setEndpoint,
  oid,
  setOid,
  hiddenFields,
  setHiddenFields,
  leadFormFields,
  setLeadFormFields,
}) {
  return (
    <>
      <div className={styles['content-container']}>
        <div className={styles['block-container']}>
          <Text as="h2" size="400" weight="semibold">
            Define endpoint
          </Text>
          <Text as="p">
            To send your TrustRadius leads to Salesforce, please follow these
            steps in your Salesforce instance to generate an HTML sample with
            the elements shown below.
          </Text>
        </div>
        <div className={styles['block-container']}>
          <Text as="h2" size="300" weight="semibold">
            Endpoint URL
          </Text>
          <Text as="p">
            Copy and paste your endpoint url from your Salesforce instance into
            this field.
          </Text>
          <Input
            id="endpointUrl"
            type="text"
            autoComplete="off"
            name="endpointUrl"
            value={endpoint}
            onChange={(event) => setEndpoint(event.target.value)}
            hideLabel
          />
        </div>
        <div className={styles['block-container']}>
          <Text as="h2" size="300" weight="semibold">
            OID value
          </Text>
          <Text as="p">
            Copy and paste the OID value from your Salesforce sample HTML into
            this field.
          </Text>
          <Input
            id="oid"
            type="text"
            autoComplete="off"
            name="oid"
            value={oid}
            onChange={(event) => setOid(event.target.value)}
            hideLabel
          />
        </div>
        <div className={styles['block-container']}>
          <Text as="h2" size="300" weight="semibold">
            Map lead form fields
          </Text>
          <Text as="p">
            Use this table to map your TrustRadius Lead form fields to their
            corresponding fields in Salesforce.
          </Text>
          <ul>
            <li>
              <Text as="p">
                The fields from your TrustRadius Lead form are shown in the left
                column of the table below.
              </Text>
            </li>
            <li>
              <Text as="p">
                In the “Salesforce Field Name” column, please add the names of
                the corresponding fields from your Salesforce instance. You can{' '}
                <Button url="/" variant="none">
                  find the Salesforce field names
                </Button>
                in the sample HTML that you generated.
              </Text>
            </li>
          </ul>
        </div>
        <LeadFormFields
          leadFormFields={leadFormFields}
          changeLeadFormFields={setLeadFormFields}
          type="salesforce"
        />
        <div className={styles['block-container']}>
          <Text as="h2" size="300" weight="semibold">
            Add hidden fields
          </Text>
          <Text as="p">Copy</Text>
          <CustomFields
            customHeaders={hiddenFields}
            setCustomHeaders={setHiddenFields}
          />
        </div>
      </div>
    </>
  );
}

SalesForcePage.propTypes = {
  endpoint: PropTypes.string,
  setEndpoint: PropTypes.func,
  oid: PropTypes.string,
  setOid: PropTypes.func,
  hiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setHiddenFields: PropTypes.func,
  leadFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setLeadFormFields: PropTypes.func,
};
