import { useSelector } from 'react-redux';
import { AwardTrackingCardContainer } from './cards/AwardTrackingCard';
import { CategoryIntentCardContainer } from './cards/CategoryIntentCard';
import { DemandBaseCard } from './cards/DemandBaseCard';
import { InteractiveDemosCardContainer } from './cards/InteractiveDemosCard';
import { LinkedInCard } from './cards/LinkedInCard';
import { NoReviewsCardContainer } from './cards/NoReviewsCard';
import { SalesforceCard } from './cards/SalesforceCard';
import { SixSenseCard } from './cards/SixSenseCard';
import { TrustQuotesCardContainer } from './cards/TrustQuotesCard';
import { WelcomeCardContainer } from './cards/WelcomeCard';
import { WhisticCardContainer } from './cards/WhisticCard';
import { ReviewGrowthKitCard } from './cards/ReviewGrowthKitCard';
import styles from './NewsFeed.module.scss';

export function NewsFeed() {
  const product = useSelector((state) => state.vendors?.currentVendorProduct);
  const vendors = useSelector((state) => state.vendors);

  return (
    <div className={styles.container}>
      <WelcomeCardContainer product={product} />
      <ReviewGrowthKitCard product={product} />
      <NoReviewsCardContainer product={product} />
      <InteractiveDemosCardContainer product={product} />
      <WhisticCardContainer product={product} />
      <AwardTrackingCardContainer product={product} />
      <CategoryIntentCardContainer product={product} />
      <DemandBaseCard product={product} />
      <LinkedInCard vendorId={vendors?.currentVendor?._id} product={product} />
      <TrustQuotesCardContainer product={product} />
      <SixSenseCard product={product} />
      <SalesforceCard product={product} />
    </div>
  );
}
