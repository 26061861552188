import PropTypes from 'prop-types';
import { DashCard } from 'components/DashCard';
import { Skeleton } from 'components/Skeleton';
import { BannerCompanies } from './BannerCompanies';

export function CategoryIntentCard({
  product,
  categoryWatchers,
  hasLicense,
  isLoading,
}) {
  if (isLoading) return <Skeleton />;

  if (!product || hasLicense) {
    return null;
  }

  return (
    <DashCard
      ctaText="Schedule a demo today"
      ctaHref="https://www.trustradius.com/resources/assets/global-demo"
      product={product}
    >
      <BannerCompanies companies={categoryWatchers}>
        <>
          <h3>You&apos;re missing out!</h3>
          <p>
            With downstream intent data from TrustRadius, get access to brands
            checking out your competitors (but not you) to get deals you may be
            missing.&nbsp;
          </p>
        </>
      </BannerCompanies>
    </DashCard>
  );
}

CategoryIntentCard.propTypes = {
  product: PropTypes.object,
  categoryWatchers: PropTypes.shape({
    topCompetitors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
      }),
    ),
    currentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  hasLicense: PropTypes.bool,
  isLoading: PropTypes.bool,
};
