import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import { DemandBaseMobile, DemandBaseDesktop } from './assets';

export function DemandBaseCard({ product }) {
  return (
    <DashCard
      ctaText="Learn More"
      ctaHref="https://www.trustradius.com/vendor-blog/how-logicmonitor-boosted-account-engagement-by-81-with-trustradius-buyer-intent-data"
      product={product}
    >
      <Announcement
        mSrc={DemandBaseMobile}
        mSrc2x={DemandBaseMobile}
        src={DemandBaseDesktop}
        src2x={DemandBaseDesktop}
        alt="Differentiated Insights into Buying Signals"
      >
        <>
          <h3>Check out our new Demandbase integration</h3>
          <p>
            We are excited to partner with Demandbase to integrate our
            downstream intent data into their ABM platform to help you reach and
            engage accounts researching your products and competitors’ products.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

DemandBaseCard.propTypes = {
  product: PropTypes.object,
};
