import PropTypes from 'prop-types';
import styles from './HeroLink.module.scss';

export function HeroLink({ text, href }) {
  return (
    <p className={styles.wrapper}>
      <a href={href} className={styles.link}>
        {text}
      </a>
    </p>
  );
}

HeroLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
