import { useEffect, useState } from 'react';

export function useWebhookConfigMock(webhookId, type) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const webhook = {
      id: webhookId,
      name: 'Webex suite webhook',
      updatedAt: new Date(),
      connectedLeadForm: 'Test Lead Form',
      destination: 'Salesforce',
      leadFormFields: [
        { name: 'First Name', value: 'firstName' },
        { name: 'Last Name', value: 'lastName' },
        { name: 'Email', value: 'email' },
        { name: 'Company', value: 'company name' },
        { name: 'Message', value: 'user message' },
        { name: 'Interested in', value: 'demo' },
        { name: 'Product', value: 'zoominfo' },
      ],
      hiddenFields: [{ name: 'source', value: 'TrustRadius' }],
    };
    if (type !== 'salesforce') {
      webhook.endpoint = '/123';
      webhook.format = 'json';
      webhook.customHeaders = [{ name: 'auth', value: 'token' }];
    }
    if (type === 'salesforce') {
      webhook.oid = '233414';
      webhook.endpoint = '/';
    }
    setData(webhook);
    setLoading(false);
  }, [webhookId, type]);
  return [data, loading];
}
