import PropTypes from 'prop-types';
import { WhisticCard } from './WhisticCard';

export function WhisticCardContainer({ product }) {
  if (!product || product.security?.whisticLink) {
    return <></>;
  }

  return <WhisticCard product={product} />;
}

WhisticCardContainer.propTypes = {
  product: PropTypes.shape({
    logos: PropTypes.shape({
      square180: PropTypes.string,
      default: PropTypes.string,
      logo: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
    security: PropTypes.shape({
      whisticLink: PropTypes.string,
    }),
  }).isRequired,
};
