function LinkedInCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <title>LinkedIn</title>
      <path
        d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0ZM14.52,24.1H11.78V15.87h2.74Zm-1.37-9.35h0a1.43,1.43,0,1,1,0-2.85,1.43,1.43,0,1,1,0,2.85ZM24.38,24.1H21.64V19.7c0-1.11-.39-1.87-1.38-1.87a1.48,1.48,0,0,0-1.4,1,1.68,1.68,0,0,0-.09.66v4.6H16s0-7.46,0-8.23h2.74V17a2.69,2.69,0,0,1,2.46-1.36c1.8,0,3.15,1.17,3.15,3.7Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default LinkedInCircle;
