import ReactBootstrapDropdownMenu from 'react-bootstrap/DropdownMenu';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';

/**
 * TrustRadius wrapped DropdownMenu component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function DropdownMenu(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles['dropdown-menu']);
  // Provide default variant
  return (
    <ReactBootstrapDropdownMenu
      className={classes}
      bsPrefix="trDropdown-menu"
      {...otherProps}
    >
      {children}
    </ReactBootstrapDropdownMenu>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
DropdownMenu.propTypes = ReactBootstrapDropdownMenu.propTypes;
