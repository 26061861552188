import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from 'components/TrustradiusUICore';
import styles from './Logo.module.scss';
import { NoLogo } from './images';

/**
 * TrustRadius Logo component, defaults to our no logo image, and has a lazyload styles option.
 * Added local styles for this component as well
 */

export function Logo({ className, logo, lazyLoad, altText }) {
  // Use provided logo or default to no logo
  const src = logo || NoLogo;
  const alt = altText || '';

  // Merge any provided classNames with local component styling
  const classes =
    lazyLoad && !/no_logo.png/.test(src)
      ? classNames(className, styles.logo, 'lazyload')
      : classNames(className, styles.logo);

  return <Image src={src} alt={alt} className={classes} />;
}

Logo.propTypes = {
  logo: PropTypes.string,
  altText: PropTypes.string,
  lazyLoad: PropTypes.bool,
  className: PropTypes.string,
};
