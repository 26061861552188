import ReactBootstrapModalHeader from 'react-bootstrap/ModalHeader';
import classNames from 'classnames';

import './Modal.scss';

/**
 * TrustRadius wrapped ModalHeader component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function ModalHeader(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapModalHeader
      className={classes}
      bsPrefix="trModal-header"
      {...otherProps}
    >
      {children}
    </ReactBootstrapModalHeader>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
ModalHeader.propTypes = ReactBootstrapModalHeader.propTypes;
