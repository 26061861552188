import { axiosRequestWithAuthToken } from './axios_utils';

export const getProductsChangesInfo = async (vendorId) => {
  try {
    const response = await axiosRequestWithAuthToken({
      url: `/api/products-changes/${vendorId}`,
      method: 'GET',
    });

    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
