import { useSelector } from 'react-redux';
import {
  ListChecks,
  FastForward,
  Megaphone,
  Locate,
  Trophy,
  ExternalLink,
} from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { IntentsifySquare } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { IntentsifyBanner, IntentsifyBanner2x } from './assets';

export function Intentsify() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Fuel your GTM strategies with holistic, validated account and buyer intent intelligence.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Intentsify',
    logo: <img src={IntentsifySquare} alt="Intentsify" />,
    subtitle: subTitle,
    actionBlock: (
      <Button
        size="large"
        url="https://www.intentsify.io/contact-us"
        targetBlank
      >
        Request a demo
        <ExternalLink />
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: 'validate',
      icon: <ListChecks />,
      text: 'Validate your target account lists and discover new high-value in-market buyers',
    },
    {
      id: 'accelerate',
      icon: <FastForward />,
      text: 'Reach out to in-market accounts at the right time to accelerate deals',
    },
    {
      id: 'messaging',
      icon: <Megaphone />,
      text: 'Deliver relevant messaging and content that’s unique to each account’s research stage, needs, and interests',
    },
    {
      id: 'speed',
      icon: <Locate />,
      text: 'Leverage speed and precision to strengthen your competitive advantage',
    },
    {
      id: 'retention',
      icon: <Trophy />,
      text: 'Increase win rates and customer retention',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000590781"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = [
    'Account Based Marketing',
    'Intent Data',
    'Content Syndication',
    'Display Advertising',
    'Demand Generation',
  ];

  return (
    <>
      <SecondaryPageHeader {...headerProps} hasShadow />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={IntentsifyBanner}
            srcSet={`${IntentsifyBanner} 1x, ${IntentsifyBanner2x} 2x`}
            alt="Intentsify: Boost team efficiency, improve the buyer experience, and scale pipeline velocity and revenue growth—all at the same time."
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Intentsify</Text>
          <Text as="p">
            Intentsify provides B2B organizations with the most accurate,
            comprehensive buyer-intent intelligence in the market today, and
            solutions to act on that intelligence. By delivering and activating
            proprietary, next-generation precision intent data,
            Intentsify&rsquo;s Intelligence Activation Platform identifies
            companies exhibiting research behaviors directly related to your
            business solutions, pinpoints where they are in the buying process,
            surfaces the issues they care about most, and enables you to engage
            identified companies with full-funnel buying experiences that drive
            revenue.
          </Text>
          <Text as="h3">Intentsify and TrustRadius</Text>
          <Text as="p">
            Marketers can enrich their segmentation, targeting, and direct sales
            efforts by integrating TrustRadius’ downstream intent data with
            Intentsify’s Intelligence Activation Platform.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Hone your Target Account List (TAL)</Text>
          <Text as="p">
            Validate your TAL and discover new in-market accounts with a
            comprehensive, full-funnel view of research behaviors and content
            engagement activities.
          </Text>
          <Text as="h3">Personalize your messaging and content</Text>
          <Text as="p">
            Tailor your marketing programs and sales talk tracks based on each
            target account&rsquo;s unique buying stage and interests, derived
            from a variety of intent sources.
          </Text>
          <Text as="h3">Prioritize high-value, in-market accounts</Text>
          <Text as="p">
            See who&rsquo;s researching topics and keywords related to your
            product, your competitors products&rsquo;, or your category and
            focus your time and effort on the target accounts most likely to
            convert.
          </Text>
          <Text as="h3">Augment your ad creative with social proof</Text>
          <Text as="p">
            Create compelling ad creative with social proof from TrustRadius
            reviews and deploy it using Intentsify&rsquo;s content syndication
            and display advertising services.
          </Text>
          <Text as="h3">
            Identify customer churn risk and revenue opportunities
          </Text>
          <Text as="p">
            Know when customers are researching other solutions and what
            they&rsquo;re looking for so you can personalize your outreach to
            mitigate churn and improve upsell and cross-sell rates.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
