export const FETCH_PRODUCT_METRICS_SUCCESS = 'FETCH_PRODUCT_METRICS_SUCCESS';
export const FETCH_PRODUCT_METRICS_FAILED = 'FETCH_PRODUCT_METRICS_FAILED';

export const FETCH_PRODUCT_TEAM_SUCCESS = 'FETCH_PRODUCT_TEAM_SUCCESS';
export const FETCH_PRODUCT_TEAM_FAILED = 'FETCH_PRODUCT_TEAM_FAILED';

export const fetchProductMetricsSuccess = (metrics) => ({
  type: FETCH_PRODUCT_METRICS_SUCCESS,
  payload: {
    metrics,
  },
});

export const fetchProductMetricsFailed = (error) => ({
  type: FETCH_PRODUCT_METRICS_FAILED,
  payload: {
    error,
  },
});

export const fetchProductTeamSuccess = (team) => ({
  type: FETCH_PRODUCT_TEAM_SUCCESS,
  payload: {
    team,
  },
});

export const fetchProductTeamFailed = (error) => ({
  type: FETCH_PRODUCT_TEAM_FAILED,
  payload: {
    error,
  },
});
