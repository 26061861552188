import { Text } from '@trustradius/radiant-ui';

export function ReviewGenerationFooter() {
  return (
    <>
      <Text as="h3" size="200">
        Getting started
      </Text>
      <ul>
        <Text as="li">
          All vendors can drive reviews to their product listings for FREE.
          Learn about sourcing{' '}
          <a
            href="https://www.trustradius.com/vendor/b2b-reviews/how-to-ask-customers-for-reviews-that-build-trust"
            target="_blank"
            rel="noopener noreferrer"
          >
            unbiased reviews
          </a>{' '}
          that build buyer trust.
        </Text>
        <Text as="li">
          Our{' '}
          <a
            href="https://go.trustradius.com/rs/827-FOI-687/images/TrustRadius_Review-Growth-Kit.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Review Growth Kit
          </a>{' '}
          is a step-by-step guide on how to leverage your campaign link to get
          more reviews using email marketing, embedded invitations, and more!
        </Text>
        <Text as="li">
          Want to run incentivized campaigns?{' '}
          <a
            href="https://www.trustradius.com/resources/assets/global-demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get in touch
          </a>{' '}
          with us to learn how TrustRadius can help you drive more reviews.
        </Text>
      </ul>
    </>
  );
}
