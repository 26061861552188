import axios from 'axios';

export async function getSettings() {
  try {
    const options = {
      url: '/api/settings',
      method: 'GET',
    };
    const response = await axios(options);
    return response.data;
  } catch (error) {
    console.error('Error fetching settings', error);
  }
}
