import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageHeader.module.scss';

export function SecondaryPageHeader({
  backLinkProps,
  title,
  subtitle,
  logo,
  actionBlock,
  hasShadow,
  maxWidth = '1246px',
}) {
  return (
    <header
      className={classNames(
        styles.header,
        hasShadow ? styles['has-shadow'] : null,
      )}
    >
      <div className={styles['header-width-wrapper']} style={{ maxWidth }}>
        <div className={styles['title-block']}>
          {backLinkProps && (
            <Link className={styles['back-link']} {...backLinkProps}>
              <ChevronLeft />
            </Link>
          )}
          <div className={styles['title-container']}>
            {logo}
            <div className={styles['title-content-block']}>
              <Text as="h1" size="600" weight="bold" className={styles.title}>
                {title}
              </Text>
              {subtitle && <Text className={styles.subtitle}>{subtitle}</Text>}
            </div>
          </div>
        </div>
        {actionBlock && (
          <div className={styles['action-block']}>{actionBlock}</div>
        )}
      </div>
    </header>
  );
}

SecondaryPageHeader.propTypes = {
  backLinkProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  logo: PropTypes.node,
  actionBlock: PropTypes.node,
  hasShadow: PropTypes.bool,
  maxWidth: PropTypes.string,
};
