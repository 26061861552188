import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookie } from 'react-use';
import { AppProvider as RadiantAppProvider } from '@trustradius/radiant-ui';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth0Provider } from '@auth0/auth0-react';
import { CookiesProvider } from 'react-cookie';
import { GrowthBookProvider } from 'providers';
import { getSettings } from 'store/apis/settingsApi';
import { useAuthorizeLima } from 'components/LinkedinMatchedAudiences';
import { Spinner } from 'components/Spinner';
import { StoreProvider } from './store';
import { SettingsContext } from './settings';
import { initGTM } from './initGTM';

export function AppProvider({ children }) {
  const [settings, setSettings] = useState(null);
  const [, updateCookie] = useCookie('chrome_extension');
  const query = useMemo(
    () => new URLSearchParams(window?.location?.search),
    [],
  );

  const extension = query.get('extension');

  useEffect(() => {
    if (extension) {
      updateCookie(extension);
    }
  }, [extension, updateCookie]);

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      setSettings(data);
    };

    fetchSettings();
  }, []);

  const isLimaAuthorizing = useAuthorizeLima();

  const isLoadComplete = settings && !isLimaAuthorizing;

  useEffect(() => {
    if (isLoadComplete) {
      initGTM(settings.gtmConfig);
    }
  }, [isLoadComplete, settings]);

  if (!isLoadComplete) {
    return <Spinner />;
  }

  const httpLink = createHttpLink({
    uri: settings.internalApiUrl,
  });

  const authLink = setContext((_, { headers }) => {
    const token = window.sessionStorage.internalApiToken;

    if (!token) {
      // does this redirect to login
      throw new Error('no token provided');
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const { audience, scope, clientId, domain } = settings.auth0;

  const authorizationParams = {
    audience,
    scope: `openid profile email ${scope}`,
    redirect_uri: `${window.location.origin}/vendor`,
  };

  return (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      cookieDomain={settings.cookieDomain}
      authorizationParams={authorizationParams}
    >
      <SettingsContext.Provider value={settings}>
        <GrowthBookProvider>
          <ApolloProvider client={client}>
            <CookiesProvider>
              <StoreProvider>
                <RadiantAppProvider>{children}</RadiantAppProvider>
              </StoreProvider>
            </CookiesProvider>
          </ApolloProvider>
        </GrowthBookProvider>
      </SettingsContext.Provider>
    </Auth0Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node,
};
