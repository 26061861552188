import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ActionMenu } from 'components/ActionMenu';
import styles from './DashCard.module.scss';

export function DashCard({
  date,
  relativeDate,
  children,
  ctaText,
  ctaHref,
  linkProps,
  withActionMenu,
  product,
  isTargetBlank = true,
}) {
  const logo =
    product?.logos?.square180 ||
    product?.logos?.default ||
    product?.logos?.logo;

  const link = useMemo(() => {
    if (linkProps) {
      return <Link {...linkProps} />;
    }

    if (ctaText && ctaHref) {
      const target = isTargetBlank ? '_blank' : '_self';

      return (
        <a href={ctaHref} target={target} rel="noreferrer">
          {ctaText}
        </a>
      );
    }

    return null;
  }, [ctaText, ctaHref, isTargetBlank, linkProps]);

  return (
    <article className={styles.datecard}>
      <footer className={styles.footer}>
        <div className={styles['logo-time']}>
          <div className={styles.logos}>
            {logo && (
              <img className={styles.logo} src={logo} alt={product.name} />
            )}
          </div>
          <time className={styles.time} dateTime={date}>
            {relativeDate}
          </time>
        </div>
        {withActionMenu && (
          <ActionMenu label="Menu">
            <ul className={styles.menu}>
              <li>
                <button className={styles['menu-button']} type="button">
                  Dismiss
                </button>
              </li>
              <li>
                <button className={styles['menu-button']} type="button">
                  Pin
                </button>
              </li>
            </ul>
          </ActionMenu>
        )}
      </footer>
      <div>{children}</div>
      <div className={classNames(styles.actions)}>{link}</div>
    </article>
  );
}

DashCard.propTypes = {
  date: PropTypes.string,
  relativeDate: PropTypes.string,
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string,
  ctaHref: PropTypes.string,
  linkProps: PropTypes.object,
  withActionMenu: PropTypes.bool,
  product: PropTypes.shape({
    logos: PropTypes.shape({
      square180: PropTypes.string,
      default: PropTypes.string,
      logo: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  isTargetBlank: PropTypes.bool,
};
