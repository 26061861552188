import { Loading } from './assets';
import styles from './Spinner.module.scss';

export function Spinner() {
  return (
    <div className={styles.spinner}>
      <Loading alt="Spinner" />
    </div>
  );
}
