import { Suspense } from 'react';
import { Await, useLoaderData, defer } from 'react-router-dom';
import { intersection } from 'underscore';
import { User, Activity, FileSpreadsheet, PieChart } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import {
  SecondaryPageHeader,
  TextBanner,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  Prerequisites,
  UseCases,
} from 'components/SecondaryPage';
import { TrustradiusApiKey } from 'components/TrustradiusApiKey';
import { getApiKey } from 'store/apis/vendorApi';
import { getProductsFeaturesByVendor } from 'store/apis/productApi';
import { SalesforceLogo } from 'assets/logos-companies';
import { salesforceBanner, salesforceBanner2x } from './assets';

export function salesforceLoader({ params: { vendorId } }) {
  return defer({
    apiKey: getApiKey(vendorId),
    productsFeaturesByVendor: getProductsFeaturesByVendor(vendorId),
  });
}

const NEEDED_LICENSES = [
  'api-free-intent-comparisons',
  'api-vi-category-level',
  'api-vi-product-level',
];

export function Salesforce() {
  const data = useLoaderData();

  const title = 'Salesforce Connector';

  const subtitle = (
    <Text as="p">
      Enrich your sales pipeline and drive revenue with TrustRadius intent data.
    </Text>
  );

  const textBanner = (
    <TextBanner>
      This integration requires TrustRadius intent data.{' '}
      <a
        href="https://www.trustradius.com/resources/assets/global-demo"
        target="_blank"
        rel="noopener noreferrer"
      >
        Activate your free trial
      </a>
      .
    </TextBanner>
  );

  const keyBenefits = [
    {
      id: 'buyer',
      icon: <User />,
      text: 'See buyer activity for existing opportunities and new accounts',
    },
    {
      id: 'activity',
      icon: <Activity />,
      text: 'Take a closer look at account activity to personalize your outreach',
    },
    {
      id: 'abm',
      icon: <FileSpreadsheet />,
      text: 'Power your ABM strategy with dynamic target account lists',
    },
    {
      id: 'reports',
      icon: <PieChart />,
      text: 'Whip up helpful custom reports to drive strategy or share with your team',
    },
  ];

  const installation = [
    {
      id: 'appexchange',
      componentContent: (
        <>
          Check out the TrustRadius Connector on the{' '}
          <a
            href="https://appexchange.salesforce.com/listingDetail?listingId=a0N3u00000ONAIeEAP&tab=e"
            target="_blank"
            rel="noopener noreferrer"
          >
            Salesforce AppExchange
          </a>
        </>
      ),
    },
    {
      id: 'user-guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000582348"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const prerequisites = [
    {
      id: 'license',
      componentContent: (
        <>
          An intent data license is required to unlock this integration.{' '}
          <a
            href="https://www.trustradius.com/resources/assets/global-demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      ),
    },
  ];

  const useCases = ['Customer Relationship Management (CRM)'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={{
          to: '..',
          relative: 'path',
          'aria-label': 'Integration page',
        }}
        title={title}
        subtitle={subtitle}
        logo={<img src={SalesforceLogo} alt="Salesforce" />}
        actionBlock={
          <Suspense fallback={<TrustradiusApiKey />}>
            <Await resolve={data.apiKey} errorElement={<TrustradiusApiKey />}>
              {(apiKeyResponse) => (
                <TrustradiusApiKey apiKey={apiKeyResponse.data} />
              )}
            </Await>
          </Suspense>
        }
        hasShadow
      />
      <Suspense>
        <Await
          resolve={data.productsFeaturesByVendor}
          errorElement={textBanner}
        >
          {(productsFeaturesByVendorResponse) => {
            const availableLicenses = intersection(
              productsFeaturesByVendorResponse.data?.records ?? [],
              NEEDED_LICENSES,
            );

            return availableLicenses.length > 0 ? null : textBanner;
          }}
        </Await>
      </Suspense>
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'prerequisites',
            element: <Prerequisites content={prerequisites} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={salesforceBanner}
            srcSet={`${salesforceBanner} 1x, ${salesforceBanner2x} 2x`}
            alt="Salesforce"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Salesforce</Text>
          <Text as="p">
            Salesforce brings companies and customers together in the #1 CRM
            platform, sharing the news, events, and innovation you need.
            Salesforce unites your marketing, sales, commerce, service, and IT
            teams from anywhere with Customer 360—an integrated CRM platform
            that powers our entire suite of connected apps. With Customer 360,
            you can focus your employees on what’s important right now:
            stabilizing your business, reopening, and getting back to delivering
            exceptional customer experiences.
          </Text>
          <Text as="h3">Salesforce and TrustRadius</Text>
          <Text as="p">
            Enrich your sales pipeline, optimize your ABM strategy, and mitigate
            churn risk by integrating downstream intent data through the
            TrustRadius&apos; Salesforce Connecter app, which maps intent data
            directly to your Salesforce instance, automatically matching
            visitors on TrustRadius with opportunities in Salesforce at the
            account level.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Land and expand accounts matching your ICP</Text>
          <Text as="p">
            The TrustRadius Salesforce Connecter app not only allows you to
            engage target accounts already on your radar, it also helps generate
            new opportunities by reaching out to new accounts matching your
            ideal customer profile.
          </Text>
          <Text as="h3">Get a higher engagement rate</Text>
          <Text as="p">
            The detailed context of the activity provided helps B2B marketers
            and sellers take a personalized approach to prospects and customers
            for a higher engagement rate.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
