import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Switch.module.scss';

/**
 * Checkbox input component that looks like a switch
 */
export function Switch({
  className,
  size,
  orientation,
  labelClass,
  hideLabel,
  callback,
  checked,
  label,
  labelPosition = 'before',
  disabled = false,
}) {
  const isLabelAfter = labelPosition === 'after';

  const handleChange = (event) => {
    callback(event.target.checked);
  };

  return (
    <label
      className={classNames(
        className,
        styles.label,
        styles[orientation],
        styles[size],
        disabled && styles.disabled,
      )}
    >
      {!isLabelAfter && (
        <span
          className={classNames(
            labelClass ? labelClass : styles['label-text'],
            hideLabel ? styles['sr-only'] : null,
          )}
        >
          {label}
        </span>
      )}
      <div
        className={classNames(
          styles.switch,
          isLabelAfter && styles['label-right'],
        )}
      >
        {}
        <input
          type="checkbox"
          className={styles.checkbox}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
        />
        <span className={styles.slider} />
      </div>
      {isLabelAfter && (
        <span
          className={classNames(
            labelClass ? labelClass : styles['label-text'],
            hideLabel ? styles['sr-only'] : null,
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
}

Switch.propTypes = {
  /** Function for handling state change and other consequences of the Switch being changed */
  callback: PropTypes.func.isRequired,
  /** text label for the Switch */
  label: PropTypes.string.isRequired,
  /** boolean for checked state value */
  checked: PropTypes.bool,
  /** Choose the size of the Switch */
  size: PropTypes.string,
  /** Choose the orientation of the Switch (row or column) */
  orientation: PropTypes.string,
  /** Change the class for the label text (useful for styling it differently than the default) */
  labelClass: PropTypes.string,
  /** Add a className to the containng element */
  className: PropTypes.string,
  /** Visually hide the label text (text will still be read by assistive devices) */
  hideLabel: PropTypes.bool,
  /** Position of label after or before */
  labelPosition: PropTypes.oneOf(['before', 'after']),
  disabled: PropTypes.bool,
};
