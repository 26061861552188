import PropTypes from 'prop-types';
import { CheckCircle2, XCircle } from 'lucide-react';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import styles from './ProgressItem.module.scss';

export function ProgressItem({
  heading,
  description,
  item,
  status,
  threshold,
  passed,
}) {
  const isDesktop = useMedia('(min-width:901px)');

  const icon = passed ? (
    <CheckCircle2 size="20" color="#007f74" aria-hidden />
  ) : (
    <XCircle size="20" color="#BD0909" aria-hidden />
  );

  return (
    <section
      className={classNames(styles.item, {
        [styles['not-desktop']]: !isDesktop,
      })}
    >
      <div className={styles['item-header']}>
        <h4 className={styles.heading}>
          {icon}
          <span className={styles['header-text']}>{heading}</span>
        </h4>
        <p
          className={classNames(styles.description, {
            [styles['not-desktop']]: !isDesktop,
          })}
        >
          {description}
        </p>
      </div>
      <dl
        className={classNames(styles.values, {
          [styles['not-desktop']]: !isDesktop,
        })}
      >
        <div className={styles.value}>
          {!isDesktop && (
            <p className={styles['not-desktop-title']}>Your Progress</p>
          )}
          <dt className={styles['sr-only']}>Your Progress</dt>
          <dd>
            <strong>{status}</strong> {item}
          </dd>
        </div>
        <div className={styles.value}>
          {!isDesktop && (
            <p className={styles['not-desktop-title']}>Top Rated</p>
          )}
          <dt className={styles['sr-only']}>Top Rated</dt>
          <dd>
            <strong>{threshold}</strong> {item}
          </dd>
        </div>
      </dl>
    </section>
  );
}

ProgressItem.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  threshold: PropTypes.string.isRequired,
  passed: PropTypes.bool.isRequired,
};
