import { createSelector } from '@reduxjs/toolkit';

export function selectCurrentVendorProductsById(state) {
  return state.vendors.currentVendorProductsById;
}

export const selectCurrentVendorPublishedProducts = createSelector(
  selectCurrentVendorProductsById,
  (currentVendorProductsById) =>
    Object.values(currentVendorProductsById).filter(
      (product) => product.published,
    ),
);

export const selectCurrentVendorPublishedProductsCount = createSelector(
  selectCurrentVendorPublishedProducts,
  (currentVendorPublishedProducts) => currentVendorPublishedProducts.length,
);
