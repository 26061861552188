import { useSelector } from 'react-redux';
import { useIntentRegistration, useHaveLicense } from 'hooks';
import { Zoominfo } from './Zoominfo';

export function ZoominfoContainer() {
  const vendorId = useSelector((state) => state.vendors.currentVendor._id);
  const zoominfo = useSelector(
    (state) => state.vendors?.currentVendor?.zoominfo,
  );

  const intentLicenses = 'api-vi-category-level,api-vi-product-level';
  const { hasLicense } = useHaveLicense(vendorId, intentLicenses);

  const { intentRegistration } = useIntentRegistration(
    vendorId,
    'zoominfo',
    true,
  );

  return (
    <Zoominfo
      vendorId={vendorId}
      hasLicense={hasLicense}
      zoominfo={zoominfo || {}}
      zoominfoIntentRegistration={intentRegistration}
    />
  );
}
