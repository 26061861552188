import { Button } from '@trustradius/radiant-ui';
import { ExternalLink } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { WebhookCard } from 'components/WebhookCard/WebhookCard';
import { CircleIcon } from 'components/CircleIcon';
import { LeadsHubEmptyState } from 'components/LeadsHubEmptyState';
import { CreateWebhookModal } from 'components/LeadsV2Modals';

export function LeadsV2Webhooks() {
  const { search } = useLocation();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const webhook = {
    title: 'Webex suite webhook',
    updatedAt: new Date(),
    connectedLeadForm: 'Test Lead Form',
    destination: 'Salesforce',
  };

  const systems = [
    { id: 'salesforce', name: 'Salesforce' },
    { id: 'other', name: 'Other System' },
  ];

  const leadForms = [
    { id: 'test', name: 'Test Lead Form' },
    { id: 'other', name: 'Other Lead Form' },
  ];

  return (
    <>
      <SecondaryPageHeader
        title="Leads Webhooks"
        subtitle="Engage new leads immediately by automatically delivering TrustRadius leads data to your third-party systems."
        actionBlock={
          <CreateWebhookModal systems={systems} leadForms={leadForms} />
        }
        hasShadow
        maxWidth="1532px"
      />
      {!queryString.get('isEmpty') && (
        <>
          <WebhookCard {...webhook} />
          <WebhookCard {...webhook} hasError />
          <WebhookCard {...webhook} hasUpdatedFields />
        </>
      )}
      {queryString.get('isEmpty') && (
        <LeadsHubEmptyState
          icon={<CircleIcon iconType="webhook" />}
          title="You haven’t created any webhooks yet"
          description="Create a webhook to start sending leads data to your internal systems."
          button={
            <Button variant="secondary">
              Learn more
              <ExternalLink />
            </Button>
          }
          button2={<CreateWebhookModal />}
        />
      )}
    </>
  );
}
