import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  matchPath,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uniq } from 'underscore';
import { Main } from 'components/Main';
import { SideBar } from 'components/SideBar';
import { VendorSwitcher, useVendorSwitcher } from 'components/VendorSwitcher';
import { LeftMenuWithLicenses } from 'components/LeftMenu';
import { Spinner } from 'components/Spinner';
import { MobileView } from 'components/SecondaryPage';
import {
  changeCurrentVendor,
  changeCurrentVendorProduct,
  fetchVendorProductsSuccess,
  fetchVendorsByIdSuccess,
  removeVendorPoducts,
} from 'store/actions/vendorActions';
import { fetchVendorById, fetchVendorProducts } from 'store/apis/vendorApi';
import {
  setUser,
  setUserLoading,
  setUserRoles,
} from 'store/actions/userActions';
import { useAclGrants } from './useAclGrants';
import styles from './VendorHome.module.scss';

export function VendorHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [redirectPath, setRedirectPath] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useMedia('(min-width:901px)');
  const isPhone = useMedia('(max-width:600px)');
  const [isVendorSwitcherActive] = useVendorSwitcher();
  const [aclGrants, isFetchingAclGrants] = useAclGrants();
  const { productId, vendorId } = useParams();

  function isValidMongoId(stringId) {
    // Is valid hexadecimal string
    const regex = new RegExp(/^[a-f\d]{24}$/, 'i');
    return regex.test(stringId);
  }

  useEffect(() => {
    const fetchData = async () => {
      const hasGrantRecords = aclGrants.length > 0;
      // If user has no grants we send them to the /request-access page
      if (!hasGrantRecords) {
        setRedirectPath('/request-access');
        setIsLoading(false);
        return;
      }
      let currentVendorId = aclGrants[0].vendor?._id;
      const vendorsFromGrants = aclGrants.map((record) => record.vendor);
      let vendors = uniq(vendorsFromGrants, false, (vendor) => vendor._id);

      if (
        (productId && !isValidMongoId(productId)) ||
        (vendorId && !isValidMongoId(vendorId))
      ) {
        setError('Bad request');
      }

      if (vendorId) {
        if (aclGrants.some((grant) => grant.vendor._id === vendorId)) {
          currentVendorId = vendorId;
        } else if (
          aclGrants.some((grant) => grant.role.name === 'vp:tr_admin')
        ) {
          const vendorResponse = await fetchVendorById(vendorId);
          const vendor = vendorResponse?.data?.records?.[0];
          if (vendor) {
            currentVendorId = vendorId;
            vendors = [vendor];
          }
        }
      }

      if (!currentVendorId) {
        setError('No vendor id on ACL grants');
        setIsLoading(false);
        return;
      }

      dispatch(fetchVendorsByIdSuccess(vendors));
      dispatch(
        changeCurrentVendor(
          vendors.find((vendor) => vendor._id === currentVendorId),
        ),
      );
      dispatch(setUser(aclGrants[0].user));
      dispatch(setUserRoles(aclGrants));
      dispatch(setUserLoading(false));

      const { data: productData } = await fetchVendorProducts(currentVendorId);
      const hasProductRecords = productData?.records?.length > 0;
      if (!hasProductRecords) {
        dispatch(removeVendorPoducts());
        setRedirectPath(`${currentVendorId}/welcome`);
        setIsLoading(false);
        return;
      }
      let localStorageProductId = localStorage.getItem('currentProductId');
      if (
        !productData?.records
          ?.map((product) => product?._id)
          ?.includes(localStorageProductId)
      ) {
        localStorageProductId = null;
      }
      const currentProductId =
        productId || localStorageProductId
          ? productId || localStorageProductId
          : productData.records[0]._id;
      dispatch(fetchVendorProductsSuccess(productData.records));
      dispatch(changeCurrentVendorProduct(currentProductId));
      localStorage.setItem('currentProductId', currentProductId);

      setIsLoading(false);

      setRedirectPath(`${currentVendorId}/dashboard/${currentProductId}`);
    };

    if (!isFetchingAclGrants) {
      fetchData();
    }
  }, [
    aclGrants,
    isFetchingAclGrants,
    dispatch,
    productId,
    vendorId,
    location.search,
  ]);

  useEffect(() => {
    if (error !== null) {
      throw new Error(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      !isLoading &&
      redirectPath.length > 0 &&
      location.pathname.match(/^\/vendor[/]?$/)
    ) {
      navigate(redirectPath);
    }
  }, [isLoading, redirectPath, navigate, location]);

  if (isLoading) return <Spinner />;

  const mobilelessPaths = [
    '/vendor/:vendorId/awards/:award/*',
    '/vendor/:vendorId/integrations/:integration/*',
    '/vendor/:vendorId/buyers/*',
  ];

  if (
    isPhone &&
    mobilelessPaths.some((path) => matchPath(path, location.pathname))
  ) {
    return <MobileView />;
  }

  return (
    <div className={styles.container}>
      {isDesktop && (
        <SideBar>
          <VendorSwitcher />
          {!isVendorSwitcherActive && <LeftMenuWithLicenses />}
        </SideBar>
      )}
      <Main>
        <Outlet />
      </Main>
    </div>
  );
}
