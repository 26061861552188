import PropTypes from 'prop-types';
import { AlertTriangle } from 'lucide-react';
import styles from './ErrorList.module.scss';

export function ErrorList({ errorList, errorMessage }) {
  function renderMessage() {
    if (errorMessage && errorMessage.length > 0) {
      return <p className={styles.message}>{errorMessage}</p>;
    } else {
      return null;
    }
  }

  function errorItem(err, idx) {
    return <li key={`${err.param}_error_${idx}`}>{err.msg}</li>;
  }

  function renderErrorList() {
    if (errorList && errorList.length > 0) {
      return (
        <ul className={styles['error-list']}>{errorList.map(errorItem)}</ul>
      );
    } else {
      return null;
    }
  }

  function containerStyle() {
    if (
      (!errorMessage || errorMessage.length === 0) &&
      (!errorList || errorList.length === 0)
    ) {
      return 'error-container hidden';
    } else {
      return 'error-container';
    }
  }

  return (
    <div className={styles[containerStyle()]} role="alert">
      <AlertTriangle color="#ed3755" />
      <div className={styles['text-content']}>
        {renderMessage()}
        {renderErrorList()}
      </div>
    </div>
  );
}

ErrorList.propTypes = {
  errorList: PropTypes.arrayOf(
    PropTypes.shape({
      param: PropTypes.string,
      msg: PropTypes.string,
    }),
  ),
  errorMessage: PropTypes.string,
};
