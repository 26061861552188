import { Navigate, Outlet } from 'react-router-dom';
import _ from 'underscore';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PermissionsContext } from 'permissions/PermissionsContext';

export function ProtectedLayout({
  allowedWithPermissions,
  allowedForCustomers,
  unAuthorizedRedirectTo,
}) {
  const userPermissions = useContext(PermissionsContext);
  const userRoles = useSelector((state) => state?.users?.rolesAllNames);
  const currentVendor = useSelector((state) => state?.vendors?.currentVendor);
  const isAllowed =
    _.every(allowedWithPermissions, (permission) => {
      return _.includes(userPermissions, permission);
    }) ||
    userRoles?.includes('vp:tr_admin') ||
    (allowedForCustomers && currentVendor?.isCustomer);

  if (!isAllowed) {
    return <Navigate to={unAuthorizedRedirectTo} />;
  }

  return <Outlet />;
}

ProtectedLayout.propTypes = {
  allowedWithPermissions: PropTypes.arrayOf(PropTypes.string),
  allowedForCustomers: PropTypes.bool,
  unAuthorizedRedirectTo: PropTypes.string,
};
