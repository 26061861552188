export function useLeadCards(isEmpty, vendorProducts) {
  const appliedWebhooks = [
    {
      name: 'test webhook 1',
      id: '1',
    },
    {
      name: 'test webhook 1',
      id: '1',
    },
  ];

  const cards = [
    {
      id: '1',
      title: 'Lead Form Name',
      appliedProducts: [
        ...Object.keys(vendorProducts).map(
          (productId) => vendorProducts[productId],
        ),
      ],
      appliedWebhooks,
      updated: new Date().toISOString(),
    },
    {
      id: '2',
      title: 'Lead Form Name 2',
      appliedProducts: [
        ...Object.keys(vendorProducts).map(
          (productId) => vendorProducts[productId],
        ),
      ],
      appliedWebhooks,
      updated: new Date().toISOString(),
    },
  ];

  return isEmpty ? [] : cards;
}
