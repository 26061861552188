function FacebookCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <title>Facebook</title>
      <path
        d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm4,13.64H19.82a.73.73,0,0,0-.73.72v2.19H22l-.73,2.9H19.09v5.82H16.18V19.45H14v-2.9h2.18V14.36a3.64,3.64,0,0,1,3.64-3.63H22Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default FacebookCircle;
