/* eslint-disable import/order */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Download } from 'lucide-react';
import classNames from 'classnames';
import moment from 'moment';
import { Button } from '@trustradius/radiant-ui';
import { Switcher } from 'components/Switcher';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { Pagination, Table } from 'components/Table';
import { EmptyTable } from 'components/EmptyTable';
import { SearchFilter, DateFilter } from 'pages/LeadActivities/Filters';
import styles from './LeadActivities.module.scss';
import { withVendorLeadsData } from 'hoc/withVendorLeadsData';

function EmptyTableLeadFormNotEnabled() {
  return (
    <EmptyTable
      iconName="mail"
      title="You haven’t enabled your lead form yet"
      description={
        <>
          <Link to="/[VENDOR-ID]/buyers/lead-form">Enable your lead form</Link>{' '}
          to begin receiving leads.
        </>
      }
    />
  );
}

function EmptyTableNoLeads() {
  return (
    <EmptyTable
      iconName="filter"
      title="You haven’t generated any leads yet"
      description={
        <>
          <a
            href="https://www.trustradius.com/vendor-blog/improving-product-profile-guide"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>{' '}
          about optimizing your product listings to increase your lead
          generation.
        </>
      }
    />
  );
}

function EmptyTableNoResults() {
  return (
    <EmptyTable
      iconName="search"
      title="No results found"
      description="Your search did not match any leads."
    />
  );
}

function renderTable({ data, enabled, isDesktop, tableProps }) {
  if (!enabled) {
    return <EmptyTableLeadFormNotEnabled />;
  }

  if (data.length === 0) {
    return tableProps.totalLeads === 0 ? (
      <EmptyTableNoLeads />
    ) : (
      <EmptyTableNoResults />
    );
  }

  return (
    <Table
      additionalStyles={classNames(styles.table, {
        [styles.hidden]: !isDesktop,
      })}
      getTableProps={tableProps.getTableProps}
      getTableBodyProps={tableProps.getTableBodyProps}
      headerGroups={tableProps.headerGroups}
      page={tableProps.page}
      prepareRow={tableProps.prepareRow}
    />
  );
}

export function LeadActivities({
  enabled = true,
  actionBlockEnabled = false,
  toolbarEnabled = false,
  paginationEnabled = false,
  leads,
  sortHandler,
  timeFilterValue,
  searchWord,
  defaultSortField,
  isDesc,
}) {
  const [timeFilter, setTimeFilter] = useState(timeFilterValue || '');
  const [search, setSearch] = useState(searchWord || '');

  const isDesktop = useMedia('(min-width:901px)');
  const isDisabled = false;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        disableSortBy: true,
        accessor: (row) => {
          return `${row?.firstName} ${row?.lastName}`;
        },
        id: 'name',
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Product',
        disableSortBy: true,
        accessor: (row) => {
          return row.productName;
        },
      },
      {
        Header: 'Submission date',
        accessor: 'createdAt',
        Cell: (cell) => {
          return moment(cell?.row?.values?.createdAt).format('MMM DD, YYYY');
        },
        filter: 'greaterThanNumDays',
        id: 'createdAt',
      },
      {
        Header: '',
        accessor: (row) => {
          return <a href={`leads-activity/${row.id}`}>See details</a>;
        },
        disableSortBy: true,
        id: 'actions',
      },
    ],
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      greaterThanNumDays: (rows, id, filterValue) => {
        if (!filterValue) {
          return rows;
        }
        return rows.filter((row) => {
          return (
            moment(row.values.created).unix() >
            moment().subtract(filterValue, 'days').unix()
          );
        });
      },
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: { pageIndex, sortBy },
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data: leads || [],
      filterTypes,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      manualFilters: true,
      autoResetFilters: false,
      pageSize: 10,
      initialState: {
        sortBy: [
          {
            id: defaultSortField,
            desc: isDesc,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (sortBy?.length > 0) {
      sortHandler(sortBy[0]?.id, sortBy[0]?.desc ? 'DESC' : 'ASC');
    } else {
      sortHandler(defaultSortField, 'ASC');
    }
  }, [sortBy, sortHandler, defaultSortField]);

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(['company.name']);
    }
  }, [isDesktop, setHiddenColumns]);

  const ActionBlock = useMemo(() => {
    return (
      <div className={styles.actions}>
        <Switcher data={leads} />
        <Button size="large" isDisabled={isDisabled}>
          <Download /> Download CSV
        </Button>
      </div>
    );
  }, [isDisabled, leads]);

  const leadsQuantityText = ` lead${leads?.length === 1 ? ' ' : 's '}generated`;

  return (
    <>
      <SecondaryPageHeader
        title="Leads Activity"
        subtitle="Engage qualified in-market buyers with TrustRadius Leads"
        actionBlock={actionBlockEnabled && ActionBlock}
      />
      <div className={styles.body}>
        <p className={styles['leads-quantity']}>
          {leads?.length}
          {leadsQuantityText}
        </p>
        <div className={styles.container}>
          {toolbarEnabled && (
            <div className={styles.toolbar}>
              <DateFilter
                isDisabled={isDisabled}
                setFilter={setTimeFilter}
                filterValue={timeFilter}
              />
              <SearchFilter
                isDisabled={isDisabled}
                searchFilter={search}
                setGlobalFilter={setSearch}
              />
            </div>
          )}
          <div
            className={classNames({
              [styles['table-wrap']]: !leads?.length,
              [styles['table-wrap-empty']]: leads?.length === 0,
            })}
          >
            {leads &&
              renderTable({
                data: leads,
                enabled,
                isDesktop,
                tableProps: {
                  getTableProps,
                  getTableBodyProps,
                  headerGroups,
                  page,
                  prepareRow,
                  totalLeads: leads?.length,
                },
              })}
          </div>
        </div>
        {leads?.length > 0 && enabled && paginationEnabled && (
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
          />
        )}
      </div>
    </>
  );
}

LeadActivities.propTypes = {
  enabled: PropTypes.bool,
  actionBlockEnabled: PropTypes.bool,
  toolbarEnabled: PropTypes.bool,
  paginationEnabled: PropTypes.bool,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
    }),
  ),
  sortHandler: PropTypes.func.isRequired,
  timeFilterValue: PropTypes.string.isRequired,
  searchWord: PropTypes.string.isRequired,
  defaultSortField: PropTypes.string.isRequired,
  isDesc: PropTypes.bool.isRequired,
};

export const LeadActivitiesWithData = withVendorLeadsData(LeadActivities);
