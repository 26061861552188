import React from 'react';
import ReactBootstrapToast from 'react-bootstrap/Toast';
import classNames from 'classnames';
import styles from './Toast.module.scss';

/**
 * TrustRadius wrapped toast component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Toast = React.forwardRef((props, ref) => {
  /**
   * Toast styles
   * variant: danger | success
   */
  const {
    className,
    heading,
    children,
    variant,
    closeButton,
    onClose,
    ...otherProps
  } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles.toast);
  // Provide default variant
  return (
    <ReactBootstrapToast
      ref={ref}
      className={[classes, variant ? styles[`toast--${variant}`] : '']}
      onClose={onClose}
      {...otherProps}
    >
      {heading && (
        <ReactBootstrapToast.Header
          closeButton={closeButton || false}
          className={[
            styles['toast-heading'],
            variant ? styles[`toast-heading--${variant}`] : '',
          ]}
        >
          {heading}
        </ReactBootstrapToast.Header>
      )}
      {children && (
        <ReactBootstrapToast.Body className={styles['toast-body']}>
          {children}
        </ReactBootstrapToast.Body>
      )}
    </ReactBootstrapToast>
  );
});

Toast.displayName = 'Toast';
// eslint-disable-next-line react/forbid-foreign-prop-types
Toast.propTypes = ReactBootstrapToast.propTypes;
