import classNames from 'classnames';
import moment from 'moment';
import _ from 'underscore';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTopRatedTracks } from 'store/apis/awardsApi';
import { PageBody } from 'components/PageBody';
import { awardStatus, TopRatedAwardTrackCard } from 'components/Awards';
import { AwardDetailsHeader } from 'components/AwardDetailsHeader';
import { Skeleton } from 'components/Skeleton';
import { Icon, CsvLink } from 'components/TrustradiusUICore';
import { formatTitle, generateBadgeUrlForAward } from 'utils/awards';
import { useSettings } from 'settings';
import { csvHeaders } from './data';
import styles from './TopRatedDetails.module.scss';

const GRAY_70 = '#65656F';
const ALL_TRACKS_FILTER = { value: '', text: 'All' };
const WINNER_STATUS = {
  [awardStatus.onTrack]: true,
  [awardStatus.notOnTrack]: false,
  [awardStatus.awarded]: true,
  [awardStatus.notAwarded]: false,
};

function getModifiedAward(award) {
  const year = Number(moment(award.calculatedEndDate).format('YYYY'));
  return {
    ...award,
    year,
  };
}

function filterByStatus(tracks, status) {
  let filteredTracks = [];
  const isAwardStatusAll = status === ALL_TRACKS_FILTER.value;
  if (isAwardStatusAll) {
    return tracks;
  }
  const groupedByProduct = _.groupBy(tracks, (track) => track.product._id);
  for (const productId in groupedByProduct) {
    if (!groupedByProduct[productId]) {
      continue;
    }
    const isWinnerStatus = WINNER_STATUS[status];
    const productTracks = groupedByProduct[productId];

    const isMetStatus = isWinnerStatus
      ? productTracks.some((track) => track.isWinner)
      : productTracks.every((track) => !track.isWinner);
    if (isMetStatus) {
      filteredTracks = [...filteredTracks, ...productTracks];
    }
  }

  return filteredTracks;
}

function getAwards(tracks) {
  const awards = {};
  for (const track of tracks) {
    if (track.award) {
      const { award } = track;
      if (!awards[award._id]) {
        const modifiedAward = getModifiedAward(award);
        awards[award._id] = modifiedAward;
      }
    }
  }
  return Object.values(awards).filter((award) => award.year >= 2022);
}

function isCurrentAward(award) {
  const now = moment();
  if (Number(now.format('YYYY')) + 1 === award.year) {
    return true;
  } else if (
    Number(now.format('YYYY')) === award.year &&
    now.isBefore(award.calculatedEndDate)
  ) {
    return true;
  } else {
    return false;
  }
}

function isPriorAward(award) {
  if (isEmpty(award)) {
    return true;
  }
  const currentYear = Number(moment().format('YYYY'));
  return award.year < currentYear;
}

function calculateStatus(tracks, vendor) {
  const now = moment().format('L');
  for (const track of tracks) {
    track.now = now;
    track.ratingProgress = Number(track.ratingProgress.toFixed(1));
    track.relevancyProgress = Number(track.relevancyProgress.toFixed(2));
    track.recencyMatch = track.recencyProgress >= track.award.recencyCriteria;
    track.ratingMatch =
      Number(track.ratingProgress.toFixed(1)) >= track.award.ratingCriteria;
    track.relevancyMatch =
      Number(track.relevancyProgress.toFixed(2)) >=
      track.award.relevancyCriteria;
    track.isWinner = Boolean(
      track.recencyMatch && track.ratingMatch && track.relevancyMatch,
    );
    track.meetingCriteria = track.isWinner ? 'Yes' : 'No';
    track.vendor = vendor;
    track.award = getModifiedAward(track.award);

    if (isCurrentAward(track.award)) {
      track.status = track.isWinner
        ? awardStatus.onTrack
        : awardStatus.notOnTrack;
      track.isCurrentAwardTrack = true;
    } else {
      track.status = track.isWinner
        ? awardStatus.awarded
        : awardStatus.notAwarded;
      track.isCurrentAwardTrack = false;
    }
  }
}

function getDefaultQuantity() {
  return {
    matched: 0,
    unMatched: 0,
  };
}

let mappedQuantity = {
  'no-data': getDefaultQuantity(),
};

function calculateQuantity(tracks) {
  mappedQuantity = {
    'no-data': getDefaultQuantity(),
  };
  if (!tracks || !tracks.length) {
    return mappedQuantity;
  }

  const groupedByAward = _.groupBy(tracks, (track) => track.award._id);

  for (const awardId in groupedByAward) {
    if (!awardId) {
      continue;
    }

    if (!mappedQuantity[awardId]) {
      mappedQuantity[awardId] = getDefaultQuantity();
    }

    const awardTracks = groupedByAward[awardId];

    const groupedByProduct = _.groupBy(
      awardTracks,
      (track) => track.product._id,
    );

    for (const productId in groupedByProduct) {
      if (!productId) {
        continue;
      }

      const productTracks = groupedByProduct[productId];
      const isMetProduct = productTracks.some(
        (productTrack) => productTrack.isWinner,
      );
      mappedQuantity[awardId][isMetProduct ? 'matched' : 'unMatched'] += 1;
    }
  }

  return mappedQuantity;
}

function formatTracks(tracks) {
  const productTracks = {};
  for (const track of tracks) {
    if (!track.product) {
      continue;
    }

    const { product, award } = track;
    if (!productTracks[product._id]) {
      productTracks[product._id] = {};
    }

    if (productTracks[product._id][award._id]) {
      productTracks[product._id][award._id].push(track);
    } else {
      productTracks[product._id][award._id] = [];
      productTracks[product._id][award._id].push(track);
    }
  }

  return productTracks;
}

function isEmpty(obj) {
  return !obj || !Object.keys(obj).length;
}

function sortByName(tracks) {
  tracks.sort(function (firstTrack, secondTrack) {
    if (firstTrack.product.name > secondTrack.product.name) {
      return 1;
    }
    if (firstTrack.product.name < secondTrack.product.name) {
      return -1;
    }
    return 0;
  });
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function copyArray(array) {
  const copiedArray = [];
  for (const obj of array) {
    const deepCopied = deepCopy(obj);
    copiedArray.push(deepCopied);
  }
  return copiedArray;
}

export function TopRatedDetails() {
  const { vendorId } = useParams();
  const [awards, setAwards] = useState([]);
  const [selectedAward, setSelectedAward] = useState({});
  const [rawAwardTracks, setRawAwardTracks] = useState([]);
  const [awardTracks, setAwardTracks] = useState({});
  const [searchName, setSearchName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [quantity, setQuantity] = useState(mappedQuantity);
  const [selectedProductCards, setSelectedProductCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  if (isError) {
    throw new Error('Error getting award tracks.');
  }

  const settings = useSettings();
  const currentVendor = useSelector((state) => state.vendors.currentVendor);

  const updateTracksListCb = useCallback((tracks) => {
    const formattedTracks = formatTracks(tracks);
    setAwardTracks(formattedTracks);
    expandCards(formattedTracks);
  }, []);

  const currentAwardTracks = useMemo(() => {
    if (
      isEmpty(selectedAward) ||
      isEmpty(currentVendor) ||
      !rawAwardTracks.length
    ) {
      return [];
    }
    const copiedTracks = copyArray(rawAwardTracks);
    sortByName(copiedTracks);
    calculateStatus(copiedTracks, currentVendor);
    const productsResult = calculateQuantity(copiedTracks);
    setQuantity(productsResult);
    updateTracksListCb(copiedTracks);

    const filteredTracks = copiedTracks.filter(
      (track) => selectedAward.year === track.award.year,
    );
    return filteredTracks;
  }, [updateTracksListCb, rawAwardTracks, currentVendor, selectedAward]);

  const isCardSelectedCb = useCallback(
    (productId) => {
      return selectedProductCards.includes(productId);
    },
    [selectedProductCards],
  );

  const onCardClickCb = useCallback(
    (productId) => {
      return function () {
        let selectedCards = [];
        if (isCardSelectedCb(productId)) {
          selectedCards = selectedProductCards.filter(
            (product) => product !== productId,
          );
        } else {
          selectedCards = [...selectedProductCards, productId];
        }
        setSelectedProductCards(selectedCards);
      };
    },
    [isCardSelectedCb, selectedProductCards],
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const tracksResponse = await getTopRatedTracks(vendorId);
        setRawAwardTracks(tracksResponse.data);

        const plainAwards = getAwards(tracksResponse.data);
        plainAwards.sort(
          (firstAward, secondAward) => secondAward.year - firstAward.year,
        );
        setAwards(plainAwards);
        setSelectedAward(plainAwards[0]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        console.error('Getting award tracks details error: ', error);
      }
    })();
  }, [vendorId]);

  function expandCards(tracks) {
    if (!isEmpty(tracks) && Object.keys(tracks).length <= 2) {
      const cardsToExpand = [];
      for (const product in tracks) {
        if (Object.prototype.hasOwnProperty.call(tracks, product)) {
          cardsToExpand.push(product);
        }
      }
      setSelectedProductCards(cardsToExpand);
    } else if (!isEmpty(tracks) && Object.keys(tracks).length > 2) {
      setSelectedProductCards([Object.keys(tracks)[0]]);
    }
  }

  function nameFilter(search) {
    return function (track) {
      return search ? new RegExp(search, 'gi').test(track.product.name) : true;
    };
  }

  function onSearchName(event) {
    const search = event.target.value;
    setSearchName(search);
    const nameFilterCb = nameFilter(search);
    let productTracks = filterByStatus(currentAwardTracks, searchStatus);
    productTracks = productTracks.filter(nameFilterCb);
    updateTracksListCb(productTracks);
  }

  function onSearchStatus(event) {
    const search = event.target.value;
    setSearchStatus(search);
    const nameFilterCb = nameFilter(searchName);
    let productTracks = filterByStatus(currentAwardTracks, search);
    productTracks = productTracks.filter(nameFilterCb);
    updateTracksListCb(productTracks);
  }

  function onMatchedFilter() {
    if (isEmpty(selectedAward)) {
      return;
    }

    if (
      searchStatus === awardStatus.onTrack ||
      searchStatus === awardStatus.awarded
    ) {
      updateTracksListCb(currentAwardTracks);
      setSearchStatus(ALL_TRACKS_FILTER.value);
      return;
    }

    const status = isCurrentAward(selectedAward)
      ? awardStatus.onTrack
      : awardStatus.awarded;

    const productTracks = filterByStatus(currentAwardTracks, status);
    setSearchStatus(status);
    updateTracksListCb(productTracks);
  }

  function onUnMatchedFilter() {
    if (isEmpty(selectedAward)) {
      return;
    }

    if (
      searchStatus === awardStatus.notOnTrack ||
      searchStatus === awardStatus.notAwarded
    ) {
      updateTracksListCb(currentAwardTracks);
      setSearchStatus(ALL_TRACKS_FILTER.value);
      return;
    }
    const status = isCurrentAward(selectedAward)
      ? awardStatus.notOnTrack
      : awardStatus.notAwarded;

    const productTracks = filterByStatus(currentAwardTracks, status);
    setSearchStatus(status);
    updateTracksListCb(productTracks);
  }

  function onSelectAward(event) {
    const award = awards.find(
      (awardRecord) => awardRecord.year === Number(event.target.value),
    );
    setSelectedAward(award);
    setSearchStatus(ALL_TRACKS_FILTER.value);
    updateTracksListCb(currentAwardTracks);
  }

  function getQuantity() {
    if (isEmpty(selectedAward) || isEmpty(quantity[selectedAward._id])) {
      return quantity['no-data'];
    }

    return quantity[selectedAward._id];
  }

  function getMatchedOption() {
    let result = '';
    if (isEmpty(selectedAward)) {
      return result;
    }
    if (isCurrentAward(selectedAward)) {
      result = awardStatus.onTrack;
    } else {
      result = awardStatus.awarded;
    }

    return result;
  }

  function getUnMatchedOption() {
    let result = '';
    if (isEmpty(selectedAward)) {
      return result;
    }
    if (isCurrentAward(selectedAward)) {
      result = awardStatus.notOnTrack;
    } else {
      result = awardStatus.notAwarded;
    }

    return result;
  }

  function getAwardDetails() {
    if (isLoading || isEmpty(selectedAward)) {
      return <></>;
    }

    return (
      <>
        <img
          src={
            generateBadgeUrlForAward(selectedAward, settings.staticCdnHostName)
              ?.x1
          }
          alt={selectedAward?.title || 'Top Rated award'}
          height="80"
          width="80"
        />
        {formatTitle(selectedAward.title)}
      </>
    );
  }

  function determineTracksStatus() {
    if (!isEmpty(selectedAward) && !isCurrentAward(selectedAward)) {
      return {
        matched: 'Awarded',
        unmatched: 'Not Awarded',
      };
    }
    return {
      matched: 'Products On Track',
      unmatched: 'Products Not On Track',
    };
  }

  function getCsvFileName() {
    if (isEmpty(selectedAward) || isEmpty(currentVendor)) {
      return 'No-selected-award.csv';
    }
    return `${currentVendor.slug}-${selectedAward.awardType}-${selectedAward.year}.csv`;
  }

  const cards = useMemo(() => {
    if (isLoading) {
      return (
        <>
          {Array(3)
            .fill()
            .map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles['award-track-card']}>
                <Skeleton className={styles.loader} />
              </div>
            ))}
        </>
      );
    }
    return Object.keys(awardTracks).map((productId) => (
      <div
        onClick={onCardClickCb(productId)}
        key={productId}
        className={styles['award-track-card']}
        onKeyDown={onCardClickCb(productId)}
        data-testid="track-card"
      >
        <TopRatedAwardTrackCard
          awardTracks={awardTracks[productId][selectedAward._id] || []}
          isExpanded={
            isCardSelectedCb(productId) && !isPriorAward(selectedAward)
          }
          possibleExpanding={!isPriorAward(selectedAward)}
        />
      </div>
    ));
  }, [isCardSelectedCb, onCardClickCb, isLoading, awardTracks, selectedAward]);

  const countedQuantity = getQuantity();
  const matchedOption = getMatchedOption();
  const unMatchedOption = getUnMatchedOption();

  const yearValue = selectedAward ? selectedAward.year : '';
  const backLink = `/vendor/${vendorId}/awards`;

  function headerContent() {
    return (
      <>
        <div className={styles['header-metrics-wrapper']}>
          <span onClick={onMatchedFilter} onKeyDown={onMatchedFilter}>
            {countedQuantity.matched}
          </span>
          <div className={styles['header-matched']}>
            {determineTracksStatus().matched}
          </div>
        </div>
        <div className={styles['header-metrics-wrapper']}>
          <span onClick={onUnMatchedFilter} onKeyDown={onUnMatchedFilter}>
            {countedQuantity.unMatched}
          </span>
          <div className={styles['header-unmatched']}>
            {determineTracksStatus().unmatched}
          </div>
        </div>
        <CsvLink
          className="button button-primary round"
          data={currentAwardTracks}
          headers={csvHeaders}
          disabled={!currentAwardTracks.length}
          onClick={() => {
            // Stop the handling of component if no data to download
            if (!currentAwardTracks.length) {
              return false;
            }
          }}
          filename={getCsvFileName()}
        >
          <div>Download Report</div>
        </CsvLink>
      </>
    );
  }

  return (
    <>
      <AwardDetailsHeader
        backLink={backLink}
        awardInfo={getAwardDetails()}
        content={headerContent()}
      />
      <PageBody className={styles['page-body']}>
        <div className={styles['results-block']}>
          <div className={styles['filter-block']}>
            <div className={styles['search-wrapper']}>
              {}
              <input
                onChange={onSearchName}
                className={classNames(styles['search-text'], {
                  [styles.active]: Boolean(searchName),
                })}
                id="search"
                name="search"
                type="text"
                value={searchName}
                placeholder="Search"
                autoComplete="on"
              />
              <Icon name="search" width="20" height="20" color={GRAY_70} />
            </div>
            <div className={styles['right-filter']}>
              <div className={styles['status-filter']}>
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  name="status"
                  onChange={onSearchStatus}
                  value={searchStatus}
                >
                  <option value={ALL_TRACKS_FILTER.value}>
                    {ALL_TRACKS_FILTER.text}
                  </option>
                  <option value={matchedOption}>{matchedOption}</option>
                  <option value={unMatchedOption}>{unMatchedOption}</option>
                </select>
              </div>
              <div className={styles['year-filter']}>
                <label htmlFor="year">Award Year:</label>
                <select
                  id="year"
                  name="year"
                  onChange={onSelectAward}
                  value={yearValue}
                >
                  {awards.map((award) => (
                    <option key={award.year} value={award.year}>
                      {award.year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={styles['award-track-cards']}>{cards}</div>
        </div>
        {/* <div className={styles['info-block']} /> */}
      </PageBody>
    </>
  );
}
