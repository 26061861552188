import { Button, Text } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import { CircleIcon } from 'components/CircleIcon';
import { Modal } from 'components/Modal';
import styles from 'components/LeadsV2Modals/DeleteWebhookModal/DeleteWebhookModal.module.scss';

export function DeleteWebhookModal({ trigger, isOpen, setIsOpen }) {
  return (
    <Modal
      className={styles['delete-modal']}
      trigger={trigger}
      isOpen={isOpen}
      icon={
        <div className={styles['delete-icon']}>
          <CircleIcon
            iconType="delete"
            color="#F44336"
            backgroundColor="#FFDEEA"
            backgroundColorFade="#FEEFF4"
          />
        </div>
      }
      title={
        <Text as="span" className={styles.gray120} weight="semibold" size="400">
          Delete webhook
        </Text>
      }
      description={
        <Text className={styles.gray100} data-testid="description" as="p">
          Are you sure you want to delete this webhook and all associated
          fields? This action can’t be undone.
        </Text>
      }
      closeHandler={() => setIsOpen(false)}
      actionButtons={[
        <div key="cancel-delete" className={styles['cancel-button']}>
          <Button variant="secondary-warning" size="large">
            Cancel
          </Button>
        </div>,
        <div key="confirm-delete" className={styles['delete-button']}>
          <Button variant="primary-warning" size="large">
            Delete
          </Button>
        </div>,
      ]}
    />
  );
}

DeleteWebhookModal.propTypes = {
  trigger: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
