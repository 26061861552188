import { useInterval } from 'react-use';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { getUserGrants } from 'store/apis/usersApi';

const MAX_RETRIES = 3;
const DELAY = 5000;

export function useAclGrants() {
  const [counter, setCounter] = useState(0);
  const [aclGrants, setAclGrants] = useState([]);

  const isRetrying = counter < MAX_RETRIES && aclGrants.length === 0;

  const fetchAclGrants = useCallback(async () => {
    const { data } = await getUserGrants();
    console.log('Fetched acl grants.', { data });
    if (data?.records?.length > 0) {
      setAclGrants(data?.records);
    } else {
      setCounter((prevCounter) => prevCounter + 1);
    }
  }, []);

  useEffect(() => {
    console.log('useAclGrants => starting timer');
    console.time('fetchAclGrants');
    fetchAclGrants();
  }, [fetchAclGrants]);

  useEffect(() => {
    if (!isRetrying) {
      console.timeEnd('fetchAclGrants');
    }
  }, [isRetrying]);

  useInterval(
    () => {
      fetchAclGrants();
    },
    isRetrying ? DELAY : null,
  );

  return useMemo(() => [aclGrants, isRetrying], [aclGrants, isRetrying]);
}
