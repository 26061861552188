import React from 'react';

export function Arrow() {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17855 5.37318C5.79541 5.72711 5.20459 5.72711 4.82145 5.37318L0.882517 1.73455C0.213978 1.11698 0.65094 -1.07959e-06 1.56107 -1.00002e-06L9.43893 -3.11318e-07C10.3491 -2.31752e-07 10.786 1.11698 10.1175 1.73455L6.17855 5.37318Z"
        fill="#191919"
      />
    </svg>
  );
}
