import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Spinner } from 'components/Spinner';
import { getURL } from '../../store/apis/reportingApi';
import styles from './LookerContainer.module.scss';

export function LookerContainer({ dashboardId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [embedURL, setEmbedURL] = useState('');
  const currentVendor = useSelector((state) => state.vendors?.currentVendor);

  useEffect(() => {
    const lookerReports = {
      'multi-product-dashboard': 'prod_vendor_pg_core::multi_product_dashboard',
      'single-product-dashboard':
        'prod_vendor_pg_core::single_product_dashboard',
      'competitor-dashboard': 'prod_vendor_pg_core::competitor_dashboard',
    };

    if (!lookerReports[dashboardId]) {
      throw new Error(404, 'Not Found');
    }

    const fetchData = async () => {
      try {
        const response = await getURL(
          lookerReports[dashboardId],
          currentVendor?._id,
        );

        if (response?.status === 200) {
          setEmbedURL(response.data);
        } else {
          throw new Error(response.status);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentVendor, dashboardId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="lookercontainer" className={styles['iframe-container']}>
      <iframe src={embedURL} title="lookeriframe" className={styles.iframe} />
    </div>
  );
}

LookerContainer.propTypes = {
  dashboardId: PropTypes.string.isRequired,
};
