import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Image.module.scss';

const defaultProps = {
  alt: '',
};

/**
 * TrustRadius wrapped Image component
 * Implemented using basic image tag.
 * Added local styles for this component as well
 */
export function Image(props) {
  const { className, alt, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles.image);
  // Provide default variant
  return <img alt={alt} {...otherProps} className={classes} />;
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = defaultProps;
