import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { InteractiveDemosCard } from './InteractiveDemosCard';

export function InteractiveDemosCardContainer({ product }) {
  if (
    !product ||
    isEmpty(product) ||
    product.productDemos?.files?.some(
      ({ demoType }) => demoType === 'interactive',
    )
  ) {
    return null;
  }

  return <InteractiveDemosCard product={product} />;
}

InteractiveDemosCardContainer.propTypes = {
  product: PropTypes.shape({
    productDemos: PropTypes.shape({
      files: PropTypes.arrayOf(
        PropTypes.shape({
          demoType: PropTypes.string,
        }).isRequired,
      ),
    }),
  }),
};
