import { axiosRequestWithAuthToken } from './axios_utils';

export const setWelcomeJourneyToUser = async (userId) => {
  try {
    const options = {
      url: `/api/users/${userId}`,
      method: 'PUT',
    };

    const data = await axiosRequestWithAuthToken(options);
    return data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getAuthenticatedUser = async () => {
  try {
    const options = {
      url: `/api/user`,
      method: 'GET',
    };

    const data = await axiosRequestWithAuthToken(options);
    return data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getUserGrants = async () => {
  try {
    const options = {
      url: `/api/acl_grants`,
      method: 'GET',
    };

    const response = await axiosRequestWithAuthToken(options);
    return response;
  } catch (error) {
    console.error('Occurred error getting user grants: ', error);
    return { data: {}, error };
  }
};

export const logoutUser = async () => {
  try {
    const options = {
      url: `/api/user/logout`,
      method: 'GET',
    };

    const response = await axiosRequestWithAuthToken(options);
    return response;
  } catch (error) {
    console.error('Occurred error logging out user: ', error);
    return { data: {}, error };
  }
};
