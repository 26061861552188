import { combineReducers } from 'redux';
import globalState from './globalStateReducers';
import vendors from './vendorReducer';
import products from './productReducer';
import competitors from './competitorsReducer';
import watchers from './watchersReducer';
import errors from './errorsReducer';
import users from './userReducer';

export default combineReducers({
  globalState,
  vendors,
  products,
  competitors,
  watchers,
  errors,
  users,
});
