import { Alert, Button, Text } from '@trustradius/radiant-ui';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useBlocker } from 'react-router-dom';
import {
  ChangesWillNotBeSavedModal,
  DeleteWebhookModal,
} from 'components/LeadsV2Modals';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { SalesForcePage } from 'pages/WebhookConfigurationPage/SalesForcePage';
import { OtherSystemsPage } from 'pages/WebhookConfigurationPage/OtherSystemsPage';
import { useWebhookConfigMock } from 'pages/WebhookConfigurationPage/useWebhookConfigMock';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from './WebhookConfigurationPage.module.scss';

export function WebhookConfigurationPage() {
  const { search } = useLocation();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const type = queryString.get('type');
  const blocker = useBlocker(true);
  const [data] = useWebhookConfigMock(123, type);

  const [endpoint, setEndpoint] = useState(data?.endpoint || '');
  const [oid, setOid] = useState(data?.oid || '');
  const [format, setFormat] = useState(data?.format || 'JSON');
  const [hiddenFields, setHiddenFields] = useState(data?.hiddenFields || []);
  const [customHeaders, setCustomHeaders] = useState(data?.customHeaders || []);
  const [leadFormFields, setLeadFormFields] = useState(
    data?.leadFormFields || [],
  );

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showTestLeadModal, setShowTestLeadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const setDefaultValues = (webhookData) => {
    setEndpoint(webhookData?.endpoint || '');
    setOid(webhookData?.oid || '');
    setFormat(webhookData?.format || 'JSON');
    setHiddenFields(webhookData?.hiddenFields || []);
    setCustomHeaders(webhookData?.customHeaders || []);
    setLeadFormFields(webhookData?.leadFormFields || []);
  };
  useEffect(() => {
    setEndpoint(data?.endpoint || '');
    setOid(data?.oid || '');
    setFormat(data?.format || 'JSON');
    setHiddenFields(data?.hiddenFields || []);
    setCustomHeaders(data?.customHeaders || []);
    setLeadFormFields(data?.leadFormFields || []);
  }, [data]);
  const subtitle = type === 'salesforce' ? 'Salesforce' : 'Other systems';

  useEffect(() => {
    console.log(blocker);
  }, [blocker]);
  return (
    <form>
      <SecondaryPageHeader
        title={data?.name || ''}
        subtitle={subtitle}
        actionBlock={
          <Button
            variant="primary-warning"
            size="large"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete webhook
          </Button>
        }
        maxWidth="100%"
      />
      <div className={styles['alert-container']}>
        <Alert
          variant="error"
          title="There’s a problem with your webhook."
          id="error-alert"
        >
          <Text as="p" className={styles['alert-error']}>
            We were unable to deliver leads data to your endpoint. To begin
            receiving leads data, please verify that the webhook is available
            and configured correctly. If the issue persists, please contact your
            TrustRadius CSM or try again later. Error Code{' '}
          </Text>
          <Text as="p" key="errroMessage" className={styles['alert-error']}>
            Some error
          </Text>
        </Alert>
        <Alert
          variant="success"
          title="Your webhook is working correctly."
          id="success-alert"
        >
          <Text as="p" className={styles['alert-success']}>
            Last successful test lead delivery:{' '}
            {new Date().toLocaleDateString()}
          </Text>
        </Alert>
      </div>
      {type === 'salesforce' && (
        <SalesForcePage
          endpoint={endpoint}
          setEndpoint={setEndpoint}
          oid={oid}
          setOid={setOid}
          hiddenFields={hiddenFields}
          setHiddenFields={setHiddenFields}
          leadFormFields={leadFormFields}
          setLeadFormFields={setLeadFormFields}
        />
      )}
      {type !== 'salesforce' && (
        <OtherSystemsPage
          endpoint={endpoint}
          setEndpoint={setEndpoint}
          format={format}
          setFormat={setFormat}
          hiddenFields={hiddenFields}
          setHiddenFields={setHiddenFields}
          leadFormFields={leadFormFields}
          setLeadFormFields={setLeadFormFields}
          customHeaders={customHeaders}
          setCustomHeaders={setCustomHeaders}
        />
      )}
      <div className={styles.footer}>
        <div className={styles['left-container']}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => setShowTestLeadModal(true)}
          >
            Send test lead
          </Button>
        </div>
        <div className={styles['right-container']}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => setShowDiscardModal(true)}
          >
            Discard changes
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              console.log(endpoint);
              console.log(oid);
              console.log(format);
              console.log(hiddenFields);
              console.log(customHeaders);
              console.log(leadFormFields);
              setShowSaveModal(true);
            }}
          >
            Save changes
          </Button>
        </div>
      </div>
      <Modal
        icon={<CircleIcon iconType="save" />}
        title="Your changes will not be saved"
        description="Are you sure you want to discard your recent changes?"
        className={styles.modal}
        isOpen={showDiscardModal}
        actionButtons={[
          <Button
            variant="secondary"
            size="large"
            key="discard_cancel"
            onClick={() => setShowDiscardModal(false)}
          >
            Cancel
          </Button>,
          <Button
            variant="primary"
            size="large"
            key="discard_confirm"
            onClick={() => setDefaultValues(data)}
          >
            Discard changes
          </Button>,
        ]}
        closeHandler={() => {
          setShowDiscardModal(false);
        }}
      />
      <ChangesWillNotBeSavedModal blocker={blocker} />
      <Modal
        icon={<CircleIcon iconType="paperAirplane" />}
        title="Sending your test lead"
        description="Please wait while we ensure your webhook is working correctly. This could take up to 30 seconds."
        className={styles.modal}
        isOpen={showTestLeadModal}
        closeHandler={() => {
          setShowTestLeadModal(false);
        }}
      />
      <Modal
        icon={<CircleIcon iconType="celebrate" />}
        title="Changes saved!"
        description="Now try sending a test lead to make sure everything’s working correctly."
        className={styles.modal}
        isOpen={showSaveModal}
        closeHandler={() => {
          setShowSaveModal(false);
        }}
        actionButtons={[
          <Button
            variant="secondary"
            size="large"
            key="discard_cancel"
            onClick={() => setShowSaveModal(false)}
          >
            Close
          </Button>,
          <Button
            variant="primary"
            size="large"
            key="discard_confirm"
            onClick={() => {
              setShowSaveModal(false);
              setShowTestLeadModal(true);
            }}
          >
            Send test lead
          </Button>,
        ]}
      />
      <DeleteWebhookModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
      />
    </form>
  );
}
