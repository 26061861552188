import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { getTrustquotesWebSummary } from '../../../../store/apis/productApi';
import { TrustQuotesCard } from './TrustQuotesCard';

export function TrustQuotesCardContainer() {
  const product = useSelector((state) => state.vendors.currentVendorProduct);

  const [isLoading, setIsLoading] = useState(true);
  const [hasReviewIn30Days, setHasReviewIn30Days] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getTQWRenders = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await getTrustquotesWebSummary(product._id);

      if (response?.status !== 200) {
        throw new Error("Can't get data of tqw");
      }

      if (response?.data?.renders > 0) {
        setHasReviewIn30Days(true);
      } else {
        setHasReviewIn30Days(false);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [product._id]);

  useEffect(() => {
    (async () => {
      if (product && product._id) {
        await getTQWRenders();
      }
    })();
  }, [product, getTQWRenders]);

  const refreshHandler = async () => {
    await getTQWRenders();
  };

  if (hasReviewIn30Days || _.isEmpty(product)) return null;

  return (
    <TrustQuotesCard
      product={product}
      isLoading={isLoading}
      hasError={hasError}
      refreshHandler={refreshHandler}
    />
  );
}
