import PropTypes from 'prop-types';
import { Trash2 } from 'lucide-react';
import { Button, Input, Text } from '@trustradius/radiant-ui';
import { Modal } from 'components/LeadsWebhookTabs/Modal';
import styles from './Field.module.scss';

export function Field({ heading, summary, handleRemove, input, errorMessage }) {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-body']}>
          <Text as="h3" size="300" weight="semibold" className={styles.heading}>
            {heading}
          </Text>
          <Text as="p" size="100">
            {summary}
          </Text>
        </div>
        {handleRemove && (
          <div className={styles['header-action']}>
            <Modal
              title="Delete webhook"
              description="Are you sure you want to delete this webhook and all associated fields? This action can’t be undone."
              closeText="Cancel"
              actionText="Delete"
              warning
              onAction={handleRemove}
              trigger={
                <Button variant="icon-secondary" iconOnly size="large">
                  <span className={styles['sr-only']}>Delete Endpoint</span>
                  <Trash2 size="20" aria-hidden />
                </Button>
              }
            />
          </div>
        )}
      </header>
      <Input
        label={input.label}
        id={input.id}
        type="text"
        hideLabel
        onChange={(evt) => input.changeHandler(evt)}
        {...input}
      />
      {errorMessage && (
        <Text size="100" className={styles.error}>
          {errorMessage}
        </Text>
      )}
    </section>
  );
}

Field.propTypes = {
  heading: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  input: PropTypes.object,
  handleRemove: PropTypes.func,
  errorMessage: PropTypes.string,
};
