import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './MenuLabel.module.scss';

export function MenuLabel({ type = 'new' }) {
  return (
    <div
      className={classNames(styles['label-wrapper'], styles[`type-${type}`])}
    >
      <span>{type}</span>
    </div>
  );
}

MenuLabel.propTypes = {
  type: PropTypes.oneOf(['new', 'beta']),
};
