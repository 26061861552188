import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Arrow } from 'assets/icons';
import { Radio } from 'components/TrustradiusUICore';
import styles from './TimeFrameSwitcher.module.scss';

export function TimeFrameSwitcher({
  timeFrame,
  isOpen,
  onChange,
  setModalOpen,
}) {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#timeSwitcher')) {
        setModalOpen(false);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [setModalOpen]);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setModalOpen(!isOpen);
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={handleButtonClick}
        className={styles.timeFrameSwitcher}
      >
        <span className={styles.timeFrame}>Last {timeFrame} days</span>
        <Arrow
          className={classNames(styles.arrowBlack, {
            [styles.arrowBlackReversed]: isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div
          className={classNames(styles['time-frame-modal'])}
          id="timeSwitcher"
        >
          <Radio
            label="Last 30 days"
            name="Last 30 days"
            value={30}
            checked={timeFrame === '30'}
            onChange={onChange}
            className={styles.radio}
          />
          <Radio
            label="Last 60 days"
            name="Last 60 days"
            value={60}
            checked={timeFrame === '60'}
            onChange={onChange}
            className={styles.radio}
          />
          <Radio
            label="Last 90 days"
            name="Last 90 days"
            value={90}
            checked={timeFrame === '90'}
            onChange={onChange}
            className={styles.radio}
          />
        </div>
      )}
    </div>
  );
}

TimeFrameSwitcher.propTypes = {
  timeFrame: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  setModalOpen: PropTypes.func,
};
