import { CheckCircle } from 'lucide-react';
import { Button } from '@trustradius/radiant-ui';
import styles from './BookDemo.module.scss';

export function BookDemo() {
  const onClick = () => {
    window.open('https://www.trustradius.com/resources/assets/global-demo');
  };
  return (
    <>
      <p>
        Don&apos;t leave your buyers waiting and wanting more from you. By
        becoming a TrustRadius customer you can unlock more ways to:
      </p>
      <ul className={styles.list}>
        <li className={styles['list-item']}>
          <CheckCircle className={styles.icon} />
          Tell a differentiated brand narrative
        </li>
        <li className={styles['list-item']}>
          <CheckCircle className={styles.icon} />
          Generate more pipeline, faster
        </li>
        <li className={styles['list-item']}>
          <CheckCircle className={styles.icon} />
          Never miss a deal - accelerating your sales cycle
        </li>
        <li className={styles['list-item']}>
          <CheckCircle className={styles.icon} />
          Reduce churn and find expansion opportunities
        </li>
      </ul>
      <Button size="large" onClick={onClick}>
        Book a demo
      </Button>
    </>
  );
}
