import { useSelector } from 'react-redux';
import { MousePointer, TrendingUp, Lightbulb } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { TestboxSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { TestboxBanner, TestboxBanner2x } from './assets';

export function Testbox() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Test and compare software solutions and choose the best one for your team.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'TestBox',
    logo: <img src={TestboxSquareLogo} alt="Testbox" />,
    subtitle: subTitle,
    actionBlock: (
      <Button
        size="large"
        url="https://www.testbox.com/trustradius-partnership"
        targetBlank
      >
        Learn more
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: 'ability',
      icon: <MousePointer />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: 'tailor',
      icon: <TrendingUp />,
      text: 'Increase your sales velocity by connecting with downstream buyers',
    },
    {
      id: 'insights',
      icon: <Lightbulb />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in.',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = [
    'Lead Generation',
    'Presales',
    'Interactive demos',
    'Sandbox Environments',
  ];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={TestboxBanner}
            srcSet={`${TestboxBanner} 1x, ${TestboxBanner2x} 2x`}
            alt="Testbox: Let buyers experience exactly what it's like to use your product."
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About TestBox</Text>
          <Text as="p">
            TestBox helps you test and compare multiple software solutions and
            choose the best one for your team.
          </Text>
          <Text as="p">
            We take the pain out of finding the right software for your company,
            giving you instant access to live products that you can play with
            side-by-side, so you can skip the sales decks and get your hands
            dirty from day 1.
          </Text>
          <Text as="h3">TestBox and TrustRadius</Text>
          <Text as="p">
            Both TestBox & TrustRadius believe in the importance of empowering
            buyers to research products on their own. Through TestBox&rsquo;s
            integration with TrustRadius, buyers can now access TestBox sandbox
            instances on TrustRadius and start test-driving products that
            they&rsquo;re interested in.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Expand your marketplace</Text>
          <Text as="p">
            Drive a new revenue stream through the TestBox marketplace and build
            brand awareness across your category. Get introduced to warm
            opportunities from buyers who are comfortable with your platform
          </Text>
          <Text as="h3">Increase your sales velocity</Text>
          <Text as="p">
            Increase your sales reps&rsquo; efficiency by focusing on the right
            deals at the right time. Enablement teams also leverage TestBox to
            train their new sales reps.
          </Text>
          <Text as="h3">Boost your marketing strategy</Text>
          <Text as="p">
            Feature TestBox on your website to engage buyers who want to get
            their hands on your product.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
