import { forwardRef } from 'react';
import ReactBootstrapModal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import { ModalHeader } from './ModalHeader';
import { ModalTitle } from './ModalTitle';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalPanel } from './ModalPanel';

import './Modal.scss';

/**
 * TrustRadius wrapped modal component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Modal = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapModal
      ref={ref}
      className={classes}
      bsPrefix="trModal"
      {...otherProps}
    >
      {children}
    </ReactBootstrapModal>
  );
});

Modal.displayName = 'Modal';
// eslint-disable-next-line react/forbid-foreign-prop-types
Modal.propTypes = ReactBootstrapModal.propTypes;

// Mimicking the access pattern that react bootstrap sets up for this inner component
Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Panel = ModalPanel;
