export const mapLeadFormFields = {
  firstName: {
    id: 'firstName',
    label: 'First name',
    maxLength: 40,
  },
  lastName: {
    id: 'lastName',
    label: 'Last name',
    maxLength: 40,
  },
  email: {
    id: 'email',
    label: 'Work email',
    maxLength: 40,
  },
  country: {
    id: 'country',
    label: 'Country',
    maxLength: 40,
  },
  company: {
    id: 'company',
    label: 'Company',
    maxLength: 40,
  },
  message: {
    id: 'message',
    label: 'Message',
    maxLength: 40,
  },
  interestedIn: {
    id: 'interestedIn',
    label: 'Interested In',
    maxLength: 40,
  },
  productName: {
    id: 'productName',
    label: 'Product name',
    maxLength: 40,
  },
  leadSource: {
    id: 'leadSource',
    label: 'Lead source',
    maxLength: 40,
  },
};
