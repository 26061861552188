import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapOverlay from 'react-bootstrap/Overlay';
import classNames from 'classnames';

/**
 * TrustRadius wrapped Overlay component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Overlay = forwardRef(
  ({ className, children, ...otherProps }, ref) => {
    // Merge any provided classNames with local component styling
    const classes = classNames(className);
    // Provide default variant
    return (
      <ReactBootstrapOverlay ref={ref} className={classes} {...otherProps}>
        {children}
      </ReactBootstrapOverlay>
    );
  },
);

Overlay.displayName = 'Overlay';
Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
