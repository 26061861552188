export default function CustomerVerified(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      {...props}
    >
      <path
        d="M12 0l2.69 2.06L18 1.6l1.26 3.14L22.4 6l-.46 3.31L24 12l-2.06 2.69.46 3.31-3.14 1.26L18 22.4l-3.31-.46L12 24l-2.69-2.06L6 22.4l-1.26-3.14L1.6 18l.46-3.31L0 12l2.06-2.69L1.6 6l3.14-1.26L6 1.6l3.31.46z"
        fill="#00b2a3"
      />
      <path
        d="M17 9l-6.88 6.25L7 12.41"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
