/* eslint-disable @trustradius/jsx-prefer-fragment-wrappers */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import { PageBody } from 'components/PageBody';
import { Switch, OverlayTrigger, Popover } from 'components/TrustradiusUICore';
import {
  LeadCaptureModalReplica,
  EligibleProductsModalWithData,
} from 'components/LeadForm';
import { withLeadFormData } from 'hoc/withLeadFormData';
import styles from './LeadForm.module.scss';

export function LeadForm({ isLeadFormActive, switchHandler }) {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <header className={styles.header}>
        <div className={styles['title-block']}>
          <h1>Lead Form</h1>
          <div className={styles.subtitle}>
            Enable your lead form to collect contact information from interested
            buyers through your TrustRadius product listings.
          </div>
        </div>
        <div className={styles['switch-block']}>
          <OverlayTrigger
            interactive
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={
              <Popover className={styles.popover}>
                <Popover.Content>
                  <div id="tooltip-key-api">
                    Enabling your lead form will update eligible product
                    listings to include a &quot;Contact&quot; CTA, allowing
                    buyers to reach out for more information
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            {/* This div is needed to be here to be recognised by OverlayTrigger as a child */}
            <div>
              <Switch
                label="Enable lead form"
                size="large"
                checked={isLeadFormActive}
                labelClass={styles['switch-label']}
                callback={(value) => {
                  switchHandler(value);
                }}
              />
            </div>
          </OverlayTrigger>
        </div>
      </header>
      <PageBody className={styles['page-body']}>
        <section>
          <h2 className={styles['section-title']}>How it works</h2>
          <p className={styles['section-text']}>
            TrustRadius leads are generated when buyers who are interested in
            your product submit their contact details via a lead form. Buyers
            can access the lead form through a “Contact” CTA on your TrustRadius
            product listing, pricing page, comparison page, and more.
          </p>
        </section>
        <section>
          <h2 className={styles['section-title']}>Your lead form</h2>
          <div className={styles['modal-replica-container']}>
            <LeadCaptureModalReplica />
            <button
              type="button"
              disabled
              className={styles['modal-close-button']}
              aria-label="Close modal"
            >
              <X color="#4A4A54" />
            </button>
          </div>
        </section>
        <button
          type="button"
          className={styles['eligible-products-button']}
          onClick={() => {
            setIsModalShown(true);
          }}
        >
          View eligible products
        </button>
      </PageBody>
      {isModalShown && (
        <EligibleProductsModalWithData
          onHide={() => {
            setIsModalShown(false);
          }}
        />
      )}
    </>
  );
}

export const LeadFormWithData = withLeadFormData(LeadForm);

LeadForm.propTypes = {
  isLeadFormActive: PropTypes.bool.isRequired,
  switchHandler: PropTypes.func.isRequired,
};
