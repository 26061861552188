import { ApolloConsumer } from '@apollo/client';

export const withApolloClient = (Element) => {
  function WrappedComponent(props) {
    return (
      <ApolloConsumer>
        {(client) => <Element {...props} client={client} />}
      </ApolloConsumer>
    );
  }
  return WrappedComponent;
};
