import { useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { X, Menu, HelpCircle } from 'lucide-react';
import { UserMenu } from 'components/UserMenu';
import { collapseSideBar, expandSideBar } from 'store/actions/globalActions';
import styles from './MobileMenuContainer.module.scss';

export function MobileMenuContainer() {
  const isDesktop = useMedia('(min-width:901px)');
  const isMenuOpen = useSelector(
    (state) => state.globalState.isMenuContainerExpanded,
  );
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(isMenuOpen ? collapseSideBar() : expandSideBar());
  };

  const desktopView = () => (
    <div
      className={styles['desktop-right-block']}
      data-testid="mobile-menu-container"
    >
      <a
        className={styles.helpIcon}
        href="https://trustradius.freshdesk.com/support/home"
        target="_blank"
        rel="noreferrer"
      >
        <HelpCircle size={25} />
        <span>Get Support</span>
      </a>
      <UserMenu color />
    </div>
  );

  const mobileView = () => {
    const label = isMenuOpen ? 'X' : 'Open Menu';
    const Icon = isMenuOpen ? X : Menu;

    return (
      <button
        type="button"
        className={styles.button}
        onTouchStart={toggleMenu}
        aria-label={label}
      >
        <Icon color="#FFFFFF" />
      </button>
    );
  };

  if (!isDesktop) {
    return mobileView();
  }

  return desktopView();
}
