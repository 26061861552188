export function sendGTMEvent(event, data) {
  if (window.dataLayer) {
    console.debug('sendGTMEvent', { event, data });
    window.dataLayer.push({
      event,
      ...data,
    });
  } else {
    console.warn(
      'GTM dataLayer is not available. Make sure GTM is initialized correctly',
    );
  }
}
