import { forwardRef } from 'react';
import ReactBootstrapDropdown from 'react-bootstrap/Dropdown';
import classNames from 'classnames';
import { DropdownButton } from './DropdownButton';
import { DropdownToggle } from './DropdownToggle';
import { DropdownMenu } from './DropdownMenu';
import { DropdownItem } from './DropdownItem';
import { DropdownHeader } from './DropdownHeader';
import { DropdownDivider } from './DropdownDivider';

/**
 * TrustRadius wrapped dropdown component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Dropdown = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapDropdown ref={ref} className={classes} {...otherProps}>
      {children}
    </ReactBootstrapDropdown>
  );
});

Dropdown.displayName = 'Dropdown';
// eslint-disable-next-line react/forbid-foreign-prop-types
Dropdown.propTypes = ReactBootstrapDropdown.propTypes;

// Mimicking the access pattern that react bootstrap sets up for this inner component
Dropdown.Button = DropdownButton;
Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
Dropdown.Header = DropdownHeader;
Dropdown.Divider = DropdownDivider;
