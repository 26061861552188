import PropTypes from 'prop-types';
import { DashCard } from 'components/DashCard';
import { Skeleton } from 'components/Skeleton';
import { CardErrorState } from 'components/CardErrorState';
import { useSettings } from 'settings';
import { BannerQuote } from './BannerQuote';
import styles from './TrustQuotesCard.module.scss';

export function TrustQuotesCard({
  product,
  isLoading,
  hasError,
  refreshHandler,
}) {
  const settings = useSettings();
  if (isLoading) return <Skeleton className={styles.loader} />;
  if (hasError) return <CardErrorState refreshHandler={refreshHandler} />;

  return (
    <DashCard
      ctaText="Get started"
      ctaHref={`${settings.legacyBaseUrl}/products/${product._id}/trustquoteslibrary`}
      product={product}
      isTargetBlank={false}
    >
      <BannerQuote
        quote="I love that I can easily create widgets based on tags for customer ratings/reviews that I can leverage on our website and marketing."
        name="LaShanda Jackson, MailChimp"
      >
        <h3>Work with TrustRadius to leverage your customer’s voice</h3>
        <p>
          Start today and learn how you can customize and syndicate customer
          quotes from your reviews. Turn your customer’s voice into a
          competitive advantage and increase traffic and conversion on your
          landing pages.
        </p>
      </BannerQuote>
    </DashCard>
  );
}

TrustQuotesCard.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    logos: PropTypes.shape({
      default: PropTypes.string,
      squareOriginal: PropTypes.string,
      square180: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  refreshHandler: PropTypes.func,
};
