import {
  Link,
  Form,
  useNavigate,
  useNavigation,
  useActionData,
  redirect,
} from 'react-router-dom';
import { Button } from '@trustradius/radiant-ui';
import { Modal } from 'components/TrustradiusUICore';
import { unauthorizeLima } from 'store/apis/limaApi';
import styles from './RemoveLinkedinIntegrationModal.module.scss';

export async function removeLinkedinIntegrationAction({ params }) {
  try {
    await unauthorizeLima();

    return redirect(`/vendor/${params.vendorId}/integrations/linkedin`);
  } catch (error) {
    return { isError: true };
  }
}

export function RemoveLinkedinIntegrationModal() {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const actionData = useActionData();

  return (
    <Modal
      show
      className={styles.modal}
      dialogClassName={styles.dialog}
      backdropClassName={styles.backdrop}
      onHide={() => {
        navigate('..', { replace: true });
      }}
    >
      <Modal.Header className={styles.header} closeButton>
        <Modal.Title className={styles.title}>
          Are you sure you want to remove your LinkedIn Account?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <p>
          Removing your LinkedIn Ad Account will remove the connection between
          TrustRadius and LinkedIn Campaign Manager and will stop refreshing
          your existing segments.
        </p>
        {actionData?.isError && (
          <div className={styles['error-message']}>Something went wrong</div>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Link
          to=".."
          replace
          className={`button button-secondary-pill ${styles['cancel-button']}`}
        >
          Cancel
        </Link>
        <Form method="post">
          <Button
            type="submit"
            variant="primary-warning"
            isDisabled={navigation.state !== 'idle'}
          >
            Remove
          </Button>
        </Form>
      </Modal.Footer>
    </Modal>
  );
}
