/* @TODO [TECHX-67] Fix a11y violations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { collapseSideBar, expandSideBar } from 'store/actions/globalActions';
import styles from './SideBar.module.scss';

export function SideBar({ children }) {
  const isExpanded = useSelector(
    (reduxState) => reduxState.globalState.isMenuContainerExpanded,
  );
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <nav
        aria-label="Main"
        className={classNames(styles.sidebar, {
          [styles.expanded]: isExpanded,
        })}
        onMouseLeave={() => dispatch(collapseSideBar())}
        onMouseOver={() => dispatch(expandSideBar())}
      >
        {children}
      </nav>
    </div>
  );
}

SideBar.propTypes = {
  children: PropTypes.node,
};
