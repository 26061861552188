import PropTypes from 'prop-types';
import { Button } from '@trustradius/radiant-ui';
import { Modal } from 'components/TrustradiusUICore';
import styles from './DeleteSegmentModal.module.scss';

export function DeleteSegmentModal({
  show,
  handleClose,
  segmentName,
  decisionHandler,
}) {
  if (!show) {
    return null;
  }

  return (
    <Modal
      show
      className={styles.modal}
      dialogClassName={styles.dialog}
      backdropClassName={styles.backdrop}
      onHide={handleClose}
    >
      <Modal.Header className={styles.header} closeButton />
      <Modal.Body className={styles.body}>
        <h3 className={styles.title}>
          Are you sure you want to delete this segment?
        </h3>
        <p>
          You are about to delete the &quot;{segmentName}&quot; segment which
          will remove it from TrustRadius and LinkedIn.
        </p>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button
          variant="tertiary"
          size="large"
          className={styles['cancel-button']}
          onClick={() => decisionHandler(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary-warning"
          size="large"
          onClick={() => decisionHandler(true)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DeleteSegmentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  segmentName: PropTypes.string.isRequired,
  decisionHandler: PropTypes.func.isRequired,
};
