import { useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './Main.module.scss';

function useScrollReset() {
  const elementRef = useRef();

  const { pathname } = useLocation();

  useLayoutEffect(
    () => {
      elementRef.current.scroll({ top: 0 });
    },
    // Scroll on pathname change
    [pathname],
  );

  return elementRef;
}

export function Main({ children }) {
  const { isMenuExpanded } = useSelector((state) => state.globalState);

  const ref = useScrollReset();

  return (
    <main
      ref={ref}
      className={classNames(styles.body, {
        [styles['menu-open']]: isMenuExpanded,
      })}
    >
      {children}
    </main>
  );
}

Main.propTypes = {
  children: PropTypes.node,
};
