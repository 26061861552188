import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import { SalesforceDesktop, SalesforceMobile } from './assets';

export function SalesforceCard({ product }) {
  return (
    <DashCard
      ctaText="Get started"
      ctaHref="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3u00000ONAIeEAP"
      product={product}
    >
      <Announcement
        mSrc={SalesforceMobile}
        mSrc2x={SalesforceMobile}
        src={SalesforceDesktop}
        src2x={SalesforceDesktop}
        alt="Drive revenue with intent data"
      >
        <>
          <h3>Check out the Salesforce Connector</h3>
          <p>
            Make downstream intent data actionable with Salesforce. TrustRadius
            gets you reliable intent data to build pipeline, boost your ABM and
            outbound strategies, and even help you mediate churn risk—and it’s
            easy to set up.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

SalesforceCard.propTypes = {
  product: PropTypes.object,
};
