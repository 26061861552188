import { useCallback } from 'react';
import { useCookie } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { logoutUser } from 'store/apis/usersApi';
import { useSettings } from 'settings';

export function useLogout() {
  const { logout } = useAuth0();
  const [, , deleteCookie] = useCookie('_vp_user');
  const isNewAuth0FlowEnabled = useFeatureIsOn('auth0');
  const buyerSiteUrl = useSettings().buyerSiteUrl;

  return useCallback(async () => {
    // Call the API to kill the server session
    await logoutUser();

    // Clear the _vp_user cookie
    deleteCookie();

    // Determine logout URL based on Auth0 feature flag
    const logoutUrl = isNewAuth0FlowEnabled
      ? `${buyerSiteUrl}/api/auth/logout?nexturl=/`
      : buyerSiteUrl;

    // Clears the application session and performs a redirect to /v2/logout,
    // uses parameters provided as arguments, to clear the Auth0 session
    logout({
      logoutParams: {
        returnTo: logoutUrl,
      },
    });
  }, [logout, deleteCookie, isNewAuth0FlowEnabled, buyerSiteUrl]);
}
