import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { getLastPublishedReviewByProductId } from 'store/apis/productApi';
import { NoReviewsCard } from './NoReviewsCard';

export function NoReviewsCardContainer() {
  const [hasReviewIn90Days, setHasReviewIn90Days] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasError, setHasError] = useState(false);
  const product = useSelector((state) => state.vendors.currentVendorProduct);

  const isShowCard = useCallback(async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      // 90 days in ms
      const cutOffDate = new Date().getTime() - 90 * 24 * 60 * 60 * 1000;
      if (!product?._id) {
        return;
      }
      const lastReviewDate = await getLastReviewDate(product._id);

      if (lastReviewDate > cutOffDate) {
        setHasReviewIn90Days(true);
      } else {
        setHasReviewIn90Days(false);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [product]);

  const refreshHandler = async () => {
    await isShowCard();
  };

  useEffect(() => {
    isShowCard();
  }, [product, isShowCard]);

  async function getLastReviewDate(productId) {
    const response = await getLastPublishedReviewByProductId(productId);
    if (response.status !== 200) {
      throw new Error("Can't get last review");
    }

    if (response?.data?.records?.length > 0) {
      return new Date(response?.data.records[0].publishedDate).getTime();
    }
    return new Date();
  }

  return (
    <>
      {!hasReviewIn90Days && !_.isEmpty(product) && (
        <NoReviewsCard
          product={product}
          hasError={hasError}
          isLoading={isLoading}
          refreshHandler={refreshHandler}
        />
      )}
    </>
  );
}
