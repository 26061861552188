import PropTypes from 'prop-types';
import { DashCard } from 'components/DashCard';
import { TRMessage } from './TRMessage';
import styles from './WelcomeCard.module.scss';

export function WelcomeCard({ product }) {
  return (
    <DashCard product={product}>
      <TRMessage>
        <p>
          Based on your feedback, we’ll be launching several features and
          updates to improve the overall vendor portal experience and usability.
          Over the coming months, be on the lookout as we roll out new features
          and updates, like:
        </p>
        <ul className={styles.list}>
          <li>Awards tracking</li>
          <li>Improved metrics and reporting</li>
          <li>New ABM integrations</li>
          <li>New LinkedIn integration</li>
          <li>and more</li>
        </ul>
      </TRMessage>
    </DashCard>
  );
}

WelcomeCard.propTypes = {
  product: PropTypes.object,
};
