import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Tag.module.scss';

export function Tag({ className, children, ...props }) {
  return (
    <div className={classNames(className, styles.tag)} {...props}>
      {children}
    </div>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
