import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductAwardTracks } from 'store/apis/awardsApi';
import { AwardTrackingCard } from './AwardTrackingCard';

export function AwardTrackingCardContainer() {
  const product = useSelector((state) => state?.vendors?.currentVendorProduct);
  const vendor = useSelector((state) => state?.vendors?.currentVendor);
  const [productAwardTracks, setProductAwardTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  async function getAwardTracks(productId) {
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await getProductAwardTracks(productId);
      if (response?.status !== 200) {
        setHasError(true);
      }

      setProductAwardTracks(response?.data?.records || []);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (product && product._id) {
        await getAwardTracks(product._id);
      }
    })();
  }, [product]);

  return (
    <AwardTrackingCard
      product={product}
      vendor={vendor}
      productAwardTracks={productAwardTracks}
      isLoading={isLoading}
      hasError={hasError}
      refreshHandler={getAwardTracks}
    />
  );
}
