import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './LinkedInAuthEmpty.module.scss';

export function LinkedInAuthEmpty({ text, createSegmentHref, learnMoreHref }) {
  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>Create Segment</h2>
      <div className="body">
        <p>
          {text} <a href={learnMoreHref}>Learn more</a>
        </p>
      </div>
      <footer className={styles['segment-footer']}>
        <Link to={createSegmentHref}>Create Segment</Link>
      </footer>
    </section>
  );
}

LinkedInAuthEmpty.propTypes = {
  createSegmentHref: PropTypes.string.isRequired,
  learnMoreHref: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
