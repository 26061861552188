import { useSelector } from 'react-redux';
import { useSettings } from 'settings';
import { ProductHeader } from './ProductHeader';

export function ProductHeaderContainer() {
  const settings = useSettings();
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );

  return <ProductHeader product={currentProduct} settings={settings} />;
}
