import { Select, SelectItem } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { CreateCheckboxField } from '../CreateCheckboxField';
import { CreateDropdown } from '../CreateDropdown';
import { CreateTextField } from '../CreateTextField';
import styles from './CustomFieldConfiguration.module.scss';

export function CustomFieldConfiguration({
  fieldType = 'menu',
  fieldTypeChangeHandler,
  titleInputLabel,
  titleInputValue,
  titleInputChangeHandler,
  dropdownNameFieldId,
  dropdownNameFieldChangeHandler,
  dropdownItemChangeHandler,
  dropdownItems = [],
  checkboxTextAreaValue,
  checkboxMaxTextAreaLength = 100,
  checkboxTextAreaChangeHandler,
  textLabelFieldId,
  textHelpTextId,
  textHelpTextValue,
  textHelpTextChangeHandler,
}) {
  return (
    <div className={styles.container}>
      <Select
        isFullWidth
        required
        value={fieldType}
        label="Field type"
        onValueChange={(value) => fieldTypeChangeHandler(value)}
      >
        <SelectItem value="CUSTOM_MENU">Menu</SelectItem>
        <SelectItem value="CUSTOM_CHECKBOX">Checkbox</SelectItem>
        <SelectItem value="CUSTOM_TEXT">Text Field</SelectItem>
      </Select>
      {fieldType === 'CUSTOM_MENU' && (
        <CreateDropdown
          nameFieldId={dropdownNameFieldId}
          dropdownName={titleInputValue}
          nameFieldLabel={titleInputLabel}
          nameFieldChangeHandler={dropdownNameFieldChangeHandler}
          dropdownItems={dropdownItems}
          dropdownItemChangeHandler={dropdownItemChangeHandler}
        />
      )}
      {fieldType === 'CUSTOM_CHECKBOX' && (
        <CreateCheckboxField
          labelTitle={titleInputLabel}
          labelValue={titleInputValue}
          textAreaValue={checkboxTextAreaValue}
          maxTextAreaLength={checkboxMaxTextAreaLength}
          labelNameFieldChangeHandler={titleInputChangeHandler}
          textAreaChangeHandler={checkboxTextAreaChangeHandler}
        />
      )}
      {fieldType === 'CUSTOM_TEXT' && (
        <CreateTextField
          labelFieldId={textLabelFieldId}
          labelTitle={titleInputLabel}
          labelValue={titleInputValue}
          labelChangeHandler={titleInputChangeHandler}
          helpTextId={textHelpTextId}
          helpTextValue={textHelpTextValue}
          helpTextChangeHandler={textHelpTextChangeHandler}
        />
      )}
    </div>
  );
}

CustomFieldConfiguration.propTypes = {
  fieldType: PropTypes.string.isRequired,
  titleInputLabel: PropTypes.string,
  titleInputValue: PropTypes.string,
  titleInputChangeHandler: PropTypes.func,
  fieldTypeChangeHandler: PropTypes.func.isRequired,
  dropdownNameFieldId: PropTypes.string,
  dropdownItemChangeHandler: PropTypes.func,
  dropdownNameFieldChangeHandler: PropTypes.func,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  checkboxTextAreaValue: PropTypes.string,
  checkboxMaxTextAreaLength: PropTypes.number,
  checkboxTextAreaChangeHandler: PropTypes.func,
  textLabelFieldId: PropTypes.string,
  textHelpTextId: PropTypes.string,
  textHelpTextValue: PropTypes.string,
  textHelpTextChangeHandler: PropTypes.func,
};
