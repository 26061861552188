import { Text, Input } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import styles from 'pages/WebhookConfigurationPage/WebhookConfigurationPage.module.scss';

export function LeadFormFields({ leadFormFields, changeLeadFormFields, type }) {
  const onChange = (event) => {
    const { name, value } = event.target;
    const newLeadFormMapping = leadFormFields.map((header) => {
      if (header.name === name) {
        return {
          ...header,
          [name.includes('_key') ? 'name' : 'value']: value,
        };
      }
      return header;
    });
    changeLeadFormFields(newLeadFormMapping);
  };

  const destinationTitle =
    type === 'salesforce' ? 'SALESFORCE FIELD' : 'DESTINATION FIELD';
  return (
    <>
      <div className={styles['mapping-container']}>
        <Text as="h2" size="200" weight="semibold">
          LEAD FORM FIELD
        </Text>
        <Text as="h2" size="200" weight="semibold">
          {destinationTitle}
        </Text>
        {leadFormFields.map((field) => [
          <div
            key={`${field?.name?.replace(' ', '_')}_label`}
            className={styles['field-container']}
          >
            <Text key={`${field}_name`}>{field.name}</Text>
          </div>,
          <div key={`${field?.name?.replace(' ', '_')}`}>
            <Input
              id={`${field.name}`}
              type="text"
              autoComplete="off"
              value={field.value}
              name={`${field.name}`}
              onChange={onChange}
              hideLabel
            />
          </div>,
        ])}
      </div>
    </>
  );
}

LeadFormFields.propTypes = {
  leadFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  changeLeadFormFields: PropTypes.func.isRequired,
};
