import { Button } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from 'components/LeadsV2Modals/ChangesWillNotBeSavedModal/ChangesWillNotBeSavedModal.module.scss';

export function NoProductSelectedModal({
  isOpen,
  closeHandler,
  discardChanges,
}) {
  return (
    <Modal
      icon={<CircleIcon iconType="pointer" />}
      title="You haven’t selected products"
      description="Navigating away from this page without selecting products will mean that your lead form won’t appear for buyers."
      className={styles.content}
      isOpen={isOpen}
      closeHandler={() => {
        closeHandler();
      }}
      actionButtons={[
        <Button
          variant="secondary"
          size="large"
          key="discard_cancel"
          onClick={() => closeHandler()}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          size="large"
          key="discard_confirm"
          onClick={() => discardChanges()}
        >
          Discard changes
        </Button>,
      ]}
    />
  );
}

NoProductSelectedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};
