import PropTypes from 'prop-types';
import styles from './NotificationBanner.module.scss';

export function NotificationBanner({ leftItem = null, rightItem = null }) {
  return (
    <section className={styles.container}>
      <div className={styles.innerItem}>{leftItem || null}</div>
      <div className={styles.innerItem}>{rightItem || null}</div>
    </section>
  );
}

NotificationBanner.propTypes = {
  leftItem: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  rightItem: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
};
