import ReactBootstrapDropdown from 'react-bootstrap/Dropdown';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';

/**
 * TrustRadius wrapped DropdownHeader component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function DropdownHeader(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles['dropdown-header']);
  // Provide default variant
  return (
    <ReactBootstrapDropdown.Header
      className={classes}
      bsPrefix="trDropdown-header"
      {...otherProps}
    >
      {children}
    </ReactBootstrapDropdown.Header>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
DropdownHeader.propTypes = ReactBootstrapDropdown.Header.propTypes;
