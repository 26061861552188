import { gql } from '@apollo/client';

const WEBHOOK_FIELDS = gql`
  fragment webhookFields on Webhook {
    id
    headers
    mapping
    SaleForceOID
    vendorId
    endpoint
    type
    dataType
    createdAt
    updatedAt
    deletedAt
  }
`;

export const WEBHOOKS_BY_VENDOR_ID = gql`
  ${WEBHOOK_FIELDS}
  query getWebhooksByVendorId($vendorId: String!) {
    webhooksByVendorId(vendorId: $vendorId) {
      ...webhookFields
    }
  }
`;

export const CREATE_LEAD_WEBHOOK_PROCESS_JOB_BY_WEBHOOK_ID = gql`
  query CreateLeadWebhookProcessJobByWebhookId(
    $webhookId: String!
    $testLead: CreateLeadInput!
  ) {
    createLeadWebhookProcessJobByWebhookId(
      webhookId: $webhookId
      testLead: $testLead
    ) {
      config {
        headers
      }
      data
      url
    }
  }
`;

export const EXECUTE_WEBHOOK_PROCESS_JOB = gql`
  mutation ExecuteWebhookProcessJob($sendWebhookInput: SendToWebhookInput!) {
    executeWebhookProcessJob(SendWebhookInput: $sendWebhookInput) {
      processTime
      retryAttempts
      webhookActivity {
        payload
        success
        message
        status
      }
    }
  }
`;

export const CREATE_WEBHOOK = gql`
  ${WEBHOOK_FIELDS}
  mutation createWebhook($createWebhookInput: CreateWebhookInput!) {
    createWebhook(createWebhookInput: $createWebhookInput) {
      ...webhookFields
    }
  }
`;

export const UPDATE_WEBHOOK = gql`
  ${WEBHOOK_FIELDS}
  mutation updateWebhook($updateWebhookInput: UpdateWebhookInput!) {
    updateWebhook(updateWebhookInput: $updateWebhookInput) {
      ...webhookFields
    }
  }
`;

export const REMOVE_WEBHOOK = gql`
  ${WEBHOOK_FIELDS}
  mutation removeWebhook($id: String!) {
    removeWebhook(id: $id) {
      ...webhookFields
    }
  }
`;
