import React from 'react';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import styles from './Filters.module.scss';

export function DateFilter({ filterValue, setFilter, isDisabled = false }) {
  const filterOptions = [
    { value: '', label: 'All time' },
    { value: '30', label: 'Last 30 days' },
    { value: '60', label: 'Last 60 days' },
  ];

  const defaultValue = filterValue ? filterValue : filterOptions[0].label;

  return (
    <ToggleGroup.Root
      className={styles['toggle-group']}
      type="single"
      defaultValue={defaultValue}
      aria-label="Filer by"
      disabled={isDisabled}
    >
      {filterOptions.map((option) => {
        return (
          <ToggleGroup.Item
            key={option.label}
            className={styles['toggle-group-item']}
            value={option.label}
            onValueChange={() => setFilter(option.value)}
          >
            {option.label}
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
}

DateFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export function SearchFilter({ searchFilter, setGlobalFilter, isDisabled }) {
  const [value, setValue] = React.useState(searchFilter);
  const onChange = useAsyncDebounce((filterValue) => {
    setGlobalFilter(filterValue || undefined);
  }, 1000);
  return (
    <div className={styles.search}>
      <input
        value={value || ''}
        onChange={(evt) => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
        autoComplete="off"
        aria-label="Search"
        placeholder="Search"
        disabled={isDisabled}
        className={styles['search-input']}
      />
    </div>
  );
}

SearchFilter.propTypes = {
  searchFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
