const HEAD_SCRIPT_ID = 'gtm-head-script';
const BODY_SCRIPT_ID = 'gtm-body-script';

export function initGTM(config) {
  if (!config.tagId) {
    throw new Error('Google Tag Manager ID is required');
  }

  const defaultConfig = {
    env: null,
    auth: null,
    debugEnabled: false,
    dataLayerName: 'dataLayer',
  };

  console.debug('initGTM(config)', config);

  const { tagId, env, auth, debugEnabled, dataLayerName } = {
    ...defaultConfig,
    ...config,
  };

  let additionalParams = '';

  if (env !== null) {
    additionalParams += `&gtm_preview=${env}`;
  }

  if (auth !== null) {
    additionalParams += `&gtm_auth=${auth}`;
  }

  if (debugEnabled) {
    additionalParams += '&gtm_cookies_win=x';
  }

  const getBodyScript = () => {
    const script = document.createElement('noscript');
    script.id = BODY_SCRIPT_ID;
    script.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagId}${additionalParams}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    return script;
  };

  const getHeadScript = () => {
    const script = document.createElement('script');
    script.id = HEAD_SCRIPT_ID;
    script.innerHTML = `(function(w,d,s,l,i,p){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+p;f.parentNode.insertBefore(j,f);
    })(window,document,'script','${dataLayerName}','${tagId}','${additionalParams}');`;
    return script;
  };

  if (!document.getElementById(HEAD_SCRIPT_ID)) {
    document.head.insertBefore(getHeadScript(), document.head.childNodes[0]);
  }

  if (!document.getElementById(BODY_SCRIPT_ID)) {
    document.body.insertBefore(getBodyScript(), document.body.childNodes[0]);
  }
}
