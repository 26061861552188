import { useState } from 'react';
import PropTypes from 'prop-types';
import { AlertTriangle, ChevronDown, ChevronRight } from 'lucide-react';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import moment from 'moment';
import { Button, Toast, Switch, HelpIcon } from 'components/TrustradiusUICore';
import { deleteLinkedInSegment } from 'store/apis/limaApi';
import { accountShape, segmentShape } from './types';
import { PrimaryMetrics } from './PrimaryMetrics';
import { SecondaryMetrics } from './SecondaryMetrics';
import { AccountsModal } from './AccountsModal';
import { DeleteSegmentModal } from './DeleteSegmentModal';
import styles from './SegmentItem.module.scss';

const MAX_NAMES_LENGTH = 2;

export function SegmentItem({
  className,
  segment,
  updateSegments,
  switchEnabled,
  companies,
}) {
  const [toggle, setToggle] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [showDeleteSegment, setShowDeleteSegment] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ heading: '', body: '' });
  const isMobile = useMedia('(max-width: 900px)');
  const destination = segment.destinations.find(
    (segmentDestination) => segmentDestination.destination === 'LINKEDIN',
  );
  const totalAccounts = segment.inputCount;
  const dateCreated = moment(segment.created).format('MM/DD/YYYY');
  const matchedAccounts = destination.matchedCount;
  const { productRecords, categoryRecords, companiesError, userRecord } =
    segment.config;

  function getDescription() {
    let audienceBaseType = '';
    let audienceBaseNames;
    let itemsLength;

    if (productRecords && productRecords.length) {
      audienceBaseType = 'Products';
      itemsLength = productRecords.length;
      audienceBaseNames = productRecords.map((product) => product.name);
    } else if (categoryRecords && categoryRecords.length) {
      audienceBaseType = 'Categories';
      itemsLength = categoryRecords.length;
      audienceBaseNames = categoryRecords.map((category) => category.name);
    }

    if (itemsLength <= MAX_NAMES_LENGTH) {
      return audienceBaseNames.join(' & ');
    }

    return `${itemsLength} ${audienceBaseType}`;
  }

  function getStatusName(status) {
    const lowStatus = status.toLowerCase();
    return lowStatus.charAt(0).toUpperCase() + lowStatus.slice(1);
  }

  function getMatchedCount() {
    if (!matchedAccounts || !totalAccounts) {
      return '-';
    }

    return `${Math.round((matchedAccounts / totalAccounts) * 100)}%`;
  }

  function getMatchedAndTotalAccounts() {
    if (!matchedAccounts || !totalAccounts) {
      return '-';
    }

    return `${matchedAccounts}/${totalAccounts}`;
  }

  function getProducts() {
    if (productRecords && productRecords.length) {
      const productsList = productRecords.slice(0, 3);
      let names = productsList.map((product) => product.name).join(', ');
      if (productRecords.length > 3) {
        names += ' ...';
      }
      return names;
    } else {
      return null;
    }
  }

  function getCategories() {
    if (categoryRecords && categoryRecords.length) {
      const categoriesList = categoryRecords.slice(0, 3);
      let names = categoriesList.map((category) => category.name).join(', ');
      if (categoryRecords.length > 3) {
        names += ' ...';
      }
      return names;
    } else {
      return null;
    }
  }

  function toggleDetails(evt) {
    // to prevent changing toggle on closing modal
    if (evt.target.closest('.trModal')) {
      return;
    }

    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    setToggle((prev) => !prev);
  }

  function toggleAccountsModal(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if (!companies || !companies.length) {
      return;
    }

    setShowAccounts((prev) => !prev);
  }

  function toggleDeleteSegmentModal(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setShowDeleteSegment((prev) => !prev);
  }

  async function decisionHandler(agree) {
    toggleDeleteSegmentModal();

    const segmentId = segment.linkedInSegmentId;

    if (agree) {
      try {
        await deleteLinkedInSegment(segmentId);
        updateSegments();
      } catch (err) {
        let errorMessage = 'Something went wrong';

        const { status } = err.response;
        if (status === 400) {
          errorMessage = 'Bad request';
        } else if (status === 403) {
          errorMessage = "You don't have permission to delete";
        } else if (status === 404) {
          errorMessage = 'Segment is not found';
        }

        setShowToast(true);
        setToastMessage({
          heading: 'Something went wrong while deleting your segment',
          body: `${errorMessage} Please try again or contact us support@trustradius.com`,
        });
        console.error(
          (err.response && err.response.data && err.response.data.message) ||
            err,
        );
      }
    }
  }

  const toggleChevron = toggle ? <ChevronDown /> : <ChevronRight />;

  function renderViewCompanies() {
    return (!companies || !companies.length) &&
      companiesError &&
      !companiesError.includes('No Audience Builder results') ? (
      <HelpIcon
        iconType="custom"
        icon={<AlertTriangle />}
        helpTip={<p>{companiesError}</p>}
        placement="bottom"
      />
    ) : (
      <Button
        className={styles['view-companies']}
        disabled={!companies || companies.length === 0}
        variant="link"
        onClick={toggleAccountsModal}
      >
        View Companies
      </Button>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={toggleDetails}
      className={classNames(
        className,
        styles.container,
        switchEnabled ? styles['switch-enabled'] : null,
      )}
    >
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        closeButton
        autohide
        heading={<span>{toastMessage.heading}</span>}
        className={styles.toast}
      >
        <span>{toastMessage.body}</span>
      </Toast>
      <div className={styles.toggle}>
        {toggleChevron}
        <span className={styles['sr-only']}>Show Details</span>
      </div>
      <div className={styles.header}>
        <h3 className={styles.heading}>{segment.name}</h3>
        <div className={styles.description}>{getDescription()}</div>
      </div>
      {switchEnabled && (
        <div className={styles.switch}>
          <Switch label="Activate Segment" hideLabel size="large" />
        </div>
      )}
      {(!isMobile || toggle) && (
        <PrimaryMetrics
          audience={destination.audienceSize || '-'}
          matchedAndTotalAccounts={getMatchedAndTotalAccounts()}
          matchRate={getMatchedCount()}
          segmentStatus={getStatusName(destination.statusEnum)}
        />
      )}
      {toggle && (
        <div className={styles['secondary-content']}>
          <SecondaryMetrics
            author={`${userRecord.name.first} ${userRecord.name.last}`}
            dateCreated={dateCreated}
            account={{ name: segment.account.name, id: segment.account.id }}
            products={getProducts()}
            category={getCategories()}
          />
          <div className={styles.accounts}>
            <AccountsModal
              show={showAccounts}
              handleClose={toggleAccountsModal}
              segmentName={segment.name}
              accounts={companies}
              noAccountsText="No Audience Builder accounts found"
            />
            {renderViewCompanies()}
            <DeleteSegmentModal
              show={showDeleteSegment}
              handleClose={toggleDeleteSegmentModal}
              segmentName={segment.name}
              decisionHandler={decisionHandler}
            />
            <Button
              className={styles.delete}
              variant="negative-pill"
              onClick={toggleDeleteSegmentModal}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

SegmentItem.propTypes = {
  segment: segmentShape.isRequired,
  updateSegments: PropTypes.func.isRequired,
  className: PropTypes.string,
  switchEnabled: PropTypes.bool,
  companies: PropTypes.arrayOf(accountShape),
};
