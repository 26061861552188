import ReactBootstrapModalBody from 'react-bootstrap/ModalBody';
import classNames from 'classnames';

import './Modal.scss';

/**
 * TrustRadius wrapped ModalBody component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function ModalBody(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapModalBody
      bsPrefix="trModal-body"
      className={classes}
      {...otherProps}
    >
      {children}
    </ReactBootstrapModalBody>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
ModalBody.propTypes = ReactBootstrapModalBody.propTypes;
