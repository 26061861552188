import { PropTypes } from 'prop-types';
import styles from './CircleIcon.module.scss';
import icons from './icons';

export function CircleIcon({
  iconType = 'emptyLead',
  color = '#116BF2',
  backgroundColor = '#DAEAFE',
  backgroundColorFade = '#E7F2FE',
}) {
  return (
    <div
      className={styles.icon}
      data-testid="fade-background"
      style={{ color, background: backgroundColorFade }}
    >
      <div
        className={styles['icon-inner']}
        data-testid="background"
        style={{ color, background: backgroundColor }}
      >
        {icons[iconType]}
      </div>
    </div>
  );
}

CircleIcon.propTypes = {
  iconType: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorFade: PropTypes.string,
};
