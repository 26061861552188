import { useTable, usePagination } from 'react-table';
import { useState } from 'react';
import { Input, Text, Pagination } from '@trustradius/radiant-ui';
import { Search } from 'lucide-react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { ReviewGenerationCopyButton } from 'components/ReviewGenerationCopyButton';
import styles from './ReviewGenerationProductTable.module.scss';

async function copyToClipboard(slug) {
  await navigator.clipboard.writeText(
    `https://www.trustradius.com/welcome/${slug}?utm_source=VendorSourced&utm_campaign=VPCRC`,
  );
}

const columns = [
  {
    Header: () => <span>Product Name</span>,
    accessor: 'name',
  },
  {
    id: 'link',
    accessor: (row) => (
      <span className={styles['copy-button']}>
        <ReviewGenerationCopyButton
          variant="none"
          text="Copy campaign link"
          value={row.slug}
          copyText="Copied!"
          clickHandler={copyToClipboard}
        />
      </span>
    ),
    Header: () => <span>Shareable review campaign link</span>,
  },
];

export function ReviewGenerationProductTable({ products = [] }) {
  const [data, setData] = useState(products);
  const [search, setSearch] = useState('');

  const setSearchFilter = (value) => {
    setSearch(value);
    const newData = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase()),
    );
    setData(newData);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageOptions,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    usePagination,
  );

  const { pageIndex } = state;

  return (
    <>
      <div className={styles['table-container']}>
        <div className={styles.header}>
          <Text as="h2">Non-incentivized campaign links</Text>
          <div className={styles.search}>
            <Search className={styles['search-icon']} />
            <Input
              placeholder="Search"
              value={search}
              onChange={(event) => setSearchFilter(event?.target?.value)}
              hideLabel
            />
          </div>
        </div>
        <table {...getTableProps()} className={styles.table}>
          <thead className={styles['table-head']}>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()} className={styles.tr}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td {...cell.getCellProps()} className={styles.td}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles['pagination-container']}>
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={_.range(1, pageOptions.length + 1)}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
        />
      </div>
    </>
  );
}

ReviewGenerationProductTable.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
};
