import { useParams } from 'react-router-dom';
import { PageBody } from 'components/PageBody';
import { LookerContainer } from 'components/LookerContainer';
import styles from './Metrics.module.scss';

export function Metrics() {
  const { report } = useParams();

  return (
    <div className={styles.metrics}>
      <PageBody className={styles['page-body']}>
        <div className={styles['metrix-iframe']}>
          <LookerContainer dashboardId={report} />
        </div>
      </PageBody>
    </div>
  );
}
