import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trustradius/radiant-ui';
import { Toast } from 'components/TrustradiusUICore';
import {
  getLinkedInSegments,
  getLinkedInSegmentsCompanies,
} from 'store/apis/limaApi';
import { useNavigateToLinkedIn } from '../limaAuth';
import { LinkedInAuthEmpty } from './LinkedInAuthEmpty';
import { SegmentItem } from './SegmentItem';
import { NotificationBanner } from './NotificationBanner';
import styles from './LimaSegmentsBody.module.scss';

export function LimaSegmentsBody({ vendorId, hasAnalyticsScopes }) {
  const [segments, setSegments] = useState({});
  const [companies, setCompanies] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ heading: '', body: '' });

  const navigateToLinkedIn = useNavigateToLinkedIn();

  const getSegments = useCallback(async () => {
    try {
      const result = await getLinkedInSegments({ vendor: vendorId });
      setSegments(result.data.segments);
      return result.data.segments;
    } catch (err) {
      setShowToast(true);
      setToastMessage({
        heading: 'Something went wrong while loading your segments',
        body: 'Please try again or contact us support@trustradius.com',
      });
      console.error(
        (err.response && err.response.data && err.response.data.message) || err,
      );
    }
  }, [vendorId]);

  async function getCompanies(segmentsIds) {
    try {
      const result = await getLinkedInSegmentsCompanies({
        segments: segmentsIds.join(','),
      });
      setCompanies(result.data.companies);
    } catch (err) {
      setShowToast(true);
      setToastMessage({
        heading: 'Something went wrong while loading segments companies',
        body: 'Please refresh a page or contact us support@trustradius.com',
      });
      console.error(
        (err.response && err.response.data && err.response.data.message) || err,
      );
    }
  }

  useEffect(() => {
    async function loadPageInfo() {
      const dmpSegments = await getSegments();

      const segmentsIds = [];
      for (const profileId of Object.keys(dmpSegments)) {
        for (const segment of dmpSegments[profileId]) {
          segmentsIds.push(segment.linkedInSegmentId);
        }
      }

      if (segmentsIds.length) {
        await getCompanies(segmentsIds);
      }
    }

    loadPageInfo();
  }, [getSegments]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function updateSegments() {
    getSegments();
  }

  function getProfileName(profile) {
    // we can get first because they are filtered by account id
    return segments[profile][0].account.name;
  }

  function renderSegments() {
    return (
      <>
        {Object.keys(segments).map((profile) => (
          <div key={profile}>
            <div className={styles.profile}>
              <div className={styles['profile-name']}>
                {getProfileName(profile)}&nbsp;
              </div>
              <div className={styles['profile-id']}>{`(ID: ${profile})`}</div>
            </div>
            <div className={styles['segments-container']}>
              {segments[profile].map((segment) => (
                <SegmentItem
                  key={segment._id}
                  className={styles['segment-container']}
                  segment={segment}
                  updateSegments={updateSegments}
                  segmentName={segment.name}
                  companies={companies[segment.linkedInSegmentId]}
                />
              ))}
            </div>
          </div>
        ))}
      </>
    );
  }

  function getBannerText() {
    return (
      <div className={styles.textWrapper}>
        <div className={styles['banner-title']}>
          Unlock LinkedIn campaign reporting!
        </div>
        <div className={styles['banner-subtitle']}>
          Grant TrustRadius access to your campaign reporting for more
          insights.&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://trustradius.freshdesk.com/support/solutions/articles/43000672436-how-to-enable-campaign-reporting-for-linkedin-matched-audiences"
          >
            Learn more
          </a>
        </div>
      </div>
    );
  }
  const rightItem = (
    <Button variant="secondary" size="large" onClick={navigateToLinkedIn}>
      Re-authenticate to LinkedIn
    </Button>
  );

  const LinkedInAuthEmptyArgs = {
    text: 'Create your first segment to get started. Not sure how to get started?',
    learnMoreHref:
      'https://trustradius.freshdesk.com/support/solutions/folders/43000581494',
    createSegmentHref: 'create',
  };

  const mainContent = isEmpty(segments) ? (
    <LinkedInAuthEmpty {...LinkedInAuthEmptyArgs} />
  ) : (
    renderSegments()
  );

  return (
    <>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        closeButton
        autohide
        heading={<span>{toastMessage.heading}</span>}
        className={styles.toast}
      >
        <span>{toastMessage.body}</span>
      </Toast>
      {!hasAnalyticsScopes && (
        <NotificationBanner leftItem={getBannerText()} rightItem={rightItem} />
      )}
      {mainContent}
    </>
  );
}

LimaSegmentsBody.propTypes = {
  vendorId: PropTypes.string.isRequired,
  hasAnalyticsScopes: PropTypes.bool.isRequired,
};
