import PropTypes from 'prop-types';

export const accountShape = PropTypes.shape({
  name: PropTypes.string,
  website: PropTypes.string,
});

const configShape = PropTypes.shape({
  userRecord: PropTypes.shape({
    name: PropTypes.shape({
      first: PropTypes.string.isRequired,
      last: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  productRecords: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categoryRecords: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  companies: PropTypes.arrayOf(accountShape),
});

export const segmentShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  linkedInSegmentId: PropTypes.number.isRequired,
  inputCount: PropTypes.number,
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      destination: PropTypes.string.isRequired,
      statusEnum: PropTypes.string.isRequired,
      audienceSize: PropTypes.number,
      matchedCount: PropTypes.number,
    }),
  ).isRequired,
  config: configShape.isRequired,
});
