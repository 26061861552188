import { useSelector } from 'react-redux';
import { RightBarContainer } from 'components/RightBarContainer';

const supportLink = 'https://trustradius.freshdesk.com/support/solutions';
const ticketLink = 'https://trustradius.freshdesk.com/support/tickets/new';

export function TRInnerCircleContainer() {
  const productTeam = useSelector((state) => state.products.team);
  const sectionTitle = productTeam.length
    ? 'Your TrustRadius Inner Circle'
    : "We're here for you";

  return (
    <RightBarContainer title={sectionTitle}>
      <section>
        <p>
          For more information please check out our&nbsp;
          <a href={supportLink} target="_blank" rel="noreferrer">
            support documentation
          </a>
          . If you have further questions or need additional support,
          please&nbsp;
          <a href={ticketLink} target="_blank" rel="noreferrer">
            submit a ticket
          </a>
          .
        </p>
      </section>
    </RightBarContainer>
  );
}
