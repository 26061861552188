function LinkedIn(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>LinkedIn</title>
      <rect fill="currentColor" width="4.72" height="14.22" x="1.26" y="8.32" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.29,3.92A2.46,2.46,0,0,0,3.65,1.46a2.47,2.47,0,1,0-.06,4.92h0A2.46,2.46,0,0,0,6.29,3.92Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23,14.38C23,10,20.69,8,17.58,8a4.69,4.69,0,0,0-4.26,2.34v-2H8.6c.06,1.33,0,14.22,0,14.22h4.72V14.6a3.16,3.16,0,0,1,.16-1.16,2.58,2.58,0,0,1,2.42-1.73c1.71,0,2.4,1.31,2.4,3.22v7.61H23Z"
      />
    </svg>
  );
}

export default LinkedIn;
