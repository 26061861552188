import PropTypes from 'prop-types';
import { Logo, ClearbitLogo } from 'components/TrustradiusUICore';
import styles from './VendorInfo.module.scss';

export function VendorInfo({
  logo,
  name,
  industry,
  domain,
  companySize,
  city,
  regionShort,
  countryShort,
}) {
  const companyLogo = domain ? (
    <ClearbitLogo website={domain} />
  ) : (
    <Logo logo={logo} />
  );

  const companyInfo = [industry, companySize].filter(Boolean).join(' | ');
  const locationInfo = [city, regionShort, countryShort]
    .filter(Boolean)
    .join(', ');

  return (
    <div className={styles.container}>
      <div className={styles.logo}>{companyLogo}</div>
      <div className={styles.info}>
        <h2 className={styles.name}>{name}</h2>
        <p>{companyInfo}</p>
        <p>{locationInfo}</p>
      </div>
    </div>
  );
}

VendorInfo.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  industry: PropTypes.string,
  domain: PropTypes.string,
  companySize: PropTypes.string,
  city: PropTypes.string,
  regionShort: PropTypes.string,
  countryShort: PropTypes.string,
};
