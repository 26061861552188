import PropTypes from 'prop-types';
import styles from './EmptyTable.module.scss';
import { Mail, Filter, Search } from '.';

export function EmptyTable({ iconName, title, description }) {
  const icons = {
    filter: <Filter />,
    search: <Search />,
    mail: <Mail />,
  };

  return (
    <div className={styles.container}>
      {icons[iconName] && icons[iconName]}
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
}

EmptyTable.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
