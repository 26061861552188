import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getVendorLicensesByProduct } from 'store/apis/vendorApi';

export const withLicenseData = (Element) => {
  function WrappedComponent(props) {
    const vendor = useSelector((state) => state.vendors.currentVendor);
    const [vendorFeatures, setVendorFeatures] = useState([]);
    const [vendorFeaturesByProductId, setVendorFeaturesByProductId] = useState(
      {},
    );

    useEffect(() => {
      async function fetch() {
        if (!vendor?._id) {
          return;
        }
        const data = await getVendorLicensesByProduct(vendor._id);
        setVendorFeaturesByProductId(data?.data || {});
        const features = new Set();

        if (data?.data) {
          Object.keys(data?.data).forEach((productId) => {
            data?.data[productId].features.forEach((feature) => {
              features.add(feature?.slug);
            });
          });
          setVendorFeatures(Array.from(features));
        }
      }
      fetch();
    }, [vendor?._id, setVendorFeatures, setVendorFeaturesByProductId]);

    return (
      <Element
        {...props}
        vendorFeatures={vendorFeatures}
        vendorFeaturesByProductId={vendorFeaturesByProductId}
      />
    );
  }

  return WrappedComponent;
};
