import { Suspense } from 'react';
import { Await, Outlet, useLoaderData, defer } from 'react-router-dom';
import { LimaContent } from 'components/LinkedinMatchedAudiences';
import { getLimaStatus } from 'store/apis/limaApi';

export function linkedinMatchedAudiencesLoader({ params }) {
  return defer({
    limaStatus: getLimaStatus({ vendorId: params.vendorId }),
  });
}

export function LinkedinMatchedAudiencesPage() {
  const data = useLoaderData();

  return (
    <>
      <Suspense fallback={<LimaContent isLoading />}>
        <Await
          resolve={data.limaStatus}
          errorElement={<LimaContent isLoaderError />}
        >
          {(limaStatus) => <LimaContent limaStatus={limaStatus} />}
        </Await>
      </Suspense>
      <Outlet />
    </>
  );
}
