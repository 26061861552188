import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '@trustradius/radiant-ui';
import { IntegrationsCard } from 'components/IntegrationsCard/IntegrationsCard';
import { getApiKey } from 'store/apis/vendorApi';
import { TrustradiusApiKey } from 'components/TrustradiusApiKey';
import { useSettings } from 'settings';
import { getIntegrationCards } from 'pages/Integrations/integrationCards';
import styles from './Integrations.module.scss';

export function Integrations() {
  const { vendorId } = useParams();
  const [apiKey, setApiKey] = useState();
  const settings = useSettings();

  useEffect(() => {
    (async () => {
      const vendorApiKey = await getApiKey(vendorId);
      if (vendorApiKey && vendorApiKey.data) {
        setApiKey(vendorApiKey.data);
      }
    })();
  }, [vendorId]);

  const integrationCards = useMemo(
    () => getIntegrationCards(settings, vendorId),
    [vendorId, settings],
  );

  return (
    <section className={styles.section}>
      <header className={styles['page-header']}>
        <div className={styles['title-block']}>
          <Text as="h1" size="600" weight="bold" className={styles.heading}>
            Integrations
          </Text>
        </div>
        <div className={styles['action-block']}>
          <TrustradiusApiKey apiKey={apiKey} />
        </div>
      </header>
      <div className={styles['page-body']}>
        {integrationCards.map((card) => (
          <div
            key={card.title.replace(' ', '-')}
            data-testid="card"
            className={styles['card-wrapper']}
          >
            <IntegrationsCard {...card} />
          </div>
        ))}
      </div>
    </section>
  );
}
