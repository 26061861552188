import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ChevronLeft from 'components/TrustradiusUICore/Icon/Icons/ChevronLeft';
import ChevronRight from 'components/TrustradiusUICore/Icon/Icons/ChevronRight';
import styles from './Pagination.module.scss';

export function Pagination({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
}) {
  return (
    <div className={styles.pagination}>
      <button
        className={styles.button}
        type="button"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        aria-label="Previous page"
      >
        <ChevronLeft />
      </button>{' '}
      <button
        type="button"
        className={classNames(styles.button, {
          [styles.selected]: true,
        })}
      >
        {pageIndex + 1}
      </button>
      {pageCount - pageIndex > 2 && (
        <button
          type="button"
          onClick={() => gotoPage(pageIndex + 1)}
          className={styles.button}
        >
          {pageIndex + 2}
        </button>
      )}
      {pageCount - pageIndex > 3 && (
        <button
          type="button"
          onClick={() => gotoPage(pageIndex + 2)}
          className={styles.button}
        >
          {pageIndex + 3}
        </button>
      )}
      <button
        type="button"
        onClick={() => nextPage()}
        className={styles.button}
        disabled={!canNextPage}
        aria-label="Next page"
      >
        <ChevronRight />
      </button>
    </div>
  );
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
};
