import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { GET_LEAD_BY_ID } from 'graphql/leads';
import { Spinner } from 'components/Spinner';
import { LeadDetail } from './LeadDetail';

export function LeadDetailContainer() {
  const { leadId } = useParams();

  const { loading, data, error } = useQuery(GET_LEAD_BY_ID, {
    variables: {
      id: leadId,
    },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    throw new Error("Can't get lead data");
  }
  const { lead } = data;

  const date = moment(lead.createdAt).format('MMM DD, YYYY');

  const leadData = {
    name: `${lead.firstName}  ${lead.lastName}`,
    date,
    email: lead.email,
    location: lead.country?.name,
    company: lead.company,
    product: lead.productName,
    interest: lead.interestedIn,
    message: lead?.message,
  };

  return <LeadDetail data={leadData} />;
}
