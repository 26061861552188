import PropTypes from 'prop-types';
import Icons from './Icons';

export function Icon({ name, ...otherProps }) {
  const CurrentIcon = Icons[name];
  return <CurrentIcon {...otherProps} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};
