import {
  Filter,
  Trash2,
  Users,
  Edit,
  Send,
  PartyPopper,
  Save,
  Unlink,
  Pointer,
} from 'lucide-react';
import styles from '../CircleIcon.module.scss';
import { ReactComponent as Webhook } from './webhook.svg';

const icons = {
  delete: <Trash2 data-testid="delete" />,
  emptyLead: (
    <div className={styles['empty-lead']} data-testid="empty-lead">
      <Users />
      <Filter />
    </div>
  ),
  webhook: <Webhook data-testid="webhook" />,
  edit: <Edit data-testid="edit" />,
  celebrate: <PartyPopper data-testid="celebrate" />,
  paperAirplane: <Send data-testid="paper-airplane" />,
  save: <Save data-testid="save" />,
  unlink: <Unlink data-testid="unlink" />,
  pointer: <Pointer data-testid="pointer" />,
};

export default icons;
