import {
  fetchCompetitorsByProductIdSuccess,
  fetchCompetitorsByProductIdStart,
  fetchCompetitorsByProductIdFailed,
} from '../actions/competitorsActions';
import { axiosRequestWithAuthToken } from './axios_utils';

export const fetchCompetitorsByProductId =
  (productId, timeFrame) => async (dispatch) => {
    try {
      dispatch(fetchCompetitorsByProductIdStart());
      const params = {
        days: timeFrame,
      };
      const options = {
        url: `/api/products/${productId}/competitors`,
        method: 'GET',
        params,
      };
      const data = await axiosRequestWithAuthToken(options);
      dispatch(fetchCompetitorsByProductIdSuccess(data.data.records));
    } catch (error) {
      handleError(dispatch, error);
    }
  };

function handleError(dispatch, err) {
  console.error('Fetching competitors failed', err);
  dispatch(fetchCompetitorsByProductIdFailed(err));
}
