import { Users, Zap, ListOrdered, DollarSign } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Text } from '@trustradius/radiant-ui';
import {
  SecondaryPageHeader,
  TextBanner,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  Prerequisites,
  UseCases,
} from 'components/SecondaryPage';
import { TrustradiusApiKey } from 'components/TrustradiusApiKey';
import { SixsenseSquareLogo } from 'assets/logos-companies';
import { getApiKey } from 'store/apis/vendorApi';
import { useIntentRegistration, useHaveLicense } from 'hooks';
import { sixSenseBanner, sixSenseBanner2x } from './assets';

export function SixSense() {
  const { vendorId } = useParams();
  const vendor = useSelector((state) => state.vendors.currentVendor);
  const [apiKey, setApiKey] = useState();
  const intentLicenses =
    'api-free-intent-comparisons,api-vi-category-level,api-vi-product-level';
  const { hasLicense } = useHaveLicense(vendorId, intentLicenses);
  const { intentRegistration } = useIntentRegistration(
    vendorId,
    '6sense',
    hasLicense,
  );

  useEffect(() => {
    async function initialSetApiKey() {
      const vendorApiKey = await getApiKey(vendorId);
      if (vendorApiKey && vendorApiKey.data) {
        setApiKey(vendorApiKey.data);
      }
    }

    initialSetApiKey();
  }, [vendorId]);

  const subTitle = intentRegistration?.enabled
    ? `Configured on: ${moment(intentRegistration?.created).format(
        'MM/DD/YYYY',
      )} |  Last data delivery: ${moment(
        intentRegistration?.lastDelivery,
      ).format('MM/DD/YYYY')}`
    : 'Target the right accounts using TrustRadius downstream intent data in 6sense.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
    },
    title: '6sense',
    logo: <img src={SixsenseSquareLogo} alt="6sense" />,
    subtitle: subTitle,
    actionBlock: <TrustradiusApiKey apiKey={apiKey} />,
  };

  const withoutIntentMessage = () => {
    return (
      <Text as="p">
        This integration requires TrustRadius intent data.
        <a
          href="https://www.trustradius.com/resources/assets/global-demo"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Activate your free trial
        </a>
        .
      </Text>
    );
  };

  const keyBenefits = [
    {
      id: 1,
      icon: <Users />,
      text: 'Discover new high-value, in-market buyers',
    },
    {
      id: 2,
      icon: <Zap />,
      text: 'Power your ABM strategy',
    },
    {
      id: 3,
      icon: <ListOrdered />,
      text: 'Prioritize qualified accounts with 6sense scoring',
    },
    {
      id: 4,
      icon: <DollarSign />,
      text: 'Access deal intelligence to increase win rates and retention',
    },
  ];

  const installation = [
    {
      id: 'instruction',
      componentContent: (
        <a href="https://trustradius.freshdesk.com/support/solutions/articles/43000639050-how-to-configure-the-6sense-trustradius-integration">
          Installation instructions
        </a>
      ),
    },
  ];

  const prerequisites = [
    {
      id: 'subscriptions',
      componentContent: (
        <Text as="p">
          An intent data license is required to unlock this feature.&nbsp;
          <a
            href="https://www.trustradius.com/vendor/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </Text>
      ),
    },
  ];

  const useCases = ['Account Based Marketing (ABM)'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      {!hasLicense && <TextBanner>{withoutIntentMessage()}</TextBanner>}
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'prerequisites',
            element: <Prerequisites content={prerequisites} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={sixSenseBanner}
            srcSet={`${sixSenseBanner} 1x, ${sixSenseBanner2x} 2x`}
            alt="SixSense"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About 6sense</Text>
          <Text as="p">
            6sense is a comprehensive account-based sales and marketing platform
            that helps B2B organizations achieve predictable revenue growth by
            putting the power of AI, big data, and machine learning behind every
            member of the revenue team.
          </Text>
          <Text as="h3">6sense and TrustRadius</Text>
          <Text as="p">
            Through the TrustRadius and 6sense partnership, our mutual customers
            can leverage TrustRadius downstream intent data to build audiences
            and provide deal intelligence to sales. These audiences can then be
            built into rich target segments where vendors can measure the
            success and outcome of high-value accounts through ABM campaigns.
            TrustRadius&apos; high-fidelity intent signals are available in the
            timeline view and Salesforce iframe within 6sense, making it the
            only second-party intent source fully integrated into the 6sense
            platform.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Discover new high-value, in-market buyers</Text>
          <Text as="p">
            Create dynamic account lists of companies that are showing intent
            for your product, your competitors&apos;, or your category on
            TrustRadius. You can also combine TrustRadius intent with other
            intent sources and 6sense filters to create more refined account
            lists.
          </Text>
          <Text as="h3">Power your ABM strategy</Text>
          <Text as="p">
            Improve conversions by reaching out at the right time with the right
            messaging to in-market accounts across your marketing campaigns and
            sales outreach.
          </Text>
          <Text as="h3">Prioritize qualified accounts with 6sense scoring</Text>
          <Text as="p">
            6sense&apos;s proprietary data model scores intent activities,
            allowing you to easily prioritize accounts that are engaging with
            TrustRadius and are more likely to make a purchase decision.
          </Text>
          <Text as="h3">
            Access deal intelligence to increase win rates and retention
          </Text>
          <Text as="p">
            By integrating your TrustRadius intent data with 6sense&apos;s
            segmentation tool, you can see who is looking at pricing, FAQs,
            product alternatives, feature scorecards, and reviews, then act
            quickly to convert those buyers. You can also capitalize on upsell
            and cross-sell opportunities and mitigate churn risk.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
