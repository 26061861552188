import { useSelector } from 'react-redux';
import { User, Sliders, PieChart } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { TourialSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { tourialBanner, tourialBanner2x } from './assets';

export function Tourial() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Create interactive product tours that engage buyers faster and drive more conversions.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Tourial',
    logo: <img src={TourialSquareLogo} alt="Tourial" />,
    subtitle: subTitle,
    actionBlock: (
      <Button
        size="large"
        url="https://tourial.com/tlp/get-a-free-tour-on-your-trustradius-page"
        targetBlank
      >
        Create a free demo
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: '1',
      icon: <User />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: '2',
      icon: <Sliders />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: '3',
      icon: <PieChart />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={tourialBanner}
            srcSet={`${tourialBanner} 1x, ${tourialBanner2x} 2x`}
            alt="Tourial"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Tourial</Text>
          <Text as="p">
            Tourial’s Product Tour Marketing platform is built for demand gen
            and product marketing teams to drive more conversions and increase
            qualified pipeline. Tourial’s intuitive builder enables teams to
            create interactive walkthroughs of their platform, share them
            anywhere, and gauge tour performance and buyer intent using
            analytics and engagement data.
          </Text>
          <Text as="h3">Tourial and TrustRadius</Text>
          <Text as="p">
            With TrustRadius’ integration with Tourial, you can easily add your
            interactive product tour powered by Tourial to TrustRadius profile
            pages. By adding your tour, visitors can quickly see your product’s
            “how” without ever having to leave your TrustRadius profile.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Connect buyers with high-value interactive demos</Text>
          <Text as="p">
            Helping buyers self-educate allows them to see value early on and
            leads to better quality conversations later.
          </Text>
          <Text as="h3">Send prospects on a memorable journey</Text>
          <Text as="p">
            Enable buyers to take a self-guided tour of your product and
            evaluate whether it’s a fit for their organization.
          </Text>
          <Text as="h3">Increase lead velocity by eliminating friction</Text>
          <Text as="p">
            Optimize your tour to answer frequently asked questions.
          </Text>
          <Text as="h3">Get intelligence on who’s shopping your product</Text>
          <Text as="p">
            Understand whether buyers are engaging with your product tour.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
