import { Text, RadioGroup, RadioItem, Input } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import { CustomFields } from 'pages/WebhookConfigurationPage/CustomFields';
import { LeadFormFields } from 'pages/WebhookConfigurationPage/LeadFormFields';
import styles from './WebhookConfigurationPage.module.scss';

export function OtherSystemsPage({
  endpoint,
  setEndpoint,
  format,
  setFormat,
  hiddenFields,
  setHiddenFields,
  leadFormFields,
  setLeadFormFields,
  customHeaders,
  setCustomHeaders,
}) {
  return (
    <>
      <div className={styles['content-container']}>
        <div className={styles['block-container']}>
          <div className={styles['block-container']}>
            <Text as="h2" size="400" weight="semibold">
              Define endpoint
            </Text>
          </div>
          <div className={styles['block-container']}>
            <Text as="h2" size="300" weight="semibold">
              Endpoint URL
            </Text>
            <Text as="p">
              Copy and paste your endpoint url from your Salesforce instance
              into this field.
            </Text>
            <Input
              id="endpoint-url"
              type="text"
              autoComplete="off"
              name="endpointUrl"
              value={endpoint}
              onChange={(event) => {
                setEndpoint(event.target.value);
              }}
              hideLabel
            />
          </div>
          <div className={styles['block-container']}>
            <Text as="h2" size="300" weight="semibold">
              Headers
            </Text>
            <Text as="p">
              Add custom headers to the payload that&apos;s delivered to your
              endpoint. Custom headers can be used to authenticate webhook
              requests.
            </Text>
            <CustomFields
              customHeaders={customHeaders}
              setCustomHeaders={setCustomHeaders}
            />
          </div>
          <div className={styles['block-container']}>
            <Text as="h2" size="300" weight="semibold">
              Format
            </Text>
            <Text as="p">
              Select the format that should be used when sending your leads
              data.
            </Text>

            <RadioGroup
              name="default-group"
              disabled={false}
              id="format"
              onChange={(value) => {
                setFormat(value);
              }}
            >
              <RadioItem
                id="radio-children-group-0"
                label="Form Data"
                value="formData"
                selected={format === 'formData'}
              />
              <RadioItem
                id="radio-children-group-1"
                label="JSON"
                value="json"
                selected={format === 'json'}
              />
            </RadioGroup>
          </div>
        </div>
        <div className={styles['block-container']}>
          <Text as="h2" size="300" weight="semibold">
            Map lead form fields
          </Text>
          <Text as="p">
            Use this table to map your Lead Form fields to their corresponding
            fields in your destination system. You can also specify a default
            value to use if a field is left blank.
          </Text>
        </div>
        <LeadFormFields
          leadFormFields={leadFormFields}
          changeLeadFormFields={setLeadFormFields}
        />
        <div className={styles['block-container']}>
          <Text as="h2" size="400" weight="semibold">
            Add hidden fields
          </Text>
          <Text as="p">Copy</Text>
          <CustomFields
            customHeaders={hiddenFields}
            setCustomHeaders={setHiddenFields}
          />
        </div>
      </div>
    </>
  );
}

OtherSystemsPage.propTypes = {
  endpoint: PropTypes.string,
  setEndpoint: PropTypes.func,
  format: PropTypes.string,
  setFormat: PropTypes.func,
  hiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setHiddenFields: PropTypes.func,
  leadFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setLeadFormFields: PropTypes.func,
  customHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setCustomHeaders: PropTypes.func,
};
