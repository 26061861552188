import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { extend, indexBy } from 'underscore';
import { useParams, useNavigate } from 'react-router-dom';
import { getProductsChangesInfo } from 'store/apis/productChangesApi';
import { ProductSwitcher } from './ProductSwitcher';

export function ProductSwitcherContainer() {
  const [productsChangeInfo, setProductsChangeInfo] = useState([]);
  const { vendorId, productId } = useParams();
  const navigate = useNavigate();
  const products = useSelector(
    (state) => state.vendors.currentVendorProductsById,
  );

  useEffect(() => {
    (async () => {
      const data = await getProductsChangesInfo(vendorId);
      if (data?.length > 0) {
        setProductsChangeInfo(data);
      }
    })();
  }, [vendorId]);

  const productsArray = useMemo(() => {
    const productsChangeInfoByIndex = indexBy(
      productsChangeInfo,
      (item) => item.changeInfo.product,
    );

    const productsWithStatuses = [];

    for (const productItemId in products) {
      if (Object.prototype.hasOwnProperty.call(products, productItemId)) {
        const product = products[productItemId];
        const productChangeInfo = productsChangeInfoByIndex[product._id];

        if (!productChangeInfo) {
          productsWithStatuses.push(product);
          continue;
        }

        const productWithChangeInfo = JSON.parse(JSON.stringify(product));
        extend(productWithChangeInfo, { changeInfo: { ...productChangeInfo } });
        productsWithStatuses.push(productWithChangeInfo);
      }
    }
    return productsWithStatuses;
  }, [products, productsChangeInfo]);

  const currentProduct = products[productId];

  const changeProduct = (event) => {
    event.stopPropagation();
    navigate(`/vendor/${vendorId}/dashboard/${event.target.value}`);
  };

  if (!currentProduct?._id) return null;

  return (
    <ProductSwitcher
      product={currentProduct}
      products={productsArray}
      handleProductChange={changeProduct}
    />
  );
}
