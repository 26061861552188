import { Component, createRef, Children, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import ReactBootstrapOverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Fade from 'react-bootstrap/Fade';
import PropTypes from 'prop-types';
import { Overlay } from '../Overlay';

const normalizeDelay = (delay) =>
  delay && typeof delay === 'object' ? delay : { show: delay, hide: delay };

class RefHolder extends Component {
  render() {
    return this.props.children;
  }
}
const refPropTypes = {
  children: PropTypes.element.isRequired,
};
RefHolder.propTypes = refPropTypes;

const defaultProps = {
  // defaultShow: false,
  delay: { show: 300, hide: 300 },
  trigger: ['hover', 'focus'],
};

/**
 * InteractiveOverlayTrigger attaches the desired event handlers to both the triggering
 * component as well as the overlay to be shown, making it stay visible while the use is
 * "interacting" with the overlay. Basically a copy of react-bootstrap's
 * implementation of OverlayTrigger but with ability for the overlay content to
 * be interacted with by user
 * https://github.com/react-bootstrap/react-bootstrap/blob/master/src/OverlayTrigger.js
 */
class InteractiveOverlayTrigger extends Component {
  state = {
    open: null,
  };

  triggerRef = createRef();

  // Defining a class function for finding the DOM node for the ref that we're
  // keeping track of so that popover can be shown properly for the overlay trigger
  getTarget = () => ReactDOM.findDOMNode(this.triggerRef.current);

  getAriaModifier = () => {
    // We add aria-describedby in the case where the overlay is a role="tooltip"
    // for other cases describedby isn't appropriate (e.g. a popover with inputs) so we don't add it.

    return {
      enabled: true,
      order: 900,
      fn: (data) => {
        const { popper } = data.instance;
        const target = this.triggerRef.current;
        if (!this.state.open || !target) {
          return data;
        }

        const role = popper.getAttribute('role') || '';
        if (popper.id && role.toLowerCase() === 'tooltip') {
          target.setAttribute('aria-describedby', popper.id);
        }
        return data;
      },
    };
  };

  handleClick = (event) => {
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    this.handleOpen(event);
  };

  handleFocus = (event) => {
    // Workaround for https://github.com/facebook/react/issues/7769
    // The autoFocus of React might trigger the event before the componentDidMount.
    // We need to account for this eventuality.
    if (!this.triggerRef) {
      this.triggerRef = event.currentTarget;
    }

    this.handleEnter(event);
  };

  handleEnter = (event) => {
    const { delay } = this.props;
    const delayNormalized = normalizeDelay(delay);

    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    if (delayNormalized.show) {
      this.enterTimer = setTimeout(() => {
        this.handleOpen(event);
      }, delayNormalized.show);
    } else {
      this.handleOpen(event);
    }
  };

  handleLeave = (event) => {
    const { delay } = this.props;
    const delayNormalized = normalizeDelay(delay);

    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    if (delayNormalized.hide) {
      this.leaveTimer = setTimeout(() => {
        this.handleClose(event);
      }, delayNormalized.hide);
    } else {
      this.handleClose(event);
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    clearTimeout(this.closeTimer);
    // eslint-disable-next-line no-empty-function
    this.closeTimer = setTimeout(() => {}, 600);
  };

  handleOpen = () => {
    if (!this.state.open) {
      this.setState({ open: true });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.closeTimer);
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
  }

  render() {
    const {
      trigger,
      children,
      overlay,
      popperConfig = {},
      ...props
    } = this.props;

    const child = Children.only(children);
    const overlayElement = Children.only(overlay);
    const eventProps = this.BuildEventProps(trigger);
    // const ariaModifier = this.getAriaModifier();

    return (
      <>
        <RefHolder ref={this.triggerRef}>
          {cloneElement(child, eventProps)}
        </RefHolder>
        <Overlay
          {...props}
          popperConfig={{
            ...popperConfig,
            modifiers: popperConfig.modifiers || [],
          }}
          show={this.state.open}
          target={this.getTarget}
          transition={Fade}
        >
          {cloneElement(overlayElement, eventProps)}
        </Overlay>
      </>
    );
  }

  BuildEventProps(trigger) {
    // Copied implementation from React-Bootstrap Overlaytrigger
    // React-bootstrap trigger proptype can have the following values:
    // 'hover' | 'click' |'focus' | Array<'hover' | 'click' |'focus'>

    const triggerProps = {};
    const triggers = trigger === null ? [] : [].concat(trigger);

    if (triggers.indexOf('click') !== -1) {
      triggerProps.onClick = this.handleClick;
    }

    if (triggers.indexOf('focus') !== -1) {
      triggerProps.onFocus = this.handleFocus;
      triggerProps.onBlur = this.handleLeave;
    }

    if (triggers.indexOf('hover') !== -1) {
      triggerProps.onMouseOver = this.handleEnter;
      triggerProps.onMouseOut = this.handleLeave;
    }

    return triggerProps;
  }
}
// Disabling the following rule because prop types are too complex in this case
// and this component is likely to go away.
// eslint-disable-next-line react/forbid-foreign-prop-types
InteractiveOverlayTrigger.propTypes = ReactBootstrapOverlayTrigger.propTypes;
InteractiveOverlayTrigger.defaultProps = defaultProps;

export default InteractiveOverlayTrigger;
