import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components/TrustradiusUICore';
import { awardStatus } from '../data';
import styles from './TopRatedAwardTrackCard.module.scss';

const colorsMap = {
  [awardStatus.onTrack]: 'green',
  [awardStatus.notOnTrack]: 'red',
  [awardStatus.notAwarded]: 'gray',
  [awardStatus.awarded]: 'blue',
};
const colors = {
  green: '#28A228',
  red: '#BD0909',
};

function getStatus(tracks) {
  if (!tracks || !tracks.length) {
    return '';
  }
  const winner = tracks.find((track) => track.isWinner);
  return winner ? winner.status : tracks[0].status;
}

export function TopRatedAwardTrackCard({
  awardTracks,
  isExpanded,
  possibleExpanding,
}) {
  const [selectedAwardTrack, setSelectedAwardTrack] = useState();

  useEffect(() => {
    let mainTrack = awardTracks.find(
      (track) => track.product.primaryCategory === track.category._id,
    );
    if (!mainTrack) {
      mainTrack = awardTracks[0];
    }
    setSelectedAwardTrack(mainTrack);
  }, [awardTracks]);

  const status = useMemo(() => {
    return getStatus(awardTracks);
  }, [awardTracks]);

  function selectCategory(categoryId) {
    const selectedTrack = awardTracks.find(
      (track) => categoryId === track.category._id,
    );
    setSelectedAwardTrack(selectedTrack);
  }

  function onSelectCategory(event) {
    selectCategory(event.target.value);
  }

  function onClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (!awardTracks || !awardTracks.length) {
    return <></>;
  }

  function getChevron() {
    if (!possibleExpanding) {
      return <></>;
    }
    if (isExpanded) {
      return <Icon name="chevron-down" size={24} />;
    } else {
      return <Icon name="chevron-right" size={24} />;
    }
  }

  function getCategories() {
    function showCategories() {
      const categoryValue = selectedAwardTrack
        ? selectedAwardTrack.category._id
        : '';

      return (
        <>
          <label htmlFor="category">Category:</label>
          <select
            value={categoryValue}
            id="category"
            onClick={onClick}
            onChange={onSelectCategory}
          >
            {awardTracks
              .filter(
                (track) => track.product.primaryCategory === track.category._id,
              )
              .map((track) => (
                <option key={track._id} value={track.category._id}>
                  {`Primary: ${track.category.name}`}
                </option>
              ))}
            {awardTracks
              .filter(
                (track) => track.product.primaryCategory !== track.category._id,
              )
              .map((track) => (
                <option key={track._id} value={track.category._id}>
                  {`Secondary: ${track.category.name}`}
                </option>
              ))}
          </select>
        </>
      );
    }
    if (!possibleExpanding || isExpanded) {
      return showCategories();
    }
    return <></>;
  }

  function getCategoriesQuantity() {
    const quantity = (
      <div className={styles['category-quantity']}>
        {`${awardTracks.length} ${
          awardTracks.length === 1 ? 'Category' : 'Categories'
        }`}
      </div>
    );
    if (!possibleExpanding || isExpanded) {
      return quantity;
    }
    return <></>;
  }

  function getCriteriaStatusIcon() {
    const statusMatchIcon = (
      <Icon name="check-circle-2" size={14} color={colors.green} />
    );
    const statusFailIcon = (
      <Icon name="x-circle" size={14} color={colors.red} />
    );
    if (!selectedAwardTrack) {
      return {
        recencyMatch: statusFailIcon,
        ratingMatch: statusFailIcon,
        relevancyMatch: statusFailIcon,
      };
    }
    return {
      recencyMatch: selectedAwardTrack.recencyMatch
        ? statusMatchIcon
        : statusFailIcon,
      ratingMatch: selectedAwardTrack.ratingMatch
        ? statusMatchIcon
        : statusFailIcon,
      relevancyMatch: selectedAwardTrack.relevancyMatch
        ? statusMatchIcon
        : statusFailIcon,
    };
  }

  const chevron = getChevron();
  const categoriesSelector = getCategories();
  const categoriesQuantity = getCategoriesQuantity();

  return (
    <div className={styles['award-track-card']}>
      <div className={styles.header}>
        <div className={styles['card-header']}>
          {chevron}
          <div className={styles['product-name-wrapper']}>
            <div className={styles['product-name']}>
              {selectedAwardTrack && selectedAwardTrack.product.name}
            </div>
            {categoriesSelector}
          </div>
        </div>
        <div className={styles['award-status-wrapper']}>
          <div className={classNames(styles.status, styles[colorsMap[status]])}>
            {status}
          </div>
          {categoriesQuantity}
        </div>
      </div>
      <div
        className={classNames(styles.body, { [styles.expanded]: isExpanded })}
      >
        <div />
        <div />
        <div className={styles['progress-header']}>your progress</div>
        <div className={styles['top-rated-header']}>top rated</div>
        {getCriteriaStatusIcon().recencyMatch}
        <div className={styles['criterion-block']}>
          <div>Recency</div>
          <div>
            Products must have 10+ new or updated reviews in the past 12 months
          </div>
        </div>
        <div className={styles['progress-block']}>
          <div>{selectedAwardTrack && selectedAwardTrack.recencyProgress}</div>
          <div>Reviews</div>
        </div>
        <div className={styles['top-rated-block']}>
          <div className={styles.recencyCriteria}>
            {selectedAwardTrack && selectedAwardTrack.award.recencyCriteria}
          </div>
          <div>Reviews</div>
        </div>
        {getCriteriaStatusIcon().ratingMatch}
        <div className={styles['criterion-block']}>
          <div>Rating</div>
          <div>
            Products must have at least 4 stars with a trScore of 7.5 or above
          </div>
        </div>
        <div className={styles['progress-block']}>
          <div>{selectedAwardTrack && selectedAwardTrack.ratingProgress}</div>
          <div>trScore</div>
        </div>
        <div className={styles['top-rated-block']}>
          <div className={styles['rating-criteria']}>
            {selectedAwardTrack && selectedAwardTrack.award.ratingCriteria}
          </div>
          <div>trScore</div>
        </div>
        {getCriteriaStatusIcon().relevancyMatch}
        <div className={styles['criterion-block']}>
          <div>Relevancy</div>
          <p>
            Products must receive at least{' '}
            {selectedAwardTrack &&
              `${selectedAwardTrack.award.relevancyCriteria}%`}{' '}
            of the traffic volume in that category
          </p>
        </div>
        <div className={styles['progress-block']}>
          <div className={styles.relevancyProgress}>
            {selectedAwardTrack && `${selectedAwardTrack.relevancyProgress}%`}
          </div>
          <p>Traffic Volume</p>
        </div>
        <div className={styles['top-rated-block']}>
          <div className={styles.relevancyCriteria}>
            {selectedAwardTrack &&
              `${selectedAwardTrack.award.relevancyCriteria}%`}
          </div>
          <p>Traffic Volume</p>
        </div>
      </div>
    </div>
  );
}

TopRatedAwardTrackCard.propTypes = {
  awardTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  possibleExpanding: PropTypes.bool.isRequired,
};
