import {
  FETCH_COMPETITORS_BY_VENDOR_ID_START,
  FETCH_COMPETITORS_BY_VENDOR_ID_FAILED,
  FETCH_COMPETITORS_BY_VENDOR_ID_SUCCESS,
} from '../actions/competitorsActions';
import { normalizeState } from '../utils';

const initialState = {
  byId: {},
  allIds: [],
};

const competitors = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPETITORS_BY_VENDOR_ID_START:
      return {
        ...state,
      };
    case FETCH_COMPETITORS_BY_VENDOR_ID_SUCCESS:
      return {
        ...state,
        ...normalizeState(action.payload.competitors, 'productId'),
      };
    case FETCH_COMPETITORS_BY_VENDOR_ID_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default competitors;
