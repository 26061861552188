import {
  FETCH_VENDORS_BY_ID_SUCCESS,
  FETCH_VENDORS_BY_ID_FAILED,
  CHANGE_CURRENT_VENDOR,
  CHANGE_CURRENT_VENDOR_PRODUCT_TO_FIRST,
  FETCH_VENDOR_PRODUCTS_START,
  FETCH_VENDOR_PRODUCTS_SUCCESS,
  REMOVE_VENDOR_PRODUCTS,
  FETCH_VENDOR_PRODUCTS_FAILED,
  CHANGE_CURRENT_VENDOR_PRODUCT,
  ASSIGN_CURRENT_VENDOR_PRODUCT,
  REMOVE_CURRENT_VENDOR,
  REMOVE_VENDORS_BY_ID,
} from 'store/actions/vendorActions';
import { normalizeState } from '../utils';

const initialState = {
  availableVendors: null,
  currentVendor: null,
  isLoadingProducts: false,
  allIds: [],
  byId: {},
  currentVendorProduct: {},
  currentVendorProductsById: {},
  currentVendorProductsAllIds: [],
};

const vendors = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_VENDOR_PRODUCT:
      return {
        ...state,
        currentVendorProduct:
          state.currentVendorProductsById[action.payload.productId],
      };
    case CHANGE_CURRENT_VENDOR_PRODUCT_TO_FIRST: {
      const currentProduct = state.currentVendorProductsAllIds.length
        ? state.currentVendorProductsById[state.currentVendorProductsAllIds[0]]
        : {};
      return {
        ...state,
        currentVendorProduct: currentProduct,
      };
    }
    case ASSIGN_CURRENT_VENDOR_PRODUCT:
      return {
        ...state,
        currentVendorProduct: action.payload.product,
      };
    case FETCH_VENDORS_BY_ID_SUCCESS:
      return {
        ...state,
        // @TODO check why rest router returns [null] when user has access to 0 vendors
        availableVendors:
          action.payload.totalCount || action.payload.vendors.length,
        ...normalizeState(action.payload.vendors),
      };
    case REMOVE_VENDORS_BY_ID:
      return {
        ...state,
        availableVendors: null,
      };
    case FETCH_VENDORS_BY_ID_FAILED:
      return {
        ...state,
        hasError: true,
        error: action.payload.error,
      };
    case REMOVE_CURRENT_VENDOR:
      return {
        ...state,
        currentVendor: null,
      };
    case CHANGE_CURRENT_VENDOR:
      return {
        ...state,
        currentVendor: action.payload.vendor,
      };
    case FETCH_VENDOR_PRODUCTS_START:
      return {
        ...state,
      };
    case FETCH_VENDOR_PRODUCTS_SUCCESS: {
      const normalizedPayload = normalizeState(action.payload.products);
      return {
        ...state,
        currentVendorProductsById: normalizedPayload.byId,
        currentVendorProductsAllIds: normalizedPayload.allIds,
      };
    }
    case REMOVE_VENDOR_PRODUCTS:
      return {
        ...state,
        currentVendorProduct: {},
        currentVendorProductsById: {},
        currentVendorProductsAllIds: [],
      };
    case FETCH_VENDOR_PRODUCTS_FAILED:
    default:
      return state;
  }
};

export default vendors;
