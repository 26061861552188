import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import { SixSenseDesktop, SixSenseMobile } from './assets';

export function SixSenseCard({ product }) {
  return (
    <DashCard
      ctaText="Learn More"
      ctaHref="https://www.trustradius.com/vendor-blog/trustradius-and-6sense"
      product={product}
    >
      <Announcement
        mSrc={SixSenseMobile}
        mSrc2x={SixSenseMobile}
        src={SixSenseDesktop}
        src2x={SixSenseDesktop}
        alt="Differentiated Insights into Buying Signals"
      >
        <>
          <h3>Check out the 6sense integration</h3>
          <p>
            Activate rich insights using TrustRadius downstream intent data and
            6sense&apos;s ABM solution. 6sense users can create a segment and
            combine it with a variety of other data sources to enable their
            account ABM strategy.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

SixSenseCard.propTypes = {
  product: PropTypes.object,
};
