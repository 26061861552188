import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import {
  WhisticDesktop,
  WhisticDesktop2x,
  WhisticMobile,
  WhisticMobile2x,
} from './assets';

export function WhisticCard({ product }) {
  return (
    <DashCard
      linkProps={{
        to: '../../integrations/whistic',
        relative: 'path',
        children: 'Learn more',
      }}
      product={product}
    >
      <Announcement
        mSrc={WhisticMobile}
        mSrc2x={WhisticMobile2x}
        src={WhisticDesktop}
        src2x={WhisticDesktop2x}
        alt="Whistic"
      >
        <>
          <h3>Enhance your profile with Whistic Security</h3>
          <p>
            Automate vendor assessments, share security documentation, and
            create trusted connections—all from the Whistic Vendor Security
            Network.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

WhisticCard.propTypes = {
  product: PropTypes.shape({
    logos: PropTypes.shape({
      square180: PropTypes.string,
      default: PropTypes.string,
      logo: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
};
