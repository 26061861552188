import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'lucide-react';
import styles from './RightBarContainer.module.scss';

export function RightBarContainer({
  title,
  isHeaderClickable,
  linkTitle,
  linkUrl,
  children,
  styles: propStyles,
}) {
  const isLgTablet = useMedia('(min-width:1050px)');
  const [isOpen, setIsOpen] = useState(true);

  function renderButton(label) {
    const chevron = isOpen ? <ChevronDown /> : <ChevronRight />;

    return (
      <button
        type="button"
        className={classNames(styles.button, propStyles?.button)}
        onClick={() => {
          setIsOpen((prevIsOpen) => !prevIsOpen);
        }}
      >
        {chevron}
        {label}
      </button>
    );
  }

  const titleContent =
    !isLgTablet && isHeaderClickable ? (
      renderButton(title)
    ) : (
      <>
        {!isLgTablet && renderButton()}
        {title}
      </>
    );

  return (
    <section className={classNames(styles.container, propStyles?.container)}>
      <header className={classNames(styles.header, propStyles?.header)}>
        <h2 className={classNames(styles.title, propStyles?.title)}>
          {titleContent}
        </h2>
        {linkTitle && <a href={linkUrl}>{linkTitle}</a>}
      </header>
      {(isOpen || isLgTablet) && (
        <div className={classNames(styles.children, propStyles?.children)}>
          {children}
        </div>
      )}
    </section>
  );
}

RightBarContainer.propTypes = {
  title: PropTypes.string,
  isHeaderClickable: PropTypes.bool,
  linkTitle: PropTypes.string,
  linkUrl: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.shape({
    container: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.string,
    children: PropTypes.string,
  }),
};
