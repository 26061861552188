import { Button, Text, Input } from '@trustradius/radiant-ui';
import { Trash2 } from 'lucide-react';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import styles from 'pages/WebhookConfigurationPage/WebhookConfigurationPage.module.scss';

export function CustomFields({ customHeaders = [], setCustomHeaders }) {
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const onChange = (event) => {
    const { name, value } = event.target;
    const id = name.replace('_key', '').replace('_value', '');
    const newHeaders = customHeaders.map((header) => {
      if (header.id === id) {
        return {
          ...header,
          [name.includes('_key') ? 'name' : 'value']: value,
        };
      }
      return header;
    });
    setCustomHeaders(newHeaders);
  };

  const addNewHeader = () => {
    setCustomHeaders([
      ...customHeaders,
      {
        id: `custom_header_${customHeaders.length}`,
        name: newKey,
        value: newValue,
      },
    ]);
    setNewKey('');
    setNewValue('');
  };

  const removeHeader = (id) => {
    setCustomHeaders(customHeaders.filter((header) => header.id !== id));
  };
  return (
    <>
      <div className={styles['hidden-fields-container']}>
        <Text as="h2" size="100" weight="semibold">
          KEY
        </Text>
        <Text as="h2" size="100" weight="semibold">
          VALUE
        </Text>
        <Text as="h2" size="100" weight="semibold">
          {' '}
        </Text>
        {customHeaders.map((field) => [
          <div key={`${field.id}_key`}>
            <Input
              id={`${field.id}_key`}
              type="text"
              autoComplete="off"
              name={`${field.id}_key`}
              value={field.name}
              onChange={(event) => onChange(event)}
              hideLabel
            />
          </div>,
          <div
            key={`${field.id}_value`}
            className={styles['input-with-delete']}
          >
            <Input
              id={`${field.id}_value`}
              type="text"
              autoComplete="off"
              value={field.value}
              name={`${field.id}_value`}
              onChange={(event) => onChange(event)}
              hideLabel
            />
          </div>,
          <div
            className={styles['delete-icon']}
            key={`${field.id}_delete_button`}
          >
            <Button
              variant="icon-secondary"
              size="large"
              onClick={() => removeHeader(field.id)}
            >
              <Trash2 />
            </Button>
          </div>,
        ])}
        {customHeaders.length < 10 && (
          <>
            <div key="empty_key">
              <Input
                id="empty_key"
                type="text"
                autoComplete="off"
                name="empty_key"
                value={newKey}
                hideLabel
                onChange={(event) => setNewKey(event.target.value)}
              />
            </div>
            <div key="empty_value">
              <Input
                id="empty_value"
                type="text"
                autoComplete="off"
                name="empty_value"
                value={newValue}
                onChange={(event) => setNewValue(event.target.value)}
                onBlur={addNewHeader}
                hideLabel
              />
            </div>
            <Text as="h2" size="100" weight="semibold">
              {' '}
            </Text>
          </>
        )}
      </div>
    </>
  );
}

CustomFields.propTypes = {
  customHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setCustomHeaders: PropTypes.func.isRequired,
};
