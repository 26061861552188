import PropTypes from 'prop-types';

function CircleX({ title, id, size, color, ...props }) {
  return (
    <svg
      width={size || 20}
      height={size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={id || null}
      {...props}
    >
      {title && <title id={id}>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6084 3.55896C18.7976 6.74824 18.7976 11.9191 15.6084 15.1084C12.4191 18.2976 7.24821 18.2976 4.05896 15.1084C0.869671 11.9191 0.869671 6.74821 4.05896 3.55896C7.24824 0.369671 12.4191 0.369671 15.6084 3.55896Z"
        stroke={color || '#FF6E9C'}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5558 6.61133L7.11133 12.0558"
        stroke={color || '#FF6E9C'}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11133 6.61133L12.5558 12.0558"
        stroke={color || '#FF6E9C'}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CircleX.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CircleX;
