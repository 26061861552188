import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  useIntentRegistration,
  useAddZoominfoApiKey,
  useHaveLicense,
} from 'hooks';
import { ZoominfoSquare } from 'assets/logos-companies';
import { ZoominfoConnect } from './ZoominfoConnect';

export function ZoominfoConnectContainer() {
  const zoominfo = useSelector(
    (state) => state.vendors?.currentVendor?.zoominfo,
  );
  const [apiKey, setApiKey] = useState(zoominfo?.apiKey || '');
  const [isOpen, setIsOpen] = useState(false);
  const { status, saveZoominfoKey } = useAddZoominfoApiKey();
  const vendorId = useSelector((state) => state.vendors.currentVendor._id);

  const intentLicenses = 'api-vi-category-level,api-vi-product-level';
  const { hasLicense } = useHaveLicense(vendorId, intentLicenses);
  const { intentRegistration } = useIntentRegistration(
    vendorId,
    'zoominfo',
    hasLicense,
  );

  let configuredSubTitle = `Configured on: ${moment(zoominfo?.createdAt).format(
    'MM/DD/YYYY',
  )}`;

  if (intentRegistration?.lastDelivery) {
    configuredSubTitle += ` |  Last data delivery: ${moment(
      intentRegistration?.lastDelivery,
    ).format('MM/DD/YYYY')}`;
  }
  const subTitle =
    apiKey && hasLicense
      ? configuredSubTitle
      : 'Identify and connect with your next best customer today.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendorId}/integrations/zoominfo`,
      'aria-label': 'Integration page',
    },
    title: 'ZoomInfo',
    logo: <img src={ZoominfoSquare} alt="Demostack" />,
    subtitle: subTitle,
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <ZoominfoConnect
      isOpen={isOpen}
      headerProps={headerProps}
      apiKey={apiKey}
      status={status}
      notificationMessage={getNotificationMessage(status, apiKey)}
      notificationTitle={getNotificationTitle(status, apiKey)}
      notificationStatus={status}
      onCloseHandler={onCloseHandler}
      onConnectHandler={async (vendorApiKey) => {
        const vendor = await saveZoominfoKey(vendorId, vendorApiKey);
        setApiKey(vendor?.data?.zoominfo?.apiKey || '');
      }}
      onDisconnectHandler={async () => {
        const vendor = await saveZoominfoKey(vendorId, '');
        setApiKey(vendor?.data?.zoominfo?.apiKey || '');
        setIsOpen(false);
      }}
      openModal={() => setIsOpen(true)}
    />
  );
}

function getNotificationTitle(status, apiKey) {
  if (status === 'success' && apiKey) {
    return 'Integration successfully activated';
  }
  if (status === 'success' && !apiKey) {
    return 'Integration successfully disabled';
  }
  if (status === 'error') {
    return 'Error: Something went wrong';
  }
  return '';
}

function getNotificationMessage(status, apiKey) {
  if (status === 'success' && apiKey) {
    return 'Your organization`s ZoomInfo account is connected with TrustRadius. Your TrustRadius intent data is delivered to the ZoomInfo platform on a daily basis.';
  }
  if (status === 'error' && apiKey) {
    return 'Please verify that you have the correct API key and try again. if the issue persists, please contact ZoomInfo.';
  }
  if (status === 'success' && !apiKey) {
    return 'Your organization`s ZoomInfo account is disconnected from TrustRadius.';
  }
  if (status === 'error' && !apiKey) {
    return 'Please verify that you have the correct API key and try again. if the issue persists, please contact ZoomInfo.';
  }
  return '';
}
