import { gql } from '@apollo/client';

const LEAD_FIELDS = gql`
  fragment leadFields on Lead {
    id
    vendorId
    productName
    firstName
    lastName
    email
    company
    country {
      id
      name
    }
    message
    interestedIn
    createdAt
  }
`;

export const GET_LEADS = gql`
  ${LEAD_FIELDS}
  query getAllLeads {
    leads {
      ...leadFields
    }
  }
`;

export const GET_LEADS_BY_VENDOR_ID = gql`
  ${LEAD_FIELDS}
  query getLeadsByVendorId(
    $vendorId: String!
    $sortField: String!
    $sortOrder: String!
  ) {
    leadsByVendorId(
      vendorId: $vendorId
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...leadFields
    }
  }
`;

export const GET_LEAD_BY_ID = gql`
  ${LEAD_FIELDS}
  query getLead($id: ID!) {
    lead(id: $id) {
      ...leadFields
    }
  }
`;
