import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function TextSection({ content }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getText() {
    const text = <Text className={styles.text}>{content.text}</Text>;

    if (!isTablet) {
      return text;
    } else if (isOpen) {
      return text;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          {content.title}
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getText()}
    </section>
  );
}

export const TextSectionShape = PropTypes.shape({
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
});

TextSection.propTypes = TextSectionShape.isRequired;
