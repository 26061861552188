import { useMemo } from 'react';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TimeFrameSwitcherContainer } from 'components/TimeFrameSwitcher';
import { ProductMetricsContainer } from 'components/ProductMetrics';
import { ProductSwitcherContainer } from 'components/ProductSwitcher';
import {
  TrustScore,
  TRScoreSize,
  RatingStarSize,
} from 'components/TrustradiusUICore';
import { Skeleton } from 'components/Skeleton';
import styles from './ProductHeader.module.scss';

export function ProductHeader({ product, settings }) {
  const isDesktop = useMedia('(min-width:1200px)');
  const isLgTablet = useMedia('(min-width:900px)');
  const isMobile = useMedia('(max-width:599px)');

  const sizes = useMemo(
    () => ({
      starSize: isDesktop
        ? RatingStarSize.HALF_SMALL
        : RatingStarSize.EXTRA_SMALL,
      trScoreSize: isDesktop ? TRScoreSize.LARGE : TRScoreSize.SMALL,
    }),
    [isDesktop],
  );

  return (
    <>
      <header className={styles.productHeaderContainer}>
        {product?.name && (
          <section className={styles.product}>
            <ProductSwitcherContainer />
            <TrustScore
              ratingValue={product?.rating?.avg}
              ratingCount={product?.rating?.count}
              showCount={false}
              showTrueBadge={false}
              className={styles.trustScore}
              starSize={sizes.starSize}
              trScoreSize={sizes.trScoreSize}
              baseUrl={settings.legacyBaseUrl}
            />
          </section>
        )}
        {!product.name && <Skeleton className={styles.loading} />}
        {isLgTablet && (
          <div className={styles.metrics}>
            <ProductMetricsContainer />
            <TimeFrameSwitcherContainer />
          </div>
        )}
      </header>
      {!isLgTablet && (
        <div className={classNames({ [styles.tablet]: !isMobile })}>
          <TimeFrameSwitcherContainer />
          <ProductMetricsContainer />
        </div>
      )}
    </>
  );
}

ProductHeader.propTypes = {
  product: PropTypes.object.isRequired,
  settings: PropTypes.object,
};
