import PropTypes from 'prop-types';
import styles from './SegmentItem.module.scss';

export function SecondaryMetrics({
  author,
  dateCreated,
  account,
  products,
  category,
}) {
  const passedMetric = products ? (
    <div className={styles['description-block']}>
      <dt>Products</dt>
      <dd>{products}</dd>
    </div>
  ) : (
    <div className={styles['description-block']}>
      <dt>Categories</dt>
      <dd>{category}</dd>
    </div>
  );

  return (
    <dl className={styles['secondary-metrics']}>
      <div className={styles['description-block']}>
        <dt>Created by</dt>
        <dd>{author}</dd>
      </div>
      <div className={styles['description-block']}>
        <dt>Date Created</dt>
        <dd>{dateCreated}</dd>
      </div>
      {passedMetric}
      <div className={styles['description-block']}>
        <dt>Ad Account</dt>
        <dd className={styles['account-data']}>
          <span>{account.name}</span>
          <span>{`(ID: ${account.id})`}</span>
        </dd>
      </div>
    </dl>
  );
}

SecondaryMetrics.propTypes = {
  author: PropTypes.string,
  dateCreated: PropTypes.string,
  account: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  products: PropTypes.string,
  category: PropTypes.string,
};
