import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { Body } from 'components/DashCard';
import { Stars } from './assets';
import styles from './NoReviewsNotice.module.scss';

export function NoReviewsNotice({ product }) {
  const isMobile = useMedia('(max-width:488px)');
  return (
    <Body className={styles.body}>
      <h3 className={styles.heading}>
        {product} hasn’t had a new review in over 90 days
      </h3>
      <p>
        Work with TrustRadius to learn how to source reviews and connect with
        in-market buyers.
      </p>
      <div className={classNames(styles.media, { [styles.mobile]: isMobile })}>
        <Stars alt="Empty rating" />
        <ul>
          <li>Start generating reviews with our review kit</li>
          <li>Start working on your review-generation campaign</li>
          <li>If you are a customer, get in touch with your CSM</li>
        </ul>
      </div>
    </Body>
  );
}

NoReviewsNotice.propTypes = {
  product: PropTypes.string.isRequired,
};
