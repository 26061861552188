import { Plus } from 'lucide-react';
import { useState } from 'react';
import {
  Button,
  Input,
  Select,
  SelectItem,
  Text,
} from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from './CreateWebhookModal.module.scss';

export function CreateWebhookModal({ leadForms = [], systems = [] }) {
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const changeHandler = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length > 60) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };
  return (
    <Modal
      title="Create webhook"
      description="Choose a name to help you easily identify and manage your webhook. This name is for internal use only and will not be displayed to prospects."
      icon={<CircleIcon iconType="webhook" />}
      className={styles.content}
      closeHandler={() => {
        console.log('closeHandler');
      }}
      actionButtons={[
        <Button variant="secondary" size="large" key="cancel">
          Cancel
        </Button>,
        <Button key="create" size="large">
          Create new webhook
        </Button>,
      ]}
      trigger={
        <Button variant="primary" size="large">
          {' '}
          <Plus /> Create new webhook
        </Button>
      }
    >
      <div className={styles.body}>
        <div className={styles['input-item']}>
          <Input
            label="Webhook name"
            placeholder="Name your webhook"
            required
            value={inputValue}
            onChange={changeHandler}
            isInvalid={hasError}
            id="webhookName"
          />
          <Text
            size="200"
            className={classNames(styles.counter, { [styles.error]: hasError })}
          >
            {inputValue?.length} / 60
          </Text>
        </div>
        <div className={styles['input-item']}>
          <Select
            label="Send data from"
            placeholder="Choose lead form"
            required
            isFullWidth
            position="popper"
            id="leadSource"
          >
            {leadForms.map(({ id, name }) => (
              <SelectItem key={id} value={id} className={styles.item}>
                {name}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className={styles['input-item']}>
          <Select
            label="Send data to"
            placeholder="Choose system"
            required
            isFullWidth
            position="popper"
            id="system"
          >
            {systems.map(({ id, name }) => (
              <SelectItem key={id} value={id} className={styles.item}>
                {name}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
}

CreateWebhookModal.propTypes = {
  leadForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  systems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};
