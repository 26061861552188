import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimeFrame } from 'store/actions/globalActions';
import { TimeFrameSwitcher } from './TimeFrameSwitcher';

export function TimeFrameSwitcherContainer() {
  const timeFrame = useSelector((state) => state.globalState.timeFrame);
  const [isOpenTimeFrameSwitcher, setIsOpenTimeFrameSwitcher] = useState(false);
  const dispatch = useDispatch();

  const onChangeTimeFrame = (event) => {
    setIsOpenTimeFrameSwitcher(false);
    dispatch(changeTimeFrame(event.target.value));
  };

  return (
    <TimeFrameSwitcher
      timeFrame={timeFrame}
      isOpen={isOpenTimeFrameSwitcher}
      onChange={onChangeTimeFrame}
      setModalOpen={setIsOpenTimeFrameSwitcher}
    />
  );
}
