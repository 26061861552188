import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { Body } from 'components/DashCard';
import styles from './BannerCompanies.module.scss';

export function BannerCompanies({ companies = {}, children }) {
  const isMobile = useMedia('(max-width:488px)');
  const topCompetitors = companies.topCompetitors
    ? companies.topCompetitors.filter(
        (competitor) => Boolean(competitor.name) && Boolean(competitor.logo),
      )
    : [];
  return (
    <div className="container">
      <Body className={styles.body}>
        <>{children}</>
      </Body>
      <div className={classNames(styles.banner, { [styles.mobile]: isMobile })}>
        <div className={styles.logos}>
          {topCompetitors.slice(0, 5).map((competitor) => (
            <div
              key={`banner-company-${competitor.name}`}
              className={styles['logo-container']}
            >
              <img src={competitor.logo} alt={competitor.name} />
            </div>
          ))}
        </div>
        <div
          className={classNames({
            [styles['banner-content']]: true,
            [styles['no-companies']]: !topCompetitors.length,
          })}
        >
          <p className={styles.super}>{companies.currentCount}</p>
          <p className={styles.text}>
            Companies are researching your competitors on TrustRadius
          </p>
        </div>
      </div>
    </div>
  );
}

BannerCompanies.propTypes = {
  companies: PropTypes.shape({
    topCompetitors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
      }),
    ),
    currentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  children: PropTypes.element.isRequired,
};
