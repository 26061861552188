function RatingStarEmpty(props) {
  return (
    <svg width="34" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M8.45.81C8.63.32 9.1 0 9.62 0h23.55c.52 0 .89.52.7 1.01l-8.32 22.18c-.18.49-.65.81-1.17.81H.83c-.52 0-.89-.52-.7-1.01L8.45.81z"
          fill="#DDDDE2"
        />
        <path
          d="M16.53 3.35c.16-.45.79-.45.94 0l1.89 5.41c.07.2.25.33.46.33l5.73.13a.5.5 0 0 1 .29.9l-4.56 3.47c-.17.13-.24.34-.18.54l1.65 5.49c.14.45-.37.82-.76.55l-4.71-3.26a.496.496 0 0 0-.57 0L12 20.18c-.39.27-.9-.1-.76-.55l1.65-5.49a.49.49 0 0 0-.18-.54l-4.56-3.47a.5.5 0 0 1 .29-.9l5.73-.13c.21 0 .39-.14.46-.33l1.9-5.42z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default RatingStarEmpty;
