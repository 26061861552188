import { axiosRequestWithAuthToken } from './axios_utils';

export const fetchVendorById = async (vendorId) => {
  try {
    const options = {
      url: `/api/vendors/?id=${vendorId}&fields=name,slug,logos,isCustomer,hasBuyerLeadsActivated,zoominfo`,
      method: 'GET',
    };
    const response = await axiosRequestWithAuthToken(options);
    return response;
  } catch (error) {
    console.error('Fetching vendor by ID failed', error);
    return { data: {} };
  }
};

export const fetchChromeExtensionApiKey = async (vendorId, user) => {
  try {
    const options = {
      url: `/api/vendors/${vendorId}/chrome-extension-key`,
      method: 'POST',
      data: { user },
    };
    const response = await axiosRequestWithAuthToken(options);
    return response;
  } catch (error) {
    console.error('Fetching chrome extension api key failed', error);
    return { data: {} };
  }
};

export const enableVendorLeadForm = async (vendorId, body) => {
  try {
    // note: protect this api,or should we just guard internal api for now?
    const options = {
      url: `/api/vendors/${vendorId}/enable-lead-form`,
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        SameSite: 'None; Secure',
      },
      data: body,
    };

    const response = await axiosRequestWithAuthToken(options, body);
    return response;
  } catch (error) {
    return { data: {} };
  }
};

export const fetchVendorProducts = async (vendorId, params) => {
  try {
    const defaultParams = {
      fields:
        'name slug previousSlugs vendorName description categories primaryCategory rating aliases logos published isSubscribed productDemos security',
      published: true,
    };
    const requestParams = params || defaultParams;
    const options = {
      url: `/api/vendors/${vendorId}/products`,
      method: 'GET',
      params: requestParams,
    };

    const response = await axiosRequestWithAuthToken(options);
    return response;
  } catch (error) {
    console.error('Occurred error getting vendor products: ', error);
    return { data: {} };
  }
};

export const findVendorsByName = async (name, params) => {
  try {
    const defaultParams = {
      fields:
        'name slug previousSlugs vendorName description categories primaryCategory rating aliases logos logo published isSubscribed isCustomer hasBuyerLeadsActivated zoominfo',
    };
    const requestParams = params || defaultParams;
    const options = {
      url: `/api/vendors/find/${name}`,
      method: 'GET',
      params: requestParams,
    };

    const response = await axiosRequestWithAuthToken(options);
    return response.data;
  } catch (error) {
    console.error('Occurred error getting vendors by name: ', error);
    return [];
  }
};

export const getApiKey = async (vendorId) => {
  try {
    const options = {
      url: `/api/vendors/${vendorId}/api-key`,
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        SameSite: 'None; Secure',
      },
    };
    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
    return { data: '' };
  }
};

export const getVendorLicensesByProduct = async (vendorId) => {
  try {
    const options = {
      url: `/api/vendors/${vendorId}/licenses-by-product`,
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        SameSite: 'None; Secure',
      },
    };
    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
    return { data: '' };
  }
};
