import { Icon, Pill } from 'components/TrustradiusUICore';

export function TruePill() {
  return (
    <Pill>
      <Icon name="star" size={18} color="#0093a1" />
      <span>TRUE</span>
    </Pill>
  );
}
