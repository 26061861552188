import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setWelcomeJourneyToUser } from 'store/apis/usersApi';
import { WelcomeCard } from './WelcomeCard';

export function WelcomeCardContainer() {
  const user = useSelector((state) => state?.users?.user);
  const product = useSelector((state) => state.vendors.currentVendorProduct);

  useEffect(() => {
    const fetchData = async () => {
      await setWelcomeJourneyToUser(user._id);
    };

    if (user?._id && !user?.journeys?.welcomeToNewVendorPortal) {
      fetchData();
    }
  }, [user]);

  function getDifferenceBetweenTwoDatesInDays(firstDate, secondDate) {
    const differenceInMilliseconds =
      new Date(firstDate).getTime() - new Date(secondDate).getTime();
    return differenceInMilliseconds / (24 * 60 * 60 * 1000);
  }

  const isShowWelcomeCard = () => {
    if (!user?.journeys?.welcomeToNewVendorPortal) {
      return true;
    }

    const differenceInDays = getDifferenceBetweenTwoDatesInDays(
      new Date(),
      user?.journeys?.welcomeToNewVendorPortal?.finishDate,
    );
    if (differenceInDays > 30) {
      return false;
    }

    return true;
  };

  if (isShowWelcomeCard()) {
    return <WelcomeCard product={product} />;
  }

  return null;
}
