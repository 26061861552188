import { useSelector } from 'react-redux';
import { useSettings } from 'settings';
import { noProducts } from './images';
import styles from './Welcome.module.scss';

export function Welcome() {
  const vendor = useSelector((state) => state?.vendors?.currentVendor);
  const settings = useSettings();
  return (
    <div className={styles.container}>
      <h2>Welcome to the Vendor Portal!</h2>
      <p>
        {vendor?.name} does not have any products or services defined yet. Add a
        listing to get started!
      </p>
      <div className={styles['button-container']}>
        <a
          href={`${
            settings?.legacyBaseUrl || 'https://www.trustradius.com/vendoradmin'
          }/products/create`}
          className={styles.cta}
        >
          {' '}
          Create a Product Listing
        </a>
        <a
          href="https://story.screenspace.io/trustradius/vp/"
          className={styles.help}
          target="_blank"
          rel="noreferrer"
        >
          Take a tour to see how it works
        </a>
      </div>
      <img src={noProducts} alt="" className={styles.image} />
    </div>
  );
}
