import { useState, useMemo } from 'react';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Avatar } from '@trustradius/radiant-ui';
import { LeftMenuItem } from 'components/LeftMenuItem';
import { withLicenseData } from 'hoc/withLicenseData';
import { useSettings } from 'settings';
import { useLogout } from 'hooks';
import { usePermissions } from 'permissions';
import { getMenuItems, linkNames } from './menuItems';
import styles from './LeftMenu.module.scss';

const PAGES_REQUIRED_PRODUCTS = {
  [linkNames.awards]: true,
  [linkNames.integrations]: true,
};

function applySingleParameter({ url, param, value }) {
  if (!url || (url.includes(param) && !value)) {
    return undefined;
  }

  return url.replace(param, value);
}

function applyParametersToUrl({ url, product, vendor }) {
  const urlWithProductId = applySingleParameter({
    url,
    param: ':productId',
    value: product?._id,
  });
  const urlWithProductSlug = applySingleParameter({
    url: urlWithProductId,
    param: ':productSlug',
    value: product?.slug,
  });
  const urlWithVendorId = applySingleParameter({
    url: urlWithProductSlug,
    param: ':vendorId',
    value: vendor?._id,
  });

  return urlWithVendorId;
}

export function LeftMenu({ vendorFeatures = [] }) {
  const settings = useSettings();
  const { user } = useAuth0();
  const permissions = usePermissions();
  const pictureUrl = user?.picture || '/images/no_photo.png';
  const firstName = user?.given_name || 'Unknown';
  const lastName = user?.family_name || 'User';
  const location = useLocation();
  const product = useSelector((state) => state.vendors.currentVendorProduct);
  const products = useSelector(
    (state) => state.vendors.currentVendorProductsById,
  );
  const hasMultipleSubscribedProducts =
    Object.keys(products).filter((id) => products[id]?.isSubscribed)?.length >
    1;
  const vendor = useSelector((state) => state.vendors.currentVendor);
  const roles = useSelector((state) => state.users.rolesAllNames);
  const lgTablet = useMedia('(min-width:901px)');
  const hasBuyerLeadLicense = vendorFeatures.includes('buyer-leads');
  const isNewLeadFormEnabled = useFeatureIsOn('leads-v2');
  const menuItemsWithUrls = useMemo(() => {
    const menuItems = getMenuItems(
      settings,
      roles,
      permissions,
      vendor?.isCustomer,
      hasBuyerLeadLicense,
      hasMultipleSubscribedProducts,
      isNewLeadFormEnabled,
    );

    return menuItems.flatMap((menuItem) => {
      const menuItemUrl = applyParametersToUrl({
        url: menuItem.template,
        product,
        vendor,
      });

      const nestedMenuItems = menuItem.nestedMenuItems?.flatMap(
        (nestedMenuItem) => {
          const nestedMenuItemUrl = applyParametersToUrl({
            url: nestedMenuItem.template,
            product,
            vendor,
          });

          if (!nestedMenuItemUrl) {
            return [];
          }

          return [
            {
              ...nestedMenuItem,
              url: nestedMenuItemUrl,
            },
          ];
        },
      );

      if (!menuItemUrl && (!nestedMenuItems || nestedMenuItems.length === 0)) {
        return [];
      }

      // If a vendor does not have products then remove the link of the page which requires to have at least 1 product
      if (
        _.isEmpty(product) &&
        Boolean(PAGES_REQUIRED_PRODUCTS[menuItem.title])
      ) {
        return [];
      }

      function isActiveMenuItem() {
        const isActive = Boolean(
          menuItemUrl === location.pathname || isNestedIsActive(),
        );

        const isActiveRelatedPage = menuItem.relatedPaths?.some((path) =>
          matchPath(path, location.pathname),
        );
        return isActive || isActiveRelatedPage;
      }

      function isNestedIsActive() {
        let isOneOfNestedActive = false;
        nestedMenuItems?.forEach((nestedMenuItem, index) => {
          if (nestedMenuItem.url === location.pathname) {
            isOneOfNestedActive = true;
          }
          nestedMenuItems[index].isCurrent =
            nestedMenuItem.url === location.pathname;
        });

        return isOneOfNestedActive;
      }

      return [
        {
          ...menuItem,
          url: menuItemUrl,
          nestedMenuItems,
          isCurrent: isActiveMenuItem(),
        },
      ];
    });
  }, [
    settings,
    product,
    vendor,
    roles,
    permissions,
    location.pathname,
    hasBuyerLeadLicense,
    hasMultipleSubscribedProducts,
    isNewLeadFormEnabled,
  ]);

  const [openedMenuItem, setOpenedMenuItem] = useState(() => {
    const currentMenuItem = menuItemsWithUrls.find(
      (menuItem) => menuItem.isCurrent,
    );

    return currentMenuItem?.title;
  });

  const openNestedMenu = (title) => {
    setOpenedMenuItem((prevOpenedMenuItem) =>
      title === prevOpenedMenuItem ? undefined : title,
    );
  };

  const logoutWithRedirect = useLogout();

  const altText = `${
    firstName && lastName ? `${firstName} ${lastName}` : 'Anonymous'
  } | TrustRadius Reviewer`;

  return (
    <>
      {product && (
        <>
          <ul className={styles.menu}>
            {menuItemsWithUrls.map((menuItem) => {
              return (
                <li key={`menu-item-${menuItem.title}`} data-testid="menu-item">
                  <LeftMenuItem
                    menuItem={menuItem}
                    isOpen={menuItem.title === openedMenuItem}
                    openNestedMenu={openNestedMenu}
                    label={menuItem.label}
                  />
                </li>
              );
            })}
          </ul>
          {!lgTablet && (
            <>
              <div className={styles.separator} />
              <div className={styles['user-block']}>
                <div className={styles['user-info']}>
                  <Avatar
                    src={pictureUrl}
                    name={`${firstName} ${lastName}`}
                    alt={altText}
                    size="sm"
                  />
                  <div
                    className={styles['full-name']}
                  >{`${firstName} ${lastName}`}</div>
                </div>
                <button
                  type="button"
                  className={styles.logout}
                  onClick={() => logoutWithRedirect()}
                >
                  Logout
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

LeftMenu.propTypes = {
  vendorFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LeftMenu;

export const LeftMenuWithLicenses = withLicenseData(LeftMenu);
