import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RatingStarSize } from './constants';
import styles from './RatingStars.module.scss';

export function RatingStars({ insufficientData, trScore, size }) {
  // results
  const starNames = [];

  if (insufficientData) {
    [4, 3, 2, 1, 0].forEach(() => starNames.push('empty'));
  } else {
    // convert 10 point scale of numbers into a 5 point scale and mround to nearest .5
    const starRating = trScore > 0 ? Math.round(trScore) / 2 : 0;

    // full stars
    for (const star of [1, 2, 3, 4, 5]) {
      if (starRating >= star) {
        starNames.push('full');
      }
    }

    // half stars
    if (Math.floor(starRating) < starRating) {
      starNames.push('half');
    }

    // empty stars
    for (const star of [4, 3, 2, 1, 0]) {
      if (starRating <= star) {
        starNames.push('empty');
      }
    }
  }

  return (
    <div
      className={classNames(styles.stars, [
        styles[size ? size : RatingStarSize.NORMAL],
      ])}
    >
      {starNames.map((starName, i) => (
        <div
          key={`rating-star-${i + 1}`}
          className={`${styles.star} ${styles[starName]}`}
          data-testid="star-element"
        />
      ))}
    </div>
  );
}

RatingStars.propTypes = {
  /** True if there is not enough data to support showing score yet for the product */
  insufficientData: PropTypes.bool,
  /** TrustRadius score value between 0 and 10 */
  trScore: PropTypes.number,
  size: PropTypes.oneOf(Object.values(RatingStarSize)),
};
