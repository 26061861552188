import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MoreHorizontal } from 'lucide-react';
import styles from './ActionMenu.module.scss';

export function ActionMenu({ label, children }) {
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleButton = () => setIsOpen(!isOpen);

  useEffect(() => {
    const pageClickEvent = (event) => {
      if (
        menuRef.current !== null &&
        (!menuRef.current.contains(event.target) || event.key === 'Escape')
      ) {
        setIsOpen(!isOpen);
      }
    };

    if (isOpen) {
      window.addEventListener('click', pageClickEvent);
      window.addEventListener('keydown', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
      window.removeEventListener('keydown', pageClickEvent);
    };
  }, [isOpen]);

  return (
    <div className={classNames('menu', styles['action-menu'])}>
      <button type="button" onClick={handleButton} className={styles.button}>
        <MoreHorizontal aria-hidden color="#808088" />
        <span className={styles.label}>{label}</span>
      </button>
      {isOpen && (
        <div className={styles.menu} ref={menuRef}>
          {children}
        </div>
      )}
    </div>
  );
}

ActionMenu.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};
