import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Skeleton } from 'components/Skeleton';
import styles from './GeneralAwardCard.module.scss';
import 'components/TrustradiusUICore/Button/Button.scss';

export function GeneralAwardCard({
  isLoading,
  badge,
  name,
  values,
  description,
  linkProps,
}) {
  const badgeBlock = useMemo(() => {
    if (!badge) {
      return null;
    }

    const { alt, ...otherBadgeProps } = badge;

    return (
      <img alt={alt} className={styles['award-badge']} {...otherBadgeProps} />
    );
  }, [badge]);

  const valuesBlock = useMemo(() => {
    if (isLoading) {
      return <Skeleton className={styles.loader} />;
    }

    if (!values || values.length === 0) {
      return null;
    }

    return (
      <div className={styles['award-card-values']}>
        {values.map(({ id, value, title }) => (
          <div className={styles['award-card-value-block']} key={id}>
            <div
              className={styles['award-card-value']}
              data-testid={`${id}-value`}
            >
              {value}
            </div>
            <div className={styles['award-card-value-label']}>{title}</div>
          </div>
        ))}
      </div>
    );
  }, [values, isLoading]);

  const actionBlock = useMemo(() => {
    if (!linkProps) {
      return (
        <div className={styles['award-card-coming-soon']}>Coming Soon</div>
      );
    }

    return (
      <Link
        className={`button button-secondary-pill ${styles['award-card-link']}`}
        {...linkProps}
      />
    );
  }, [linkProps]);

  return (
    <div
      className={classNames(styles['award-card'], linkProps && styles.active)}
    >
      {badgeBlock}
      <div className={styles['award-card-title']}>{name}</div>
      {valuesBlock}
      <div className={styles['award-card-description']}>{description}</div>
      {actionBlock}
    </div>
  );
}

GeneralAwardCard.propTypes = {
  isLoading: PropTypes.bool,
  badge: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ),
  description: PropTypes.node.isRequired,
  linkProps: PropTypes.object,
};
