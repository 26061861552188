import { ArrowRight, Users, Activity, Locate } from 'lucide-react';
import PropTypes from 'prop-types';
import { Button, Text } from '@trustradius/radiant-ui';
import moment from 'moment';
import { ZoominfoSquare } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
  Prerequisites,
  TextBanner,
} from 'components/SecondaryPage';
import { zoominfoBanner, zoominfoBanner2x } from './assets';

export function Zoominfo({
  vendorId,
  zoominfo,
  zoominfoIntentRegistration,
  hasLicense,
}) {
  const headerButtonUrl = hasLicense
    ? `/vendor/${vendorId}/integrations/zoominfo/connect`
    : ' https://www.trustradius.com/resources/assets/global-demo';

  const subTitle =
    zoominfo?.apiKey && hasLicense
      ? `Configured on: ${moment(zoominfo?.createdAt).format(
          'MM/DD/YYYY',
        )} |  Last data delivery: ${moment(
          zoominfoIntentRegistration?.lastDelivery,
        ).format('MM/DD/YYYY')}`
      : 'Identify and connect with your next best customer today.';

  const getConnectButtonWithLicense = () => {
    return (
      <Text as="p">
        Continue to integration <ArrowRight />
      </Text>
    );
  };

  const headerButtonText = hasLicense
    ? getConnectButtonWithLicense()
    : 'Request a demo';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendorId}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'ZoomInfo',
    logo: <img src={ZoominfoSquare} alt="Demostack" />,
    subtitle: subTitle,
    actionBlock: (
      <Button size="large" url={headerButtonUrl} targetBlank={!hasLicense}>
        {headerButtonText}
      </Button>
    ),
  };

  const prerequisites = [
    {
      id: 'subscriptions',
      componentContent: (
        <>
          A TrustRadius intent data license is required to unlock this
          feature.&nbsp;
          <a
            href="https://www.trustradius.com/vendor/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      ),
    },
    {
      id: 'zoominfo-subscription',
      componentContent: (
        <>
          You must be a ZoomInfo customer and your package must include
          Intent.&nbsp;
        </>
      ),
    },
  ];

  const keyBenefits = [
    {
      id: 'ability',
      icon: <Users />,
      text: 'Get contact data for people at the accounts showing buyer intent for your products on TrustRadius',
    },
    {
      id: 'tailor',
      icon: <Activity />,
      text: 'Access and action on intent signals from two intent ecosystems in a single platform',
    },
    {
      id: 'insights',
      icon: <Locate />,
      text: 'Identify, target, engage, and convert your high-intent and most valuable pipeline',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000591692"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = [
    'Account Based Marketing (ABM)',
    'Intent Data',
    'Demand Generation',
  ];
  return (
    <>
      <SecondaryPageHeader {...headerProps} hasShadow />
      {!hasLicense && (
        <TextBanner>
          {' '}
          <>
            This integration requires TrustRadius intent data.
            <a
              href="https://www.trustradius.com/resources/assets/global-demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;Activate your free trial
            </a>
            .
          </>
        </TextBanner>
      )}
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'prerequisites',
            element: <Prerequisites content={prerequisites} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={zoominfoBanner}
            srcSet={`${zoominfoBanner} 1x, ${zoominfoBanner2x} 2x`}
            alt="Demostack: Showcase your product in the best light, without the hassle."
          />
        }
      >
        <section>
          <Text as="h2" size="400">
            Overview
          </Text>
          <Text as="h3">About Zoominfo</Text>
          <Text as="p">
            ZoomInfo is the go-to-market platform that helps businesses find,
            acquire and grow their customers. ZoomInfo delivers accurate,
            real-time data, insights, and technology to more than 35,000
            companies worldwide. Businesses use ZoomInfo&apos;s data and
            platform to increase efficiency, align sales and marketing teams,
            and consolidate technology stacks.
          </Text>
          <Text as="h3">Zoominfo and TrustRadius</Text>
          <Text as="p">
            Sales and Marketing teams can enrich their segmentation, targeting,
            and outreach efforts by integrating TrustRadius&apos; downstream
            intent data with ZoomInfo&apos;s SalesOS platform.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Connect with contacts at down-funnel accounts</Text>
          <Text>
            Match the accounts that are researching your product(s) on
            TrustRadius with decision maker contact information in
            ZoomInfo&apos;s SalesOS platform.
          </Text>

          <Text as="h3">Prioritize high-value, in-market accounts</Text>
          <Text a="p">
            See who&apos;s researching topics and keywords related to your
            product, your competitors products&apos;, or your category and focus
            your time and effort on the target accounts most likely to convert.
          </Text>

          <Text as="h3">
            Identify customer churn risk and revenue opportunities
          </Text>
          <Text as="p">
            Know when customers are researching other solutions and what
            they&apos;re looking for so you can personalize your outreach to
            mitigate churn and improve upsell and cross-sell rates.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}

Zoominfo.propTypes = {
  vendorId: PropTypes.string.isRequired,
  zoominfo: PropTypes.shape({
    apiKey: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  zoominfoIntentRegistration: PropTypes.shape({
    lastDelivery: PropTypes.string,
  }).isRequired,
  hasLicense: PropTypes.bool.isRequired,
};
