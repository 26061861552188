/* eslint-disable @trustradius/jsx-no-complex-expressions */
import PropTypes from 'prop-types';
import { Body } from './Body';
import styles from './Announcement.module.scss';

export function Announcement({ children, mSrc, mSrc2x, src, src2x, alt }) {
  return (
    <div className={styles.container}>
      <Body className={styles.body}>{children}</Body>
      <div className={styles.banner}>
        {mSrc && mSrc2x ? (
          <picture>
            <source
              media="(max-width: 599px)"
              srcSet={`${mSrc} 600w, ${mSrc2x} 1200w`}
            />
            <img
              className={styles.img}
              src={src}
              srcSet={src2x ? `${src} 729w, ${src2x} 1458w` : false}
              alt={alt ? alt : ''}
            />
          </picture>
        ) : (
          <img
            className={styles.img}
            src={src}
            srcSet={src2x ? `${src} 729w, ${src2x} 1458w` : false}
            alt={alt ? alt : ''}
          />
        )}
      </div>
    </div>
  );
}

Announcement.propTypes = {
  children: PropTypes.node.isRequired,
  mSrc: PropTypes.string,
  mSrc2x: PropTypes.string,
  src: PropTypes.string,
  src2x: PropTypes.string,
  alt: PropTypes.string,
};
