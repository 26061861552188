/* eslint-disable @trustradius/jsx-prefer-fragment-wrappers */
export function formatTitle(title = '') {
  const formattedTitle = title.replace(/\d+/g, '');
  const year = title.replace(/[^0-9]/g, '');
  if (year) {
    return (
      <>
        {formattedTitle}
        <span>&nbsp;{year}</span>
      </>
    );
  }
  return title;
}

export function generateBadgeUrlForAward(award, staticCdnHostname) {
  const baseUrl = `https://${staticCdnHostname}/images/awards/`;
  let badgeUrl = null;
  if (award.awardBadgeFileName) {
    badgeUrl = {
      x1: `${baseUrl}${award.awardBadgeFileName}.png`,
      x2: `${baseUrl}${award.awardBadgeFileName}_2x.png`,
    };
  } else if (!award.awardBadgeFileName && award.awardType === 'toprated') {
    const year = new Date(award.publishedDate).getFullYear() || 0;
    badgeUrl = {
      x1: `${baseUrl}top_rated_${year}.png`,
      x2: `${baseUrl}top_rated_${year}_2x.png`,
    };
  }

  return badgeUrl;
}
