import classNames from 'classnames';
import {
  Button,
  Checkbox,
  Input,
  RadioGroup,
  RadioItem,
  Switch,
  Text,
} from '@trustradius/radiant-ui';
import { Eye } from 'lucide-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'pages/LeadFormConfiguration/LeadFormConfiguration.module.scss';
import { FieldConfigurationContainer } from 'components/FieldConfigurationContainer';
import { CreateCheckboxField } from 'components/CreateCheckboxField';
import { CustomFieldConfiguration } from 'components/CustomFieldConfiguration';
import { LeadFormProductSelection } from 'components/LeadFormProductSelection';
import { Footer } from 'pages/LeadFormConfiguration/Footer';

export function RightColumn({
  isDesktop,
  leadFormFields,
  setLeadFormFields,
  setShowPreview,
  setShowDeleteModal,
  setShowNoProductSelectedModal,
  leadFormName,
  setLeadFormName,
}) {
  const [showCustomFieldButton, setShowCustomFieldButton] = useState(true);
  const vendorProducts = useSelector(
    (state) => state.vendors.currentVendorProductsById,
  );

  const subscribedProducts = Object.values(vendorProducts).filter(
    (product) => product.isSubscribed,
  );

  const [selectedProducts, setSelectedProducts] = useState({});

  const brandingOption = leadFormFields?.find(
    (field) => field?.slug === 'branding',
  );

  const switchChangeHandler = (id, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          enabled: value,
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const addCustomButton = () => {
    let enableCustomFieldCounter = 0;
    let didAddCustomField = false;
    const newFields = leadFormFields.map((field) => {
      if (field.slug.includes('custom_field') && field.enabled) {
        enableCustomFieldCounter += 1;
      }
      if (
        field.slug.includes('custom_field') &&
        !field.enabled &&
        !didAddCustomField
      ) {
        enableCustomFieldCounter += 1;
        didAddCustomField = true;
        return {
          ...field,
          enabled: true,
          config: {
            ...field.config,
            configurable: true,
          },
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
    setShowCustomFieldButton(enableCustomFieldCounter < 3);
  };

  const requiredHandler = (slug, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.slug === slug) {
        return {
          ...field,
          required: value,
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const updateValue = (slug, value) => {
    const newFields = leadFormFields?.map((field) => {
      if (field?.slug === slug) {
        field.value = value;
      }

      return field;
    });
    setLeadFormFields([...newFields]);
  };

  const changeFieldType = (id, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          type: value,
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const customFieldLabelChangeHandler = (id, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          config: {
            ...field.config,
            label: value,
          },
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const customFieldHelperTextChangeHandler = (id, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          config: {
            ...field.config,
            helpText: value,
          },
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const updateCheckboxText = (id, value) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          checkboxText: value,
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };

  const dropdownItemsChangeHandler = (id, dropdownItems) => {
    const newFields = leadFormFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          config: {
            ...field.config,
            options: dropdownItems?.map((item) => {
              return { value: item.id, label: item.value };
            }),
          },
        };
      }
      return field;
    });
    setLeadFormFields(newFields);
  };
  return (
    <div
      className={classNames(styles['right-column'], {
        [styles.mobile]: !isDesktop,
      })}
    >
      <div className={classNames(styles.body)}>
        <div
          className={classNames(styles.header, {
            [styles.mobile]: !isDesktop,
          })}
        >
          {!isDesktop && (
            <div className={classNames(styles['mobile-header'])}>
              <Button
                size="large"
                variant="secondary"
                onClick={() => setShowPreview(true)}
              >
                <Eye />
                Preview lead form
              </Button>
            </div>
          )}
          <Text as="h2" weight="bold" size="400">
            Customize form
          </Text>
        </div>
        <div className={styles['fields-config']}>
          <div className={styles['field-container']}>
            <Text as="h3" weight="bold" size="300">
              Name
            </Text>
            <Input
              hideLabel
              placeholder="Name"
              required
              value={leadFormName}
              onChange={(evt) => setLeadFormName(evt.target.value)}
            />
          </div>
          <div className={styles['field-container']}>
            <Text as="h3" weight="bold" size="300">
              Styling
            </Text>
            <FieldConfigurationContainer
              iconType="brand"
              text="Brand name and icon"
            >
              <RadioGroup
                name="brand"
                value={brandingOption?.value || 'company'}
                onChange={(value) => updateValue(brandingOption.slug, value)}
              >
                <RadioItem
                  id="companyRadioItem"
                  value="company"
                  label={
                    <div className={styles['brand-container']}>
                      <Text className={styles.gray120}>Company</Text>
                      <Text className={styles.gray100}>
                        Select this option to display your company’s name and
                        icon on your lead form. Choosing this option will mean
                        that your lead form will have consistent branding
                        regardless of which product listing it’s applied to.
                      </Text>
                    </div>
                  }
                  required
                />
                <RadioItem
                  id="productRadioItem"
                  value="product"
                  label={
                    <div className={styles['brand-container']}>
                      <Text className={styles.gray120}>Product</Text>
                      <Text className={styles.gray100}>
                        Select this option to display your product(s) name and
                        icon on your lead form. Choosing this option will mean
                        that your lead form will have branding specific to the
                        product listings it’s applied to.
                      </Text>
                    </div>
                  }
                  required
                />
              </RadioGroup>
            </FieldConfigurationContainer>
          </div>
          <div className={styles['field-container']}>
            <div className={styles['right-title']}>
              <Text as="h3" weight="bold" size="300">
                Form fields
              </Text>
              {showCustomFieldButton && (
                <Button
                  size="large"
                  variant="no-border"
                  onClick={addCustomButton}
                >
                  + Add custom field
                </Button>
              )}
            </div>
            <>
              {leadFormFields
                ?.filter((field) => field?.config?.configurable)
                ?.map((field) => {
                  const tooltipText =
                    field?.slug === 'companySize' || field?.slug === 'industry'
                      ? 'The values used for this dropdown menu are pre-defined by TrustRadius. If you would like to use different values, please add a custom field and input your own dropdown values'
                      : null;
                  return (
                    <FieldConfigurationContainer
                      key={field.id}
                      id={field.id}
                      text={field.label}
                      iconType={field?.config?.icon}
                      tooltipText={tooltipText}
                      titleButton={
                        <>
                          {!field?.slug?.includes('custom_field') && (
                            <Switch
                              checked={field.enabled}
                              hideLabel
                              onChange={(value) =>
                                switchChangeHandler(field.id, value)
                              }
                            />
                          )}
                          {field?.slug?.includes('custom_field') && (
                            <Button
                              size="large"
                              variant="no-border"
                              onClick={() => {
                                const newFields = leadFormFields.map(
                                  (leadField) => {
                                    if (leadField.id === field.id) {
                                      return {
                                        ...leadField,
                                        enabled: false,
                                        config: {
                                          ...leadField.config,
                                          configurable: false,
                                        },
                                      };
                                    }
                                    return leadField;
                                  },
                                );
                                setLeadFormFields(newFields);
                                setShowCustomFieldButton(true);
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </>
                      }
                      footer={
                        <Checkbox
                          labelText="Make this field required"
                          checked={field.required}
                          onCheckedChange={(value) =>
                            requiredHandler(field.slug, value)
                          }
                        />
                      }
                    >
                      {field?.slug === 'privacy' && (
                        <CreateCheckboxField
                          hideNameConfig
                          textAreaValue={field.value}
                          textAreaChangeHandler={(value) => {
                            updateValue(field.slug, value);
                          }}
                        />
                      )}
                      {field?.slug?.includes('custom_field') && (
                        <CustomFieldConfiguration
                          fieldType={field?.type}
                          fieldTypeChangeHandler={(value) =>
                            changeFieldType(field?.id, value)
                          }
                          titleInputLabel={field?.config?.labelTitle}
                          titleInputValue={field?.config?.label}
                          titleInputChangeHandler={(event) =>
                            customFieldLabelChangeHandler(
                              field?.id,
                              event?.target?.value,
                            )
                          }
                          checkboxTextAreaValue={field?.checkboxText}
                          checkboxTextAreaChangeHandler={(value) =>
                            updateCheckboxText(field?.id, value)
                          }
                          dropdownNameFieldId={`dropdown_${field?.id}`}
                          dropdownNameFieldChangeHandler={(event) =>
                            customFieldLabelChangeHandler(
                              field?.id,
                              event?.target?.value,
                            )
                          }
                          dropdownItems={field?.config?.options?.map((item) => {
                            return { id: item.value, value: item.label };
                          })}
                          dropdownItemChangeHandler={(items) =>
                            dropdownItemsChangeHandler(field?.id, items)
                          }
                          textLabelFieldId={`text_${field?.id}`}
                          textHelpTextId={`text_help_${field?.id}`}
                          textHelpTextValue={field?.config?.helpText}
                          textHelpTextChangeHandler={(event) =>
                            customFieldHelperTextChangeHandler(
                              field?.id,
                              event?.target?.value,
                            )
                          }
                        />
                      )}
                    </FieldConfigurationContainer>
                  );
                })}
              {subscribedProducts?.length && (
                <LeadFormProductSelection
                  licensedProducts={subscribedProducts}
                  selectedProducts={selectedProducts}
                  onSelectedProductsChange={setSelectedProducts}
                />
              )}
            </>
          </div>
        </div>
      </div>
      <Footer
        isDesktop={isDesktop}
        setShowPreview={setShowPreview}
        setShowDeleteModal={setShowDeleteModal}
        setShowNoProductSelectedModal={setShowNoProductSelectedModal}
        selectedProducts={selectedProducts}
      />
    </div>
  );
}

RightColumn.propTypes = {
  isDesktop: PropTypes.bool,
  leadFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      label: PropTypes.string,
      enabled: PropTypes.bool,
      required: PropTypes.bool,
      config: PropTypes.shape({
        icon: PropTypes.string,
        configurable: PropTypes.bool,
        label: PropTypes.string,
        helpText: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  ),
  setLeadFormFields: PropTypes.func,
  setShowPreview: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  setLeadFormName: PropTypes.func,
  setShowNoProductSelectedModal: PropTypes.func,
  leadFormName: PropTypes.string,
};
