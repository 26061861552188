import PropTypes from 'prop-types';
import styles from './Pill.module.scss';

export function Pill({ children }) {
  return <div className={styles.pill}>{children}</div>;
}

Pill.propTypes = {
  children: PropTypes.node,
};
