import React from 'react';
import { CSVLink } from 'react-csv';
import classNames from 'classnames';

/**
 * TrustRadius wrapped CSVLink component
 * Implemented using react-csv
 * Added local styles for this component as well
 */
export const CsvLink = React.forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <CSVLink ref={ref} className={classes} {...otherProps}>
      {children}
    </CSVLink>
  );
});

CsvLink.displayName = 'CsvLink';
// eslint-disable-next-line react/forbid-foreign-prop-types
CsvLink.propTypes = CSVLink.propTypes;
