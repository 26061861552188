import ReactBootstrapModalFooter from 'react-bootstrap/ModalFooter';
import classNames from 'classnames';

import './Modal.scss';

/**
 * TrustRadius wrapped ModalFooter component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function ModalFooter(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapModalFooter
      className={classes}
      bsPrefix="trModal-footer"
      {...otherProps}
    >
      {children}
    </ReactBootstrapModalFooter>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
ModalFooter.propTypes = ReactBootstrapModalFooter.propTypes;
