/* eslint-disable @trustradius/jsx-no-complex-expressions */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Info } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Input, Text, Tooltip } from '@trustradius/radiant-ui';
import { LeadsWebhookFooter, Field } from 'components/LeadsWebhookTabs';
import { mapLeadFormFields } from './mapLeadFormFieldsSalesforce';
import styles from './Tab.module.scss';

export function TabSalesforce({
  webhookData,
  createWebhookHandler,
  updateWebhookHandler,
  deleteWebhookHandler,
  sendTestLeadHandler,
  setTestLeadResult,
}) {
  const defaultValues = {
    endpoint: webhookData?.endpoint || '',
    SaleForceOID: webhookData?.SaleForceOID || '',
    company: webhookData?.mapping?.company || '',
    country: webhookData?.mapping?.country || '',
    email: webhookData?.mapping?.email || '',
    firstName: webhookData?.mapping?.firstName || '',
    interestedIn: webhookData?.mapping?.interestedIn || '',
    lastName: webhookData?.mapping?.lastName || '',
    message: webhookData?.mapping?.message || '',
    productName: webhookData?.mapping?.productName || '',
    leadSource: webhookData?.mapping?.leadSource || '',
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const vendorId = useSelector((state) => state?.vendors?.currentVendor?._id);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isTestLeadModalOpen, setIsTestLeadModalOpen] = useState(false);

  const deleteWebhook = async (id) => {
    await deleteWebhookHandler(id);
    const values = {
      endpoint: '',
      SaleForceOID: '',
      company: '',
      country: '',
      email: '',
      firstName: '',
      interestedIn: '',
      lastName: '',
      message: '',
      productName: '',
      leadSource: '',
    };
    reset(values);
  };

  const formToRequestParams = (formData, id) => {
    const res = {
      vendorId,
      type: 'salesforce',
      dataType: 'formdata',
      endpoint: formData.endpoint,
      SaleForceOID: formData.SaleForceOID,
      mapping: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        company: formData.company,
        country: formData.country,
        message: formData.message,
        dataType: formData.dataType,
        interestedIn: formData.interestedIn,
        productName: formData.productName,
        leadSource: formData.leadSource,
      },
    };

    if (id) {
      res.id = id;
    }
    return res;
  };

  const onSubmit = async (data) => {
    let result;
    if (webhookData?.id) {
      result = await updateWebhookHandler(
        formToRequestParams(data, webhookData?.id),
      );
    } else {
      result = await createWebhookHandler(formToRequestParams(data));
    }

    if (result?.errors) {
      return;
    }
    setIsSaveModalOpen(true);
  };

  const discardChanges = () => {
    reset(defaultValues);
  };

  const sendTestLead = async () => {
    setIsSaveModalOpen(false);
    setIsTestLeadModalOpen(true);
    const result = await sendTestLeadHandler(webhookData?.id);
    setIsTestLeadModalOpen(false);
    setTestLeadResult(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <section>
          <h2 className={styles.heading}>Define endpoint</h2>
          <p>
            To send your TrustRadius leads to Salesforce, please{' '}
            <a
              href="https://trustradius.freshdesk.com/en/support/solutions/articles/43000694907-set-up-a-salesforce-webhook-for-trustradius-leads"
              target="_blank"
              rel="nofollow noreferrer"
            >
              follow these steps
            </a>{' '}
            in your Salesforce instance to generate an HTML sample with the
            elements shown below.
          </p>

          <Field
            heading="Endpoint URL"
            summary="Copy and paste your endpoint url from your Salesforce instance into
            this field."
            handleRemove={() => {
              deleteWebhook(webhookData?.id);
            }}
            input={{
              label: 'label',
              id: 'endpoint',
              required: 'required',
              ...register('endpoint', {
                required: {
                  value: true,
                  message: 'Endpoint URL is a required field',
                },
              }),
              'aria-invalid': errors.endpoint ? 'true' : 'false',
              isInvalid: errors.endpoint,
            }}
            errorMessage={errors.endpoint?.message}
          />

          <Field
            heading="OID value"
            summary="Copy and paste the OID value from your Salesforce sample HTML
          into this field."
            input={{
              label: 'OID value',
              id: 'oid-value',
              required: 'required',
              ...register('SaleForceOID', {
                required: {
                  value: true,
                  message: 'OID value is a required field',
                },
              }),
              'aria-invalid': errors.SaleForceOID ? 'true' : 'false',
              isInvalid: errors.SaleForceOID,
            }}
            errorMessage={errors.SaleForceOID?.message}
          />

          <section className={styles['map-fields']}>
            <h2 className={styles.heading}>Map lead form fields</h2>
            <p>
              Use this table to map your TrustRadius Lead form fields to their
              corresponding fields in Salesforce.
            </p>
            <Text as="ul" size="100">
              <li>
                The fields from your TrustRadius Lead form are shown in the left
                column of the table below.
              </li>
              <li>
                In the “Salesforce Field Name” column, please add the names of
                the corresponding fields from your Salesforce instance.{' '}
                <a href="https://trustradius.freshdesk.com/en/support/solutions/articles/43000694907-set-up-a-salesforce-webhook-for-trustradius-leads">
                  You can find the Salesforce field names
                </a>{' '}
                in the sample HTML that you generated
              </li>
            </Text>
            <table className={styles['form-table']}>
              <caption>Table of lead form fields</caption>
              <thead>
                <tr>
                  <th scope="col">
                    <Tooltip
                      align="start"
                      triggerComponent={
                        <button type="button" className={styles.tooltip}>
                          <span>Lead form field</span>
                          <Info size="18" aria-hidden />
                        </button>
                      }
                      content={
                        <Text size="100">
                          The field in the TrustRadius lead form.
                        </Text>
                      }
                    />
                  </th>
                  <th scope="col">
                    <Tooltip
                      triggerComponent={
                        <button type="button" className={styles.tooltip}>
                          <span>Salesforce Field</span>
                          <Info size="18" aria-hidden />
                        </button>
                      }
                      content={
                        <Text size="100">
                          This is the corresponding field that the TrustRadius
                          field should be mapped to in Salesforce.
                        </Text>
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(mapLeadFormFields).map((key) => {
                  const field = mapLeadFormFields[key];
                  const fieldId = field.id;
                  const fieldError = errors[fieldId];

                  return (
                    <tr key={fieldId}>
                      <th scope="row">
                        <label htmlFor={`map-lead-form-${fieldId}`}>
                          {field.label}
                        </label>
                      </th>
                      <td colspan="2" className={styles['input-column']}>
                        <Input
                          id={`map-lead-form-${fieldId}`}
                          name={fieldId}
                          label={fieldId}
                          type="text"
                          autoComplete="off"
                          required
                          hideLabel
                          aria-label={field.label}
                          {...register(fieldId, {
                            required: {
                              value: Boolean(mapLeadFormFields[key].required),
                              message: `${field.label} is a required field`,
                            },
                            maxLength: {
                              value: mapLeadFormFields[key].maxLength,
                              message: `Maximum length of the field should be ${mapLeadFormFields[key].maxLength} symbols`,
                            },
                          })}
                          aria-invalid={fieldError ? 'true' : 'false'}
                          isInvalid={fieldError}
                        />
                        {fieldError && (
                          <Text size="100" className={styles.error}>
                            {fieldError.message}
                          </Text>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </section>
        <LeadsWebhookFooter
          isSaveModalOpen={isSaveModalOpen}
          setIsSaveModalOpen={setIsSaveModalOpen}
          discardChanges={discardChanges}
          sendTestLead={sendTestLead}
          isTestLeadModalOpen={isTestLeadModalOpen}
          setIsTestLeadModalOpen={setIsTestLeadModalOpen}
          webhookId={webhookData.id}
        />
      </form>
    </>
  );
}

TabSalesforce.propTypes = {
  webhookData: PropTypes.object,
  createWebhookHandler: PropTypes.func.isRequired,
  updateWebhookHandler: PropTypes.func.isRequired,
  deleteWebhookHandler: PropTypes.func.isRequired,
  sendTestLeadHandler: PropTypes.func.isRequired,
  setTestLeadResult: PropTypes.func.isRequired,
};
