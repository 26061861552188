import { Checkbox, Text } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { LeadFormProductCheckbox } from 'components/LeadFormProductCheckbox';
import styles from './LeadFormProductSelection.module.scss';

export function LeadFormProductSelection({
  licensedProducts,
  selectedProducts,
  onSelectedProductsChange,
}) {
  const onSelectAllProducts = (value) => {
    if (!value) {
      onSelectedProductsChange({});
      return;
    }
    const products = {};
    licensedProducts?.forEach((product) => {
      if (!product?.leadForm?.id) {
        products[product._id] = true;
      }
    });
    onSelectedProductsChange(products);
  };

  const onSelectProduct = (productId, value) => {
    if (value) {
      onSelectedProductsChange({
        ...selectedProducts,
        [productId]: true,
      });
      return;
    }
    const products = { ...selectedProducts };
    delete products[productId];
    onSelectedProductsChange(products);
  };

  return (
    <>
      <div className={styles.header}>
        <Text as="h2" size="400" weight="semibold">
          Select products
        </Text>

        <Text as="p" size="200">
          Select the product listings you would like this lead form to be
          displayed on.
        </Text>
      </div>
      <div className={styles['product-selection']}>
        <div className={styles.all}>
          <Checkbox
            labelText="Select all products"
            onCheckedChange={(value) => onSelectAllProducts(value)}
          />
        </div>
        <Text as="h3" size="100" className="gray100">
          Licensed products:
        </Text>

        {licensedProducts?.length > 0 &&
          licensedProducts?.map((product) => {
            return (
              <LeadFormProductCheckbox
                key={product._id}
                product={product}
                selected={Boolean(selectedProducts[product._id])}
                onChangeHandler={(value) =>
                  onSelectProduct(product?._id, value)
                }
              />
            );
          })}
      </div>
    </>
  );
}

LeadFormProductSelection.propTypes = {
  licensedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      leadForm: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
  selectedProducts: PropTypes.object,
  onSelectedProductsChange: PropTypes.func,
};
