import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'components/TrustradiusUICore';
import styles from './AuthenticatedUsersSubtitle.module.scss';

const TOOLTIP_MAX_USERS = 10;

export function AuthenticatedUsersSubtitle({ users }) {
  return (
    <OverlayTrigger
      placement="bottom-start"
      trigger={['focus', 'hover']}
      overlay={(props) => {
        if (users.length === 0) {
          return <div />;
        }

        const unmentionedUsers = users.length - TOOLTIP_MAX_USERS;

        return (
          <Tooltip {...props} className={styles.tooltip}>
            {users
              .slice(0, TOOLTIP_MAX_USERS)
              .map((user) => `${user.name?.first} ${user.name?.last}`.trim())
              .join(', ')}
            {unmentionedUsers > 0 && `, and ${unmentionedUsers} more`}
          </Tooltip>
        );
      }}
    >
      {(triggerHandler) => {
        const userCount =
          users.length === 1 ? '1 user' : `${users.length} users`;

        return <span {...triggerHandler}>{userCount} authenticated</span>;
      }}
    </OverlayTrigger>
  );
}

AuthenticatedUsersSubtitle.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.shape({
        first: PropTypes.string,
        last: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
};
