import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapPopover from 'react-bootstrap/Popover';
import classNames from 'classnames';

// Mimicking the access pattern that react bootstrap sets up for the inner
// components for Popover so we can specify bsPrefix prop
export const PopoverTitle = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapPopover.Title
      ref={ref}
      className={classes}
      bsPrefix="tr-popover-header"
      {...otherProps}
    >
      {children}
    </ReactBootstrapPopover.Title>
  );
});

PopoverTitle.displayName = 'PopoverTitle';
PopoverTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
