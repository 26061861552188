import { Button, Text } from '@trustradius/radiant-ui';
import { X } from 'lucide-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { UnlinkLeadFormModal } from 'components/LeadsV2Modals';
import styles from './LeadFormPill.module.scss';

export function LeadFormPill({ leadFormId, leadFormName }) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <div className={styles.container} data-testid="lead-form-pill">
        <Text>{leadFormName}</Text>
        <Button
          variant="icon"
          onClick={() => setIsShowModal(true)}
          data-testid="delete-button"
        >
          <X />
        </Button>
      </div>
      <UnlinkLeadFormModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        deleteHandler={() => {
          console.log(`deleteHandler for ${leadFormId}`);
        }}
      />
    </>
  );
}

LeadFormPill.propTypes = {
  leadFormId: PropTypes.string.isRequired,
  leadFormName: PropTypes.string.isRequired,
};
