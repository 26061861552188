import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Competitor } from 'components/Competitor';
import { Skeleton } from 'components/Skeleton';
import { fetchCompetitorsByProductId } from '../../store/apis/competitorsApi';
import styles from './CompetitorsContainer.module.scss';

export function CompetitorsContainer({ editCompetitorsLink, hasAccessToEdit }) {
  const maxCompetitorsOnPage = 4;
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );
  const competitors = useSelector((state) => state.competitors);
  const hasError = useSelector((state) => state.competitors.error);
  const timeFrame = useSelector((state) => state.globalState.timeFrame);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentProduct?._id) {
      (async () => {
        setIsLoading(true);
        await fetchCompetitorsByProductId(
          currentProduct._id,
          timeFrame,
        )(dispatch);
        setIsLoading(false);
      })();
    }
  }, [currentProduct, timeFrame, dispatch]);

  return (
    <>
      {!isLoading && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div>Products</div>
            <div>Page views</div>
          </div>
          {competitors.allIds.slice(0, maxCompetitorsOnPage).map((id) => (
            <Competitor
              key={id}
              name={competitors.byId[id]?.name}
              trScore={competitors.byId[id]?.trScore}
              logos={competitors.byId[id]?.logos}
              current={competitors.byId[id]?.current}
              deltaPct={competitors.byId[id]?.deltaPct}
            />
          ))}
          {competitors.allIds.length === 0 && !hasError && (
            <div className={styles.empty}>
              <p>
                {hasAccessToEdit && (
                  <a href={editCompetitorsLink}>
                    Add your competitors to see more
                  </a>
                )}
                {!hasAccessToEdit && (
                  <span>Add your competitors to see more</span>
                )}
              </p>
            </div>
          )}
        </div>
      )}
      {!isLoading && hasError && (
        <div className={styles.empty}>
          <p>
            Something went wrong.
            <br />
            Please refresh to try again.
          </p>
        </div>
      )}
      {isLoading && <Skeleton className={styles.empty} />}
    </>
  );
}

CompetitorsContainer.propTypes = {
  editCompetitorsLink: PropTypes.string,
  hasAccessToEdit: PropTypes.bool,
};
