import { useSelector } from 'react-redux';
import { DollarSign, ShieldAlert, Timer, ClipboardList } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { WhisticSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { whisticBanner, whisticBanner2x } from './assets';

export function Whistic() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Accelerate the vendor assessment process with the Whistic Security Network';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Whistic',
    logo: <img src={WhisticSquareLogo} alt="Whistic" />,
    subtitle: subTitle,
    actionBlock: (
      <Button size="large" url="https://lp.whistic.com/trustradius" targetBlank>
        Get Started
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: '1',
      icon: <DollarSign />,
      text: 'Close deals faster by streamlining the security assessment process',
    },
    {
      id: '2',
      icon: <ShieldAlert />,
      text: 'Build trust by proactively sharing your security posture with customers',
    },
    {
      id: '3',
      icon: <Timer />,
      text: 'Eliminate time-consuming security questionnaire requests',
    },
    {
      id: '4',
      icon: <ClipboardList />,
      text: 'Access Whistic’s extensive library of standard questionnaires',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588667"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Vendor Risk Management'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={whisticBanner}
            srcSet={`${whisticBanner} 1x, ${whisticBanner2x} 2x`}
            alt="Whistic"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Whistic</Text>
          <Text as="p">
            The Whistic Vendor Security Network accelerates the vendor
            assessment process by enabling businesses to access and evaluate a
            vendor’s Whistic Profile and create trusted connections that last
            well beyond the initial assessment. Make security your competitive
            advantage and join businesses like Airbnb, Okta, Zendesk, Asana,
            Atlassian, Snap, Notion, TripActions, and G2 that are leveraging
            Whistic to modernize their vendor security programs.
          </Text>
          <Text as="h3">Whistic and TrustRadius</Text>
          <Text as="p">
            Whistic and TrustRadius have partnered to allow you to add your
            Whistic Profile link to your TrustRadius product listing. This will
            enable interested buyers to request access to your Whistic Security
            Profile. Granting buyers access to your Profile allows them to
            quickly evaluate your security qualifications, accelerating the
            security assessment process of the sales cycle.
          </Text>
          <Text as="p">
            Note: Buyers who want to view your complete Whistic Profile will
            still need to request access from you, so you will have complete
            control over and visibility into who is accessing your profile.
          </Text>
          <Text as="h3">Don’t have a Whistic profile?</Text>
          <Text as="p">
            Whistic is offering a free basic profile to all TrustRadius
            customers. Click “Get started” to more.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h2">Promote transparency and build trust with buyers</Text>
          <Text as="p">
            Proactively sharing security documentation through a Whistic profile
            gives buyers peace of mind that the product they’re shopping is
            compliant with their organization’s security standards.
          </Text>
          <Text as="h3">
            Accelerate sales cycles by streamlining the security assessment
            process
          </Text>
          <Text as="p">
            Adding a Whistic profile to your TrustRadius product listing means
            buyers can start evaluating your security qualifications
            immediately. It eliminates the red tape of traditional questionnaire
            requests.
          </Text>
          <Text as="h3">
            Remain competitive in the digital marketplaces where buyers conduct
            their research
          </Text>
          <Text as="p">
            Enhancing your TrustRadius listing with a Whistic profile enables
            buyers to self-serve—allowing your organization to easily share your
            security posture with prospects early in the sales cycle.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
