import { useEligibleProductsValues } from 'hooks';
import { GeneralAwardCard } from '../GeneralAwardCard';

export function TechCaresAwardCard() {
  const eligibleProductsValues = useEligibleProductsValues();

  return (
    <GeneralAwardCard
      badge={{
        src: 'https://static.trustradius.com/images/awards/tech_cares.png',
        alt: 'Tech Cares 2022',
      }}
      name="Tech Cares"
      values={eligibleProductsValues}
      description={
        <>
          Companies and organizations that are influencing the B2B tech space
          with their social responsibility standards.
        </>
      }
      linkProps={{
        to: '../awards/tech-cares',
        relative: 'path',
        children: 'View details',
      }}
    />
  );
}
