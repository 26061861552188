import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function Prerequisites({ content }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getList() {
    const list = (
      <ul>
        {content.map(({ id, componentContent }) => (
          <Text as="li" key={id}>
            {componentContent}
          </Text>
        ))}
      </ul>
    );

    if (!isTablet) {
      return list;
    } else if (isOpen) {
      return list;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          Prerequisites
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getList()}
    </section>
  );
}

export const PrerequisitesShape = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      componentContent: PropTypes.node.isRequired,
    }),
  ).isRequired,
});

Prerequisites.propTypes = PrerequisitesShape.isRequired;
