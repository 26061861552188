import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Modal.scss';

export function ModalPanel(props) {
  const { className, children, ...otherProps } = props;
  const classes = classNames(className);
  return (
    <div className={`trModal-panel ${classes}`} {...otherProps}>
      {children}
    </div>
  );
}

ModalPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
