import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Skeleton.module.scss';

export function Skeleton({ className, children }) {
  return (
    <div className={classNames(className, styles.shimmer)} data-testid="loader">
      {children}
    </div>
  );
}

Skeleton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
