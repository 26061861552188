import { Fragment } from 'react';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import { Text } from '@trustradius/radiant-ui';
import { PageBody } from 'components/PageBody';
import styles from './SecondaryPageContent.module.scss';

export function SecondaryPageContent({
  rightRail,
  slider,
  imageBanner,
  children,
}) {
  const isDesktop = useMedia('(min-width:901px)');

  return (
    <PageBody className={styles['page-body']}>
      {rightRail && rightRail.length && (
        <aside className={styles.aside}>
          <div className={styles.features}>
            {rightRail.map((section, index) => {
              return (
                <Fragment key={section.id}>
                  {section.element}
                  {index + 1 < rightRail.length && isDesktop && (
                    <div className={styles.separator} />
                  )}
                </Fragment>
              );
            })}
          </div>
          <div className={styles.slider}>
            <section>
              <Text as="h3" className={styles['slider-title']}>
                {slider?.title}
              </Text>
              {slider?.component}
            </section>
          </div>
        </aside>
      )}
      <main className={styles.main}>
        {imageBanner}
        <div className={styles.content}>{children}</div>
      </main>
    </PageBody>
  );
}

SecondaryPageContent.propTypes = PropTypes.shape({
  rightRail: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      element: PropTypes.node.isRequired,
    }),
  ),
  slider: PropTypes.shape({
    title: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
  }),
  imageBanner: PropTypes.node,
  children: PropTypes.node,
}).isRequired;
