import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function MobileView() {
  const currentVendor = useSelector((state) => state.vendors.currentVendor);
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );

  return (
    <div className={styles['mobile-view']}>
      <Text as="h2">TrustRadius Vendor Portal</Text>
      <Text as="p">
        Sign in on your desktop or tablet for the best experience with this
        page.
      </Text>
      <Link
        to={`/vendor/${currentVendor?._id}/dashboard/${currentProduct?._id}`}
        className={`button button-primary-pill ${styles.link}`}
      >
        <ArrowLeft size="20" /> Return to Dashboard
      </Link>
    </div>
  );
}
