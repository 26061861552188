import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { ChevronDown } from 'lucide-react';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from './Filters.module.scss';

export function CategoryFilter({
  primaryCategory,
  categories,
  selectedCategory,
  setCategory,
}) {
  const categoryType =
    selectedCategory?._id === primaryCategory?._id ? 'Primary' : 'Secondary';

  const displayName = selectedCategory
    ? `${categoryType}: ${selectedCategory.name}`
    : 'None';

  const onSelect = (eventKey) => {
    if (eventKey === primaryCategory?._id) {
      setCategory(primaryCategory);
    } else {
      const category = categories.find((cat) => cat?._id === eventKey);
      setCategory(category);
    }
  };

  const categoryCompare = (lhs, rhs) => {
    const lhsName = lhs.name.toUpperCase();
    const rhsName = rhs.name.toUpperCase();
    if (lhsName < rhsName) return -1;
    if (rhsName < lhsName) return 1;
    return 0;
  };

  const notDesktop = useMedia('(max-width:900px)');

  return (
    <div
      role="group"
      className={classNames(styles.filter, { [styles.notDesktop]: notDesktop })}
      aria-labelledby="category_filter"
    >
      <div className={styles['filter-label']} id="category_filter">
        Category:
      </div>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle
          className={styles['filter-toggle']}
          variant="default"
          id="dropdown-basic"
        >
          <span className={styles['filter-toggle-text']}>{displayName}</span>
          <ChevronDown />
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles['dropdown-menu']} bsPrefix="tr">
          {primaryCategory && (
            <>
              <Dropdown.Header
                className={styles['filter-sublabel']}
                id="category_filter_primary_category"
              >
                Primary Category
              </Dropdown.Header>
              <Dropdown.Item
                eventKey={primaryCategory._id}
                className={styles['dropdown-item-button']}
              >
                {primaryCategory.name}
              </Dropdown.Item>
            </>
          )}
          {categories && categories.length > 0 && (
            <>
              <Dropdown.Header
                className={styles['filter-sublabel']}
                id="category_filter_secondary_category"
              >
                Secondary Categories
              </Dropdown.Header>
              {categories.sort(categoryCompare).map((category) => (
                <Dropdown.Item
                  eventKey={category._id}
                  key={category.slug}
                  className={styles['dropdown-item-button']}
                >
                  {category.name}
                </Dropdown.Item>
              ))}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const CategoryShape = PropTypes.shape({
  slug: PropTypes.string,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
});

CategoryFilter.propTypes = {
  primaryCategory: CategoryShape,
  categories: PropTypes.arrayOf(CategoryShape),
  selectedCategory: CategoryShape,
  setCategory: PropTypes.func,
};
