import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PageBody.module.scss';

export function PageBody({ className, children }) {
  return <div className={classNames(className, styles.body)}>{children}</div>;
}

PageBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
