import PropTypes from 'prop-types';
import {
  Button,
  Text,
  Input,
  Select,
  SelectItem,
  TextArea,
  Checkbox,
} from '@trustradius/radiant-ui';
import { useState } from 'react';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import { ProductPlaceholderImage } from 'components/LeadFormV2/images';
import styles from './LeadFormV2.module.scss';

export function LeadFormV2({ disabled, vendor, fields, product }) {
  const initialState = {};
  fields?.forEach((field) => {
    if (field?.enabled) {
      initialState[field.slug] = field?.config?.default;
    }
  });
  initialState.privacyPolicy = false;
  initialState.demo = false;
  initialState.quote = false;
  initialState.other = false;

  const [formData, setFormData] = useState(initialState);
  const isDesktop = useMedia('(min-width: 1200px)');
  const changeHandler = (evt) => {
    const { id, name, value } = evt.target || {};
    if (
      id === 'privacyPolicy' ||
      id === 'demo' ||
      id === 'quote' ||
      id === 'other'
    ) {
      setFormData({
        ...formData,
        [id]: value === 'on',
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const selectChangeHandler = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const submitHandler = (evt) => {
    evt.preventDefault();
  };
  const brandingProp = fields.find((field) => field.slug === 'branding');
  let formIcon = ProductPlaceholderImage;
  if (brandingProp?.value === 'product' && product?.logos?.square180) {
    formIcon = product.logos.square180;
  }
  if (brandingProp?.value === 'company' && vendor?.logos?.square180) {
    formIcon = vendor.logos.square180;
  }

  let formTitleName = `[Product Name]`;
  if (brandingProp?.value === 'product' && product?.name) {
    formTitleName = product?.name;
  }
  if (brandingProp?.value === 'company' && vendor?.name) {
    formTitleName = vendor?.name;
  }
  return (
    <div className={styles.wrapper}>
      {disabled && <div className={styles.disabled}> </div>}
      <form
        className={classNames(styles.container, {
          [styles.mobile]: !isDesktop,
        })}
      >
        <div
          className={classNames(styles['left-column'], {
            [styles.mobile]: !isDesktop,
          })}
        >
          <div className={styles['logo-container']}>
            <img className={styles.logo} src={formIcon} alt={vendor?.name} />
            <Text as="h2" size="300" weight="bold">
              Connect with {formTitleName}
            </Text>
          </div>
          <>
            <Text
              as="h3"
              size="100"
              className={classNames(styles['reasons-title'], {
                [styles.mobile]: !isDesktop,
              })}
            >
              WHAT ARE YOU INTERESTED IN?
            </Text>
            <div
              className={classNames(styles.reasons, {
                [styles.mobile]: !isDesktop,
              })}
            >
              <Checkbox
                id="demo"
                checked={formData.demo}
                labelText={
                  <Text
                    className={classNames(styles['reason-label'], {
                      [styles['mobile-textbox']]: !isDesktop,
                    })}
                  >
                    Request a demo
                  </Text>
                }
                onClick={changeHandler}
              />
              <Checkbox
                id="quote"
                checked={formData.quote}
                labelText={
                  <Text
                    className={classNames(styles['reason-label'], {
                      [styles['mobile-textbox']]: !isDesktop,
                    })}
                  >
                    Request a quote
                  </Text>
                }
                onClick={changeHandler}
              />
              <Checkbox
                id="other"
                checked={formData.other}
                labelText={
                  <Text
                    className={classNames(styles['reason-label'], {
                      [styles['mobile-textbox']]: !isDesktop,
                    })}
                  >
                    Other
                  </Text>
                }
                onClick={changeHandler}
              />
            </div>
          </>
        </div>
        <div className={styles['right-column']}>
          <div className={styles['two-fields-in-row']}>
            <div className={styles.input}>
              <Input
                id="firstName"
                name="firstName"
                label="First Name"
                required
                onChange={changeHandler}
              />
            </div>
            <div className={styles.input}>
              <Input
                id="lastName"
                name="lastName"
                label="Last Name"
                required
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className={styles['with-tooltip']}>
            <Input
              id="email"
              name="email"
              label="Email"
              required
              onChange={changeHandler}
            />
          </div>
          <div className={styles['with-tooltip']}>
            <Input
              id="company"
              name="company"
              label="Company"
              required
              onChange={changeHandler}
            />
          </div>
          {fields?.map((field) => {
            if (!field?.enabled || !field?.config?.configurable) return null;
            if (field.type === 'SELECT' || field.type === 'CUSTOM_MENU') {
              const options = field?.config?.options || [];
              const label = field?.config?.label || field.label;
              return (
                <div className={styles.select} key={field.id}>
                  <Select
                    id={field.slug}
                    label={label}
                    name={field.slug}
                    value={formData[field.id]}
                    options={options}
                    placeholder={field.placeholder}
                    required={field.required}
                    onValueChange={(value) =>
                      selectChangeHandler(field.slug, value)
                    }
                  >
                    {field?.config?.options?.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
              );
            }

            if (field.type === 'TEXT') {
              return (
                <div className={styles.input} key={field.id}>
                  <TextArea
                    id={field.slug}
                    label={field.label}
                    required={field.required}
                    name={field.slug}
                    onChange={changeHandler}
                  />
                </div>
              );
            }

            if (field.type === 'CHECKBOX' || field.type === 'CUSTOM_CHECKBOX') {
              return (
                <div key={field.id}>
                  {field?.config?.label && (
                    <Text as="h3" size="100">
                      {field?.config?.label}
                    </Text>
                  )}
                  <Checkbox
                    id={field.slug}
                    name={field.slug}
                    labelText={
                      <Markdown>
                        {field?.checkboxText || field.value || field.label}
                      </Markdown>
                    }
                    multiLineLabel
                    required={field.required}
                    onChange={changeHandler}
                  />
                </div>
              );
            }

            if (field.type === 'INPUT' || field.type === 'CUSTOM_TEXT') {
              return (
                <div key={field.slug}>
                  <Input
                    id={field.slug}
                    tooltipText={field?.config?.helpText}
                    label={field?.config?.label || field.label}
                    required={field.required}
                    name={field.slug}
                    onChange={changeHandler}
                  />
                </div>
              );
            }
            return null;
          })}
          <Text>
            You hereby consent to have TrustRadius share the information
            supplied on this form with Cisco so that Cisco and TrustRadius may
            contact you in regard to the information requested.
          </Text>
          <Checkbox
            id="privacyPolicy"
            multiLineLabel
            onChange={changeHandler}
            name="privacyPolicy"
            labelText={
              <Text>
                By checking this box, I acknowledge that I have read and agree
                to the TrustRadius{' '}
                <a href="https://www.trustradius.com/static/privacy-policy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://www.trustradius.com/static/terms-of-use">
                  Terms Of Use
                </a>
                .
              </Text>
            }
          />
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              onClick={submitHandler}
              isDisabled
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

LeadFormV2.propTypes = {
  disabled: PropTypes.bool,
  vendor: PropTypes.shape({
    logos: PropTypes.shape({
      square180: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logos: PropTypes.shape({
      square180: PropTypes.string,
    }),
  }),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      id: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
  ),
};
