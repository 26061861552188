import { useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import styles from './CardErrorState.module.scss';

// @TODO Add animation on close
export function CardErrorState({
  text = 'Oops, something went wrong loading this card.',
  buttonTitle = 'Refresh',
  refreshHandler,
}) {
  const [close, setClose] = useState(false);

  if (close) return null;

  return (
    <div data-testid="card-error" className={styles.container}>
      <div className={styles.message}>
        <p data-testid="error-message">{text}</p>
        {buttonTitle && refreshHandler && (
          <button
            type="button"
            onClick={refreshHandler}
            className={styles.refresh}
            data-testid="refresh"
          >
            {buttonTitle}
          </button>
        )}
      </div>
      <button
        type="button"
        className={styles.close}
        onClick={() => {
          setClose(true);
        }}
        data-testid="close-button"
      >
        <X />
      </button>
    </div>
  );
}

CardErrorState.propTypes = {
  text: PropTypes.string,
  buttonTitle: PropTypes.string,
  refreshHandler: PropTypes.func,
};
