import {
  COLLAPSE_SIDEBAR,
  EXPAND_SIDEBAR,
  CHANGE_TIMEFRAME,
  ACTIVATE_VENDOR_SWITCHER,
  DEACTIVATE_VENDOR_SWITCHER,
} from '../actions/globalActions';

const initialState = {
  loading: false,
  hasError: false,
  error: {},
  isMenuContainerExpanded: false,
  isVendorSwitcherActive: false,
  timeFrame: '30',
};
const globalState = (state = initialState, action) => {
  switch (action.type) {
    case EXPAND_SIDEBAR:
      return {
        ...state,
        isMenuContainerExpanded: true,
      };
    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        isVendorSwitcherActive: false,
        isMenuContainerExpanded: false,
      };
    case CHANGE_TIMEFRAME:
      return {
        ...state,
        timeFrame: action.payload.timeFrame,
      };
    case ACTIVATE_VENDOR_SWITCHER:
      return {
        ...state,
        isVendorSwitcherActive: true,
      };
    case DEACTIVATE_VENDOR_SWITCHER:
      return {
        ...state,
        isVendorSwitcherActive: false,
      };
    default:
      return state;
  }
};

export default globalState;
