import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import { LinkedInMobile, LinkedInDesktop } from './assets';

export function LinkedInCard({ product }) {
  return (
    <DashCard
      linkProps={{
        to: '../../integrations/linkedin',
        relative: 'path',
        children: 'Get started',
      }}
      product={product}
      isTargetBlank={false}
    >
      <Announcement
        mSrc={LinkedInMobile}
        mSrc2x={LinkedInMobile}
        src={LinkedInDesktop}
        src2x={LinkedInDesktop}
        alt="Increase demand by reaching in-market buyers"
      >
        <>
          <h3>
            Target in-market buyers on Linkedin with downstream intent data
          </h3>
          <p>
            Reaching in-market buyers is getting harder as more of them research
            anonymously. TrustRadius’ downstream intent data with Linkedin
            Matched Audiences helps you increase demand by reaching in-market
            buyers who are researching your products and your competitors’ on a
            daily basis.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

LinkedInCard.propTypes = {
  product: PropTypes.object,
};
