import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { axiosRequestWithAuthToken } from 'store/apis/axios_utils';
import { PermissionsContext } from './PermissionsContext';

export function PermissionsProvider({ isAuthenticated, children }) {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const options = {
        url: `/api/user/permissions`,
        method: 'GET',
      };

      const response = await axiosRequestWithAuthToken(options);
      const data = response?.data || [];
      setPermissions(data);
    };

    if (isAuthenticated) {
      fetchPermissions();
    }
  }, [isAuthenticated]);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
}

PermissionsProvider.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
};
