import PropTypes from 'prop-types';
import { CardErrorState } from 'components/CardErrorState';
import { DashCard } from 'components/DashCard';
import { Skeleton } from 'components/Skeleton';
import { NoReviewsNotice } from './NoReviewsNotice';
import styles from './NoReviewsCard.module.scss';

export function NoReviewsCard({
  product = {},
  isLoading,
  hasError,
  refreshHandler,
}) {
  if (isLoading) return <Skeleton className={styles.loader} />;
  if (hasError) return <CardErrorState refreshHandler={refreshHandler} />;
  if (Object.keys(product).length === 0) return null;

  return (
    <DashCard
      ctaText="Learn how TrustRadius can help"
      ctaHref="https://www.trustradius.com/resources/assets/global-demo"
      product={product}
    >
      <NoReviewsNotice product={product.name} />
    </DashCard>
  );
}

NoReviewsCard.propTypes = {
  product: PropTypes.shape({
    logos: PropTypes.shape({
      default: PropTypes.string,
      squareOriginal: PropTypes.string,
      square180: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  refreshHandler: PropTypes.func,
};
