import { Button } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { useBlocker } from 'react-router-dom';
import { useEffect } from 'react';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from 'components/LeadsV2Modals/ChangesWillNotBeSavedModal/ChangesWillNotBeSavedModal.module.scss';

export function ChangesWillNotBeSavedModal() {
  const blocker = useBlocker(true);
  useEffect(() => {
    console.log(blocker);
  }, [blocker]);
  return (
    <Modal
      icon={<CircleIcon iconType="save" />}
      title="Your changes will not be saved"
      description="Navigating away from this page without saving your changes will cause them to be discarded. Do you want to leave this page and discard your changes?"
      className={styles.modal}
      isOpen={blocker.state === 'blocked'}
      closeHandler={() => {
        blocker.reset();
      }}
      actionButtons={[
        <Button
          variant="secondary"
          size="large"
          key="discard_cancel"
          onClick={() => blocker.reset()}
        >
          Continue editing
        </Button>,
        <Button
          variant="primary"
          size="large"
          key="discard_confirm"
          onClick={() => blocker.proceed()}
        >
          Leave without saving
        </Button>,
      ]}
    />
  );
}

ChangesWillNotBeSavedModal.propTypes = {
  blocker: PropTypes.shape({
    state: PropTypes.string,
    reset: PropTypes.func,
    proceed: PropTypes.func,
  }),
};
