import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductMetricsByProductId } from 'store/apis/productApi';
import { ProductMetrics } from './ProductMetrics';

export function ProductMetricsContainer() {
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );
  const metrics = useSelector((state) => state.products.metrics);
  const timeFrame = useSelector((state) => state.globalState.timeFrame);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await fetchProductMetricsByProductId(
          currentProduct._id,
          timeFrame,
        )(dispatch);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentProduct && currentProduct._id) {
      fetchData();
    }
  }, [currentProduct, timeFrame, dispatch]);

  return <ProductMetrics metrics={metrics} isLoading={isLoading} />;
}
