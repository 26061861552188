import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'components/TrustradiusUICore';
import { TRScoreSize } from './constants';
import styles from './TRScore.module.scss';

const trustradiusScoringLink =
  'https://trustradius.com/static/about-trustradius-scoring';

function buildPopover(className) {
  return (
    <Popover className={className}>
      <Popover.Content>
        Our trScore algorithm rewards review recency and corrects for selection
        bias.{' '}
        <a
          href={trustradiusScoringLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more.
        </a>
      </Popover.Content>
    </Popover>
  );
}

export function TRScore({
  insufficientData,
  ratingValue,
  ratingCount,
  showCount,
  showScoreText,
  showMaxScore,
  displayAsLink,
  size,
  showPopover,
}) {
  const worstRating = 0;
  const description =
    'Our trScore algorithm rewards review recency and corrects for selection bias. Learn more.';

  function Flag(props) {
    if (props.displayAsLink) {
      return (
        <a
          aria-label="Learn more about TrustRadius Scoring"
          href={trustradiusScoringLink}
          target="_blank"
          rel="noopener noreferrer"
          className="has-tooltip"
          data-original-title={description}
        >
          <div className={styles.flag} />
        </a>
      );
    }

    return <div className={styles.flag} />;
  }

  const renderContent = () => (
    <>
      <Flag displayAsLink={displayAsLink} />
      {showScoreText && <span className={styles.label}>Score&nbsp;</span>}

      <span className={styles.score}>
        <span itemProp="ratingValue">{parseFloat(ratingValue).toFixed(1)}</span>
        {showMaxScore && (
          <>
            &nbsp;out of&nbsp;
            <span itemProp="bestRating">10</span>
            <span itemProp="worstRating" className={styles.hide}>
              {worstRating}
            </span>
          </>
        )}
      </span>
      {showCount && (
        <span className={`rating-count ${styles['rating-count']}`}>
          {ratingCount.toLocaleString()} ratings
        </span>
      )}
    </>
  );
  if (insufficientData) {
    return (
      <div className="trust-score__text">
        We don&apos;t have enough ratings and reviews to provide an overall
        score.
      </div>
    );
  }

  if (showPopover) {
    const popoverClasses = classNames('small');
    const popover = buildPopover(popoverClasses);

    return (
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus']}
        overlay={popover}
        interactive
      >
        <button type="button" className={styles['tr-score']} data-size={size}>
          {renderContent()}
        </button>
      </OverlayTrigger>
    );
  }

  return (
    <div className={styles['tr-score']} data-size={size}>
      {renderContent()}
    </div>
  );
}

TRScore.propTypes = {
  /** Indicates when there is not enough data to display stars or rating. */
  insufficientData: PropTypes.bool,
  /** That value (0-10) of the rating (i.e., the TrustScore). */
  ratingValue: PropTypes.number,
  /** The number of ratings. Only shown if showCount is true. */
  ratingCount: PropTypes.number,
  /** Indicates whether to show the maximum score ("out of 10"). */
  showMaxScore: PropTypes.bool,
  /** Indicates whether to show the number of ratings. */
  showCount: PropTypes.bool,
  /** Indicates whether to show "Score" text to right of TR Icon. */
  showScoreText: PropTypes.bool,
  /** Select a size for the TR Score */
  size: PropTypes.oneOf(Object.values(TRScoreSize)),
  /** Option to display Flag as a link or not */
  displayAsLink: PropTypes.bool,
  /** Indicates whether to show popover */
  showPopover: PropTypes.bool,
};

TRScore.defaultProps = {
  insufficientData: false,
  ratingValue: 0,
  showCount: false,
  showMaxScore: true,
  showScoreText: true,
  size: TRScoreSize.LARGE,
  displayAsLink: true,
  showPopover: false,
};
