import moment from 'moment';
import {
  Home as DashboardIcon,
  Users2 as BuyersIcon,
  BarChart4 as MetricsIcon,
  ClipboardList as ReportsIcon,
  Quote as TrustQuotesIcon,
  Star as ReviewsIcon,
  Box as ProductListingIcon,
  Trophy as AwardsIcon,
  Settings as SettingsIcon,
  Zap as IntegrationsIcon,
} from 'lucide-react';

export const linkNames = {
  dashboard: 'Dashboard',
  reviews: 'Reviews',
  reviewsGeneration: 'Generate Reviews',
  manageReviews: 'Manage Reviews',
  reviewQuestions: 'Review Questions',
  trustQuotes: 'TrustQuotes',
  trustQuotesLibrary: 'TrustQuotes Library',
  trustQuotesForWeb: 'TrustQuotes for Web',
  reports: 'Reports',
  metrics: 'Metrics',
  reviewsAndRatings: 'Reviews & Ratings',
  competitorDashboard: 'Competitor Dashboard',
  multiProductDashboard: 'Multi-Product Dashboard',
  singleProductDashboard: 'Single Product Dashboard',
  intentData: 'Intent Data',
  referenceCandidates: 'Reference Candidates',
  followupRequests: 'Followup Requests',
  productScorecard: 'Product Scorecard',
  featureScorecard: 'Feature Scorecard',
  ratingsExplorer: 'Ratings Explorer',
  productListing: 'Product Listing',
  view: 'View',
  edit: 'Edit',
  awards: 'Awards',
  topRated: 'Top Rated',
  techCares: 'Tech Cares',
  settings: 'Settings',
  companyListing: 'Company Listing',
  reviewTags: 'Review Tags',
  users: 'Users',
  integrations: 'Integrations',
  buyers: 'Buyers',
  leadsOverview: 'Leads Overview',
  leadsForm: 'Lead Form',
  leadsActivity: 'Leads Activity',
  leadsWebhook: 'Leads Webhook',
};

const getMetricsSection = (
  roles,
  isCustomer,
  hasMultipleSubscribedProducts,
) => {
  const isAdmin = roles.includes('vp:tr_admin');

  if (!isAdmin && !isCustomer) return {};

  const reportingUserReports = [
    {
      title: linkNames.singleProductDashboard,
      template: `/vendor/:vendorId/metrics/single-product-dashboard`,
      relatedPaths: ['/vendor/:vendorId/metrics/single-product-dashboard/*'],
    },
    {
      title: linkNames.competitorDashboard,
      template: `/vendor/:vendorId/metrics/competitor-dashboard`,
      relatedPaths: ['/vendor/:vendorId/metrics/competitor-dashboard/*'],
    },
  ];

  if (hasMultipleSubscribedProducts) {
    reportingUserReports.unshift({
      title: linkNames.multiProductDashboard,
      template: `/vendor/:vendorId/metrics/multi-product-dashboard`,
      relatedPaths: ['/vendor/:vendorId/metrics/multi-product-dashboard/*'],
    });
  }

  const menuItems = reportingUserReports;

  return {
    title: linkNames.metrics,
    icon: <MetricsIcon />,
    nestedMenuItems: menuItems,
    label: moment().isBefore('2023-11-30') ? 'new' : undefined,
  };
};

const getBuyersSection = (hasBuyerLeadLicense, isNewLeadFormEnabled) => {
  const defaultItems = [
    {
      title: linkNames.leadsOverview,
      template: '/vendor/:vendorId/buyers/leads-overview',
    },
  ];

  const menuItemsUnderLicense = [
    {
      title: `${linkNames.leadsForm}`,
      template: '/vendor/:vendorId/buyers/lead-form',
    },
    {
      title: linkNames.leadsActivity,
      template: '/vendor/:vendorId/buyers/leads-activity',
    },
    {
      title: linkNames.leadsWebhook,
      template: '/vendor/:vendorId/buyers/leads-webhook',
    },
  ];

  if (isNewLeadFormEnabled) {
    menuItemsUnderLicense.unshift({
      title: `${linkNames.leadsForm} (new)`,
      template: '/vendor/:vendorId/buyers/lead-form-hub',
    });
    menuItemsUnderLicense.push({
      title: `${linkNames.leadsWebhook} (new)`,
      template: '/vendor/:vendorId/buyers/leads-webhook-new',
    });
  }

  const menuItems = hasBuyerLeadLicense
    ? [...defaultItems, ...menuItemsUnderLicense]
    : defaultItems;

  return {
    title: linkNames.buyers,
    icon: <BuyersIcon />,
    nestedMenuItems: menuItems,
    label: moment().isBefore('2023-08-31') ? 'new' : undefined,
  };
};

export function getMenuItems(
  settings,
  userRoles,
  userPermissions,
  isCustomer,
  hasBuyerLeadLicense,
  hasMultipleSubscribedProducts,
  isNewLeadFormEnabled,
) {
  return [
    {
      title: linkNames.dashboard,
      icon: <DashboardIcon />,
      template: '/vendor/:vendorId/dashboard/:productId',
    },
    {
      title: linkNames.reviews,
      icon: <ReviewsIcon />,
      nestedMenuItems: [
        {
          title: linkNames.reviewsGeneration,
          template: `/vendor/:vendorId/reviews/generate-reviews`,
        },
        {
          title: linkNames.manageReviews,
          template: `${settings?.legacyBaseUrl}/products/:productId/reviews`,
        },
        {
          title: linkNames.reviewQuestions,
          template: `${settings?.legacyBaseUrl}/products/:productId/reviews/review-questions`,
        },
      ],
    },
    getBuyersSection(hasBuyerLeadLicense, isNewLeadFormEnabled),
    getMetricsSection(userRoles, isCustomer, hasMultipleSubscribedProducts),
    {
      title: linkNames.trustQuotes,
      icon: <TrustQuotesIcon />,
      nestedMenuItems: [
        {
          title: linkNames.trustQuotesLibrary,
          template: `${settings?.legacyBaseUrl}/products/:productId/trustquoteslibrary`,
        },
        {
          title: linkNames.trustQuotesForWeb,
          template: `${settings?.legacyBaseUrl}/products/:productId/tqw`,
        },
      ],
    },
    {
      title: linkNames.reports,
      icon: <ReportsIcon />,
      nestedMenuItems: [
        {
          title: linkNames.reviewsAndRatings,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/reviews-and-ratings`,
        },
        {
          title: linkNames.intentData,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/intent-data`,
        },
        {
          title: linkNames.referenceCandidates,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/reference-candidates`,
        },
        {
          title: linkNames.followupRequests,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/followup-requests`,
        },
        {
          title: linkNames.productScorecard,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/product-scorecard`,
        },
        {
          title: linkNames.featureScorecard,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/feature-scorecard`,
        },
        {
          title: linkNames.ratingsExplorer,
          template: `${settings?.legacyBaseUrl}/products/:productId/reports/feature-explorer`,
        },
      ],
    },
    {
      title: linkNames.productListing,
      icon: <ProductListingIcon />,
      nestedMenuItems: [
        {
          title: linkNames.view,
          template: `${
            settings?.buyerSiteUrl || 'https://www.trustradius.com/'
          }/products/:productSlug/reviews`,
          target: '_blank',
        },
        {
          title: linkNames.edit,
          template: `${
            settings?.legacyBaseUrl || 'https://www.trustradius.com/vendoradmin'
          }/products/:productId/listing`,
        },
      ],
    },
    {
      title: linkNames.awards,
      icon: <AwardsIcon />,
      template: '/vendor/:vendorId/awards',
      relatedPaths: ['/vendor/:vendorId/awards/*'],
      // Award pages are not available right now but will be available in the next versions.
      // nestedMenuItems: [
      //   {
      //     title: linkNames.topRated,
      //     template: '/vendor/:vendorId/top-rated',
      //   },
      // ],
    },
    {
      title: linkNames.integrations,
      icon: <IntegrationsIcon />,
      template: '/vendor/:vendorId/integrations',
      label: moment().isBefore('2023-01-31') ? 'new' : undefined,
      relatedPaths: ['/vendor/:vendorId/integrations/*'],
    },
    {
      title: linkNames.settings,
      icon: <SettingsIcon />,
      nestedMenuItems: [
        {
          title: linkNames.companyListing,
          template: `${
            settings?.legacyBaseUrl || 'https://www.trustradius.com/vendoradmin'
          }/company/:vendorId`,
        },
        {
          title: linkNames.reviewTags,
          template: `${
            settings?.legacyBaseUrl || 'https://www.trustradius.com/vendoradmin'
          }/settings/:vendorId/tags`,
        },
        {
          title: linkNames.users,
          template: `${
            settings?.legacyBaseUrl || 'https://www.trustradius.com/vendoradmin'
          }/settings/:vendorId/users`,
        },
      ],
    },
  ];
}
