import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { X } from 'lucide-react';
import { Input, Text, Button } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { TextFieldWithCounter } from 'components/TextFieldWithCounter';
import styles from './CreateDropdown.module.scss';

export function CreateDropdown({
  nameFieldId,
  dropdownName,
  nameFieldLabel,
  nameFieldChangeHandler,
  dropdownItems = [],
  dropdownItemChangeHandler,
  className,
}) {
  const firstItemId = dropdownItems[0]?.id || `new-item-${uuidv4()}`;
  const [firstItemValue, setFirstItemValue] = useState(dropdownItems[0]?.value);
  console.log('dropdownItems', dropdownItems);
  const onInputChange = (event, id) => {
    dropdownItemChangeHandler(
      dropdownItems?.map((item) => {
        if (item.id === id) {
          return { ...item, value: event.target.value };
        }
        return item;
      }),
    );
  };
  const firstItemChangeHandler = (event) => {
    if (dropdownItems.length === 0) {
      dropdownItemChangeHandler([
        { id: firstItemId, value: event.target.value },
      ]);
    } else {
      onInputChange(event, firstItemId);
    }
    setFirstItemValue(event.target.value);
  };

  const addNewButtonHandler = () => {
    const itemsToAdd = [{ id: `new-item-${uuidv4()}`, value: '' }];
    if (dropdownItems.length === 0) {
      itemsToAdd.push({ id: `new-item-${uuidv4()}`, value: '' });
    }
    dropdownItemChangeHandler([...dropdownItems, ...itemsToAdd]);
  };

  const deleteButtonHandler = (id) => {
    const newDropdownValues = dropdownItems.filter((item) => item.id !== id);
    if (newDropdownValues.length === 1) {
      setFirstItemValue(newDropdownValues[0]?.value);
    }
    if (newDropdownValues.length === 0) {
      setFirstItemValue('');
    }
    dropdownItemChangeHandler(newDropdownValues);
  };

  const generateDropdownItem = (item, index) => {
    const itemId = item.id || uuidv4();
    return (
      <div className={styles['input-container']} key={itemId}>
        <Input
          id={itemId}
          label=""
          hideLabel
          placeholder={`Menu item ${index + 1}`}
          value={item?.value || ''}
          onChange={(event) => onInputChange(event, itemId)}
        />
        <div className={styles['delete-button']}>
          <Button
            variant="no-border"
            onClick={() => deleteButtonHandler(itemId)}
            size="large"
            data-testid="delete-button"
          >
            <X />
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className={className}>
      <TextFieldWithCounter
        fieldId={nameFieldId}
        fieldLabel={nameFieldLabel}
        isRequired
        fieldValue={dropdownName}
        fieldChangeHandler={nameFieldChangeHandler}
        maxValueLength={25}
      />
      <div className={styles['menu-items']}>
        <Text>Menu items:</Text>
      </div>
      <div className={styles['menu-items-container']}>
        <div className={styles['input-container']} key={firstItemId}>
          <Input
            id={firstItemId}
            label=""
            hideLabel
            placeholder="Menu item 1"
            value={firstItemValue}
            onChange={(event) => firstItemChangeHandler(event)}
          />
          <div className={styles['delete-button']}>
            <Button
              variant="no-border"
              data-testid="delete-button"
              onClick={() => deleteButtonHandler(firstItemId)}
              size="large"
            >
              <X />
            </Button>
          </div>
        </div>
        {dropdownItems
          ?.slice(1)
          .map((item, index) => generateDropdownItem(item, index + 1))}
        <div className={styles.add}>
          <Button
            variant="no-border"
            onClick={addNewButtonHandler}
            size="large"
          >
            + Add menu item
          </Button>
        </div>
      </div>
    </div>
  );
}

CreateDropdown.propTypes = {
  nameFieldId: PropTypes.string,
  dropdownName: PropTypes.string,
  nameFieldLabel: PropTypes.string,
  nameFieldChangeHandler: PropTypes.func,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  dropdownItemChangeHandler: PropTypes.func,
  className: PropTypes.string,
};
