import PropTypes from 'prop-types';
import styles from './TextBanner.module.scss';

export function TextBanner({ children }) {
  return <div className={styles.banner}>{children}</div>;
}

TextBanner.propTypes = {
  children: PropTypes.node.isRequired,
};
