import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentVendorPublishedProductsCount } from 'store/selectors/vendorSelectors';

export function useEligibleProductsValues() {
  const productsCount = useSelector(selectCurrentVendorPublishedProductsCount);

  const eligibleProductsValues = useMemo(
    () =>
      productsCount
        ? [
            {
              id: 'eligible-products',
              value: productsCount,
              title: 'Eligible Products',
            },
          ]
        : undefined,
    [productsCount],
  );

  return eligibleProductsValues;
}
