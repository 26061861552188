import { setUser, setUserLoading, setUserRoles } from './userActions';

export const CHANGE_CURRENT_VENDOR_PRODUCT = 'CHANGE_CURRENT_VENDOR_PRODUCT';
export const ASSIGN_CURRENT_VENDOR_PRODUCT = 'ASSIGN_CURRENT_VENDOR_PRODUCT';
export const CHANGE_CURRENT_VENDOR_PRODUCT_TO_FIRST =
  'CHANGE_CURRENT_VENDOR_PRODUCT_TO_FIRST';
export const FETCH_VENDORS_BY_ID_FAILED = 'FETCH_VENDORS_BY_ID_FAILED';
export const FETCH_VENDORS_BY_ID_SUCCESS = 'FETCH_VENDORS_BY_ID_SUCCESS';
export const FETCH_VENDORS_FOR_USER_START = 'FETCH_VENDORS_FOR_USER_START';
export const FETCH_VENDORS_FOR_USER_FAILED = 'FETCH_VENDORS_FOR_USER_FAILED';
export const FETCH_VENDORS_FOR_USER_SUCCESS = 'FETCH_VENDORS_FOR_USER_SUCCESS';
export const CHANGE_CURRENT_VENDOR = 'CHANGE_CURRENT_VENDOR';
export const FETCH_VENDOR_PRODUCTS_START = 'FETCH_VENDOR_PRODUCTS_START';
export const FETCH_VENDOR_PRODUCTS_SUCCESS = 'FETCH_VENDOR_PRODUCTS_SUCCESS';
export const FETCH_VENDOR_PRODUCTS_FAILED = 'FETCH_VENDOR_PRODUCTS_FAILED';
export const REMOVE_VENDOR_PRODUCTS = 'REMOVE_VENDOR_PRODUCTS';
export const REMOVE_CURRENT_VENDOR = 'REMOVE_CURRENT_VENDOR';
export const REMOVE_VENDORS_BY_ID = 'REMOVE_VENDORS_BY_ID';

export const fetchVendorProductsSuccess = (products) => ({
  type: FETCH_VENDOR_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

export const fetchVendorProductsFailed = (error) => ({
  type: FETCH_VENDOR_PRODUCTS_FAILED,
  payload: {
    error,
  },
});

export const fetchVendorsByIdFailed = (error) => ({
  type: FETCH_VENDORS_BY_ID_FAILED,
  payload: {
    ...error,
  },
});

export const fetchVendorsByIdSuccess = (vendors, count) => ({
  type: FETCH_VENDORS_BY_ID_SUCCESS,
  payload: {
    vendors,
    totalCount: count,
  },
});

export const removeVendorsById = () => ({
  type: REMOVE_VENDORS_BY_ID,
});

export const fetchVendorsForUserStart = () => ({
  type: FETCH_VENDORS_FOR_USER_START,
});

export const fetchVendorsForUserFailed = (error) => ({
  type: FETCH_VENDORS_FOR_USER_FAILED,
  payload: {
    ...error,
  },
});

export const fetchVendorsForUserSuccess = (vendors) => ({
  type: FETCH_VENDORS_FOR_USER_SUCCESS,
  payload: {
    vendors,
  },
});

export const changeCurrentVendor = (vendor) => ({
  type: CHANGE_CURRENT_VENDOR,
  payload: {
    vendor,
  },
});

export const removeCurrentVendor = () => ({
  type: REMOVE_CURRENT_VENDOR,
});

export const changeCurrentVendorProduct = (productId) => ({
  type: CHANGE_CURRENT_VENDOR_PRODUCT,
  payload: {
    productId,
  },
});

export const assignCurrentVendorProduct = (product) => ({
  type: ASSIGN_CURRENT_VENDOR_PRODUCT,
  payload: {
    product,
  },
});

export const changeCurrentVendorProductToFirst = () => ({
  type: CHANGE_CURRENT_VENDOR_PRODUCT_TO_FIRST,
});

export const removeVendorPoducts = () => ({
  type: REMOVE_VENDOR_PRODUCTS,
});

export const updateUserStateFromGrants = () => (dispatch, records) => {
  dispatch(fetchVendorsByIdSuccess(records.map((record) => record.vendor)));
  dispatch(setUser(records[0].user));
  dispatch(setUserRoles(records));
  dispatch(setUserLoading(false));
};
