import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import {
  GrowthKitDesktop,
  GrowthKitDesktop2x,
  GrowthKitMobile,
  GrowthKitMobile2x,
} from './assets';

export function ReviewGrowthKitCard({ product }) {
  return (
    <DashCard
      ctaText="Learn more"
      ctaHref="https://go.trustradius.com/rs/827-FOI-687/images/TrustRadius_Review-Growth-Kit.pdf"
      product={product}
    >
      <Announcement
        mSrc={GrowthKitMobile}
        mSrc2x={GrowthKitMobile2x}
        src={GrowthKitDesktop}
        src2x={GrowthKitDesktop2x}
        alt="Garner more quality reviews and use those customer narratives across all of your marketing efforts."
      >
        <>
          <h3>Get Results With Our Review Growth Kit</h3>
          <p>
            Did you know that you can increase your TrustRadius product profile
            visibility by 50% with just five quality reviews? Use our
            step-by-step guide to begin capturing and promoting the authentic
            voice of your customers.
          </p>
        </>
      </Announcement>
    </DashCard>
  );
}

ReviewGrowthKitCard.propTypes = {
  product: PropTypes.object,
};
