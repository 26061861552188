import { PropTypes } from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import styles from './TwoColumnTemplate.module.scss';

export function TwoColumnTemplate({
  leftColumn,
  rightColumnWidth,
  rightColumn,
}) {
  const isDesktop = useMedia('(min-width:1200px)');
  const style = isDesktop
    ? { gridTemplateColumns: `auto ${rightColumnWidth || '500px'}` }
    : {};
  return (
    <div
      className={classNames({ [styles.container]: isDesktop })}
      style={style}
    >
      <div className={styles.leftColumn}>{leftColumn}</div>
      <div>{rightColumn}</div>
    </div>
  );
}

TwoColumnTemplate.propTypes = {
  leftColumn: PropTypes.node,
  rightColumnWidth: PropTypes.string,
  rightColumn: PropTypes.node,
};
