import { forwardRef } from 'react';
import ReactBootstrapTooltip from 'react-bootstrap/Tooltip';
import classNames from 'classnames';

import './Tooltip.scss';

/**
 * TrustRadius wrapped Tooltip component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Tooltip = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapTooltip
      ref={ref}
      className={classes}
      bsPrefix="tr-tooltip"
      {...otherProps}
    >
      {children}
    </ReactBootstrapTooltip>
  );
});

Tooltip.displayName = 'Tooltip';
// eslint-disable-next-line react/forbid-foreign-prop-types
Tooltip.propTypes = { ...ReactBootstrapTooltip.propTypes };
