import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { Body } from 'components/DashCard';
import styles from './BannerQuote.module.scss';

export function BannerQuote({ children, quote, avatar, name, position }) {
  const isMobile = useMedia('(max-width:488px)');
  return (
    <div className={styles.container}>
      <Body className={styles.body}>
        <>{children}</>
      </Body>
      <div className={classNames(styles.banner, { [styles.mobile]: isMobile })}>
        {avatar && <img className={styles.avatar} src={avatar} alt="" />}
        <blockquote className={styles.quote}>{quote}</blockquote>
        <div className={styles.cite}>
          – {name} {position}
        </div>
      </div>
    </div>
  );
}

BannerQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  children: PropTypes.node,
};
