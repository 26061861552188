import {
  FETCH_PRODUCT_METRICS_FAILED,
  FETCH_PRODUCT_METRICS_SUCCESS,
} from '../actions/productActions';
import { normalizeState } from '../utils';

const initialState = {
  team: [],
  metrics: { byId: {}, allIds: [] },
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_METRICS_SUCCESS:
      return {
        ...state,
        metrics: normalizeState(action.payload.metrics, 'slug'),
      };
    case FETCH_PRODUCT_METRICS_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default products;
