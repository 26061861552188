import PropTypes from 'prop-types';

function CircleCheck({ title, id, size, color, ...props }) {
  return (
    <svg
      width={size || 20}
      height={size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title id={id}>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0003 9.33366C18.0003 4.82226 14.3451 1.16699 9.83366 1.16699C5.32226 1.16699 1.66699 4.82226 1.66699 9.33366C1.66699 13.8451 5.32226 17.5003 9.83366 17.5003C14.3451 17.5003 18.0003 13.8451 18.0003 9.33366Z"
        stroke={color || '#03DBB4'}
        strokeWidth="2.33333"
      />
      <path
        d="M13.333 7L8.52051 12L6.33301 9.72727"
        stroke={color || '#03DBB4'}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CircleCheck.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CircleCheck;
