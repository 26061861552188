import moment from 'moment';
import { Text, Button } from '@trustradius/radiant-ui';
import { MoreHorizontal } from 'lucide-react';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { DropdownMenu } from 'components/DropdownMenu';
import { Modal } from 'components/Modal';
import {
  DeleteLeadFormModal,
  RenameLeadFormModal,
} from 'components/LeadsV2Modals';
import styles from './LeadCard.module.scss';

export function LeadCard({
  id,
  title,
  appliedProducts = [],
  appliedWebhooks = [],
  updated,
}) {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowRenameModal, setIsShowRenameModal] = useState(false);
  const updatedDate = updated
    ? moment(updated).format('MMM DD, YYYY hh:mm A')
    : '';
  const appliedProductsText = `${appliedProducts?.length} product${
    appliedProducts?.length === 1 ? '' : 's'
  }`;
  const appliedWebhooksText = `${appliedWebhooks?.length} webhook${
    appliedWebhooks?.length === 1 ? '' : 's'
  }`;

  const dropDownItems = [
    {
      id: 'edit',
      node: (
        <div className={styles.action}>
          <Button variant="none" onClick={() => console.log(id)}>
            {' '}
            Edit form fields
          </Button>
        </div>
      ),
    },
    {
      id: 'rename',
      node: (
        <div className={styles.action}>
          <Button variant="none" onClick={() => setIsShowRenameModal(true)}>
            Rename
          </Button>
        </div>
      ),
    },
    {
      id: 'delete',
      node: (
        <div className={styles.action}>
          <Button variant="none" onClick={() => setIsShowDeleteModal(true)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles['content-container']}>
        <Text as="h2" size="400" weight="semibold" className={styles.gray120}>
          {title}
        </Text>
        <Text className={styles.gray100}>Applied to</Text>
        <Text className={styles.gray100}>Applied to</Text>
        {updatedDate && (
          <Text className={styles.gray100}>
            Last updated: <span className={styles.gray120}>{updatedDate}</span>
          </Text>
        )}
        <Modal
          className={styles.applied}
          title={
            <Text size="300" as="span" weight="semibold">
              {`Products displaying “${title} form”`}
            </Text>
          }
          titleProps={{
            as: 'h2',
            size: 300,
            weight: 'semibold',
            className: styles['dialog-title'],
          }}
          trigger={
            <div className={styles['applied-buttons']}>
              <Button variant="none">{appliedProductsText}</Button>
            </div>
          }
        >
          <>
            <div className={styles['item-list']}>
              {appliedProducts.map((product) => (
                <div className={styles.product} key={product?._id}>
                  <img
                    src={product?.logos?.square180}
                    alt={`${product.name} logo`}
                  />
                  <Text as="p" size="100">
                    {product.name}
                  </Text>
                </div>
              ))}
            </div>
          </>
        </Modal>
        <Modal
          title={
            <Text as="span" size="300" weight="semibold">
              Applied webhooks
            </Text>
          }
          trigger={
            <div className={styles['applied-buttons']}>
              <Button variant="none">{appliedWebhooksText}</Button>
            </div>
          }
        >
          <>
            <div className={styles['item-list']}>
              {appliedWebhooks.map((webhook) => (
                <div className={styles.webhook} key={webhook?.id}>
                  <Button variant="none" url="/">
                    <Text size="100">{webhook.name}</Text>
                  </Button>
                </div>
              ))}
            </div>
            <div className={styles['webhook-hub-link']}>
              <Button variant="none" url="/">
                <Text size="200" weight="semibold">
                  Go to Webhook Hub
                </Text>
              </Button>
            </div>
          </>
        </Modal>
      </div>
      <div className={styles.actions}>
        <DropdownMenu
          dropdownTrigger={
            <div className={styles.IconButton}>
              <MoreHorizontal />
            </div>
          }
          items={dropDownItems}
        />
      </div>
      <RenameLeadFormModal
        name={title}
        isOpen={isShowRenameModal}
        closeHandler={() => setIsShowRenameModal(false)}
      />
      <DeleteLeadFormModal
        isShowDeleteModal={isShowDeleteModal}
        setIsShowDeleteModal={setIsShowDeleteModal}
      />
    </div>
  );
}

LeadCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  appliedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      logos: PropTypes.shape({
        square180: PropTypes.string,
      }),
    }),
  ),
  appliedWebhooks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  updated: PropTypes.string,
};
