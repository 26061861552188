import axios from 'axios';
import Cookies from 'universal-cookie';
import { getAuthenticatedUser } from './usersApi';

export async function authenticateWithLegacy(
  isAuthenticated,
  authToken,
  settings,
) {
  const data = {
    authToken,
  };

  if (isAuthenticated) {
    const user = await getAuthenticatedUser();
    if (!user) {
      return false;
    }

    data.user = user;
    const resp = await axios({
      method: 'post',
      url: `${settings.legacyBaseUrl}/auth/${user}`,
      withCredentials: true,
      data,
    });
    if (resp.status === 200) {
      const cookies = new Cookies();
      cookies.set('_vp_user', user, {
        domain: settings.cookieDomain,
        path: '/',
      });
    }
  }
  return false;
}
