/* eslint-disable @trustradius/jsx-no-complex-expressions */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Info, Trash2 } from 'lucide-react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  Input,
  Button,
  Text,
  RadioGroup,
  RadioItem,
  Tooltip,
} from '@trustradius/radiant-ui';
import { LeadsWebhookFooter, Field } from 'components/LeadsWebhookTabs';
import { mapLeadFormFields } from './mapLeadFormFieldsOtherSystem';
import styles from './Tab.module.scss';

export function TabOtherSystem({
  webhookData,
  createWebhookHandler,
  updateWebhookHandler,
  deleteWebhookHandler,
  sendTestLeadHandler,
  setTestLeadResult,
}) {
  const headers = [];
  if (webhookData?.headers) {
    // Copy any existing custom headers to array field
    for (const [key, value] of Object.entries(webhookData.headers)) {
      if (headers.length < 10) {
        headers.push({ key, value });
      } else {
        console.log(
          'Client does not support webhooks with more than 10 custom headers.',
        );
      }
    }
  }
  // Add default empty custom header for user to continue with
  if (headers.length < 10) {
    headers.push({ key: '', value: '' });
  }

  const defaultValues = {
    endpoint: webhookData?.endpoint || '',
    SaleForceOID: webhookData?.SaleForceOID || '',
    company: webhookData?.mapping?.company || '',
    country: webhookData?.mapping?.country || '',
    email: webhookData?.mapping?.email || '',
    firstName: webhookData?.mapping?.firstName || '',
    interestedIn: webhookData?.mapping?.interestedIn || '',
    lastName: webhookData?.mapping?.lastName || '',
    message: webhookData?.mapping?.message || '',
    productName: webhookData?.mapping?.productName || '',
    dataType: webhookData?.dataType || 'formdata',
    leadSource: webhookData?.mapping?.leadSource || '',
    headers,
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  });

  const vendorId = useSelector((state) => state?.vendors?.currentVendor?._id);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isTestLeadModalOpen, setIsTestLeadModalOpen] = useState(false);

  const deleteWebhook = async (id) => {
    await deleteWebhookHandler(id);
    const values = {
      endpoint: '',
      SaleForceOID: '',
      company: '',
      country: '',
      email: '',
      firstName: '',
      interestedIn: '',
      lastName: '',
      message: '',
      productName: '',
      dataType: '',
      leadSource: '',
      headers: [],
    };
    reset(values);
  };

  const formToRequestParams = (formData, id) => {
    let requestHeaders = {};
    requestHeaders = formData?.headers
      // removes empty data
      ?.filter((header) => header.key && header.value)
      .reduce((acc, cur) => {
        // converts to json key/value
        acc[cur.key] = cur.value;
        return acc;
      }, {});
    if (Object.keys(requestHeaders).length === 0) {
      requestHeaders = null;
    }
    const res = {
      vendorId,
      type: 'other',
      dataType: formData.dataType || 'json',
      endpoint: formData.endpoint,
      SaleForceOID: null,
      headers: requestHeaders,
      mapping: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        company: formData.company,
        country: formData.country,
        message: formData.message,
        dataType: formData.dataType,
        interestedIn: formData.interestedIn,
        productName: formData.productName,
        leadSource: formData.leadSource,
      },
    };

    if (id) {
      res.id = id;
    }
    return res;
  };

  const onSubmit = async (data) => {
    let result;
    if (webhookData?.id) {
      result = await updateWebhookHandler(
        formToRequestParams(data, webhookData?.id),
      );
    } else {
      result = await createWebhookHandler(formToRequestParams(data));
    }

    if (result?.errors) {
      return;
    }
    setIsSaveModalOpen(true);
  };

  const discardChanges = () => {
    reset(defaultValues);
  };

  const sendTestLead = async () => {
    setIsSaveModalOpen(false);
    setIsTestLeadModalOpen(true);
    const result = await sendTestLeadHandler(webhookData?.id);
    setIsTestLeadModalOpen(false);
    setTestLeadResult(result);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <section>
          <h2 className={styles.heading}>Define endpoint</h2>

          <Field
            heading="Endpoint URL"
            summary={
              <>
                To set up a webhook, enter the endpoint URL that your Leads data
                should be sent to.{' '}
                <a
                  href="https://trustradius.freshdesk.com/en/support/solutions/articles/43000694908-set-a-webhook-to-send-trustradius-leads-to-a-third-party-system"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
                .
              </>
            }
            handleRemove={() => {
              deleteWebhook(webhookData?.id);
            }}
            input={{
              label: 'label',
              id: 'endpoint',
              required: 'required',
              ...register('endpoint', {
                required: {
                  value: true,
                  message: 'Endpoint URL is a required field',
                },
              }),
              'aria-invalid': errors.endpoint ? 'true' : 'false',
              isInvalid: errors.endpoint,
            }}
            errorMessage={errors.endpoint?.message}
          />

          <section className={styles['field-section']}>
            <h3 className={styles.subheading}>Headers</h3>
            <p>
              Add custom headers to the payload that’s delivered to your
              endpoint. Custom headers can be used to authenticate webhook
              requests.
            </p>

            {fields.map((item, index) => (
              <div className={styles['headers-fields']} key={item.id}>
                <Input label="key" {...register(`headers.${index}.key`)} />
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        label="value"
                        {...field}
                        onBlur={(evt) => {
                          if (
                            fields.length < 10 &&
                            field.name ===
                              `headers.${fields.length - 1}.value` &&
                            evt.target.value
                          ) {
                            append({ key: '', value: '' });
                          }
                        }}
                      />
                    );
                  }}
                  name={`headers.${index}.value`}
                  control={control}
                />
                {index > 0 && (
                  <Button
                    className={styles['delete-header']}
                    size="large"
                    variant="icon-tertiary"
                    accessibilityLabel="Delete header"
                    iconOnly
                    onClick={() => remove(index)}
                  >
                    <Trash2 width="20px" />
                  </Button>
                )}
              </div>
            ))}
          </section>

          <section className={styles['field-section']}>
            <h3 className={styles.subheading}>Format</h3>
            <p className={styles.small}>
              Select the format that should be used when sending your leads
              data.
            </p>
            <fieldset className={styles.fieldset}>
              <legend>Format</legend>
              <Controller
                name="dataType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <RadioItem
                      id="format-form-data"
                      label="Form data"
                      value="formdata"
                    />
                    <RadioItem id="format-json" label="JSON" value="json" />
                  </RadioGroup>
                )}
              />
            </fieldset>
          </section>

          <section className={styles['map-fields']}>
            <h2 className={styles.heading}>Map lead form fields</h2>
            <p>
              Use this table to map your Lead Form fields to their corresponding
              fields in your destination system.
            </p>
            <table className={styles['form-table']}>
              <caption>Table of lead form fields</caption>
              <thead>
                <tr>
                  <th scope="col">
                    <Tooltip
                      align="start"
                      className={styles.tooltip}
                      content={
                        <Text size="100">
                          The field in the TrustRadius lead form.
                        </Text>
                      }
                      triggerComponent={
                        <button type="button" className={styles.tooltip}>
                          <span>Lead form field</span>
                          <Info size="18" aria-hidden />
                        </button>
                      }
                    />
                  </th>
                  <th scope="col">
                    <Tooltip
                      triggerComponent={
                        <button type="button" className={styles.tooltip}>
                          <span>Destination Field</span>
                          <Info size="18" aria-hidden />
                        </button>
                      }
                      content={
                        <Text size="100">
                          This is the corresponding field that the TrustRadius
                          field should be mapped to in your third-party system.
                        </Text>
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(mapLeadFormFields).map((key) => {
                  const field = mapLeadFormFields[key];
                  const fieldId = field.id;
                  const fieldError = errors[fieldId];

                  return (
                    <tr key={fieldId}>
                      <th scope="row">
                        <label htmlFor={`map-lead-form-${fieldId}`}>
                          {field.label}
                        </label>
                      </th>
                      <td colspan="2" className={styles['input-column']}>
                        <Input
                          id={`map-lead-form-${fieldId}`}
                          name={fieldId}
                          label={fieldId}
                          type="text"
                          autoComplete="off"
                          required
                          hideLabel
                          aria-label={field.label}
                          {...register(fieldId, {
                            required: {
                              value: Boolean(mapLeadFormFields[key].required),
                              message: `${field.label} is a required field`,
                            },
                            maxLength: {
                              value: mapLeadFormFields[key].maxLength,
                              message: `Maximum length of the field should be ${mapLeadFormFields[key].maxLength} symbols`,
                            },
                          })}
                          aria-invalid={fieldError ? 'true' : 'false'}
                          isInvalid={fieldError}
                        />
                        {fieldError && (
                          <Text size="100" className={styles.error}>
                            {fieldError.message}
                          </Text>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </section>
        <LeadsWebhookFooter
          isSaveModalOpen={isSaveModalOpen}
          setIsSaveModalOpen={setIsSaveModalOpen}
          discardChanges={discardChanges}
          sendTestLead={sendTestLead}
          isTestLeadModalOpen={isTestLeadModalOpen}
          setIsTestLeadModalOpen={setIsTestLeadModalOpen}
          webhookId={webhookData.id}
        />
      </form>
    </>
  );
}

TabOtherSystem.propTypes = {
  webhookData: PropTypes.object,
  createWebhookHandler: PropTypes.func.isRequired,
  updateWebhookHandler: PropTypes.func.isRequired,
  deleteWebhookHandler: PropTypes.func.isRequired,
  sendTestLeadHandler: PropTypes.func.isRequired,
  setTestLeadResult: PropTypes.func.isRequired,
};
