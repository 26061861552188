import * as DropdownMenuRadixUi from '@radix-ui/react-dropdown-menu';
import { PropTypes } from 'prop-types';
import styles from './DropdownMenu.module.scss';

export function DropdownMenu({ dropdownTrigger, items }) {
  return (
    <>
      {dropdownTrigger && items && (
        <DropdownMenuRadixUi.Root>
          <DropdownMenuRadixUi.Trigger asChild>
            <button
              type="button"
              className={styles.trigger}
              aria-label="Customise options"
              data-testid="dropdown-menu-trigger"
            >
              {dropdownTrigger}
            </button>
          </DropdownMenuRadixUi.Trigger>

          <DropdownMenuRadixUi.Portal>
            <DropdownMenuRadixUi.Content
              className={styles.DropdownMenuContent}
              align="start"
            >
              {items?.map((item) => (
                <DropdownMenuRadixUi.Item
                  className={styles.DropdownMenuItem}
                  key={item?.id}
                >
                  {item?.node}
                </DropdownMenuRadixUi.Item>
              ))}
            </DropdownMenuRadixUi.Content>
          </DropdownMenuRadixUi.Portal>
        </DropdownMenuRadixUi.Root>
      )}
    </>
  );
}

DropdownMenu.propTypes = {
  dropdownTrigger: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};
