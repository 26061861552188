import { Button, Input } from '@trustradius/radiant-ui';
import { Plus } from 'lucide-react';
import classNames from 'classnames';
import { useState } from 'react';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from './CreateLeadModal.module.scss';

export function CreateLeadModal() {
  const [value, setValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const maxLength = 60;
  const changeHandler = (event) => {
    setValue(event.target.value);
    if (event.target.value.length > maxLength) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };
  return (
    <Modal
      title="Get started collecting leads"
      description="Choose a name to help you easily identify and manage your lead form. This name is for internal use only and will not be displayed on your product listing."
      trigger={
        <Button size="large">
          <Plus />
          Create new lead form
        </Button>
      }
      actionButtons={[
        <Button variant="secondary" key="cancel" size="large">
          Cancel
        </Button>,
        <Button isDisabled={hasError} key="create" size="large">
          Create new form
        </Button>,
      ]}
      closeHandler={() => {
        console.log('close');
      }}
      icon={<CircleIcon />}
      className={styles.content}
    >
      <div className={styles['input-container']}>
        <Input
          id="lead-form-name"
          required
          placeholder="Name your lead form"
          value={value}
          hasError={hasError}
          onChange={(event) => {
            changeHandler(event);
          }}
          onPaste={(event) => {
            changeHandler(event);
          }}
          autoComplete="off"
          className={classNames(styles.input, { [styles.error]: hasError })}
        />
        <div
          className={classNames(styles['name-length'], {
            [styles.error]: hasError,
          })}
        >
          {value?.length} / {maxLength}
        </div>
      </div>
    </Modal>
  );
}
