export const leadFormConfig = {
  id: 1,
  name: 'Test form',
  fields: [
    {
      id: 97,
      label: 'Branding',
      slug: 'branding',
      type: 'RADIO',
      required: true,
      enabled: true,
      value: 'product',
      config: {
        configurable: false,
      },
    },
    {
      id: 98,
      label: 'First name',
      slug: 'firstName',
      type: 'INPUT',
      required: true,
      enabled: true,
      config: {
        configurable: false,
      },
    },
    {
      id: 99,
      label: 'Last name',
      slug: 'lastName',
      type: 'INPUT',
      required: true,
      enabled: true,
      config: {
        configurable: false,
      },
    },
    {
      id: 100,
      label: 'Work Email',
      slug: 'email',
      type: 'INPUT',
      required: true,
      enabled: true,
      config: {
        configurable: false,
      },
    },
    {
      id: 101,
      label: 'Company',
      slug: 'company',
      type: 'INPUT',
      required: true,
      enabled: true,
      config: {
        configurable: false,
      },
    },
    {
      id: 102,
      label: 'Position',
      slug: 'position',
      type: 'INPUT',
      required: true,
      enabled: false,
      config: {
        icon: 'position',
        configurable: true,
      },
    },
    {
      id: 103,
      label: 'Country',
      slug: 'country',
      type: 'SELECT',
      required: true,
      enabled: true,
      config: {
        icon: 'country',
        configurable: true,
        options: [
          {
            value: 'USA',
            label: 'United States',
          },
          {
            value: 'AFG',
            label: 'Afghanistan',
          },
          {
            value: 'ALA',
            label: 'Aland Islands',
          },
          {
            value: 'ALB',
            label: 'Albania',
          },
          {
            value: 'DZA',
            label: 'Algeria',
          },
          {
            value: 'ASM',
            label: 'American Samoa',
          },
          {
            value: 'AND',
            label: 'Andorra',
          },
          {
            value: 'AGO',
            label: 'Angola',
          },
          {
            value: 'AIA',
            label: 'Anguilla',
          },
          {
            value: 'ATA',
            label: 'Antarctica',
          },
          {
            value: 'ATG',
            label: 'Antigua and Barbuda',
          },
          {
            value: 'ARG',
            label: 'Argentina',
          },
          {
            value: 'ARM',
            label: 'Armenia',
          },
          {
            value: 'ABW',
            label: 'Aruba',
          },
          {
            value: 'AUS',
            label: 'Australia',
          },
          {
            value: 'AUT',
            label: 'Austria',
          },
          {
            value: 'AZE',
            label: 'Azerbaijan',
          },
          {
            value: 'BHS',
            label: 'Bahamas',
          },
          {
            value: 'BHR',
            label: 'Bahrain',
          },
          {
            value: 'BGD',
            label: 'Bangladesh',
          },
          {
            value: 'BRB',
            label: 'Barbados',
          },
          {
            value: 'BLR',
            label: 'Belarus',
          },
          {
            value: 'BEL',
            label: 'Belgium',
          },
          {
            value: 'BLZ',
            label: 'Belize',
          },
          {
            value: 'BEN',
            label: 'Benin',
          },
          {
            value: 'BMU',
            label: 'Bermuda',
          },
          {
            value: 'BTN',
            label: 'Bhutan',
          },
          {
            value: 'BOL',
            label: 'Bolivia',
          },
          {
            value: 'BIH',
            label: 'Bosnia and Herzegovina',
          },
          {
            value: 'BWA',
            label: 'Botswana',
          },
          {
            value: 'BRA',
            label: 'Brazil',
          },
          {
            value: 'IOT',
            label: 'British Indian Ocean Territory',
          },
          {
            value: 'BRN',
            label: 'Brunei Darussalam',
          },
          {
            value: 'BGR',
            label: 'Bulgaria',
          },
          {
            value: 'BFA',
            label: 'Burkina Faso',
          },
          {
            value: 'BDI',
            label: 'Burundi',
          },
          {
            value: 'KHM',
            label: 'Cambodia',
          },
          {
            value: 'CMR',
            label: 'Cameroon',
          },
          {
            value: 'CAN',
            label: 'Canada',
          },
          {
            value: 'CPV',
            label: 'Cape Verde',
          },
          {
            value: 'CYM',
            label: 'Cayman Islands',
          },
          {
            value: 'CAF',
            label: 'Central African Republic',
          },
          {
            value: 'TCD',
            label: 'Chad',
          },
          {
            value: 'CHL',
            label: 'Chile',
          },
          {
            value: 'CHN',
            label: 'China',
          },
          {
            value: 'CXR',
            label: 'Christmas Island',
          },
          {
            value: 'CCK',
            label: 'Cocos (Keeling) Islands',
          },
          {
            value: 'COL',
            label: 'Colombia',
          },
          {
            value: 'COM',
            label: 'Comoros',
          },
          {
            value: 'COG',
            label: 'Congo',
          },
          {
            value: 'COK',
            label: 'Cook Islands',
          },
          {
            value: 'CRI',
            label: 'Costa Rica',
          },
          {
            value: 'CIV',
            label: "Cote D'Ivoire (Ivory Coast)",
          },
          {
            value: 'HRV',
            label: 'Croatia',
          },
          {
            value: 'CUB',
            label: 'Cuba',
          },
          {
            value: 'CYP',
            label: 'Cyprus',
          },
          {
            value: 'CZE',
            label: 'Czech Republic',
          },
          {
            value: 'COD',
            label: 'Democratic Republic of the Congo',
          },
          {
            value: 'DNK',
            label: 'Denmark',
          },
          {
            value: 'DJI',
            label: 'Djibouti',
          },
          {
            value: 'DMA',
            label: 'Dominica',
          },
          {
            value: 'DOM',
            label: 'Dominican Republic',
          },
          {
            value: 'ECU',
            label: 'Ecuador',
          },
          {
            value: 'EGY',
            label: 'Egypt',
          },
          {
            value: 'SLV',
            label: 'El Salvador',
          },
          {
            value: 'GNQ',
            label: 'Equatorial Guinea',
          },
          {
            value: 'ERI',
            label: 'Eritrea',
          },
          {
            value: 'EST',
            label: 'Estonia',
          },
          {
            value: 'ETH',
            label: 'Ethiopia',
          },
          {
            value: 'FLK',
            label: 'Falkland Islands (Malvinas)',
          },
          {
            value: 'FRO',
            label: 'Faroe Islands',
          },
          {
            value: 'FSM',
            label: 'Federated States of Micronesia',
          },
          {
            value: 'FJI',
            label: 'Fiji',
          },
          {
            value: 'FIN',
            label: 'Finland',
          },
          {
            value: 'FRA',
            label: 'France',
          },
          {
            value: 'GUF',
            label: 'French Guiana',
          },
          {
            value: 'PYF',
            label: 'French Polynesia',
          },
          {
            value: 'ATF',
            label: 'French Southern Territories',
          },
          {
            value: 'GAB',
            label: 'Gabon',
          },
          {
            value: 'GMB',
            label: 'Gambia',
          },
          {
            value: 'GEO',
            label: 'Georgia',
          },
          {
            value: 'DEU',
            label: 'Germany',
          },
          {
            value: 'GHA',
            label: 'Ghana',
          },
          {
            value: 'GIB',
            label: 'Gibraltar',
          },
          {
            value: 'GRC',
            label: 'Greece',
          },
          {
            value: 'GRL',
            label: 'Greenland',
          },
          {
            value: 'GRD',
            label: 'Grenada',
          },
          {
            value: 'GLP',
            label: 'Guadeloupe',
          },
          {
            value: 'GUM',
            label: 'Guam',
          },
          {
            value: 'GTM',
            label: 'Guatemala',
          },
          {
            value: 'GGY',
            label: 'Guernsey',
          },
          {
            value: 'GIN',
            label: 'Guinea',
          },
          {
            value: 'GNB',
            label: 'Guinea-Bissau',
          },
          {
            value: 'GUY',
            label: 'Guyana',
          },
          {
            value: 'HTI',
            label: 'Haiti',
          },
          {
            value: 'HND',
            label: 'Honduras',
          },
          {
            value: 'HKG',
            label: 'Hong Kong',
          },
          {
            value: 'HUN',
            label: 'Hungary',
          },
          {
            value: 'ISL',
            label: 'Iceland',
          },
          {
            value: 'IND',
            label: 'India',
          },
          {
            value: 'IDN',
            label: 'Indonesia',
          },
          {
            value: 'IRN',
            label: 'Iran',
          },
          {
            value: 'IRQ',
            label: 'Iraq',
          },
          {
            value: 'IRL',
            label: 'Ireland',
          },
          {
            value: 'IMN',
            label: 'Isle of Man',
          },
          {
            value: 'ISR',
            label: 'Israel',
          },
          {
            value: 'ITA',
            label: 'Italy',
          },
          {
            value: 'JAM',
            label: 'Jamaica',
          },
          {
            value: 'JPN',
            label: 'Japan',
          },
          {
            value: 'JEY',
            label: 'Jersey',
          },
          {
            value: 'JOR',
            label: 'Jordan',
          },
          {
            value: 'KAZ',
            label: 'Kazakhstan',
          },
          {
            value: 'KEN',
            label: 'Kenya',
          },
          {
            value: 'KIR',
            label: 'Kiribati',
          },
          {
            value: 'KOR',
            label: 'Korea',
          },
          {
            value: 'PRK',
            label: 'Korea (North)',
          },
          {
            value: 'KWT',
            label: 'Kuwait',
          },
          {
            value: 'KGZ',
            label: 'Kyrgyzstan',
          },
          {
            value: 'LAO',
            label: 'Laos',
          },
          {
            value: 'LVA',
            label: 'Latvia',
          },
          {
            value: 'LBN',
            label: 'Lebanon',
          },
          {
            value: 'LSO',
            label: 'Lesotho',
          },
          {
            value: 'LBR',
            label: 'Liberia',
          },
          {
            value: 'LBY',
            label: 'Libya',
          },
          {
            value: 'LIE',
            label: 'Liechtenstein',
          },
          {
            value: 'LTU',
            label: 'Lithuania',
          },
          {
            value: 'LUX',
            label: 'Luxembourg',
          },
          {
            value: 'MAC',
            label: 'Macao',
          },
          {
            value: 'MKD',
            label: 'Macedonia',
          },
          {
            value: 'MDG',
            label: 'Madagascar',
          },
          {
            value: 'MWI',
            label: 'Malawi',
          },
          {
            value: 'MYS',
            label: 'Malaysia',
          },
          {
            value: 'MDV',
            label: 'Maldives',
          },
          {
            value: 'MLI',
            label: 'Mali',
          },
          {
            value: 'MLT',
            label: 'Malta',
          },
          {
            value: 'MHL',
            label: 'Marshall Islands',
          },
          {
            value: 'MTQ',
            label: 'Martinique',
          },
          {
            value: 'MRT',
            label: 'Mauritania',
          },
          {
            value: 'MUS',
            label: 'Mauritius',
          },
          {
            value: 'MYT',
            label: 'Mayotte',
          },
          {
            value: 'MEX',
            label: 'Mexico',
          },
          {
            value: 'MDA',
            label: 'Moldova',
          },
          {
            value: 'MCO',
            label: 'Monaco',
          },
          {
            value: 'MNG',
            label: 'Mongolia',
          },
          {
            value: 'MNE',
            label: 'Montenegro',
          },
          {
            value: 'MSR',
            label: 'Montserrat',
          },
          {
            value: 'MAR',
            label: 'Morocco',
          },
          {
            value: 'MOZ',
            label: 'Mozambique',
          },
          {
            value: 'MMR',
            label: 'Myanmar',
          },
          {
            value: 'NAM',
            label: 'Namibia',
          },
          {
            value: 'NRU',
            label: 'Nauru',
          },
          {
            value: 'NPL',
            label: 'Nepal',
          },
          {
            value: 'NLD',
            label: 'Netherlands',
          },
          {
            value: 'NCL',
            label: 'New Caledonia',
          },
          {
            value: 'NZL',
            label: 'New Zealand',
          },
          {
            value: 'NIC',
            label: 'Nicaragua',
          },
          {
            value: 'NER',
            label: 'Niger',
          },
          {
            value: 'NGA',
            label: 'Nigeria',
          },
          {
            value: 'NIU',
            label: 'Niue',
          },
          {
            value: 'NFK',
            label: 'Norfolk Island',
          },
          {
            value: 'MNP',
            label: 'Northern Mariana Islands',
          },
          {
            value: 'NOR',
            label: 'Norway',
          },
          {
            value: 'OMN',
            label: 'Oman',
          },
          {
            value: 'PAK',
            label: 'Pakistan',
          },
          {
            value: 'PLW',
            label: 'Palau',
          },
          {
            value: 'PSE',
            label: 'Palestinian Territory',
          },
          {
            value: 'PAN',
            label: 'Panama',
          },
          {
            value: 'PNG',
            label: 'Papua New Guinea',
          },
          {
            value: 'PRY',
            label: 'Paraguay',
          },
          {
            value: 'PER',
            label: 'Peru',
          },
          {
            value: 'PHL',
            label: 'Philippines',
          },
          {
            value: 'PCN',
            label: 'Pitcairn',
          },
          {
            value: 'POL',
            label: 'Poland',
          },
          {
            value: 'PRT',
            label: 'Portugal',
          },
          {
            value: 'PRI',
            label: 'Puerto Rico',
          },
          {
            value: 'QAT',
            label: 'Qatar',
          },
          {
            value: 'REU',
            label: 'Reunion',
          },
          {
            value: 'ROU',
            label: 'Romania',
          },
          {
            value: 'RUS',
            label: 'Russian Federation',
          },
          {
            value: 'RWA',
            label: 'Rwanda',
          },
          {
            value: 'SHN',
            label: 'Saint Helena',
          },
          {
            value: 'KNA',
            label: 'Saint Kitts and Nevis',
          },
          {
            value: 'LCA',
            label: 'Saint Lucia',
          },
          {
            value: 'SPM',
            label: 'Saint Pierre and Miquelon',
          },
          {
            value: 'VCT',
            label: 'Saint Vincent and the Grenadines',
          },
          {
            value: 'WSM',
            label: 'Samoa',
          },
          {
            value: 'SMR',
            label: 'San Marino',
          },
          {
            value: 'STP',
            label: 'Sao Tome and Principe',
          },
          {
            value: 'SAU',
            label: 'Saudi Arabia',
          },
          {
            value: 'SEN',
            label: 'Senegal',
          },
          {
            value: 'SRB',
            label: 'Serbia',
          },
          {
            value: 'SYC',
            label: 'Seychelles',
          },
          {
            value: 'SLE',
            label: 'Sierra Leone',
          },
          {
            value: 'SGP',
            label: 'Singapore',
          },
          {
            value: 'SVK',
            label: 'Slovak Republic',
          },
          {
            value: 'SVN',
            label: 'Slovenia',
          },
          {
            value: 'SLB',
            label: 'Solomon Islands',
          },
          {
            value: 'SOM',
            label: 'Somalia',
          },
          {
            value: 'ZAF',
            label: 'South Africa',
          },
          {
            value: 'ESP',
            label: 'Spain',
          },
          {
            value: 'LKA',
            label: 'Sri Lanka',
          },
          {
            value: 'SDN',
            label: 'Sudan',
          },
          {
            value: 'SUR',
            label: 'Suriname',
          },
          {
            value: 'SJM',
            label: 'Svalbard and Jan Mayen',
          },
          {
            value: 'SWZ',
            label: 'Swaziland',
          },
          {
            value: 'SWE',
            label: 'Sweden',
          },
          {
            value: 'CHE',
            label: 'Switzerland',
          },
          {
            value: 'SYR',
            label: 'Syria',
          },
          {
            value: 'TWN',
            label: 'Taiwan',
          },
          {
            value: 'TJK',
            label: 'Tajikistan',
          },
          {
            value: 'TZA',
            label: 'Tanzania',
          },
          {
            value: 'THA',
            label: 'Thailand',
          },
          {
            value: 'TLS',
            label: 'Timor-Leste',
          },
          {
            value: 'TGO',
            label: 'Togo',
          },
          {
            value: 'TKL',
            label: 'Tokelau',
          },
          {
            value: 'TON',
            label: 'Tonga',
          },
          {
            value: 'TTO',
            label: 'Trinidad and Tobago',
          },
          {
            value: 'TUN',
            label: 'Tunisia',
          },
          {
            value: 'TUR',
            label: 'Turkey',
          },
          {
            value: 'TKM',
            label: 'Turkmenistan',
          },
          {
            value: 'TCA',
            label: 'Turks and Caicos Islands',
          },
          {
            value: 'TUV',
            label: 'Tuvalu',
          },
          {
            value: 'UGA',
            label: 'Uganda',
          },
          {
            value: 'UKR',
            label: 'Ukraine',
          },
          {
            value: 'ARE',
            label: 'United Arab Emirates',
          },
          {
            value: 'GBR',
            label: 'United Kingdom',
          },
          {
            value: 'URY',
            label: 'Uruguay',
          },
          {
            value: 'UZB',
            label: 'Uzbekistan',
          },
          {
            value: 'VUT',
            label: 'Vanuatu',
          },
          {
            value: 'VAT',
            label: 'Vatican City State (Holy See)',
          },
          {
            value: 'VEN',
            label: 'Venezuela',
          },
          {
            value: 'VNM',
            label: 'Vietnam',
          },
          {
            value: 'VGB',
            label: 'Virgin Islands (British)',
          },
          {
            value: 'VIR',
            label: 'Virgin Islands (U.S.)',
          },
          {
            value: 'WLF',
            label: 'Wallis and Futuna',
          },
          {
            value: 'ESH',
            label: 'Western Sahara',
          },
          {
            value: 'YEM',
            label: 'Yemen',
          },
          {
            value: 'ZMB',
            label: 'Zambia',
          },
          {
            value: 'ZWE',
            label: 'Zimbabwe',
          },
        ],
      },
    },
    {
      id: 104,
      label: 'Company size',
      slug: 'companySize',
      type: 'SELECT',
      required: true,
      enabled: false,
      config: {
        icon: 'companySize',
        configurable: true,
        options: [
          {
            label: '1-10',
            value: '1-10',
          },
          {
            label: '11-50',
            value: '11-50',
          },
          {
            label: '51-200',
            value: '51-200',
          },
          {
            label: '201-500',
            value: '201-500',
          },
          {
            label: '501-1000',
            value: '501-1000',
          },
          {
            label: '1001-5000',
            value: '1001-5000',
          },
          {
            label: '5001-10000',
            value: '5001-10000',
          },
          {
            label: '10001+',
            value: '10001+',
          },
        ],
      },
    },
    {
      id: 105,
      label: 'Industry',
      slug: 'industry',
      type: 'SELECT',
      required: false,
      enabled: false,
      config: {
        icon: 'industry',
        configurable: true,
        options: [
          { value: '47', label: 'Accounting' },
          { value: '94', label: 'Airlines/Aviation' },
          { value: '120', label: 'Alternative Dispute Resolution' },
          { value: '125', label: 'Alternative Medicine' },
          { value: '127', label: 'Animation' },
          { value: '19', label: 'Apparel & Fashion' },
          { value: '50', label: 'Architecture & Planning' },
          { value: '111', label: 'Arts & Crafts' },
          { value: '53', label: 'Automotive' },
          { value: '52', label: 'Aviation & Aerospace' },
          { value: '41', label: 'Banking' },
          { value: '12', label: 'Biotechnology' },
          { value: '36', label: 'Broadcast Media' },
          { value: '49', label: 'Building Materials' },
          { value: '138', label: 'Business Supplies & Equipment' },
          { value: '129', label: 'Capital Markets' },
          { value: '54', label: 'Chemicals' },
          { value: '90', label: 'Civic & Social Organization' },
          { value: '51', label: 'Civil Engineering' },
          { value: '128', label: 'Commercial Real Estate' },
          { value: '118', label: 'Computer & Network Security' },
          { value: '109', label: 'Computer Games' },
          { value: '3', label: 'Computer Hardware' },
          { value: '5', label: 'Computer Networking' },
          { value: '4', label: 'Computer Software' },
          { value: '48', label: 'Construction' },
          { value: '24', label: 'Consumer Electronics' },
          { value: '25', label: 'Consumer Goods' },
          { value: '91', label: 'Consumer Services' },
          { value: '18', label: 'Cosmetics' },
          { value: '65', label: 'Dairy' },
          { value: '1', label: 'Defense & Space' },
          { value: '99', label: 'Design' },
          { value: '69', label: 'Education Management' },
          { value: '132', label: 'E-Learning' },
          { value: '112', label: 'Electrical & Electronic Manufacturing' },
          { value: '28', label: 'Entertainment' },
          { value: '86', label: 'Environmental Services' },
          { value: '110', label: 'Events Services' },
          { value: '76', label: 'Executive Office' },
          { value: '122', label: 'Facilities Services' },
          { value: '63', label: 'Farming' },
          { value: '43', label: 'Financial Services' },
          { value: '38', label: 'Fine Art' },
          { value: '66', label: 'Fishery' },
          { value: '34', label: 'Food & Beverages' },
          { value: '23', label: 'Food Production' },
          { value: '101', label: 'Fundraising' },
          { value: '26', label: 'Furniture' },
          { value: '29', label: 'Gambling & Casinos' },
          { value: '145', label: 'Glass, Ceramics & Concrete' },
          { value: '75', label: 'Government Administration' },
          { value: '148', label: 'Government Relations' },
          { value: '140', label: 'Graphic Design' },
          { value: '124', label: 'Health, Wellness and Fitness' },
          { value: '68', label: 'Higher Education' },
          { value: '150', label: 'Horticulture' },
          { value: '14', label: 'Hospital & Health Care' },
          { value: '31', label: 'Hospitality' },
          { value: '137', label: 'Human Resources' },
          { value: '134', label: 'Import & Export' },
          { value: '88', label: 'Individual & Family Services' },
          { value: '147', label: 'Industrial Automation' },
          { value: '84', label: 'Information Services' },
          { value: '96', label: 'Information Technology & Services' },
          { value: '42', label: 'Insurance' },
          { value: '74', label: 'International Affairs' },
          { value: '141', label: 'International Trade & Development' },
          { value: '6', label: 'Internet' },
          { value: '45', label: 'Investment Banking' },
          { value: '46', label: 'Investment Management' },
          { value: '73', label: 'Judiciary' },
          { value: '77', label: 'Law Enforcement' },
          { value: '9', label: 'Law Practice' },
          { value: '10', label: 'Legal Services' },
          { value: '72', label: 'Legislative Office' },
          { value: '30', label: 'Leisure, Travel & Tourism' },
          { value: '85', label: 'Libraries' },
          { value: '116', label: 'Logistics & Supply Chain' },
          { value: '143', label: 'Luxury Goods & Jewelry' },
          { value: '55', label: 'Machinery' },
          { value: '11', label: 'Management Consulting' },
          { value: '95', label: 'Maritime' },
          { value: '97', label: 'Market Research' },
          { value: '80', label: 'Marketing & Advertising' },
          { value: '135', label: 'Mechanical Or Industrial Engineering' },
          { value: '126', label: 'Media Production' },
          { value: '17', label: 'Medical Device' },
          { value: '13', label: 'Medical Practice' },
          { value: '139', label: 'Mental Health Care' },
          { value: '71', label: 'Military' },
          { value: '56', label: 'Mining & Metals' },
          { value: '35', label: 'Motion Pictures & Film' },
          { value: '37', label: 'Museums & Institutions' },
          { value: '115', label: 'Music' },
          { value: '114', label: 'Nanotechnology' },
          { value: '81', label: 'Newspapers' },
          { value: '100', label: 'Non-profit Organization Management' },
          { value: '57', label: 'Oil & Energy' },
          { value: '113', label: 'Online Media' },
          { value: '123', label: 'Outsourcing/Offshoring' },
          { value: '87', label: 'Package/Freight Delivery' },
          { value: '146', label: 'Packaging & Containers' },
          { value: '61', label: 'Paper & Forest Products' },
          { value: '39', label: 'Performing Arts' },
          { value: '15', label: 'Pharmaceuticals' },
          { value: '131', label: 'Philanthropy' },
          { value: '136', label: 'Photography' },
          { value: '117', label: 'Plastics' },
          { value: '107', label: 'Political Organization' },
          { value: '67', label: 'Primary/Secondary Education' },
          { value: '83', label: 'Printing' },
          { value: '105', label: 'Professional Training & Coaching' },
          { value: '102', label: 'Program Development' },
          { value: '79', label: 'Public Policy' },
          { value: '98', label: 'Public Relations & Communications' },
          { value: '78', label: 'Public Safety' },
          { value: '82', label: 'Publishing' },
          { value: '62', label: 'Railroad Manufacture' },
          { value: '64', label: 'Ranching' },
          { value: '44', label: 'Real Estate' },
          { value: '40', label: 'Recreational Facilities & Services' },
          { value: '89', label: 'Religious Institutions' },
          { value: '144', label: 'Renewables & Environment' },
          { value: '70', label: 'Research' },
          { value: '32', label: 'Restaurants' },
          { value: '27', label: 'Retail' },
          { value: '121', label: 'Security & Investigations' },
          { value: '7', label: 'Semiconductors' },
          { value: '58', label: 'Shipbuilding' },
          { value: '20', label: 'Sporting Goods' },
          { value: '33', label: 'Sports' },
          { value: '104', label: 'Staffing & Recruiting' },
          { value: '22', label: 'Supermarkets' },
          { value: '8', label: 'Telecommunications' },
          { value: '60', label: 'Textiles' },
          { value: '130', label: 'Think Tanks' },
          { value: '21', label: 'Tobacco' },
          { value: '108', label: 'Translation & Localization' },
          { value: '92', label: 'Transportation/Trucking/Railroad' },
          { value: '59', label: 'Utilities' },
          { value: '106', label: 'Venture Capital & Private Equity' },
          { value: '16', label: 'Veterinary' },
          { value: '93', label: 'Warehousing' },
          { value: '133', label: 'Wholesale' },
          { value: '142', label: 'Wine and Spirits' },
          { value: '119', label: 'Wireless' },
          { value: '103', label: 'Writing & Editing' },
        ],
      },
    },
    {
      id: 106,
      label: 'ZIP code',
      slug: 'zip',
      type: 'INPUT',
      required: true,
      enabled: false,
      config: {
        icon: 'zip',
        configurable: true,
      },
    },
    {
      id: 107,
      label: 'Phone number',
      slug: 'phone',
      type: 'INPUT',
      required: true,
      enabled: false,
      config: {
        icon: 'phone',
        configurable: true,
      },
    },
    {
      id: 108,
      label: 'Message',
      slug: 'message',
      type: 'TEXT',
      required: true,
      enabled: true,
      config: {
        icon: 'message',
        configurable: true,
      },
    },
    {
      id: 129,
      label: 'Privacy policy',
      slug: 'privacy',
      type: 'CHECKBOX',
      required: true,
      enabled: false,
      config: {
        icon: 'privacy',
        configurable: true,
      },
    },
    {
      id: 124,
      label: 'Custom field',
      slug: 'custom_field_1',
      type: 'CUSTOM_TEXT',
      required: true,
      enabled: false,
      config: {
        configurable: false,
        labelTitle: 'Fill with your phone number',
        label: 'phone',
      },
    },
    {
      id: 125,
      label: 'Custom field',
      slug: 'custom_field_2',
      type: 'CUSTOM_MENU',
      required: true,
      enabled: false,
      config: {
        configurable: false,
        labelTitle: 'Fill with your phone number',
        label: 'phone',
      },
    },
    {
      id: 126,
      label: 'Custom field',
      slug: 'custom_field_3',
      type: 'CUSTOM_CHECKBOX',
      required: true,
      enabled: false,
      config: {
        configurable: false,
        checked: true,
        labelTitle: 'Fill with your phone number',
        label: 'phone',
      },
    },
  ],
  brandingOption: true,
};
