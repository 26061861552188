import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import moment from 'moment';
import { Award, Star, ChevronDown, ChevronRight } from 'lucide-react';
import { AwardDetailsHeader } from 'components/AwardDetailsHeader';
import {
  SecondaryPageContent,
  KeyDates,
  TextSection,
  Assets,
} from 'components/SecondaryPage';
import { getAwards } from 'store/apis/awardsApi';
import { formatTitle, generateBadgeUrlForAward } from 'utils/awards';
import { useSettings } from 'settings';
import { TechCaresDesktop } from './assets';
import styles from './TechCaresDetails.module.scss';

const GRAY_70 = '#959596';

export function TechCaresDetails() {
  const { vendorId } = useParams();
  const [awards, setAwards] = useState([]);
  const [isHowToWinOpen, setIsHowToWinOpen] = useState(true);
  const settings = useSettings();
  const isDesktop = useMedia('(min-width:901px)');
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  useEffect(() => {
    async function getTechCaresAwards() {
      const awardsResponse = await getAwards({
        filter: { awardType: 'techcares' },
        select: 'title publishedDate awardBadgeFileName calculatedEndDate',
        lean: true,
      });
      setAwards(awardsResponse.data.awards);
    }

    getTechCaresAwards();
  }, []);

  const techCaresAward = useMemo(() => {
    const sortedAwards = awards.sort((obejct1, obejct2) => {
      if (moment(obejct1.publishedDate) > moment(obejct2.publishedDate)) {
        return -1;
      }
      if (moment(obejct1.publishedDate) < moment(obejct2.publishedDate)) {
        return 1;
      }
      return 0;
    });

    return sortedAwards[0] || {};
  }, [awards]);

  const backLink = `/vendor/${vendorId}/awards`;

  function getAwardDetails() {
    return (
      <>
        {isDesktop && (
          <img
            src={
              generateBadgeUrlForAward(
                techCaresAward,
                settings.staticCdnHostName,
              )?.x1
            }
            alt={techCaresAward.title || 'Tech Cares award'}
            hight="92"
            width="80"
          />
        )}
        {formatTitle(techCaresAward.title)}
      </>
    );
  }

  function headerContent() {
    if (!isDesktop) {
      return <></>;
    }
    return (
      <a
        className="button button-primary round"
        href="https://www.trustradius.com/vendor-blog/awards-calendar"
        target="_blank"
        rel="noopener noreferrer"
      >
        Awards calendar
      </a>
    );
  }

  const keyDates = [
    {
      id: 1,
      icon: <Star color={GRAY_70} />,
      date: 'July 15, 2024',
      description: 'Nominations close',
    },
    {
      id: 2,
      icon: <Award color={GRAY_70} />,
      date: moment(techCaresAward.publishedDate).format('LL'),
      description: 'Winners announced',
    },
  ];

  const overview = {
    title: 'Overview',
    text: `The Tech Cares Award recognizes exceptional corporate social
      responsibility initiatives. We look for ways companies help support
      diversity, environmentalism, philanthropy and more.
      Anyone can nominate a company, including their own, as long as it's
      a B2B tech company that has a presence on TrustRadius.`,
  };

  function getHowToWin() {
    const list = (
      <ul>
        <li>Be a B2B Tech Company</li>
        <li>Have a presence on TrustRadius</li>
        <li>
          Demonstrate social corporate responsibility through any of the
          following:
          <ul>
            <li>Environmental sustainability initiatives</li>
            <li>Support for Women in Tech</li>
            <li>Robust Diversity, Equity, and Inclusion programs</li>
            <li>Support for in-office and remote employees</li>
            <li>Philanthropy</li>
            <li>Volunteerism</li>
          </ul>
        </li>
        <li>
          Provide links to examples that support your nomination, such as:
          <ul>
            <li>Articles</li>
            <li>Pages on your website</li>
            <li>Reports you&apos;ve published</li>
            <li>White papers</li>
            <li>Other documentation </li>
          </ul>
        </li>
      </ul>
    );

    function howToWinBlock(howToWinList = <></>) {
      return (
        <section>
          <button type="button" onClick={onClose}>
            <h3>
              {isTablet && isHowToWinOpen && <ChevronDown />}
              {isTablet && !isHowToWinOpen && <ChevronRight />}
              How do I win?
            </h3>
          </button>
          {isTablet && <div className={styles.separator} />}
          {howToWinList}
        </section>
      );
    }

    function onClose() {
      if (isTablet) {
        setIsHowToWinOpen(!isHowToWinOpen);
      }
    }

    if (!isTablet) {
      return howToWinBlock(list);
    } else if (isHowToWinOpen) {
      return howToWinBlock(list);
    }

    return howToWinBlock();
  }

  const assets = [
    {
      id: 1,
      listItem: (
        <>
          Check out our&nbsp;
          <a
            href="https://www.trustradius.com/vendor/review-growth-kit"
            target="_blank"
            rel="noopener noreferrer"
          >
            review growth kit
          </a>
          &nbsp; to help you in sourcing reviews
        </>
      ),
    },
    {
      id: 2,
      listItem: (
        <>
          Check out previous Tech Cares award winners&nbsp;
          <a
            href="https://solutions.trustradius.com/vendor-blog/tech-cares-award"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <AwardDetailsHeader
        backLink={backLink}
        awardInfo={getAwardDetails()}
        content={headerContent()}
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-dates',
            element: <KeyDates content={keyDates} />,
          },
          {
            id: 'overview',
            element: <TextSection content={overview} />,
          },
          {
            id: 'how-to-win',
            element: getHowToWin(),
          },
          {
            id: 'assets',
            element: <Assets content={assets} />,
          },
        ]}
        imageBanner={<img src={TechCaresDesktop} alt="Tech Cares" />}
      >
        <section>
          <p>
            TrustRadius believes truly exceptional companies demonstrate
            compassion in every aspect of their company. Tech Cares celebrates
            the best parts of the tech market and those who work in this field.
            TrustRadius wants to recognize companies that demonstrate
            exceptional social responsibility, with a focus on:
          </p>
          <ul>
            <li>Environmental sustainability initiatives</li>
            <li>Support for Women in Tech</li>
            <li>Robust Diversity, Equity, and Inclusion programs</li>
            <li>Support for in-office and remote employees</li>
            <li>Philanthropy</li>
            <li>Volunteerism</li>
          </ul>
          <p>
            If you know an organization that fits these criteria, submit a
            nomination!
          </p>
          <p>
            The question to answer in the nomination form is: How has this
            organization demonstrated exceptional support and care for their
            customers, communities, or environment in the last year, per the
            criteria listed above? Please provide links to any examples that
            would support your nomination, such as press releases, articles,
            pages on your website, reports you&apos;ve published, white papers,
            or other documentation.
          </p>
          <p>
            Things to keep in mind about Tech Cares. <br />
            Anyone can nominate an organization. Employees can even nominate
            their employers! All nominations will be vetted by our research
            team, so provide all the support you can. It&apos;s completely free
            to nominate and receive the award
          </p>
        </section>
      </SecondaryPageContent>
    </>
  );
}
