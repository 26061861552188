import { useSelector } from 'react-redux';
import { User, Sliders, PieChart } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { StorylaneSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { StorylaneBanner, StorylaneBanner2x } from './assets';

export function Storylane() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle = 'Build and share interactive product demos in 10 mins.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Storylane',
    logo: <img src={StorylaneSquareLogo} alt="Storylane" />,
    subtitle: subTitle,
    actionBlock: (
      <Button size="large" url="https://app.storylane.io/sign-up" targetBlank>
        Create a free demo
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: 'ability',
      icon: <User />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: 'tailor',
      icon: <Sliders />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: 'insights',
      icon: <PieChart />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={StorylaneBanner}
            srcSet={`${StorylaneBanner} 1x, ${StorylaneBanner2x} 2x`}
            alt="Storylane"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Storylane</Text>
          <Text as="p">
            Storylane helps companies build and share interactive product demos
            with their prospects in 10 minutes. With our simple no-code
            platform, you can create engaging product experiences by bringing in
            product screens, guided tool tips, audio clips, and videos.
          </Text>
          <Text as="p">
            For both live sales demos and guided product tours, Storylane helps
            you deliver a seamless demo experience to stakeholders involved in
            the buying process.
          </Text>
          <Text as="h3">Storylane and TrustRadius</Text>
          <Text as="p">
            Create your interactive product demo powered by Storylane and add it
            to your profile page on TrustRadius. Storylane&apos;s interactive
            demos help you engage your prospects in a meaningful way. Highlight
            differentiators, increase deal velocity, and establish trust with
            interactive demos by Storylane.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Create a demo your prospects won&apos;t forget</Text>
          <Text as="p">
            Share a guided and clickable demo of your product, customized for
            your prospect as demo leave-behinds. Enable your champion buyer to
            internalize your product and accelerate deals.
          </Text>
          <Text as="h3">Share interactive demos for partners</Text>
          <Text as="p">
            Use demos as training guides for extended partners so they can
            follow guided and consistent demo flows.
          </Text>
          <Text as="h3">
            Embed demos across your top converting website pages
          </Text>
          <Text as="p">
            Embed product tours on your website to build a marketing-qualified
            lead pipeline.
          </Text>
          <Text as="h3">Embed demos on top of marketing assets</Text>
          <Text as="p">
            Embed quick guided tours of your product features in your blogs,
            resources, or on landing pages to drive better engagement.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
