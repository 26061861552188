import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { LeadFormV2 } from 'components/LeadFormV2';
import { Modal } from 'components/Modal';
import {
  ChangesWillNotBeSavedModal,
  DeleteLeadFormModal,
  NoProductSelectedModal,
} from 'components/LeadsV2Modals';
import { LeftColumn } from 'pages/LeadFormConfiguration/LeftColumn';
import { RightColumn } from 'pages/LeadFormConfiguration/RightColumn';
import styles from './LeadFormConfiguration.module.scss';
import { leadFormConfig } from './leadFormConfig';

export function LeadFormConfiguration() {
  const vendor = useSelector((state) => state.vendors.currentVendor);
  const isDesktop = useMedia('(min-width: 901px)');
  const [leadFormFields, setLeadFormFields] = useState(
    leadFormConfig.fields || [],
  );
  const blocker = useBlocker(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [leadFormName, setLeadFormName] = useState('Form Name');
  const [showPreview, setShowPreview] = useState(false);

  const [showNoProductSelectedModal, setShowNoProductSelectedModal] =
    useState(false);

  return (
    <div
      className={classNames(styles.container, { [styles.mobile]: !isDesktop })}
    >
      {isDesktop && (
        <LeftColumn
          leadFormName={leadFormName}
          leadFormFields={leadFormFields}
          vendor={vendor}
        />
      )}
      <RightColumn
        leadFormFields={leadFormFields}
        setLeadFormFields={setLeadFormFields}
        setShowDeleteModal={setShowDeleteModal}
        setShowPreview={setShowPreview}
        setShowNoProductSelectedModal={setShowNoProductSelectedModal}
        leadFormName={leadFormName}
        setLeadFormName={setLeadFormName}
        isDesktop={isDesktop}
      />

      <DeleteLeadFormModal
        setIsShowDeleteModal={setShowDeleteModal}
        isShowDeleteModal={showDeleteModal}
      />
      <Modal
        isOpen={showPreview}
        closeHandler={() => setShowPreview(false)}
        className={styles.modal}
      >
        <LeadFormV2 vendor={vendor} fields={leadFormFields} disabled />
      </Modal>
      <ChangesWillNotBeSavedModal blocker={blocker} />
      <NoProductSelectedModal
        isOpen={showNoProductSelectedModal}
        closeHandler={() => setShowNoProductSelectedModal(false)}
        discardChanges={() => setShowNoProductSelectedModal(false)}
      />
    </div>
  );
}
