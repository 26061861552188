import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Spinner } from 'components/Spinner';
import { Header } from 'components/Header';
import { GTMData } from 'components/GTMData';
import { authenticateWithLegacy } from 'store/apis/legacyApi';
import { useSettings } from 'settings';
import { PermissionsProvider } from 'permissions';
import { SilentAuthIframe } from './SilentAuthIframe';

export function AuthenticatedPageWrapper() {
  const [isVendorPortalLoading, setIsVendorPortalLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const [needsIframe, setNeedsIframe] = useState(false);
  const useNewAuthFlow = useFeatureIsOn('auth0');
  const buyerSiteUrl = useSettings().buyerSiteUrl;

  const {
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
    isLoading: isAuth0Loading,
  } = useAuth0();

  const settings = useSettings();
  const silentAuthUrl = `${settings?.buyerSiteUrl}/auth/silent`;

  // Fetches the tokens needed for the vendor portal
  useEffect(() => {
    const fetchVendorPortalToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setNeedsIframe(true);
        }
        window.sessionStorage.authToken = token;
        if (!useNewAuthFlow) {
          authenticateWithLegacy(isAuthenticated, token, settings);
        }
      } catch (err) {
        console.error('Error fetching token', err);
        throw err;
      } finally {
        setIsVendorPortalLoading(false);
      }
    };

    const fetchInternalApiToken = async () => {
      const audience = 'urn:api-internal.trustradius.com';
      const scope = 'access:internal_api';
      let token;
      try {
        token = await getAccessTokenSilently({
          authorizationParams: { audience, scope },
        });
      } catch (err) {
        if (err.error === 'consent_required') {
          token = await getAccessTokenWithPopup({
            authorizationParams: { audience, scope },
          });
        } else {
          throw err;
        }
      }

      window.sessionStorage.internalApiToken = token;
    };

    const fetchTokens = async () => {
      await Promise.all([fetchVendorPortalToken(), fetchInternalApiToken()]);
      setIsVendorPortalLoading(false);
    };

    if (isAuthenticated) {
      fetchTokens();
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    settings,
    useNewAuthFlow,
  ]);

  if (!isAuthenticated && !isAuth0Loading && !error) {
    loginWithRedirect();
    return null;
  }

  if (error === 'access_denied') {
    return (window.location.href = `${buyerSiteUrl}/vendoradmin`);
  }

  const content =
    isVendorPortalLoading || isAuth0Loading ? <Spinner /> : <Outlet />;

  return (
    <PermissionsProvider
      isAuthenticated={isAuthenticated && !isVendorPortalLoading}
    >
      <GTMData />
      <Header showMenu={isAuthenticated} />
      {content}
      <SilentAuthIframe
        show={needsIframe}
        useNewAuthFlow={useNewAuthFlow}
        silentAuthUrl={silentAuthUrl}
      />
    </PermissionsProvider>
  );
}
