import { useEffect, useCallback, useRef } from 'react';

export function useOutsideClickHandler(onOutsideClick) {
  const ref = useRef(null);

  const handleClick = useCallback(
    (event) => {
      const isOutside = ref.current && !ref.current.contains(event.target);

      if (isOutside || event?.key === 'Escape') {
        onOutsideClick(event);
      }
    },
    [onOutsideClick],
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleClick);
    };
  }, [handleClick]);

  return ref;
}
