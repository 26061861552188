import { Button, Text } from '@trustradius/radiant-ui';
import { ChevronLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import { LeadFormV2 } from 'components/LeadFormV2';
import styles from './LeadFormConfiguration.module.scss';

export function LeftColumn({ leadFormName, vendor, leadFormFields }) {
  return (
    <div className={styles['left-column']}>
      <div className={styles.header}>
        <Button size="large" variant="no-border">
          <ChevronLeft /> Back to all forms
        </Button>
        <Text as="h1" weight="bold" size="400">
          {leadFormName}
        </Text>
      </div>
      <div className={styles['lead-container']}>
        <LeadFormV2 vendor={vendor} fields={leadFormFields} disabled />
      </div>
    </div>
  );
}

LeftColumn.propTypes = {
  leadFormName: PropTypes.string,
  vendor: PropTypes.object,
  leadFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
};
