import { Button, Input } from '@trustradius/radiant-ui';
import classNames from 'classnames';
import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Modal } from 'components/Modal';
import { CircleIcon } from 'components/CircleIcon';
import styles from './RenameWebhookModal.module.scss';

export function RenameWebhookModal({ name, isOpen, closeHandler, trigger }) {
  const [value, setValue] = useState(name || '');
  const [hasError, setHasError] = useState(false);
  const maxLength = 60;
  const changeHandler = (event) => {
    setValue(event.target.value);
    if (event.target.value.length > maxLength) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      title="Rename your webhook"
      description="Choose a name to help you easily identify and manage your webhook. This name is for internal use only and will not be displayed to prospects."
      icon={<CircleIcon iconType="edit" />}
      className={styles.content}
      trigger={<>{trigger}</>}
      actionButtons={[
        <Button variant="secondary" key="cancel" size="large">
          Cancel
        </Button>,
        <Button isDisabled={hasError} key="create" size="large">
          Save
        </Button>,
      ]}
      closeHandler={() => {
        closeHandler();
      }}
    >
      <div className={styles['input-container']}>
        <Input
          id="rename-webhook"
          required
          placeholder="Name your webhook"
          value={value}
          onChange={(event) => {
            changeHandler(event);
          }}
          onPaste={(event) => {
            changeHandler(event);
          }}
          autoComplete="off"
          className={classNames(styles.input, { [styles.error]: hasError })}
        />
        <div
          className={classNames(styles['name-length'], {
            [styles.error]: hasError,
          })}
        >
          {value?.length} / {maxLength}
        </div>
      </div>
    </Modal>
  );
}

RenameWebhookModal.propTypes = {
  name: PropTypes.string,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  trigger: PropTypes.node,
};
