import { Input, Text } from '@trustradius/radiant-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './TextFieldWithCounter.module.scss';

export function TextFieldWithCounter({
  fieldId,
  fieldLabel,
  isRequired,
  fieldValue,
  hideLabel,
  maxValueLength,
  fieldChangeHandler,
}) {
  const hasError = fieldValue?.length > maxValueLength;
  return (
    <div className={styles.group}>
      <Input
        id={fieldId}
        label={fieldLabel}
        required={isRequired}
        isInvalid={hasError}
        hideLabel={hideLabel}
        onChange={fieldChangeHandler}
        value={fieldValue}
      />
      {maxValueLength && (
        <div className={styles.counter}>
          <Text
            size="100"
            className={classNames({
              [styles.error]: hasError,
            })}
          >
            {fieldValue?.length || 0}/{maxValueLength}
          </Text>
        </div>
      )}
    </div>
  );
}

TextFieldWithCounter.propTypes = {
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldValue: PropTypes.string,
  hideLabel: PropTypes.bool,
  maxValueLength: PropTypes.number,
  fieldChangeHandler: PropTypes.func,
};
