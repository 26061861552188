import { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Input, Button, Alert } from '@trustradius/radiant-ui';
import classNames from 'classnames';
import { CheckCircle2, AlertCircle, Trash2 } from 'lucide-react';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { Modal } from 'components/TrustradiusUICore';
import styles from './ZoominfoConnect.module.scss';

export function ZoominfoConnect({
  isOpen,
  headerProps,
  apiKey,
  openModal,
  status,
  notificationMessage,
  notificationTitle,
  notificationStatus,
  onCloseHandler,
  onConnectHandler,
  onDisconnectHandler,
}) {
  const [input, setInput] = useState(apiKey || '');
  const buttonText = apiKey ? 'Disconnect' : 'Connect';
  const isShowNotifications =
    notificationStatus === 'success' || notificationStatus === 'error';
  return (
    <>
      <SecondaryPageHeader {...headerProps} hasShadow />
      <section className={styles.container}>
        <div className={styles.notification}>
          {isShowNotifications && (
            <Alert variant={notificationStatus} title={notificationTitle}>
              {notificationMessage}
            </Alert>
          )}
        </div>
        <Text as="h2" size="400" weight="bold">
          Getting started
        </Text>
        <Text as="p" className={styles.paragraph}>
          To activate this integration, please enter your{' '}
          <b>ZoomInfo API key</b> below and click <b>Connect</b>. To find your
          ZoomInfo API key, please follow{' '}
          <a
            href="https://trustradius.freshdesk.com/en/support/solutions/articles/43000711966-how-to-find-your-zoominfo-api-key"
            target="_blank"
            rel="noopener noreferrer"
          >
            these instructions
          </a>
          .
        </Text>
        <Text as="p" className={styles.paragraph}>
          In order to use this integration, you must have a subscription to
          TrustRadius intent data and ZoomInfo Intent data.
        </Text>
        <div className={styles.inputGroup}>
          <Input
            label="API key"
            orientation="vertical"
            value={input}
            onChange={(event) => setInput(event.target.value)}
            required
            placeholder="Enter API key here"
            isInvalid={status === 'error'}
            className={styles.input}
          />
          {status === 'success' && (
            <CheckCircle2 className={classNames(styles.icon, styles.success)} />
          )}
          {status === 'error' && (
            <AlertCircle className={classNames(styles.icon, styles.error)} />
          )}
        </div>
        {status === 'error' && (
          <Text as="p" size="100" className={styles.error}>
            Unable to validate API key
          </Text>
        )}
        <div className={styles.button}>
          {!apiKey && (
            <Button
              variant="primary"
              onClick={() => onConnectHandler(input)}
              isDisabled={input.length === 0}
            >
              {buttonText}
            </Button>
          )}
          {apiKey && (
            <Button variant="primary-warning" onClick={openModal}>
              {buttonText}
            </Button>
          )}
        </div>
      </section>
      <Modal
        show={isOpen}
        dialogClassName={styles.dialog}
        backdropClassName={styles.backdrop}
        onHide={onCloseHandler}
      >
        <Modal.Header className={classNames(styles.header)} closeButton>
          <Modal.Title as="div" className={styles.title}>
            <div className={styles.backgroundLight}>
              <div className={styles.backgroundDark}>
                <Trash2 />
              </div>
            </div>
            <h2>Disconnect API Key</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <Text>
            Are you sure you want to disconnect your API key? Your TrustRadius
            intent signals will no longer be delivered to ZoomInfo.
          </Text>
          <div className={styles.modalButtons}>
            <Button variant="secondary" size="large" onClick={onCloseHandler}>
              Cancel
            </Button>
            <Button
              variant="primary-warning"
              size="large"
              onClick={() => {
                setInput('');
                onDisconnectHandler();
              }}
            >
              Disconnect
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

ZoominfoConnect.propTypes = {
  openModal: PropTypes.func,
  isOpen: PropTypes.bool,
  headerProps: PropTypes.object,
  status: PropTypes.string,
  apiKey: PropTypes.string,
  notificationMessage: PropTypes.string,
  notificationTitle: PropTypes.string,
  notificationStatus: PropTypes.string,
  onCloseHandler: PropTypes.func,
  onConnectHandler: PropTypes.func,
  onDisconnectHandler: PropTypes.func,
};
