import PropTypes from 'prop-types';
import { Button } from '@trustradius/radiant-ui';
import { Modal } from 'components/TrustradiusUICore';
import { accountShape } from '../types';
import styles from './AccountsModal.module.scss';

export function AccountsModal({
  show,
  handleClose,
  segmentName,
  accounts,
  noAccountsText,
}) {
  const tableContent =
    accounts && accounts.length ? (
      accounts.map((account) => (
        <tr key={`${account.name}:${account.website}`}>
          <td>{account.name || 'Unknown'}</td>
          <td>{account.website || 'Unknown'}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td>{noAccountsText}</td>
      </tr>
    );

  return (
    <Modal
      show={show}
      className={styles.modal}
      dialogClassName={styles.dialog}
      backdropClassName={styles.backdrop}
      onHide={handleClose}
    >
      <Modal.Header className={styles.header} closeButton>
        <Modal.Title className={styles.title}>
          Companies in {segmentName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th scope="col">Companies</th>
              <th scope="col">Domain</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button variant="primary" size="large" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AccountsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  segmentName: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(accountShape),
  noAccountsText: PropTypes.string.isRequired,
};
