import { useEligibleProductsValues } from 'hooks';
import { GeneralAwardCard } from '../GeneralAwardCard';

export function BuyersChoiceAwardCard() {
  const eligibleProductsValues = useEligibleProductsValues();

  return (
    <GeneralAwardCard
      badge={{
        src: 'https://static.trustradius.com/images/awards/buyers_choice.png',
        alt: "Buyer's Choice Award Badge",
      }}
      name="Buyer's Choice"
      values={eligibleProductsValues}
      description={
        <>
          Showcases Key Insights statistics for Best Feature Set, Best Value for
          Price, and Best Relationship
        </>
      }
    />
  );
}
