export const FETCH_COMPETITORS_BY_VENDOR_ID_START =
  'FETCH_COMPETITORS_BY_VENDOR_ID_START';
export const FETCH_COMPETITORS_BY_VENDOR_ID_FAILED =
  'FETCH_COMPETITORS_BY_VENDOR_ID_FAILED';
export const FETCH_COMPETITORS_BY_VENDOR_ID_SUCCESS =
  'FETCH_COMPETITORS_BY_VENDOR_ID_SUCCESS';

export const fetchCompetitorsByProductIdStart = () => ({
  type: FETCH_COMPETITORS_BY_VENDOR_ID_START,
});

export const fetchCompetitorsByProductIdSuccess = (competitors) => ({
  type: FETCH_COMPETITORS_BY_VENDOR_ID_SUCCESS,
  payload: {
    competitors,
  },
});

export const fetchCompetitorsByProductIdFailed = (error) => ({
  type: FETCH_COMPETITORS_BY_VENDOR_ID_FAILED,
  payload: {
    error,
  },
});
