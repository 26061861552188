import { Zap, Users, Webhook } from 'lucide-react';
import PropTypes from 'prop-types';
import { Button } from '@trustradius/radiant-ui';
import {
  SecondaryPageHeader,
  TextBanner,
  SecondaryPageContent,
  KeyBenefits,
  Prerequisites,
  UseCases,
} from 'components/SecondaryPage';
import { withLicenseData } from 'hoc/withLicenseData';
import { leadsBanner, leadsBanner2x } from './assets';

export function LeadsOverview({ vendorFeatures }) {
  const hasBuyersLeadsLicense = vendorFeatures.includes('buyer-leads');
  return (
    <>
      <SecondaryPageHeader
        title="Leads"
        subtitle="Engage qualified in-market buyers with TrustRadius Leads"
        actionBlock={
          !hasBuyersLeadsLicense && (
            <Button
              url="https://www.trustradius.com/resources/assets/global-demo"
              targetBlank
              size="large"
            >
              Learn More
            </Button>
          )
        }
      />
      {!hasBuyersLeadsLicense && (
        <TextBanner>
          A leads offering license is required to unlock this feature.
        </TextBanner>
      )}
      <SecondaryPageContent
        imageBanner={
          <img
            src={leadsBanner}
            srcSet={`${leadsBanner} 732w, ${leadsBanner2x} 1464w`}
            alt="Leads"
          />
        }
        rightRail={[
          {
            id: 'key-benefits',
            element: (
              <KeyBenefits
                content={[
                  {
                    id: 'power',
                    icon: <Zap />,
                    text: <>Power your demand gen strategy</>,
                  },
                  {
                    id: 'connect',
                    icon: <Users />,
                    text: (
                      <>
                        Connect with in-market buyers who are interested in your
                        product
                      </>
                    ),
                  },
                  {
                    id: 'deliver',
                    icon: <Webhook />,
                    text: (
                      <>
                        Deliver TrustRadius leads to the systems you use to
                        manage leads
                      </>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'prerequisites',
            element: (
              <Prerequisites
                content={[
                  {
                    id: 'license',
                    componentContent: (
                      <>
                        A leads offering license is required to unlock this
                        feature.{' '}
                        {!hasBuyersLeadsLicense && (
                          <a
                            href="https://www.trustradius.com/resources/assets/global-demo"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more
                          </a>
                        )}
                      </>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'use-cases',
            element: (
              <UseCases content={['Demand Generation', 'Lead Generation']} />
            ),
          },
        ]}
      >
        <section>
          <h2>Overview</h2>
          <h3>About TrustRadius Leads</h3>
          <p>
            TrustRadius leads are generated when buyers who are interested in
            your product submit their contact details via a Lead form. Buyers
            can access the Lead form through your TrustRadius product listing,
            pricing page, comparison page, and more. The Leads section of the
            vendor portal enables TrustRadius customers to activate their Lead
            form, view Leads activity, and set up webhooks so they send leads
            data to third-party systems.
          </p>
        </section>
        <section>
          <h2>Features</h2>
          <h3>Lead Form</h3>
          <p>
            With the Lead form, you can start collecting contact information for
            interested leads through the TrustRadius site. Once activated, the
            Lead form can be accessed through a “Contact” CTA on your
            TrustRadius product listing(s), pricing page, comparison page, and
            more.
          </p>
          <h3>Leads Activity</h3>
          <p>
            With the Leads Activity tab, you can view all leads that have been
            generated for your products. You can also view all the information
            that a buyer submitted through your Lead form and export your Leads
            data to a CSV file.
          </p>
          <h3>Leads Webhook</h3>
          <p>
            You can leverage webhooks to automatically deliver TrustRadius leads
            data to third-party systems. Newly submitted leads are sent
            instantly to registered webhooks, so you can integrate TrustRadius
            leads into your existing sales and marketing processes and start
            engaging new leads immediately.
          </p>
        </section>
      </SecondaryPageContent>
    </>
  );
}

export const LeadsOverviewWithData = withLicenseData(LeadsOverview);

LeadsOverview.propTypes = {
  vendorFeatures: PropTypes.arrayOf(PropTypes.string),
};
