import { Button } from '@trustradius/radiant-ui';
import {
  CandlestickChart,
  ExternalLink,
  ListOrdered,
  TrendingUp,
  Trophy,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { ReviewGenerationCaseStudyContainer } from 'components/ReviewGenerationCaseStudy';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
} from 'components/SecondaryPage';
import { ReviewGenerationProductTable } from 'components/ReviewGenerationProductTable';
import { ReviewGenerationFooter } from 'components/ReviewGenerationFooter';
import styles from './ReviewGeneration.module.scss';

export function ReviewGeneration() {
  const products = useSelector(
    (state) => state.vendors.currentVendorProductsById,
  );

  const actionBlock = () => (
    <div className={styles['cta-block']}>
      <Button
        variant="secondary"
        size="large"
        url="https://www.trustradius.com/resources/assets/global-demo"
        targetBlank
      >
        Learn how TrustRadius can help <ExternalLink />
      </Button>
      <Button
        variant="primary"
        size="large"
        url="https://go.trustradius.com/rs/827-FOI-687/images/TrustRadius_Review-Growth-Kit.pdf"
        targetBlank
      >
        Review growth kit <ExternalLink />
      </Button>
    </div>
  );

  const keyBenefits = [
    {
      id: '1',
      icon: <CandlestickChart />,
      text: 'Market influence - each month over 1 million buyers visit TrustRadius',
    },
    {
      id: '2',
      icon: <ListOrdered />,
      text: 'Higher placement - show up as one of the first results in your category',
    },
    {
      id: '3',
      icon: <Trophy />,
      text: 'Award achievements - validate your most important claims and raise your visibility',
    },
    {
      id: '4',
      icon: <TrendingUp />,
      text: 'SEO lift - improve rankings of your web pages for high-priority search terms ',
    },
  ];
  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title="Generate Reviews"
        subtitle="Start using your non-incentivized campaign links to drive reviews!"
        actionBlock={actionBlock()}
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: (
              <KeyBenefits
                content={keyBenefits}
                title="Benefits of TrustRadius reviews"
              />
            ),
          },
        ]}
        slider={{
          title: 'TrustRadius customer spotlight',
          component: <ReviewGenerationCaseStudyContainer />,
        }}
        imageBanner={
          <>
            <ReviewGenerationProductTable
              products={Object.keys(products)
                ?.map((key) => products[key])
                ?.sort((productA, productB) => {
                  return productA?.name?.localeCompare(productB.name);
                })}
            />
            <ReviewGenerationFooter />
          </>
        }
      />
    </div>
  );
}
