import { useEffect, useState } from 'react';
import { getVendorIntentRegistration } from 'store/apis/productApi';

export const useIntentRegistration = (vendorId, partnerName, hasLicense) => {
  const [intentRegistration, setIntentRegistration] = useState({});
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function getIntentRegistration() {
      try {
        if (!hasLicense) {
          return;
        }
        setIsLoading(true);
        const response = await getVendorIntentRegistration(
          vendorId,
          partnerName,
        );

        setIntentRegistration(response?.data[0] || {});
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    getIntentRegistration();
  }, [vendorId, partnerName, hasLicense]);

  return { intentRegistration, hasError, isLoading };
};
