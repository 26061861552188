function TwitterCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <title>Twitter</title>
      <path
        d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm5.46,15.2v.37a8,8,0,0,1-12.32,6.74,4.92,4.92,0,0,0,.67,0,5.64,5.64,0,0,0,3.5-1.21,2.8,2.8,0,0,1-2.63-1.95,3,3,0,0,0,.53,0,2.64,2.64,0,0,0,.74-.1,2.82,2.82,0,0,1-2.26-2.76v0A2.87,2.87,0,0,0,13,16.7a2.82,2.82,0,0,1-.88-3.76,8,8,0,0,0,5.81,2.94,2.74,2.74,0,0,1-.08-.64,2.82,2.82,0,0,1,2.82-2.82,2.86,2.86,0,0,1,2.06.89,5.48,5.48,0,0,0,1.78-.68,2.79,2.79,0,0,1-1.24,1.56,5.48,5.48,0,0,0,1.62-.45A5.72,5.72,0,0,1,23.46,15.2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default TwitterCircle;
