import { useEffect, useState } from 'react';
import { getProductsFeaturesByVendor } from 'store/apis/productApi';

export const useHaveLicense = (vendorId, licenses) => {
  const [hasLicense, setHasLicense] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function checkHasLicense() {
      try {
        setIsLoading(true);
        const vendorLicenses = await getProductsFeaturesByVendor(vendorId);
        const filteredLicenses = licenses?.split(',')?.filter((license) => {
          return vendorLicenses?.data?.records?.includes(license);
        });

        const hasVendorLicense = filteredLicenses.length > 0;
        setHasLicense(hasVendorLicense);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    checkHasLicense();
  }, [vendorId, licenses]);

  return { hasLicense, hasError, isLoading };
};
