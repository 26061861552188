import { axiosRequestWithAuthToken } from './axios_utils';

export async function getLimaStatus({ vendorId }) {
  const response = await axiosRequestWithAuthToken({
    url: `/api/lima/status/${vendorId}`,
    method: 'GET',
  });

  return response.data;
}

export function authorizeLima({ code, redirectUrl, scopes }) {
  return axiosRequestWithAuthToken({
    url: '/api/lima/authorize',
    method: 'POST',
    data: { code, redirectUrl, scopes },
  });
}

export function unauthorizeLima() {
  return axiosRequestWithAuthToken({
    url: '/api/lima/unauthorize',
    method: 'POST',
  });
}

export function getLinkedInSegments(params) {
  return axiosRequestWithAuthToken({
    url: '/api/linkedin/dmp-segments',
    method: 'GET',
    params,
  });
}

export function getLinkedInSegmentsCompanies(params) {
  return axiosRequestWithAuthToken({
    url: '/api/linkedin/dmp-segments-companies',
    method: 'GET',
    params,
  });
}

export function getLinkedInAdAccounts() {
  return axiosRequestWithAuthToken({
    url: '/api/linkedin/dmp-segments/ad-accounts',
    method: 'GET',
  });
}

export function getLinkedInLicensedProductsCategories(params) {
  return axiosRequestWithAuthToken({
    url: '/api/linkedin/products-categories',
    method: 'GET',
    params,
  });
}

export function createLinkedInSegment(data) {
  return axiosRequestWithAuthToken({
    url: '/api/linkedin/dmp-segments',
    method: 'POST',
    data,
  });
}

export function deleteLinkedInSegment(segmentId) {
  return axiosRequestWithAuthToken({
    url: `/api/linkedin/dmp-segments/${segmentId}`,
    method: 'DELETE',
  });
}
