function TRFlag() {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61994 0.500061C9.09994 0.500061 8.62994 0.820061 8.44994 1.31006L7.24994 4.50006H11.7699C11.9199 4.50006 12.0599 4.56006 12.1699 4.66006L14.8399 7.33006C14.9399 7.44006 14.9999 7.58006 14.9999 7.73006V20.2801C14.9999 20.4501 14.7999 20.5301 14.6799 20.4101L12.1599 17.8901C12.0599 17.7901 11.9999 17.6501 11.9999 17.5001V7.50006H6.12994L0.129943 23.4901C-0.0500571 23.9801 0.309943 24.5001 0.829943 24.5001H26.3799C26.8999 24.5001 27.3699 24.1801 27.5499 23.6901L28.1299 22.1401L19.4999 13.5001L21.3199 11.6801C21.3599 11.6401 21.3999 11.6301 21.4499 11.6301H23.9999V7.50006H19.4999V13.5001V17.5001C19.4999 17.6501 19.4399 17.7901 19.3399 17.9001L16.8199 20.4201C16.6999 20.5401 16.4999 20.4501 16.4999 20.2901V7.73006C16.4999 7.58006 16.5599 7.44006 16.6599 7.33006L19.3299 4.66006C19.4399 4.56006 19.5799 4.50006 19.7299 4.50006H23.7599C23.9099 4.50006 24.0499 4.56006 24.1599 4.66006L26.8299 7.33006C26.9399 7.44006 26.9999 7.58006 26.9999 7.73006V11.3901C26.9999 11.5401 26.9399 11.6801 26.8399 11.7901L24.1899 14.4401L29.1599 19.4101L35.8699 1.51006C36.0499 1.02006 35.6899 0.500061 35.1699 0.500061H9.61994Z"
        fill="#116BF2"
      />
    </svg>
  );
}

export default TRFlag;
