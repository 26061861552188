function SortDescending(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H4ZM3 12C3 12.5523 3.44772 13 4 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H4C3.44772 11 3 11.4477 3 12ZM3 7C3 7.55229 3.44772 8 4 8H20C20.5523 8 21 7.55229 21 7C21 6.44771 20.5523 6 20 6H4C3.44772 6 3 6.44771 3 7Z"
        fill="#21B1FF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="18"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 18C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H4ZM3 12C3 12.5523 3.44772 13 4 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H4C3.44772 11 3 11.4477 3 12ZM3 7C3 7.55229 3.44772 8 4 8H20C20.5523 8 21 7.55229 21 7C21 6.44771 20.5523 6 20 6H4C3.44772 6 3 6.44771 3 7Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
}

export default SortDescending;
