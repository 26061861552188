import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { SearchField } from 'components/SearchField';
import { SearchResult } from 'components/SearchResult';
import { Arrow } from 'assets/icons';
import styles from './ProductSwitcher.module.scss';

export function ProductSwitcher({ product, products, handleProductChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const isDesktop = useMedia('(min-width:901px)');

  const filteredProducts = useMemo(() => {
    if (!searchFilter) {
      return products;
    }

    return products.filter((option) => {
      return option.name.toLowerCase().includes(searchFilter.toLowerCase());
    });
  }, [searchFilter, products]);

  const subscribedProducts = useMemo(() => {
    return filteredProducts.filter((prod) => prod.isSubscribed);
  }, [filteredProducts]);

  const unsubscribedProducts = useMemo(() => {
    return filteredProducts.filter((prod) => !prod.isSubscribed);
  }, [filteredProducts]);

  const handleSearch = (value) => {
    setSearchFilter(value);
  };

  const handleChange = (event) => {
    setIsOpen(false);
    handleProductChange(event);
  };

  const openModal = (event) => {
    event.stopPropagation();
    setSearchFilter('');
    setIsOpen(true);
  };

  const closeModal = (event) => {
    if (event.target.closest('#productSwitcher')) {
      // noop
      return;
    }

    setSearchFilter('');
    setIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className={styles.productButton}
        onClick={openModal}
      >
        <h1 className={styles.productName} title={product.name}>
          {product.name}
        </h1>
        <span className={styles.productSwitcherChevron}>
          <Arrow
            className={classNames(styles.arrowBlack, {
              [styles.arrowBlackReversed]: isOpen,
            })}
          />
        </span>
      </button>
      {isOpen && (
        // @TODO [TECHX-67] - Fix a11y violation
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={styles.overlay}
          onClick={closeModal}
          data-testid="modal"
        >
          <div
            className={classNames(styles.productSwitcherContainer, {
              [styles.notDesktop]: !isDesktop,
            })}
            id="productSwitcher"
          >
            <div className={styles['search-wrapper']}>
              <SearchField
                placeholder="Search Products"
                id="product_switcher"
                onChange={handleSearch}
                className={styles.search}
              />
            </div>
            <div className={styles.separator} />
            <div className={styles.searchResults}>
              {filteredProducts.length === 0 && (
                <div className={styles.empty}>No Results</div>
              )}
              <div className={styles.searchResultsContainer}>
                <SearchResult
                  options={subscribedProducts}
                  title="Subscribed Products"
                  filter={searchFilter}
                  onChange={handleChange}
                  selected={product._id}
                  additionalStyles={styles.options}
                />
                <SearchResult
                  options={unsubscribedProducts}
                  title="Not Subscribed Products"
                  filter={searchFilter}
                  onChange={handleChange}
                  selected={product._id}
                  additionalStyles={styles.options}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ProductSwitcher.propTypes = {
  product: PropTypes.object,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      slug: PropTypes.string,
      name: PropTypes.string.isRequired,
      vendorName: PropTypes.string,
      description: PropTypes.string,
      isSubscribed: PropTypes.bool,
      changeInfo: PropTypes.shape({
        status: PropTypes.string,
        product: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  handleProductChange: PropTypes.func,
};
