import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import { GeneralAwardCard } from '../GeneralAwardCard';

export function TopRatedAwardCard() {
  const awardCardProps = {
    name: 'Top Rated',
    description: (
      <>
        Showcases today’s best products based solely on user feedback and
        satisfaction scores.
      </>
    ),
    linkProps: {
      to: '../awards/top-rated',
      relative: 'path',
      children: 'View progress',
    },
  };

  const data = useLoaderData();
  return (
    <Suspense fallback={<GeneralAwardCard {...awardCardProps} isLoading />}>
      <Await
        resolve={data.topRatedSummary}
        errorElement={<GeneralAwardCard {...awardCardProps} />}
      >
        {(topRatedSummary) => (
          <>
            {' '}
            <GeneralAwardCard
              {...awardCardProps}
              badge={{
                src: `https://static.trustradius.com/images/awards/top_rated.png`,
                alt: `Top Rated ${topRatedSummary.awardYear}`,
              }}
              values={[
                {
                  id: 'products-on-track',
                  value: topRatedSummary.summary.successful,
                  title: 'Products On Track',
                },
                {
                  id: 'products-behind',
                  value: topRatedSummary.summary.unsuccessful,
                  title: 'Products Behind',
                },
              ]}
            />
            {console.log('topRatedSummary', topRatedSummary)}
          </>
        )}
      </Await>
    </Suspense>
  );
}
