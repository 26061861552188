import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { Body } from 'components/DashCard';
import { WelcomeDesktop, WelcomeMobile } from './assets';
import styles from './TRMessage.module.scss';

export function TRMessage({ children }) {
  const isDesktop = useMedia('(min-width:599px)');

  return (
    <>
      <div
        className={classNames(styles.banner, { [styles.mobile]: !isDesktop })}
      >
        <picture>
          <source
            media="(max-width: 599px)"
            srcSet={`${WelcomeMobile} 600w, ${WelcomeMobile} 1200w`}
          />
          <img
            className={styles.img}
            src={WelcomeDesktop}
            srcSet={`${WelcomeDesktop} 729w, ${WelcomeDesktop} 1458w`}
            alt="welcome"
          />
        </picture>
      </div>
      <Body className={styles.body}>{children}</Body>
    </>
  );
}

TRMessage.propTypes = {
  children: PropTypes.node.isRequired,
};
