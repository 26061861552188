import {
  REMOVE_PRODUCT_ERROR,
  REMOVE_VENDOR_ERROR,
  REMOVE_VENDOR_WATCHER_ERROR,
  SET_PRODUCT_ERROR,
  SET_VENDOR_ERROR,
  SET_VENDOR_WATCHER_ERROR,
} from '../actions/errorsActions';

const initialState = {
  vendor_info_error: false,
  competitors_error: false,
  vendor_error: false,
  product_error: false,
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENDOR_WATCHER_ERROR:
      return {
        ...state,
        vendor_info_error: true,
      };
    case REMOVE_VENDOR_WATCHER_ERROR:
      return {
        ...state,
        vendor_info_error: false,
      };
    case SET_VENDOR_ERROR:
      return {
        ...state,
        vendor_error: true,
      };
    case REMOVE_VENDOR_ERROR:
      return {
        ...state,
        vendor_error: false,
      };
    case SET_PRODUCT_ERROR:
      return {
        ...state,
        product_error: true,
      };
    case REMOVE_PRODUCT_ERROR:
      return {
        ...state,
        product_error: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default errors;
