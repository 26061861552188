import classNames from 'classnames';
import { Button } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import styles from 'pages/LeadFormConfiguration/LeadFormConfiguration.module.scss';

export function Footer({
  isDesktop,
  setShowPreview,
  setShowDeleteModal,
  setShowNoProductSelectedModal,
  selectedProducts,
}) {
  return (
    <div
      className={classNames(styles.footer, {
        [styles['footer-mobile']]: !isDesktop,
      })}
    >
      <div className={styles.placeholder} />
      <div className={styles['footer-buttons']}>
        <Button
          size="large"
          variant="no-border"
          onClick={() => setShowPreview(true)}
        >
          Back to all forms
        </Button>

        <div className={styles.rightblock}>
          <Button
            size="large"
            variant="primary-warning"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              if (Object.keys(selectedProducts).length === 0) {
                setShowNoProductSelectedModal(true);
              }
            }}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  isDesktop: PropTypes.bool,
  setShowPreview: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  setShowNoProductSelectedModal: PropTypes.func,
  selectedProducts: PropTypes.object,
};
