import { useState } from 'react';
import { axiosRequestWithAuthToken } from 'store/apis/axios_utils';

export const useAddZoominfoApiKey = () => {
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  async function saveZoominfoKey(vendorId, apiKey) {
    setIsLoading(true);
    try {
      setHasError(false);
      setStatus(null);
      const response = await axiosRequestWithAuthToken({
        method: 'PUT',
        url: `/api/vendors/${vendorId}/zoominfo`,
        data: { apiKey },
      });

      if (response.status === 200) {
        setStatus('success');
      }
      return response;
    } catch (error) {
      setHasError(true);
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  }

  return { status, saveZoominfoKey, isLoading, hasError };
};
