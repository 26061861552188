import { useSelector } from 'react-redux';
import { MousePointer, UserCheck, Lightbulb } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { ScreenSpaceSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { ScreenSpaceBanner } from './assets';
import styles from './Screenspace.module.scss';

export function ScreenSpace() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Create an immersive demo experience that helps your buyers fall in love with your product.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'ScreenSpace',
    logo: (
      <img
        className={styles.squareLogo}
        src={ScreenSpaceSquareLogo}
        alt="ScreenSpace"
      />
    ),
    subtitle: subTitle,
    actionBlock: (
      <Button
        size="large"
        url="https://www.screenspace.io/?ref=TrustRadius"
        targetBlank
      >
        Create a free demo
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: 'ability',
      icon: <MousePointer />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: 'tailor',
      icon: <UserCheck />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: 'insights',
      icon: <Lightbulb />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in.',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader {...headerProps} hasShadow />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={ScreenSpaceBanner}
            srcSet={`${ScreenSpaceBanner} 1x`}
            alt="ScreenSpace: Help your buyers fall in love with your product"
            className={styles.banner}
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About ScreenSpace</Text>
          <Text as="p">
            How often do you lose interest in a product simply because of a
            complex sales process? You&rsquo;re not alone &mdash; 75% of buyers
            also reject that rusty, sellerdriven process. Including yours!
          </Text>

          <Text as="p">
            Imagine if that buying experience was like a video game. That hooks
            you into an immersive web experience. Where you get to select your
            persona, industry, or use case. And choose your own adventure
            through a story that matters to you. A story that immerses you in
            the world of the product. And maintains your focus and excitement.
            Until you not only discover value. You experience it.
          </Text>

          <Text as="p">
            Now, imagine if you offered this immersive buying experience. Where
            each one of your buyers get to experience your product&rsquo;s value
            faster and deeper. On their terms. And you get to engage more,
            convert more, and win more. On your terms. Eliminating lost
            opportunities due to the sales process alone.
          </Text>

          <Text as="p">
            ScreenSpace makes this journey just as fast and simple for you, as
            it is for your buyers. Spin up an immersive product Story in
            minutes. Engage your buyers in seconds. Simplify your life in a
            moment.
          </Text>

          <Text as="p">
            It&rsquo;s not a demo. It&rsquo;s not a video. It&rsquo;s an
            experience.
          </Text>
          <Text as="h3">ScreenSpace and TrustRadius</Text>
          <Text as="p">
            With the ScreenSpace / TrustRadius partnership, your buyers not only
            get to explore your product. They get to experience value &mdash;
            right on your profile page.
          </Text>

          <Text as="p">
            Attract quality buyers through TrustRadius. Engage and convert them
            through ScreenSpace.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Deliver simplicity, speed & flexibility</Text>
          <Text as="p">
            Your buyers won&rsquo;t wait for a live demo. Provide a self-service
            journey so they can make fast and informed decisions in minutes
            &mdash; not weeks.
          </Text>
          <Text as="h3">Connect those dots for every single buyer</Text>
          <Text as="p">
            Your buyers don&rsquo;t trust your product&rsquo;s value. Give them
            tangible proof by presenting value in the context of your product.
          </Text>
          <Text as="h3">Inspire excitement, passion and loyalty</Text>
          <Text as="p">
            Showcase your product in a way that instantly grabs your
            audience&rsquo;s attention and retains it indefinitely.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
