import { useMemo, useState } from 'react';
import _ from 'underscore';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { Body } from 'components/DashCard';
import { ProgressItem } from './ProgressItem';
import { CategoryFilter } from './CategoryFilter';
import { YearFilter } from './YearFilter';
import styles from './AwardsProgress.module.scss';

function AwardsProgressImplementation({ awardTracks, product }) {
  const isDesktop = useMedia('(min-width:901px)');
  const awards = useMemo(() => {
    if (!awardTracks) {
      return [];
    }

    const filteredAwards = awardTracks
      .filter((awardTrack) => {
        const calculatedEndDate = new Date(awardTrack.award.calculatedEndDate);

        // 2022 is the year tracking started
        return calculatedEndDate.getFullYear() >= 2022;
      })
      .map((awardTrack) => awardTrack.award);

    const uniqueAwards = _.uniq(filteredAwards, false, (award) => award._id);

    return _.sortBy(uniqueAwards, (award) => award.calculatedEndDate).reverse();
  }, [awardTracks]);

  const now = moment();

  function getTracksForAward(award) {
    const tracksForAward = awardTracks.filter(
      (awardTrack) => awardTrack.award._id === award._id,
    );

    if (now.isAfter(award.calculatedEndDate)) {
      return tracksForAward;
    }

    // For current awards pick only categories currently assigned to product
    const categorySet = new Set(product.categories);

    return tracksForAward.filter((awardTrack) =>
      categorySet.has(awardTrack.category._id),
    );
  }

  function extractCategories(tracksForAward) {
    const trackedCategories = tracksForAward.map(
      (awardTrack) => awardTrack.category,
    );

    return _.partition(
      trackedCategories,
      (category) => category._id === product.primaryCategory._id,
    );
  }

  function getAwardCategories(award) {
    const [primaryCategories, secondaryCategories] = extractCategories(
      getTracksForAward(award),
    );

    return [...primaryCategories, ...secondaryCategories];
  }

  const [selectedAward, setSelectedAward] = useState(() => {
    const [activeAwards, inactiveAwards] = _.partition(
      awards,
      (award) => moment(award.calculatedEndDate).diff(now, 'months') > -1,
    );

    return activeAwards[activeAwards.length - 1] ?? inactiveAwards[0];
  });

  const [selectedCategory, setSelectedCategory] = useState(() => {
    const [category] = getAwardCategories(selectedAward);

    return category;
  });

  const tracksForAward = getTracksForAward(selectedAward);

  const [[primaryCategory], categories] = extractCategories(tracksForAward);

  const selectedAwardTrack = tracksForAward.find(
    (awardTrack) => awardTrack.category._id === selectedCategory._id,
  );

  const {
    recencyProgress,
    isRecencyCriteriaMet,
    ratingProgress,
    isRatingCriteriaMet,
    relevancyProgress,
    isRelevancyCriteriaMet,
  } = useMemo(() => {
    if (!selectedAwardTrack) {
      return {
        recencyProgress: 0,
        isRecencyCriteriaMet: false,
        ratingProgress: 0,
        isRatingCriteriaMet: false,
        relevancyProgress: 0,
        isRelevancyCriteriaMet: false,
      };
    }

    const roundedRatingProgress = Number(
      selectedAwardTrack.ratingProgress.toFixed(1),
    );

    const calculatedRelevancyProgress =
      (selectedAwardTrack.categoryTrafficProgress
        ? selectedAwardTrack.productTrafficProgress /
          selectedAwardTrack.categoryTrafficProgress
        : 0) * 100;
    const roundedRelevancyProgress = Number(
      calculatedRelevancyProgress.toFixed(2),
    );

    return {
      recencyProgress: selectedAwardTrack.recencyProgress,
      isRecencyCriteriaMet:
        selectedAwardTrack.recencyProgress >= selectedAward.recencyCriteria,
      ratingProgress: roundedRatingProgress,
      isRatingCriteriaMet:
        roundedRatingProgress >= selectedAward.ratingCriteria,
      relevancyProgress: roundedRelevancyProgress,
      isRelevancyCriteriaMet:
        roundedRelevancyProgress >= selectedAward.relevancyCriteria,
    };
  }, [selectedAwardTrack, selectedAward]);

  const isWinner =
    now.isAfter(selectedAward.calculatedEndDate) &&
    isRecencyCriteriaMet &&
    isRatingCriteriaMet &&
    isRelevancyCriteriaMet;

  return (
    <div className={styles.container}>
      <Body>
        <>
          <h3>Your Top Rated awards status</h3>
          <p>
            Top Rated Awards showcase today’s best products and are based solely
            on user feedback and satisfaction scores. The review submission
            deadline is {moment(selectedAward.reviewCutoffDate).format('LL')}{' '}
            and winners will be publicly announced on{' '}
            {moment(selectedAward.publishedDate).format('LL')}.
          </p>
        </>
      </Body>
      <div className={styles['filter-container']}>
        {isWinner && (
          <div className={styles.winner}>
            Top Rated Winner!{' '}
            <span role="img" aria-label="celebrate emoji">
              🎉
            </span>
          </div>
        )}

        <div
          className={classNames(styles.filters, {
            [styles.notDesktop]: !isDesktop,
          })}
        >
          <CategoryFilter
            primaryCategory={primaryCategory}
            categories={categories}
            selectedCategory={selectedCategory}
            setCategory={setSelectedCategory}
          />
          <YearFilter
            awards={awards}
            selectedAward={selectedAward}
            setAward={(award) => {
              setSelectedAward(award);

              const awardCategories = getAwardCategories(award);

              setSelectedCategory((stateCategory) =>
                awardCategories.some(
                  (category) => category._id === stateCategory._id,
                )
                  ? stateCategory
                  : awardCategories[0],
              );
            }}
          />
        </div>
      </div>
      <div className={styles.progress}>
        {selectedAwardTrack && (
          <>
            <div
              className={classNames(styles['progress-header'], {
                [styles.notDesktop]: !isDesktop,
              })}
            >
              <div>Your Progress</div>
              <div>Top Rated</div>
            </div>
            <div className={styles['progress-body']}>
              <ProgressItem
                heading="Recency"
                description={`Products must have ${
                  selectedAward.recencyCriteria
                }+ new or updated reviews in the past 12 months. Reviews must be submitted by ${moment(
                  selectedAward.reviewCutoffDate,
                ).format('LL')}`}
                status={recencyProgress.toString()}
                threshold={selectedAward.recencyCriteria.toString()}
                item="Reviews"
                passed={isRecencyCriteriaMet}
              />
              <ProgressItem
                heading="Rating"
                description={`Products must have at least ${
                  Math.round(selectedAward.ratingCriteria) / 2
                } stars with a trScore of ${
                  selectedAward.ratingCriteria
                } or above`}
                status={ratingProgress.toString()}
                threshold={selectedAward.ratingCriteria.toString()}
                item="trScore"
                passed={isRatingCriteriaMet}
              />
              <ProgressItem
                heading="Relevancy"
                description={`Products must receive at least ${selectedAward.relevancyCriteria}% of the traffic volume in that category`}
                status={`${relevancyProgress}%`}
                threshold={`${selectedAward.relevancyCriteria}%`}
                item="Traffic Volume"
                passed={isRelevancyCriteriaMet}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const propTypes = {
  awardTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  product: PropTypes.object.isRequired,
};

AwardsProgressImplementation.propTypes = propTypes;

export function AwardsProgress({ awardTracks, product }) {
  // Component doesn't really support empty `awardTracks` array.
  // Also, it is probably better to handle such case in the parent component.
  if (awardTracks.length === 0) {
    return null;
  }

  return (
    <AwardsProgressImplementation awardTracks={awardTracks} product={product} />
  );
}

AwardsProgress.propTypes = propTypes;
