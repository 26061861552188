import React from 'react';
import ReactBootstrapButton from 'react-bootstrap/Button';
import classNames from 'classnames';
import './Button.scss';

/**
 * TrustRadius wrapped button component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Button = React.forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapButton
      ref={ref}
      className={classes}
      bsPrefix="button"
      {...otherProps}
    >
      {children}
    </ReactBootstrapButton>
  );
});

Button.displayName = 'Button';
// eslint-disable-next-line react/forbid-foreign-prop-types
Button.propTypes = ReactBootstrapButton.propTypes;
