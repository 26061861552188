import { Users, Zap, ListOrdered, DollarSign, Building } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '@trustradius/radiant-ui';
import {
  SecondaryPageHeader,
  TextBanner,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  Prerequisites,
  UseCases,
} from 'components/SecondaryPage';
import { TrustradiusApiKey } from 'components/TrustradiusApiKey';
import { DemandbaseSquareLogo } from 'assets/logos-companies';
import { getApiKey } from 'store/apis/vendorApi';
import { getProductsFeaturesByVendor } from 'store/apis/productApi';
import { demandBaseBanner, demandBaseBanner2x } from './assets';
import styles from './DemandBase.module.scss';

export function DemandBase() {
  const { vendorId } = useParams();
  const vendor = useSelector((state) => state.vendors.currentVendor);
  const [apiKey, setApiKey] = useState();
  const [isVendorHasLicense, setIsVendorHasLicense] = useState(true);

  useEffect(() => {
    async function initialSetApiKey() {
      const vendorApiKey = await getApiKey(vendorId);
      if (vendorApiKey && vendorApiKey.data) {
        setApiKey(vendorApiKey.data);
      }
    }

    initialSetApiKey();
  }, [vendorId]);

  useEffect(() => {
    async function checkHasLicense() {
      const neededLicenses = [
        'api-free-intent-comparisons',
        'api-vi-category-level',
        'api-vi-product-level',
      ];
      const vendorLicenses = await getProductsFeaturesByVendor(vendorId);

      const licenses = neededLicenses.filter((license) => {
        return vendorLicenses?.data?.records?.includes(license);
      });

      const hasLicense = licenses.length > 0;
      setIsVendorHasLicense(hasLicense);
    }

    checkHasLicense();
  }, [vendorId]);

  const subTitle =
    "Orchestrate seamless B2B buyer journeys using Demandbase's GTM platform.";

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Demandbase',
    logo: <img src={DemandbaseSquareLogo} alt="Demandbase" />,
    subtitle: subTitle,
    actionBlock: <TrustradiusApiKey apiKey={apiKey} />,
  };

  const withoutIntentMessage = () => {
    return (
      <Text as="p">
        This integration requires TrustRadius intent data.
        <a
          href="https://www.trustradius.com/resources/assets/global-demo"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Activate your free product comparison intent data
        </a>
        .
      </Text>
    );
  };

  const keyBenefits = isVendorHasLicense
    ? [
        {
          id: 'buyers',
          icon: <Users />,
          text: 'Discover new high-value in-market buyers',
        },
        {
          id: 'intent',
          icon: <Zap />,
          text: 'Enhance your engagement model by weighting TrustRadius intent activities and surfacing accounts that are showing the most intent',
        },
        {
          id: 'engagement',
          icon: <ListOrdered />,
          text: 'Use engagement minutes to prioritize high-value accounts',
        },
        {
          id: 'deals',
          icon: <DollarSign />,
          text: 'Reach out to your accounts at the right time to accelerate deals',
        },
      ]
    : [
        {
          id: 'segments',
          icon: <Users />,
          text: 'Build audience segments of companies showing purchase intent for your products and categories.',
        },
        {
          id: 'intent',
          icon: <Zap />,
          text: 'Enhance your engagement model by weighting TrustRadius intent activities and surfacing accounts that are showing the most intent',
        },
        {
          id: 'ad-spend',
          icon: <Building />,
          text: 'Optimize your ad spend by targeting in-market companies in your ICP',
        },
        {
          id: 'deals',
          icon: <DollarSign />,
          text: 'Reach out to your accounts at the right time to accelerate deals',
        },
      ];

  const prerequisites = [
    {
      id: 'subscriptions',
      componentContent: (
        <>
          An intent data license is required to unlock this feature.&nbsp;
          <a
            href="https://www.trustradius.com/vendor/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      ),
    },
    {
      id: 'platform',
      componentContent: (
        <>This integration is available on the Demandbase One platform only.</>
      ),
    },
  ];

  const installation = [
    {
      id: 'video',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/articles/43000662191-demo-setting-up-the-trustradius-integration-in-demandbase-one"
          target="_blank"
          rel="noopener noreferrer"
        >
          Video: Set up your TrustRadius integration in Demandbase One
        </a>
      ),
    },
    {
      id: 'instruction',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/articles/43000662124-set-up-your-trustradius-integration-in-demandbase"
          target="_blank"
          rel="noopener noreferrer"
        >
          Installation instructions
        </a>
      ),
    },
  ];

  const useCases = ['Account Based Marketing (ABM)'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      {!isVendorHasLicense && <TextBanner>{withoutIntentMessage()}</TextBanner>}
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'prerequisites',
            element: <Prerequisites content={prerequisites} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={demandBaseBanner}
            srcSet={`${demandBaseBanner} 1x, ${demandBaseBanner2x} 2x`}
            alt="DemandBase"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Demandbase</Text>
          <Text as="p">
            Demandbase One is a go-to-market platform redefining how B2B
            companies execute their account-based strategies. Demandbase&apos;s
            Smarter GTM™ company for B2B brands helps marketing and sales teams
            overcome the crippling data and technology fragmentation that
            inhibits insight and forces them to spam their prospects. They do
            this by injecting account intelligence into every step of the buyer
            journey and by helping B2B marketers and sellers orchestrate every
            action across their systems and channels—through advertising,
            account-based experience, and sales motions.
          </Text>
          <Text as="h3">Demandbase and TrustRadius</Text>
          <Text as="p">
            Through TrustRadius&apos; downstream intent data integration with
            Demandbase One&apos;s AI and machine-learning capabilities,
            marketers can segment, target, and direct sales efforts to the
            accounts that will most likely convert to pipeline and ultimately
            become valuable customers.
          </Text>
          <Text as="h3">With TrustRadius and Demandbase, you can:</Text>
          <ul>
            <Text as="li">
              Create account lists of companies showing specific intent for your
              product, your competitors, or your category
            </Text>
            <Text as="li">
              Combine TrustRadius intent with other intent sources and
              Demandbase filters to refine your account lists
            </Text>
            <Text as="li">
              Score intent activities with engagement minutes to prioritize
              which accounts are showing the most intent
            </Text>
            <Text as="li">
              Get deal intelligence of account engagement on TrustRadius for
              your sales team to action on
            </Text>
          </ul>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Discover new high-value in-market buyers</Text>
          <Text as="p">
            Create dynamic account lists of companies that are showing intent
            about your product, your competitors&apos;, or your category on
            TrustRadius. You can also combine TrustRadius intent with other
            intent sources and Demandbase filters to create more refined account
            lists.
          </Text>
          <Text as="h3">Power your ABM strategy</Text>
          <Text as="p">
            Improve conversions by reaching out at the right time with the right
            messaging to in-market accounts across your marketing campaigns and
            sales outreach.
          </Text>
          <Text as="h3">
            Use engagement minutes to prioritize high-value accounts
          </Text>
          <Text as="p">
            With Demandbase&apos;s engagement minutes, you can score intent
            activities to easily prioritize accounts that are engaging with
            TrustRadius and are more likely to make a purchase decision.
          </Text>
          <Text as="h3">
            Access deal intelligence to increase win rates and retention
          </Text>
          <Text as="p">
            You can see who is looking at pricing, FAQs, product alternatives,
            feature scorecards, and reviews, then act quickly to convert those
            buyers. Also, identify upsell, cross-sell, and churn risk when
            active customers are researching new use cases and products to
            ensure you grow and keep your customers.
          </Text>
        </section>
        {!isVendorHasLicense && (
          <section>
            <Text as="h2">Why subscribe to TrustRadius intent data?</Text>
            <Text as="p" className={styles['top-indent']}>
              With TrustRadius intent, you can gain more insights into qualified
              accounts who are likely to make a purchasing decision. You can
              leverage this data to acquire new leads, prioritize known accounts
              and personalize your outreach.
            </Text>
          </section>
        )}
      </SecondaryPageContent>
    </>
  );
}
