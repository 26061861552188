import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VendorInfo } from 'components/VendorInfo';
import { Skeleton } from 'components/Skeleton';
import { fetchWatchersByProductId } from 'store/apis/watchersApi';
import styles from './VendorInfoContainer.module.scss';

export function VendorInfoContainer() {
  const maxWatchers = 4;
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );

  const watchers = useSelector((state) => state.watchers);
  const hasError = useSelector((state) => state.watchers?.error);
  const timeFrame = useSelector((state) => state.globalState.timeFrame);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchWatchersByProductId(currentProduct._id, timeFrame)(dispatch);
      setIsLoading(false);
    };

    if (currentProduct?._id) {
      fetchData();
    }
  }, [currentProduct, timeFrame, dispatch]);

  return (
    <div className={styles.container}>
      {!isLoading &&
        !hasError &&
        watchers.allIds.length > 0 &&
        watchers.allIds
          .slice(0, maxWatchers)
          .map((productId) => (
            <VendorInfo
              name={watchers.byId[productId]?.name}
              industry={watchers.byId[productId]?.industry || ''}
              companySize={watchers.byId[productId]?.size || ''}
              city={watchers.byId[productId]?.city}
              countryShort={watchers.byId[productId]?.countryShort}
              regionShort={watchers.byId[productId]?.regionShort}
              domain={watchers.byId[productId]?.domain}
              key={productId}
            />
          ))}
      {!isLoading && !hasError && watchers.allIds.length === 0 && (
        <div className={styles.empty}>
          <p>No data</p>
        </div>
      )}
      {!isLoading && hasError && (
        <div className={styles.empty}>
          <p>
            Something went wrong.
            <br />
            Please refresh to try again.
          </p>
        </div>
      )}
      {isLoading && !hasError && <Skeleton className={styles.empty} />}
    </div>
  );
}
