import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function Assets({ content }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getList() {
    const list = (
      <ul>
        {content.map(({ id, listItem }) => (
          <Text as="li" key={id}>
            {listItem}
          </Text>
        ))}
      </ul>
    );

    if (!isTablet) {
      return list;
    } else if (isOpen) {
      return list;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          Assets
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getList()}
    </section>
  );
}

export const AssetsShape = PropTypes.shape({
  content: PropTypes.shape({
    id: PropTypes.number.isRequired,
    listItem: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
      .isRequired,
  }).isRequired,
});

Assets.propTypes = AssetsShape.isRequired;
