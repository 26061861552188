import {
  FETCH_WATCHERS_BY_VENDOR_ID_SUCCESS,
  FETCH_WATCHERS_BY_VENDOR_ID_FAILED,
  FETCH_WATCHERS_BY_VENDOR_ID_START,
} from '../actions/watchersActions';
import { normalizeState } from '../utils';

const initialState = {
  byId: {},
  allIds: [],
};

const watchers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHERS_BY_VENDOR_ID_START:
      return {
        ...state,
      };
    case FETCH_WATCHERS_BY_VENDOR_ID_SUCCESS:
      return {
        ...state,
        ...normalizeState(action.payload.watchers, 'accountId'),
      };
    case FETCH_WATCHERS_BY_VENDOR_ID_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default watchers;
