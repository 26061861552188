import axios from 'axios';

export const axiosRequestWithAuthToken = (options = {}) => {
  const authToken = window.sessionStorage.authToken;

  if (!authToken) {
    throw new Error('no token provided');
  }

  if (!options.headers) {
    options.headers = {};
  }

  if (!options?.headers?.Authorization) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }
  return axios(options);
};

export const axiosPostWithAuthToken = (options = {}, body = {}) => {
  const authToken = window.sessionStorage.authToken;

  if (!authToken) {
    throw new Error('no token provided');
  }

  if (!options.headers) {
    options.headers = {};
  }

  if (!options?.headers?.Authorization) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }

  return axios.post(options.url, body, options);
};

const axiosUtils = {
  axiosRequestWithAuthToken,
};

export default axiosUtils;
