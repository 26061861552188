import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSettings } from 'settings';
import { authorizeLima } from 'store/apis/limaApi';

const LIMA_REDIRECT_PATH = '/linkedin/callback-marketing';

const redirectUrl = `${window.location.origin}${LIMA_REDIRECT_PATH}`;

const LINKEDIN_AUDIENCE_BUILDER_SCOPES = [
  'r_emailaddress',
  'r_basicprofile',
  'r_ads',
  'rw_ads',
  'rw_dmp_segments',
  'r_ads_reporting',
];

export function useNavigateToLinkedIn() {
  const settings = useSettings();

  const navigateToLinkedIn = useCallback(() => {
    const state = uuidv4();

    window.sessionStorage.setItem('lima:state', state);
    window.sessionStorage.setItem('lima:path', window.location.pathname);

    window.location.assign(
      `https://www.linkedin.com/oauth/v2/authorization?${new window.URLSearchParams(
        {
          response_type: 'code',
          client_id: settings.limaApiKey,
          redirect_uri: redirectUrl,
          state,
          scope: LINKEDIN_AUDIENCE_BUILDER_SCOPES.join(' '),
        },
      )}`,
    );
  }, [settings]);

  return navigateToLinkedIn;
}

async function validateAndAuthorizeLima() {
  const storedState = window.sessionStorage.getItem('lima:state');
  const path = window.sessionStorage.getItem('lima:path');

  window.sessionStorage.removeItem('lima:state');
  window.sessionStorage.removeItem('lima:path');

  if (!path) {
    window.history.replaceState(null, '', '/vendor');
    return;
  }

  const searchParams = new window.URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  window.history.replaceState(null, '', path);

  if (code && state && state === storedState) {
    await authorizeLima({
      code,
      redirectUrl,
      scopes: LINKEDIN_AUDIENCE_BUILDER_SCOPES,
    });
  }
}

let isAppInitialized = false;

export function useAuthorizeLima() {
  const [isLimaAuthorizing, setIsLimaAuthorizing] = useState(
    () => !isAppInitialized && window.location.pathname === LIMA_REDIRECT_PATH,
  );

  useEffect(() => {
    if (isAppInitialized) {
      return;
    }

    isAppInitialized = true;

    async function authorize() {
      if (!isLimaAuthorizing) {
        return;
      }

      try {
        await validateAndAuthorizeLima();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLimaAuthorizing(false);
      }
    }

    authorize();
  }, [isLimaAuthorizing]);

  return isLimaAuthorizing;
}
