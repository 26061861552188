import { useNavigation, useParams, redirect } from 'react-router-dom';
import classNames from 'classnames';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { LimaCreateSegmentBody } from 'components/LinkedinMatchedAudiences';
import { getLimaStatus } from 'store/apis/limaApi';
import styles from './LinkedinCreateSegmentPage.module.scss';

export async function linkedinCreateSegmentPageLoader({ params }) {
  const { vendorId } = params;

  const limaStatus = await getLimaStatus({ vendorId }).catch(() => undefined);

  if (!limaStatus?.isAuthorized) {
    return redirect(`/vendor/${vendorId}/integrations/linkedin`);
  }

  return null;
}

export function LinkedinCreateSegmentPage() {
  const navigation = useNavigation();

  const { vendorId } = useParams();

  return (
    <div
      className={classNames(
        styles.page,
        navigation.state === 'loading' && styles.loading,
      )}
    >
      <SecondaryPageHeader
        backLinkProps={{
          to: '..',
          relative: 'path',
        }}
        title="Create Audience Segment"
      />
      <div className={styles['body-container']}>
        <LimaCreateSegmentBody vendorId={vendorId} />
      </div>
    </div>
  );
}
