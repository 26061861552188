import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { Logo, RatingStars } from 'components/TrustradiusUICore';
import styles from './Competitor.module.scss';

export function Competitor({ name, deltaPct, trScore, current, logos }) {
  const delta = useMemo(() => {
    if (!deltaPct) {
      return {
        icon: '',
        value: '0.0%',
        style: 'neutral',
      };
    }
    if (deltaPct > 0) {
      return {
        icon: <ArrowUpRight size={14} />,
        value: `${Number(deltaPct).toFixed(1)}%`,
        style: 'positive',
      };
    }
    if (deltaPct < 0) {
      return {
        icon: <ArrowDownRight size={14} />,
        value: `${Number(Math.abs(deltaPct)).toFixed(1)}%`,
        style: 'negative',
      };
    }
  }, [deltaPct]);

  return (
    <section className={styles.container}>
      <div className={styles.info}>
        <div className={styles['logo-container']}>
          <Logo logo={logos?.default} />
        </div>
        <header>
          <h3 className={styles.title}>{name}</h3>
          <RatingStars size="extraSmall" trScore={trScore} />
        </header>
      </div>
      <div className={styles.statistic}>
        <div className={styles.value} data-testid="current">
          {current}
        </div>
        <div
          className={classNames(styles.delta, styles[delta.style])}
          data-testid="delta"
        >
          {delta.icon}
          <span data-testid="delta-value">{delta.value}</span>
        </div>
      </div>
    </section>
  );
}

Competitor.propTypes = {
  name: PropTypes.string,
  deltaPct: PropTypes.number,
  trScore: PropTypes.number,
  current: PropTypes.number,
  logos: PropTypes.shape({
    default: PropTypes.string,
  }),
};
