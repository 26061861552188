import PropTypes from 'prop-types';

function Star(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.size || 24}
      viewBox="0 0 24 24"
      width={props.size || 24}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={props.color || 'currentColor'}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
    </svg>
  );
}

Star.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Star;
