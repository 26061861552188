import PropTypes from 'prop-types';
import { DashCard, Announcement } from 'components/DashCard';
import {
  interactiveDemosMobile,
  interactiveDemosMobile2x,
  interactiveDemosDesktop,
  interactiveDemosDesktop2x,
} from './assets';

export function InteractiveDemosCard({ product }) {
  return (
    <DashCard
      linkProps={{
        to: '../../integrations',
        relative: 'path',
        children: 'Learn more',
      }}
      product={product}
    >
      <Announcement
        mSrc={interactiveDemosMobile}
        mSrc2x={interactiveDemosMobile2x}
        src={interactiveDemosDesktop}
        src2x={interactiveDemosDesktop2x}
        alt="Let your product’s goodness speak for itself with interactive demos"
      >
        <h3>Try out Interactive Demos today!</h3>
        <p>
          Enable buyers to take a self-guided “tour” of your product, giving
          them a unique opportunity to explore and evaluate whether it’s a fit
          for them!
        </p>
      </Announcement>
    </DashCard>
  );
}

InteractiveDemosCard.propTypes = {
  product: PropTypes.object.isRequired,
};
