import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

export function Checkbox(props) {
  const { label, size, hideLabel, disabled, text, ...input } = props;
  const id = input.id || `${input.name}-${input.value}`;

  const labelElement = hideLabel ? (
    <label className={classNames(styles.label, styles[size])} htmlFor={id}>
      <span className={styles['hidden-label']}>{label}</span>
    </label>
  ) : (
    <label className={classNames(styles.label, styles[size])} htmlFor={id}>
      <span className={classNames(styles['label-text'], styles[size])}>
        {label}
      </span>
      {text && <span className={styles['label-sub-text']}>{text}</span>}
    </label>
  );

  return (
    <div className={classNames(styles.checkbox, styles[size])}>
      <input
        {...input}
        type="checkbox"
        id={id}
        disabled={disabled}
        className={classNames(styles.input, styles[size])}
      />
      {labelElement}
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.node,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
  hideLabel: PropTypes.bool,
};
