import ReactBootstrapDropdownItem from 'react-bootstrap/DropdownItem';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';

/**
 * TrustRadius wrapped DropdownItem component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function DropdownItem(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles['dropdown-item']);
  // Provide default variant
  return (
    <ReactBootstrapDropdownItem
      className={classes}
      bsPrefix="trDropdown-item"
      {...otherProps}
    >
      {children}
    </ReactBootstrapDropdownItem>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
DropdownItem.propTypes = ReactBootstrapDropdownItem.propTypes;
