import {
  ChevronDown,
  ChevronUp,
  Image,
  Briefcase,
  Globe2,
  Users,
  Factory,
  Mail,
  Phone,
  MessageCircle,
  ShieldCheck,
  Info,
} from 'lucide-react';
import { useState } from 'react';
import { Button, Text, Tooltip } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import styles from './FieldConfigurationContainer.module.scss';

export function FieldConfigurationContainer({
  id,
  text,
  iconType,
  tooltipText,
  titleButton,
  children,
  footer,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const icons = {
    brand: <Image className={styles.icon} />,
    position: <Briefcase className={styles.icon} />,
    country: <Globe2 className={styles.icon} />,
    companySize: <Users className={styles.icon} />,
    industry: <Factory className={styles.icon} />,
    zip: <Mail className={styles.icon} />,
    phone: <Phone className={styles.icon} />,
    message: <MessageCircle className={styles.icon} />,
    privacy: <ShieldCheck className={styles.icon} />,
  };

  return (
    <div className={styles['container-border']} key={id}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            variant="no-border"
            size="large"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className={styles.chevron}>
              {isOpen && <ChevronDown />}
              {!isOpen && <ChevronUp />}
            </span>
            {iconType && <Text className={styles.icon}>{icons[iconType]}</Text>}
            <Text>{text}</Text>
            {tooltipText && (
              <Tooltip content={tooltipText}>
                <Info size="18" />
              </Tooltip>
            )}
          </Button>
          {titleButton && (
            <div className={styles['title-button']}>{titleButton}</div>
          )}
        </div>
        {isOpen && children && <div className={styles.content}>{children}</div>}
        {isOpen && footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
}

FieldConfigurationContainer.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  iconType: PropTypes.string,
  tooltipText: PropTypes.string,
  titleButton: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
};
