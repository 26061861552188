import {
  Link,
  Outlet,
  useLoaderData,
  useParams,
  useNavigation,
  redirect,
} from 'react-router-dom';
import classNames from 'classnames';
import { MoreHorizontal } from 'lucide-react';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import {
  LimaSegmentsBody,
  AuthenticatedUsersSubtitle,
} from 'components/LinkedinMatchedAudiences';
import { Dropdown, Button } from 'components/TrustradiusUICore';
import { getLimaStatus } from 'store/apis/limaApi';
import { LinkedinLogo } from 'assets/logos-companies';
import styles from './LinkedinSegmentsPage.module.scss';

export async function linkedinSegmentsPageLoader({ params }) {
  const { vendorId } = params;

  const limaStatus = await getLimaStatus({ vendorId }).catch(() => undefined);

  if (!limaStatus?.isAuthorized) {
    return redirect(`/vendor/${vendorId}/integrations/linkedin`);
  }

  return limaStatus;
}

export function LinkedinSegmentsPage() {
  const limaStatus = useLoaderData();

  const navigation = useNavigation();

  const { vendorId } = useParams();

  return (
    <div
      className={classNames(
        styles.page,
        navigation.state === 'loading' && styles.loading,
      )}
    >
      <SecondaryPageHeader
        backLinkProps={{
          to: '..',
          relative: 'path',
        }}
        title="LinkedIn Matched Audiences"
        subtitle={
          <AuthenticatedUsersSubtitle
            users={limaStatus.linkedInAudienceBuilderUsers}
          />
        }
        logo={<img src={LinkedinLogo} alt="LinkedIn" />}
        actionBlock={
          <div className={styles['button-container']}>
            <Button as={Link} to="create" variant="primary-pill">
              Create segment
            </Button>
            <Dropdown className={styles.dropdown}>
              <Dropdown.Toggle className={styles['dropdown-toggle']}>
                <MoreHorizontal />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={styles['dropdown-menu']}
                popperConfig={{
                  modifiers: {
                    name: 'offset',
                    options: { offset: [0, 16] },
                  },
                }}
              >
                <Dropdown.Item
                  as={Link}
                  to=".."
                  relative="path"
                  className={styles['learn-more']}
                >
                  Learn more
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="unauthorize"
                  className={styles['remove-linkedin']}
                >
                  Remove LinkedIn account
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      />
      <div className={styles['body-container']}>
        <LimaSegmentsBody
          vendorId={vendorId}
          hasAnalyticsScopes={limaStatus.hasAnalyticsScopes}
        />
      </div>
      <Outlet />
    </div>
  );
}
