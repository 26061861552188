import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { ChevronDown } from 'lucide-react';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from './Filters.module.scss';

export function YearFilter({ awards, selectedAward, setAward }) {
  const notDesktop = useMedia('(max-width:900px)');
  return (
    <div
      role="group"
      className={classNames(styles.filter, { [styles.notDesktop]: notDesktop })}
      aria-labelledby="year_filter"
    >
      <div className={styles['filter-label']} id="year_filter">
        Year:
      </div>
      <Dropdown
        onSelect={(eventKey) => {
          setAward(awards.find((award) => award._id === eventKey));
        }}
      >
        <Dropdown.Toggle
          className={styles['filter-toggle']}
          variant="default"
          id="dropdown-basic"
        >
          <span className={styles['filter-toggle-text']}>
            {new Date(selectedAward?.calculatedEndDate).getFullYear()}
          </span>
          <ChevronDown />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles['dropdown-menu']} bsPrefix="tr">
          {awards.map((award) => (
            <Dropdown.Item
              eventKey={award._id}
              key={award._id}
              className={styles['dropdown-item-button']}
            >
              {new Date(award.calculatedEndDate).getFullYear()}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const AwardShape = PropTypes.shape({
  publishedDate: PropTypes.string.isRequired,
  calculatedStartDate: PropTypes.string.isRequired,
  calculatedEndDate: PropTypes.string.isRequired,
  reviewCutoffDate: PropTypes.string.isRequired,
  ratingCriteria: PropTypes.number.isRequired,
  recencyCriteria: PropTypes.number.isRequired,
  relevancyCriteria: PropTypes.number.isRequired,
  badgeUrl: PropTypes.shape({
    x1: PropTypes.string,
    x2: PropTypes.string,
  }),
  _id: PropTypes.string.isRequired,
});

YearFilter.propTypes = {
  awards: PropTypes.arrayOf(AwardShape.isRequired).isRequired,
  selectedAward: AwardShape.isRequired,
  setAward: PropTypes.func.isRequired,
};
