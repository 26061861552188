import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ChevronLeft } from 'lucide-react';
import styles from './AwardDetailsHeader.module.scss';

const darkTRBlue = '#1E50E5';

export function AwardDetailsHeader({
  className,
  backLink,
  awardInfo,
  content,
}) {
  return (
    <header
      id="award-details-header"
      className={classNames(styles.header, className)}
    >
      <div className={styles['award-name-wrapper']}>
        <Link className={styles['back-wrapper']} to={backLink}>
          <ChevronLeft color={darkTRBlue} />
        </Link>
        <div className={styles.separator} />
        {awardInfo}
      </div>
      {content && <div className={styles['header-metrics']}>{content}</div>}
    </header>
  );
}

AwardDetailsHeader.propTypes = {
  className: PropTypes.string,
  backLink: PropTypes.string.isRequired,
  awardInfo: PropTypes.node,
  content: PropTypes.node,
};
