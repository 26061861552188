import { Text, Button } from '@trustradius/radiant-ui';
import { ArrowRight } from 'lucide-react';
import PropTypes from 'prop-types';
import styles from './ReviewGenerationCaseStudy.module.scss';

export function ReviewGenerationCaseStudy({ caseStudy }) {
  return (
    <div className={styles.container}>
      {caseStudy?.imageHeader && (
        <>
          <img src={caseStudy?.imageHeader} alt="person info" />
        </>
      )}
      <div className={styles['content-container']}>
        <Text>{caseStudy?.quote}</Text>
        {caseStudy?.metrics && (
          <div className={styles.metrics}>
            {caseStudy?.metrics?.map((metric) => (
              <div key={metric.id} className={styles.metric}>
                <Text size="100" className={styles['metric-title']}>
                  {metric?.title}
                </Text>
                <Text size="500" weight="semibold">
                  {metric?.value}
                </Text>
              </div>
            ))}
          </div>
        )}
        {caseStudy?.caseStudyUrl && (
          <div className={styles.url}>
            <Button variant="null" url={caseStudy.caseStudyUrl} targetBlank>
              Read the full case study <ArrowRight />
            </Button>{' '}
          </div>
        )}
      </div>
    </div>
  );
}

ReviewGenerationCaseStudy.propTypes = {
  caseStudy: PropTypes.shape({
    id: PropTypes.string,
    imageHeader: PropTypes.string,
    quote: PropTypes.string,
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    caseStudyUrl: PropTypes.string,
  }),
};
