import { useSettings } from 'settings';
import { Hero, HeroButton, HeroLink } from 'components/Hero';
import { Main } from 'components/Main';
import { Header } from 'components/Header';

export function RequestAccess() {
  const settings = useSettings();

  return (
    <>
      <Header />
      <Main>
        <Hero
          title="Request access to the vendor portal"
          description="To gain access to the vendor portal you will need to claim your company’s profile."
        >
          <HeroButton
            text="Claim your profile"
            href={`${settings.buyerSiteUrl}/vendoradmin/request_access`}
          />
          <HeroLink
            text="Return to TrustRadius.com"
            href={settings.buyerSiteUrl}
          />
        </Hero>
      </Main>
    </>
  );
}
