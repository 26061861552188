import * as Dialog from '@radix-ui/react-dialog';
import { PropTypes } from 'prop-types';
import { X } from 'lucide-react';
import classNames from 'classnames';
import styles from './Modal.module.scss';

export function Modal({
  isOpen,
  icon,
  trigger,
  title,
  description,
  closeHandler,
  actionButtons,
  children,
  ...props
}) {
  return (
    <Dialog.Root open={isOpen}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Close asChild onClick={closeHandler}>
          <Dialog.Overlay className={styles.dialogOverlay} />
        </Dialog.Close>
        <Dialog.Content
          className={classNames(styles.dialogContent, props.className)}
        >
          <Dialog.Close asChild onClick={closeHandler}>
            <div className={styles.x}>
              <X />
            </div>
          </Dialog.Close>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title && <Dialog.Title>{title}</Dialog.Title>}
          {description && (
            <Dialog.Description className={styles.description}>
              {description}
            </Dialog.Description>
          )}
          <>{children}</>
          {actionButtons && (
            <div className={styles.actions}>
              {actionButtons.map((button) => (
                <Dialog.Close
                  asChild
                  key={button}
                  onClick={() => closeHandler()}
                >
                  {button}
                </Dialog.Close>
              ))}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  closeHandler: PropTypes.func,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  trigger: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};
