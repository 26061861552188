import { Text } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import styles from './LeadsHubEmptyState.module.scss';

export function LeadsHubEmptyState({
  icon,
  title,
  description,
  button,
  button2,
}) {
  return (
    <div className={styles['empty-state-container']}>
      <div className={styles.container}>
        {icon}
        <Text as="h2" size="300" weight="semibold">
          {title}
        </Text>
        <Text as="p">{description}</Text>
        <div className={styles['button-container']}>
          {button}
          {button2}
        </div>
      </div>
    </div>
  );
}

LeadsHubEmptyState.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
  button2: PropTypes.node,
};
