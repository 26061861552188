import { Button, Text } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import { CircleIcon } from 'components/CircleIcon';
import { Modal } from 'components/Modal';
import styles from './DeleteLeadFormModal.module.scss';

export function DeleteLeadFormModal({
  isShowDeleteModal,
  setIsShowDeleteModal,
}) {
  return (
    <Modal
      className={styles['delete-modal']}
      isOpen={isShowDeleteModal}
      icon={
        <div className={styles['delete-icon']}>
          <CircleIcon
            iconType="delete"
            color="#F44336"
            backgroundColor="#FFDEEA"
            backgroundColorFade="#FEEFF4"
          />
        </div>
      }
      title={
        <Text as="span" className={styles.gray120} weight="semibold" size="400">
          Delete lead form
        </Text>
      }
      description={
        <Text className={styles.gray100} data-testid="description">
          Are you sure you want to delete this lead form? Any product listings
          this form is applied to will no longer collect leads. Any webhooks
          that this lead form is applied to will also be deleted. This action
          cannot be undone.
        </Text>
      }
      closeHandler={() => setIsShowDeleteModal(false)}
      actionButtons={[
        <div key="1" className={styles['cancel-button']}>
          <Button variant="secondary" size="large">
            Cancel
          </Button>
        </div>,
        <div key="2" className={styles['delete-button']}>
          <Button variant="primary-warning" size="large">
            Delete
          </Button>
        </div>,
      ]}
    />
  );
}

DeleteLeadFormModal.propTypes = {
  isShowDeleteModal: PropTypes.bool.isRequired,
  setIsShowDeleteModal: PropTypes.func.isRequired,
};
