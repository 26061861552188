import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import ReactBootstrapOverlayTrigger from 'react-bootstrap/OverlayTrigger';
import classNames from 'classnames';
import InteractiveOverlayTrigger from './InteractiveOverlayTrigger';

/**
 * TrustRadius wrapped OverlayTrigger component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 *
 * Also added an interactive feature:
 * Interactive mode will attach the event handlers to both the trigger and the overlay
 * This provides a way to make the overlay stick while the use is visiting it.
 */
export const OverlayTrigger = forwardRef((props, ref) => {
  const { className, children, interactive, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);

  if (interactive) {
    return (
      <InteractiveOverlayTrigger {...otherProps} ref={ref}>
        {children}
      </InteractiveOverlayTrigger>
    );
  }

  // Provide default variant
  return (
    <ReactBootstrapOverlayTrigger ref={ref} className={classes} {...otherProps}>
      {children}
    </ReactBootstrapOverlayTrigger>
  );
});

OverlayTrigger.displayName = 'OverlayTrigger';
OverlayTrigger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Interactive mode will attach the event handlers to both the trigger and the overlay
   * This provides a way to make the overlay stick while the use is visiting it.
   */
  interactive: PropTypes.bool,
};
