import PropTypes from 'prop-types';
import { Check } from 'lucide-react';
import { Button } from '@trustradius/radiant-ui';
import { Modal, Celebrate, PaperAirplane } from '../Modal';
import styles from './LeadsWebhookFooter.module.scss';

export function LeadsWebhookFooter({
  isSaveModalOpen,
  setIsSaveModalOpen,
  isTestLeadModalOpen,
  setIsTestLeadModalOpen,
  discardChanges,
  sendTestLead,
  webhookId = '',
}) {
  const saveButtonText = isSaveModalOpen ? (
    <>
      <Check /> Saved
    </>
  ) : (
    'Save changes'
  );

  return (
    <footer className={styles.footer}>
      <div className={styles['test-actions']}>
        <Modal
          open={isTestLeadModalOpen}
          title="Sending your test lead"
          description="Please wait while we ensure your webhook is working correctly. This could take up to 30 seconds."
          image={<PaperAirplane />}
          onOpenChange={() => setIsTestLeadModalOpen(!isTestLeadModalOpen)}
          waiting
          trigger={
            <Button
              variant="tertiary"
              size="large"
              onClick={sendTestLead}
              isDisabled={!webhookId}
            >
              Send test Lead
            </Button>
          }
        />
      </div>
      <div className={styles['save-actions']}>
        <Modal
          title="Your changes will not be saved"
          description="Your changes will not be saved. Are you sure you want to discard your recent changes?"
          closeText="Cancel"
          actionText="Discard changes"
          onAction={discardChanges}
          warning
          trigger={
            <Button variant="secondary" size="large">
              Discard changes
            </Button>
          }
        />
        <Modal
          open={isSaveModalOpen}
          title="Changes saved!"
          description="Now try sending a test lead to make sure everything’s working correctly."
          image={<Celebrate />}
          actionText="Send test lead"
          onOpenChange={() => {
            if (isSaveModalOpen) {
              setIsSaveModalOpen(false);
            }
          }}
          onAction={sendTestLead}
          trigger={
            <Button size="large" type="submit">
              {saveButtonText}
            </Button>
          }
        />
      </div>
    </footer>
  );
}

LeadsWebhookFooter.propTypes = {
  isSaveModalOpen: PropTypes.bool.isRequired,
  setIsSaveModalOpen: PropTypes.func.isRequired,
  isTestLeadModalOpen: PropTypes.bool.isRequired,
  setIsTestLeadModalOpen: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
  sendTestLead: PropTypes.func.isRequired,
  webhookId: PropTypes.string,
};
