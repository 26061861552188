import {
  LinkedinLogo,
  DemandbaseLogo,
  SixsenseLogo,
  SalesforceLogo,
  InfluitiveLogo,
  WalnutLogo,
  NavatticLogo,
  WhisticLogo,
  StorylaneLogo,
  TourialLogo,
  TestboxLogo,
  Demostack,
  ScreenSpaceLogo,
  Intentsify,
  ZoominfoLogo,
} from 'assets/logos-companies';

export function getIntegrationCards(settings, vendorId) {
  return [
    {
      logo: LinkedinLogo,
      title: 'LinkedIn Matched Audiences',
      text: 'Connect your TrustRadius intent data with LinkedIn Campaign Manager to target and convert in-market buyers.',
      linkProps: {
        to: 'linkedin',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: ZoominfoLogo,
      title: 'ZoomInfo',
      text: 'Enrich your sales and marketing strategies by matching TrustRadius’ downstream intent data with ZoomInfo’s contact level data.',
      linkProps: {
        to: 'zoominfo',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: SixsenseLogo,
      title: '6sense',
      text: 'Leverage TrustRadius intent data in 6sense and target active, high-intent buyers researching your products, categories, or competitors.',
      linkProps: {
        to: '6sense',
        children: 'Learn more',
      },
    },
    {
      logo: DemandbaseLogo,
      title: 'Demandbase',
      text: 'Leverage TrustRadius intent data in Demandbase and target active, high-intent buyers researching your products, categories, or competitors.',
      linkProps: {
        to: 'demandbase',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: SalesforceLogo,
      title: 'Salesforce',
      text: 'Enrich your sales pipeline, optimize your ABM strategy and mitigate churn risks by integrating TrustRadius intent data with Salesforce.',
      linkProps: {
        to: 'salesforce',
        children: 'Learn more',
      },
    },
    {
      logo: Intentsify,
      title: 'Intentsify',
      text: 'Enrich your sales and marketing strategies by integrating TrustRadius’ downstream intent data with Intentsify’s Intelligence Activation Platform.',
      linkProps: {
        to: 'intentsify',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: InfluitiveLogo,
      title: 'Influitive',
      text: 'Incentivize your best customer advocates to leave reviews of your products on TrustRadius.',
      linkProps: {
        to: '',
        external: `${settings.legacyBaseUrl}/settings/${vendorId}/influitive`,
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: WhisticLogo,
      title: 'Whistic',
      text: 'Accelerate the vendor assessment process with the Whistic Security Network.',
      linkProps: {
        to: 'whistic',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: Demostack,
      title: 'Demostack',
      text: 'Spin up a demo environment and tailor your story to every buyer — all in a matter of minutes.',
      linkProps: {
        to: 'demostack',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: NavatticLogo,
      title: 'Navattic',
      text: 'Connect in-market buyers with high-value, interactive demos.',
      linkProps: {
        to: 'navattic',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: ScreenSpaceLogo,
      title: 'ScreenSpace',
      text: 'Create interactive product demos that make your story shine.',
      linkProps: {
        to: 'screenspace',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: StorylaneLogo,
      title: 'Storylane',
      text: 'Engage buyers with personalized, interactive product demos.',
      linkProps: {
        to: 'storylane',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: TestboxLogo,
      title: 'TestBox',
      text: 'Test drive popular B2B products side-by-side.',
      linkProps: {
        to: 'testbox',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: TourialLogo,
      title: 'Tourial',
      text: 'Create interactive product tours that engage buyers faster and drive more conversions.',
      linkProps: {
        to: 'tourial',
        relative: 'path',
        children: 'Learn more',
      },
    },
    {
      logo: WalnutLogo,
      title: 'Walnut',
      text: 'Embed interactive demos in your TrustRadius product listings to convert leads and close more deals.',
      linkProps: {
        to: 'walnut',
        relative: 'path',
        children: 'Learn more',
      },
    },
  ];
}
