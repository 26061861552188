export const SET_USER = 'SET_USER';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_USER_LOADING = 'SET_USER_LOADING';

export const setUser = (user) => ({
  type: SET_USER,
  payload: {
    user,
  },
});

export const setUserRoles = (userRoles) => ({
  type: SET_USER_ROLES,
  payload: {
    userRoles,
  },
});

export const setUserLoading = (isLoading) => ({
  type: SET_USER_LOADING,
  payload: {
    isLoading,
  },
});
