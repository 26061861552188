import { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertCircle } from 'lucide-react';
import { Main } from 'components/Main';
import { Header } from 'components/Header';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  refresh = () => {
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError || this.props.hasError) {
      return (
        <>
          <Header />
          <Main>
            <div className={styles.container}>
              <div className={styles.wrapper}>
                <AlertCircle color="#959596" size={33} />
                <h1 className={styles.header}>Something went wrong</h1>
                <p className={styles.text}>
                  We were unable to show your dashboard. <br />
                  Please refresh to try again.
                </p>
                <button
                  type="button"
                  className={styles.button}
                  onClick={this.refresh}
                >
                  Refresh
                </button>
                <a href="mailto:support@trustradius.com">Contact support</a>
              </div>
            </div>
          </Main>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  hasError: PropTypes.bool,
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  hasError: false,
};

export default ErrorBoundary;
