import { useState } from 'react';
import PropTypes from 'prop-types';
import { defer } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { Alert } from '@trustradius/radiant-ui';
import { ArrowRight } from 'lucide-react';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { TabSalesforce, TabOtherSystem } from 'components/LeadsWebhookTabs';
import { withWebhookData } from 'hoc/withWebhookData';
import { withApolloClient } from 'hoc/withApolloClient';
import mockData from './MockLeadsWebhookEnabled.json';
import styles from './LeadsWebhook.module.scss';

export function leadsWebhookLoader() {
  return defer({
    content: mockData,
  });
}

export function LeadsWebhook({
  webhooks,
  isLeadFormActivated,
  createWebhook,
  updateWebhook,
  removeWebhook,
  sendTestLead,
}) {
  const defaultTabValue = 'salesforce';
  const [testLeadResult, setTestLeadResult] = useState(null);

  const getTestResultContent = () => {
    const result =
      testLeadResult?.data?.executeWebhookProcessJob?.webhookActivity;
    if (result?.success === false) {
      return (
        <Alert variant="error" title="There’s a problem with your webhook.">
          <p>
            We were unable to deliver leads data to your endpoint. To begin
            receiving leads data, please verify that the webhook is available
            and configured correctly. If the issue persists, please contact your
            TrustRadius CSM or try again later. Error Code{' '}
            <p key={result?.message}>
              {result?.status} {result?.message}
            </p>
          </p>
        </Alert>
      );
    } else {
      return (
        <Alert variant="success" title="Your webhook is working correctly.">
          <p>
            Last successful test lead delivery:{' '}
            {new Date().toLocaleDateString()}
          </p>
        </Alert>
      );
    }
  };

  return (
    <section>
      <SecondaryPageHeader
        title="Leads Webhook"
        subtitle="Engage new leads immediately by automatically delivering TrustRadius leads data to your third-party systems."
        hasShadow
      />
      <div className={styles.body}>
        <Tabs.Root defaultValue={defaultTabValue}>
          <Tabs.List className={styles['tabs-list']}>
            <Tabs.Trigger className={styles['tab-trigger']} value="salesforce">
              Salesforce
            </Tabs.Trigger>
            <Tabs.Trigger className={styles['tab-trigger']} value="other">
              Other systems
            </Tabs.Trigger>
          </Tabs.List>
          <div className={styles.alerts}>
            {testLeadResult && getTestResultContent()}
            {!isLeadFormActivated && (
              <Alert variant="info" title="Enable your lead form">
                <p>
                  To receive leads data from in-market buyers via webhook, your
                  lead form must first be enabled.{' '}
                </p>
                <div className={styles['alert-text']}>
                  <a href="lead-form">Enable lead form</a>
                  <ArrowRight />
                </div>
              </Alert>
            )}
          </div>
          <Tabs.Content className={styles['tab-content']} value="salesforce">
            {webhooks && (
              <TabSalesforce
                webhookData={
                  webhooks?.filter(
                    (webhook) => webhook.type === 'salesforce',
                  )[0] || {}
                }
                sendTestLeadHandler={sendTestLead}
                createWebhookHandler={createWebhook}
                updateWebhookHandler={updateWebhook}
                deleteWebhookHandler={removeWebhook}
                setTestLeadResult={setTestLeadResult}
              />
            )}
          </Tabs.Content>
          <Tabs.Content className={styles['tab-content']} value="other">
            {webhooks && (
              <TabOtherSystem
                webhookData={
                  webhooks?.filter(
                    (webhook) => webhook.type !== 'salesforce',
                  )[0] || {}
                }
                sendTestLeadHandler={sendTestLead}
                createWebhookHandler={createWebhook}
                updateWebhookHandler={updateWebhook}
                deleteWebhookHandler={removeWebhook}
                setTestLeadResult={setTestLeadResult}
              />
            )}
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </section>
  );
}

LeadsWebhook.propTypes = {
  content: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
  }),
  webhooks: PropTypes.arrayOf({ type: PropTypes.string.isRequired }),
  isLeadFormActivated: PropTypes.bool.isRequired,
  createWebhook: PropTypes.func.isRequired,
  updateWebhook: PropTypes.func.isRequired,
  removeWebhook: PropTypes.func.isRequired,
  sendTestLead: PropTypes.func.isRequired,
};

export const LeadsWebhookWithData = withApolloClient(
  withWebhookData(LeadsWebhook),
);
