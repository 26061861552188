import classNames from 'classnames';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Check } from 'lucide-react';
import { Button } from '@trustradius/radiant-ui';
import {
  Icon,
  OverlayTrigger,
  Popover,
  Modal,
} from 'components/TrustradiusUICore';
import styles from './TrustradiusApiKey.module.scss';

export function TrustradiusApiKey({ apiKey }) {
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);
  const inputRef = useRef(null);
  const copyApiKey = async () => {
    if (!apiKey) {
      return;
    }

    await navigator.clipboard.writeText(apiKey);
    setCopied(true);
    inputRef.current.focus();
  };
  const buttonText = copied ? 'Copied' : 'Copy';

  const handleClose = () => {
    setShow(false);
    setCopied(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      {apiKey && (
        <>
          <div className={styles['api-key-block']}>
            <Icon name="key" />
            <div>Your TrustRadius API Key</div>
            <Button size="large" onClick={handleShow}>
              Get API key
            </Button>
          </div>
          <Modal
            show={show}
            className={styles.modal}
            dialogClassName={styles.dialog}
            backdropClassName={styles.backdrop}
            onHide={handleClose}
          >
            <Modal.Header className={styles.header} closeButton>
              <Modal.Title className={styles.title}>
                Your TrustRadius API key
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
              <p>
                Use this key to access the TrustRadius API or to activate
                integrations that require an API key.
              </p>
              <input
                type="text"
                defaultValue={apiKey}
                aria-label="Api key"
                ref={inputRef}
                onFocus={(event) => {
                  event.target.select();
                }}
                autoComplete="off"
                readOnly
                spellCheck={false}
                className={styles.input}
              />
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
              <Button
                variant={`primary round ${classNames(styles.button, {
                  [styles.copied]: copied,
                })}`}
                onClick={copyApiKey}
              >
                {copied && <Check color="#ffffff" />}
                <span>{buttonText}</span>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {!apiKey && (
        <div className={styles['header-buttons']}>
          <OverlayTrigger
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={
              <Popover className={styles.popover}>
                <Popover.Content>
                  <div id="tooltip-key-api">
                    Request a demo to learn more about TrustRadius APIs.
                  </div>
                </Popover.Content>
              </Popover>
            }
            interactive
          >
            <div className={styles['api-key-block']}>
              <Icon name="key" />
              <div>Get your TrustRadius API Key</div>
            </div>
          </OverlayTrigger>
          <Button
            size="large"
            url="https://www.trustradius.com/resources/assets/global-demo"
            targetBlank
          >
            Request a Demo
          </Button>
        </div>
      )}
    </>
  );
}

TrustradiusApiKey.propTypes = {
  apiKey: PropTypes.string,
};
