import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { HelpCircle } from 'lucide-react';
import classNames from 'classnames';
import { OverlayTrigger } from '../OverlayTrigger';
import { Tooltip } from '../Tooltip';
import styles from './Input.module.scss';

export const Input = forwardRef((props, ref) => {
  const {
    label,
    id = 'ID',
    value,
    type = 'text',
    disabled,
    placeholder,
    info,
    help,
    className,
    inputClassName,
    labelClassName,
    hideLabel,
    required,
    onChange,
    name,
  } = props;

  return (
    <div className={classNames(className, styles['input-container'])}>
      <label
        className={classNames(
          labelClassName,
          styles.label,
          hideLabel ? styles['hide-label'] : null,
        )}
        htmlFor={id}
      >
        {label}{' '}
        {!required && <span className={styles.optional}>(optional)</span>}
      </label>
      {info && (
        <OverlayTrigger placeholder="top" overlay={<Tooltip>{info}</Tooltip>}>
          <HelpCircle
            className={styles['help-circle']}
            size="16"
            color="#808088"
          />
        </OverlayTrigger>
      )}
      {}
      <input
        className={classNames(inputClassName, styles.input)}
        id={id}
        value={value}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        ref={ref}
        name={name}
      />
      {help && <p className={styles.help}>{help}</p>}
    </div>
  );
});

Input.displayName = 'Button';

Input.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  info: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
};
