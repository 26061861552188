import { cloneElement, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { collapseSideBar } from 'store/actions/globalActions';
import { MenuLabel } from './MenuLabel';
import styles from './LeftMenuItem.module.scss';

export function LeftMenuItem({ menuItem, isOpen, openNestedMenu, label }) {
  const dispatch = useDispatch();
  const isDesktop = useMedia('(min-width:901px)');
  const iconColor = isDesktop ? '#191919' : '#ffffff';
  const isMenuContainerExpanded = useSelector(
    (reduxState) => reduxState.globalState.isMenuContainerExpanded,
  );

  const isMenuItemExpanded = isMenuContainerExpanded && isOpen;
  const closeMenuOnMobile = useCallback(() => {
    if (!isDesktop) {
      dispatch(collapseSideBar());
    }
  }, [dispatch, isDesktop]);

  const toggleNestedMenu = useCallback(() => {
    if (menuItem.nestedMenuItems.length > 0) {
      openNestedMenu(menuItem.title);
    }
  }, [menuItem, openNestedMenu]);

  const title = useMemo(() => {
    const menuIcon = cloneElement(menuItem.icon, { stroke: iconColor });
    const menuLabel = label ? <MenuLabel type={label} /> : null;

    return (
      <>
        <div className={styles['icon-container']}>{menuIcon}</div>
        <div className={styles['item-text']}>
          <div>{menuItem.title}</div>
          {menuLabel}
        </div>
      </>
    );
  }, [menuItem, iconColor, label]);

  const topLevelItem = useMemo(() => {
    const topLevelItemClassName = classNames(styles['top-level-item'], {
      [styles['is-active']]: menuItem.isCurrent,
    });

    function renderChevron() {
      const Chevron = isMenuItemExpanded ? ChevronDown : ChevronRight;

      return (
        <div className={styles.chevron}>
          <Chevron size={16} color={iconColor} />
        </div>
      );
    }

    if (menuItem.nestedMenuItems && menuItem.url) {
      return (
        <div
          className={classNames(
            topLevelItemClassName,
            styles['with-link-and-nested'],
          )}
        >
          <Link
            to={menuItem.url}
            className={styles.link}
            onClick={closeMenuOnMobile}
          >
            {title}
          </Link>
          <button type="button" onClick={toggleNestedMenu}>
            {renderChevron()}
          </button>
        </div>
      );
    }

    if (menuItem.nestedMenuItems) {
      return (
        <button
          type="button"
          onClick={toggleNestedMenu}
          className={topLevelItemClassName}
        >
          {title}
          {renderChevron()}
        </button>
      );
    }

    return (
      <Link
        to={menuItem.url}
        className={topLevelItemClassName}
        onClick={closeMenuOnMobile}
      >
        {title}
      </Link>
    );
  }, [
    menuItem,
    iconColor,
    isMenuItemExpanded,
    title,
    toggleNestedMenu,
    closeMenuOnMobile,
  ]);

  return (
    <>
      {topLevelItem}
      {menuItem.nestedMenuItems && (
        <ul
          className={classNames(styles['collapsed-items'], {
            [styles['open-collapsed-items']]: isMenuItemExpanded,
          })}
        >
          {menuItem.nestedMenuItems.map((nestedMenuItem) => {
            return (
              <li
                key={nestedMenuItem.title}
                className={classNames(styles['nested-menu-item'], {
                  [styles['is-active']]: nestedMenuItem.isCurrent,
                })}
              >
                <Link
                  className={styles.link}
                  to={nestedMenuItem.url}
                  target={nestedMenuItem?.target || '_self'}
                  onClick={closeMenuOnMobile}
                >
                  {nestedMenuItem.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}

LeftMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    icon: PropTypes.object,
    isCurrent: PropTypes.bool,
    nestedMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        template: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  openNestedMenu: PropTypes.func,
  label: PropTypes.oneOf(['new', 'beta']),
};
