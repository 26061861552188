import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HelpCircle, Info } from 'lucide-react';
import { OverlayTrigger } from '../OverlayTrigger';
import { Popover } from '../Popover';
import styles from './HelpIcon.module.scss';

const buildPopover = (title, helpTip, className) => {
  return (
    <Popover className={className}>
      {title && <Popover.Title>{title}</Popover.Title>}
      <Popover.Content>{helpTip}</Popover.Content>
    </Popover>
  );
};

export function HelpIcon({
  className,
  title,
  helpTip,
  placement,
  icon,
  iconType,
  color,
  size,
}) {
  const popoverClasses = classNames(styles.help_popover, 'small');
  const popover = buildPopover(title, helpTip, popoverClasses);

  let TriggerIcon;
  if (iconType === 'custom') {
    TriggerIcon = icon;
  } else if (iconType === 'info') {
    TriggerIcon = <Info size={size} color={color} />;
  } else {
    TriggerIcon = <HelpCircle size={size} color={color} />;
  }

  return (
    <OverlayTrigger
      placement={placement}
      trigger={['hover', 'focus']}
      overlay={popover}
    >
      <button
        type="button"
        className={classNames(styles['trigger-button'], className)}
      >
        {TriggerIcon}
        <span className={styles['sr-only']}>{helpTip}</span>
      </button>
    </OverlayTrigger>
  );
}

HelpIcon.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  helpTip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  iconType: PropTypes.string,
  icon: PropTypes.element,
  size: PropTypes.number,
  color: PropTypes.string,
  placement: PropTypes.string,
};
