import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateVendorSwitcher,
  deactivateVendorSwitcher,
} from 'store/actions/globalActions';

export function useVendorSwitcher() {
  const dispatch = useDispatch();
  const isVendorSwitcherActive = useSelector(
    (state) => state.globalState.isVendorSwitcherActive,
  );

  const toggleVendorSwitcher = useCallback(() => {
    if (isVendorSwitcherActive) {
      dispatch(deactivateVendorSwitcher());
    } else {
      dispatch(activateVendorSwitcher());
    }
  }, [dispatch, isVendorSwitcherActive]);

  return useMemo(
    () => [isVendorSwitcherActive, toggleVendorSwitcher],
    [isVendorSwitcherActive, toggleVendorSwitcher],
  );
}
