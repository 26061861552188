import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { Radio } from 'components/TrustradiusUICore';
import styles from './SearchResult.module.scss';

function createProductName(name, status) {
  if (!status) {
    return name;
  }
  const productChangeStatuses = {
    draft: '(Changes in Draft)',
    submitted: '(Pending Approval)',
  };

  return `${name} ${productChangeStatuses[status]}`;
}

export function SearchResult({
  additionalStyles,
  onChange,
  options = [],
  selected,
  sortField = 'name',
  title,
  type = 'radio',
  filter = '',
}) {
  const isDesktop = useMedia('(min-width:901px)');
  const sortedOptions = useMemo(() => {
    return [...options].sort((firstOption, secondOption) => {
      if (
        sortField &&
        Object.prototype.hasOwnProperty.call(firstOption, sortField)
      ) {
        if (firstOption[sortField] > secondOption[sortField]) {
          return 1;
        } else if (firstOption[sortField] < secondOption[sortField]) {
          return -1;
        } else {
          return 0;
        }
      }
      console.error("Sort field doesn't in option's field list");
      return 0;
    });
  }, [sortField, options]);

  function withFilterHighlighted(name, query) {
    if (!name) {
      return '';
    }
    if (!query || !name.toUpperCase().includes(query.toUpperCase())) {
      return name;
    }
    const indexBeforeMatch = name.toUpperCase().indexOf(query.toUpperCase());
    const indexAfterMatch = indexBeforeMatch + query.length;

    const textBeforeMatch = name.substring(0, indexBeforeMatch);
    const textMatch = name.substring(indexBeforeMatch, indexAfterMatch);
    const textAfterMatch = name.substring(indexAfterMatch, name.length);

    return (
      <>
        <span>{textBeforeMatch}</span>
        <span className={styles.highlightText}>{textMatch}</span>
        <span>{textAfterMatch}</span>
      </>
    );
  }
  return (
    <div className={styles.searchResultContainer}>
      {title && Boolean(sortedOptions.length) && (
        <h2 className={styles.title}>{title}</h2>
      )}
      <ul>
        {sortedOptions.map((option, index) => {
          const key = option ? option._id : `search-option-${index}`;
          const productName = option?.name;
          const status = option?.changeInfo?.changeInfo?.status;
          const nameWithStatus = createProductName(productName, status);
          return (
            <li key={key}>
              {type === 'radio' && (
                <Radio
                  label={withFilterHighlighted(nameWithStatus, filter)}
                  name={nameWithStatus}
                  value={option?._id}
                  checked={option?._id === selected}
                  onChange={onChange}
                  className={classNames(
                    { [styles.mobile]: !isDesktop },
                    styles.radio,
                    additionalStyles,
                  )}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

SearchResult.propTypes = {
  additionalStyles: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
  sortField: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  filter: PropTypes.string,
};
