import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function KeyBenefits({ content, title = 'Key Benefits' }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getList() {
    const list = (
      <ul className={styles['no-padding']}>
        {content.map(({ id, icon, text }) => (
          <Text as="li" key={id}>
            <span className={styles.icon}>{icon}</span>
            {text}
          </Text>
        ))}
      </ul>
    );

    if (!isTablet) {
      return list;
    } else if (isOpen) {
      return list;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          {title}
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getList()}
    </section>
  );
}

export const KeyBenefitsShape = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      text: PropTypes.node.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
});

KeyBenefits.propTypes = KeyBenefitsShape.isRequired;
