import { defer } from 'react-router-dom';
import {
  TopRatedAwardCard,
  BuyersChoiceAwardCard,
  TechCaresAwardCard,
} from 'components/AwardCards';
import { PageBody } from 'components/PageBody';
import { RightBarContainer } from 'components/RightBarContainer';
import { getTopRatedSummary, getAwards } from 'store/apis/awardsApi';
import styles from './Awards.module.scss';

export function awardsLoader({ params }) {
  return defer({
    topRatedSummary: getTopRatedSummary(params.vendorId),
    awards: getAwards({
      select: 'title publishedDate awardBadgeFileName awardType',
      lean: true,
    }),
  });
}

export function Awards() {
  return (
    <div className={styles.awards}>
      <header className={styles.header}>
        <h1 className={styles.title}>Awards</h1>
        <div className={styles.subtitle}>
          Get recognized for your product’s core strengths to influence and win
          more buyers.
        </div>
      </header>
      <PageBody className={styles['page-body']}>
        <aside className={styles.aside}>
          <RightBarContainer
            title="Why do awards matter?"
            isHeaderClickable
            styles={{
              container: styles['aside-card'],
              header: styles['aside-card-header'],
              title: styles['aside-value-title'],
              button: styles['aside-value-button'],
              children: styles['aside-value-children'],
            }}
          >
            <p>
              Awards help build your brand and differentiate you among your
              competitors. Buyers use these awards as part of their decisioning
              process when researching on TrustRadius.
            </p>
            <p>
              Start qualifying for the many awards TrustRadius offers. Our team
              is here to help.
            </p>
            <a
              href="https://www.trustradius.com/vendor-blog/awards-calendar"
              className={styles['awards-value-link']}
              target="_blank"
              rel="noreferrer"
            >
              See award schedule and criteria
            </a>
          </RightBarContainer>
        </aside>
        <div className={styles['award-cards']}>
          <TopRatedAwardCard />
          <BuyersChoiceAwardCard />
          <TechCaresAwardCard />
        </div>
      </PageBody>
    </div>
  );
}
