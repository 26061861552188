import {
  SET_USER,
  SET_USER_LOADING,
  SET_USER_ROLES,
} from '../actions/userActions';

const initialState = {
  user: null,
  isLoading: true,
  rolesAllIds: [],
  rolesById: {},
  rolesByVendorId: {},
  rolesByProductId: {},
  rolesAllNames: [],
  rolesByName: {},
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case SET_USER_ROLES: {
      const rolesById = {};
      let rolesAllIds = [];
      const rolesByVendorId = {};
      const rolesByProductId = {};
      let rolesAllNames = [];
      const rolesByName = {};
      if (Array.isArray(action.payload.userRoles)) {
        action.payload.userRoles.forEach((grant) => {
          rolesById[grant.role._id] = grant.role;
          rolesAllIds.push(grant.role._id);
          rolesAllNames.push(grant.role.name);
          rolesByName[grant.role.name] = grant.role;

          if (grant.vendor && grant.vendor._id) {
            if (!rolesByVendorId[grant.role._id]) {
              rolesByVendorId[grant.vendor._id] = [];
            }
            rolesByVendorId[grant.vendor._id].push(grant.role._id);
          }
          if (grant.product && grant.product._id) {
            if (!rolesByProductId[grant.role._id]) {
              rolesByProductId[grant.product._id] = [];
            }
            rolesByProductId[grant.product._id].push(grant.role._id);
          }
        });
      }

      rolesAllNames = Array.from(new Set(rolesAllNames));
      rolesAllIds = Array.from(new Set(rolesAllIds));
      Object.keys(rolesByProductId).forEach((productId) => {
        rolesByProductId[productId] = Array.from(
          new Set(rolesByProductId[productId]),
        );
      });
      Object.keys(rolesByProductId).forEach((vendorId) => {
        rolesByVendorId[vendorId] = Array.from(
          new Set(rolesByVendorId[vendorId]),
        );
      });

      return {
        ...state,
        rolesById,
        rolesAllIds,
        rolesByVendorId,
        rolesByProductId,
        rolesAllNames,
        rolesByName,
      };
    }

    case SET_USER_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    default:
      return state;
  }
};

export default users;
