import ReactBootstrapDropdownButton from 'react-bootstrap/DropdownButton';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';

/**
 * TrustRadius wrapped DropdownButton component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function DropdownButton(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, styles['dropdown-button']);
  // Provide default variant
  return (
    <ReactBootstrapDropdownButton
      className={classes}
      bsPrefix="trDropdown-button"
      {...otherProps}
    >
      {children}
    </ReactBootstrapDropdownButton>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
DropdownButton.propTypes = ReactBootstrapDropdownButton.propTypes;
