import { caseStudy1, caseStudy2, caseStudy3 } from './images';

export const caseStudies = {
  caseStudies: [
    {
      id: 'cs-3',
      imageHeader: caseStudy3,
      quote:
        '“TrustRadius is a key part of our Inbound marketing strategy. 80% of the buying process happens before a prospect talks to a sales rep, and buyers trust the extensive detail that TrustRadius uniquely provides. ”',
      metrics: [
        {
          id: 1,
          title: 'New reviews',
          value: '313',
        },
        {
          id: 2,
          title: 'Words of additional custom content',
          value: '29,256',
        },
        {
          id: 3,
          title: 'Increase in product profile pageviews',
          value: '72%',
        },
      ],
      caseStudyUrl:
        'https://www.trustradius.com/vendor/customer-spotlight-groove',
    },
    {
      id: 'cs-2',
      imageHeader: caseStudy2,
      quote:
        '“TrustRadius is a key part of our Inbound marketing strategy. 80% of the buying process happens before a prospect talks to a sales rep, and buyers trust the extensive detail that TrustRadius uniquely provides. ”',
      metrics: [
        {
          id: 1,
          title: 'Growth in quality reviews',
          value: '13x',
        },
        {
          id: 2,
          title: 'New quotes for sales and marketing',
          value: '296',
        },
        {
          id: 3,
          title: 'Increase in pageviews',
          value: '586%',
        },
      ],
      caseStudyUrl:
        'https://www.trustradius.com/vendor/customer-spotlight-seekout',
    },
    {
      id: 'cs-1',
      imageHeader: caseStudy1,
      quote:
        '“TrustRadius is a key part of our Inbound marketing strategy. 80% of the buying process happens before a prospect talks to a sales rep, and buyers trust the extensive detail that TrustRadius uniquely provides. ”',
      metrics: [
        {
          id: 1,
          title: 'Growth in quality reviews',
          value: '2.4x',
        },
        {
          id: 2,
          title: 'New reference candidates',
          value: '272',
        },
        {
          id: 3,
          title: 'Share of TR category traffic',
          value: '21%',
        },
      ],
      caseStudyUrl:
        'https://www.trustradius.com/vendor/customer-spotlight-paylocity',
    },
  ],
};
