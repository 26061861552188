import { axiosRequestWithAuthToken } from './axios_utils';

export const getProductAwardTracks = async (productId) => {
  try {
    const options = {
      url: `/api/products/${productId}/awards`,
      method: 'GET',
    };
    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getTopRatedSummary = async (vendorId) => {
  const { data } = await axiosRequestWithAuthToken({
    url: `/api/awards/top-rated-summary/${vendorId}`,
    method: 'GET',
  });

  return data;
};

export const getTopRatedTracks = (vendorId) => {
  return axiosRequestWithAuthToken({
    url: `/api/awards/top-rated-tracks/${vendorId}`,
    method: 'GET',
  });
};

export const getAwards = (params) => {
  return axiosRequestWithAuthToken({
    url: `/api/awards`,
    method: 'GET',
    params,
  });
};
