import PropTypes from 'prop-types';
import styles from './SegmentItem.module.scss';

export function PrimaryMetrics({
  audience,
  matchedAndTotalAccounts,
  matchRate,
  segmentStatus,
}) {
  return (
    <dl className={styles['primary-metrics']}>
      <div className={styles['description-block']}>
        <dt>Audience</dt>
        <dd>{audience}</dd>
      </div>
      <div className={styles['description-block']}>
        <dt>Matched/Total Accounts</dt>
        <dd>{matchedAndTotalAccounts}</dd>
      </div>
      <div className={styles['description-block']}>
        <dt>Match rate</dt>
        <dd>{matchRate}</dd>
      </div>
      <div className={styles['description-block']}>
        <dt>Status</dt>
        <dd>{segmentStatus}</dd>
      </div>
    </dl>
  );
}

PrimaryMetrics.propTypes = {
  audience: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  matchedAndTotalAccounts: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  matchRate: PropTypes.string,
  segmentStatus: PropTypes.string,
};
