import {
  GrowthBook,
  GrowthBookProvider as GrowthBookProviderOriginal,
} from '@growthbook/growthbook-react';
import { PropTypes } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useCookie } from 'react-use';
import { useSettings } from 'settings';

export function GrowthBookProvider({ children }) {
  const [targetingAttributes] = useCookie('featureFlagOverrides');
  const [devMode] = useCookie('tr_dev_mode');
  const settings = useSettings();
  const growthBook = useMemo(() => {
    return new GrowthBook({
      apiHost: settings?.growthBook?.url,
      clientKey: settings?.growthBook?.key,
      enableDevMode:
        process.env.NODE_ENV !== 'production' || devMode === 'true',
      // Update the instance in realtime as features change in GrowthBook
      subscribeToChanges: true,
    });
  }, [settings, devMode]);

  useEffect(() => {
    async function loadFeatures() {
      await growthBook.loadFeatures();
    }
    loadFeatures();
  }, [growthBook]);

  useEffect(() => {
    console.debug(
      'Setting targeting attributes',
      JSON.parse(targetingAttributes),
    );
    if (targetingAttributes) {
      try {
        growthBook.setAttributes(JSON.parse(targetingAttributes));
      } catch (err) {
        console.error('Error setting targeting attributes', err);
      }
    }
  }, [targetingAttributes, growthBook]);

  return (
    <GrowthBookProviderOriginal growthbook={growthBook}>
      {children}
    </GrowthBookProviderOriginal>
  );
}

GrowthBookProvider.propTypes = {
  children: PropTypes.node,
};
