import PropTypes from 'prop-types';

function MoneyCircle(props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49967 0.833008C4.43967 0.833008 0.333008 4.93967 0.333008 9.99967C0.333008 15.0597 4.43967 19.1663 9.49967 19.1663C14.5597 19.1663 18.6663 15.0597 18.6663 9.99967C18.6663 4.93967 14.5597 0.833008 9.49967 0.833008ZM9.49951 17.3324C5.45701 17.3324 2.16618 14.0415 2.16618 9.99902C2.16618 5.95652 5.45701 2.66569 9.49951 2.66569C13.542 2.66569 16.8328 5.95652 16.8328 9.99902C16.8328 14.0415 13.542 17.3324 9.49951 17.3324ZM7.63871 7.67986C7.63871 8.34902 8.16121 8.79819 9.78371 9.21069C11.397 9.63236 13.1295 10.329 13.1387 12.3457C13.1295 13.8215 12.0295 14.619 10.6362 14.8849V16.4157H8.49121V14.8574C7.11621 14.5732 5.96121 13.6932 5.86955 12.1349H7.44621C7.52871 12.9782 8.10621 13.6382 9.57288 13.6382C11.1404 13.6382 11.4979 12.8499 11.4979 12.364C11.4979 11.704 11.1404 11.0715 9.35288 10.6499C7.36371 10.1732 5.99788 9.34819 5.99788 7.70736C5.99788 6.32319 7.10705 5.42486 8.49121 5.13152V3.58236H10.627V5.14986C12.112 5.51652 12.8637 6.64402 12.9095 7.87236H11.342C11.3054 6.97402 10.8287 6.36902 9.56371 6.36902C8.36288 6.36902 7.63871 6.90986 7.63871 7.67986Z"
        fill={props.color || 'currentColor'}
      />
    </svg>
  );
}

MoneyCircle.propTypes = {
  color: PropTypes.string,
};

export default MoneyCircle;
