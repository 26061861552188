import { Checkbox } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import { LeadFormPill } from 'components/LeadFormPill';
import styles from './LeadFormProductCheckbox.module.scss';

export function LeadFormProductCheckbox({
  product,
  onChangeHandler,
  selected,
}) {
  return (
    <div className={styles.container}>
      <Checkbox
        labelText={product?.name}
        disable={product?.leadForm?.id}
        checked={selected}
        disabled={Boolean(product?.leadForm?.id)}
        onCheckedChange={(value) => onChangeHandler(value)}
      />
      {product?.leadForm?.id && (
        <LeadFormPill
          leadFormId={product?.leadForm?.id}
          leadFormName={product?.leadForm?.name}
        />
      )}
    </div>
  );
}

LeadFormProductCheckbox.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    leadForm: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  selected: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};
