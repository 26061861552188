import PropTypes from 'prop-types';
import styles from './Hero.module.scss';

export function Hero({ title, description, children }) {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <h1 className={styles.heading}>{title}</h1>
        {description}
        {children}
      </div>
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
};
