import { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Search, HelpCircle } from 'lucide-react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  OverlayTrigger,
  Tooltip,
  ErrorList,
  Toast,
} from 'components/TrustradiusUICore';
import { createLinkedInSegment } from 'store/apis/limaApi';
import styles from './CreateSegmentForm.module.scss';

function Icon({ innerRef }) {
  return (
    <span className={styles['search-icon']} ref={innerRef}>
      <Search size="20" color="#4A4A54" />
    </span>
  );
}

Icon.propTypes = {
  innerRef: PropTypes.func,
};

export function CreateSegmentForm(props) {
  const { adAccounts, licensedProducts, licensedCategories, vendorId } = props;
  const [segmentName, setSegmentName] = useState('');
  const [adAccount, setAdAccount] = useState(null);
  const [period, setPeriod] = useState({ value: '30', label: '30 days' });
  const [audienceTypeName, setAudienceTypeName] = useState('');
  const [audienceBaseField, setAudienceBaseField] = useState([]);
  const [audienceBaseSelected, setAudienceBaseSelected] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState([]);
  const [clickDisabled, setClickDisabled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ heading: '', body: '' });
  const navigate = useNavigate();

  async function handleSubmit() {
    if (clickDisabled) {
      return;
    }
    setClickDisabled(true);
    displayErrors('', []);

    const data = {
      segmentName,
      adAccount: adAccount ? adAccount.value : null,
      period: period ? period.value : null,
      vendor: vendorId,
      audienceType: audienceTypeName,
      audienceBase: audienceBaseSelected
        ? audienceBaseSelected.map((item) => item.value)
        : [],
    };

    try {
      await createLinkedInSegment(data);
      navigate('..', { relative: 'path' });
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.data.message.message &&
        err.response.data.message.message.includes('403')
      ) {
        setShowToast(true);
        setToastMessage({
          heading: 'Access denied',
          body: "You don't have write access to this ad account",
        });
      }
      displayErrors(err.message, err.response.data.errors);
      setClickDisabled(false);
    }
  }

  function nameSegment(event) {
    setSegmentName(event.target.value);
  }

  function selectAdAccount(selectedAdAccount) {
    setAdAccount(selectedAdAccount);
  }

  function selectPeriod(item) {
    setPeriod(item);
  }

  function selectAudienceType(type) {
    if (type.value === 'products') {
      setAudienceBaseField(licensedProducts);
    } else {
      setAudienceBaseField(licensedCategories);
    }
    setAudienceBaseSelected([]);
    setAudienceTypeName(type.label);
  }

  function selectAudienceBase(options) {
    setAudienceBaseSelected(options);
  }

  function displayErrors(msg, errList) {
    const errListEscaped = errList || [];

    setErrorMessage(
      errListEscaped.length > 0 ? 'Please fix the following errors' : msg,
    );
    setErrorList(errListEscaped);
    const errorFields = [];
    for (const err of errListEscaped) {
      errorFields.push(err.param);
    }

    setFieldErrors(errorFields);
  }

  function fieldErrorStyle(field) {
    return fieldErrors.includes(field) ? styles.fieldError : '';
  }

  const audienceType = [
    { value: 'products', label: 'Products' },
    { value: 'categories', label: 'Categories' },
  ];

  const timePeriodOptions = [
    { value: '30', label: '30 days' },
    { value: '60', label: '60 days' },
    { value: '90', label: '90 days' },
  ];

  const search = {
    DropdownIndicator: Icon,
  };

  return (
    <section className={styles.container}>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        closeButton
        autohide
        heading={<span>{toastMessage.heading}</span>}
        className={styles.toast}
      >
        <span>{toastMessage.body}</span>
      </Toast>
      <form id="new_segment" className={styles.form}>
        <div className={styles['form-item']}>
          {errorList.length > 0 && (
            <ErrorList errorList={errorList} errorMessage={errorMessage} />
          )}
        </div>
        <div className={styles['form-item']}>
          <Input
            label="Name your segment"
            id="segment_name"
            info="This name will appear in your LinkedIn Campaign Manager instance. We recommend using a unique, descriptive name."
            type="text"
            value={segmentName}
            onChange={nameSegment}
            required
            inputClassName={fieldErrorStyle('segmentName')}
          />
        </div>
        <div className={styles['input-container']}>
          <label className={styles.label} htmlFor="ad_account">
            Ad Account
          </label>
          <OverlayTrigger
            placeholder="top"
            overlay={
              <Tooltip>
                These are the Ad Accounts you have access to. Select the
                appropriate Ad Account where you want to create your segment.
              </Tooltip>
            }
          >
            <HelpCircle
              className={styles['help-circle']}
              size="16"
              color="#808088"
            />
          </OverlayTrigger>
          <Select
            required
            className={classNames(styles.select, fieldErrorStyle('adAccount'))}
            options={adAccounts}
            inputId="ad_account"
            onChange={selectAdAccount}
            noOptionsMessage={() =>
              'No LinkedIn Ad Accounts found. An Ad Account is required to create a segment'
            }
          />
        </div>
        <div
          className={classNames(
            styles['input-container'],
            styles['time-period'],
          )}
        >
          <label className={styles.label} htmlFor="time_period">
            Select your time period
          </label>
          <Select
            required
            className={classNames(styles.select, fieldErrorStyle('period'))}
            options={timePeriodOptions}
            inputId="time_period"
            onChange={selectPeriod}
            value={period}
          />
        </div>
        <div className={classNames(styles['input-container'], styles.audience)}>
          <label className={styles.label} htmlFor="audience_type">
            Select Product or Category
          </label>
          <Select
            required
            className={classNames(
              styles.select,
              fieldErrorStyle('audienceType'),
            )}
            options={audienceType}
            inputId="audience_type"
            onChange={selectAudienceType}
          />
        </div>
        <div className={styles['input-container']}>
          <label className={styles.label} htmlFor="audience_base">
            {audienceTypeName || 'Products'}
          </label>
          <Select
            required
            className={classNames(
              styles.select,
              fieldErrorStyle('audienceBase'),
            )}
            options={audienceBaseField}
            components={search}
            inputId="audience_base"
            onChange={selectAudienceBase}
            isMulti
            value={audienceBaseSelected}
          />
        </div>
      </form>
      <footer className={styles.footer}>
        <Button as={Link} to=".." relative="path" variant="secondary-pill">
          Cancel
        </Button>
        <Button variant="primary-pill" onClick={handleSubmit}>
          Save
        </Button>
      </footer>
    </section>
  );
}

CreateSegmentForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
  adAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  licensedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  licensedCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};
