import { Button, Text } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import { CircleIcon } from 'components/CircleIcon';
import { Modal } from 'components/Modal';
import styles from 'components/LeadsV2Modals/UnlinkLeadFormModal/UnlinkLeadFormModal.module.scss';

export function UnlinkLeadFormModal({
  isShowModal,
  setIsShowModal,
  deleteHandler,
}) {
  return (
    <Modal
      className={styles['delete-modal']}
      isOpen={isShowModal}
      icon={
        <div className={styles['delete-icon']}>
          <CircleIcon
            iconType="unlink"
            color="#F44336"
            backgroundColor="#FFDEEA"
            backgroundColorFade="#FEEFF4"
          />
        </div>
      }
      title={
        <Text as="span" className={styles.gray120} weight="semibold" size="400">
          Remove lead form
        </Text>
      }
      description={
        <Text className={styles.gray100} data-testid="description">
          Are you sure you want to remove this lead form from your product
          listing? Removing a lead form from a product listing will not cause it
          to be deleted.
        </Text>
      }
      closeHandler={() => setIsShowModal(false)}
      actionButtons={[
        <div key="1" className={styles['cancel-button']}>
          <Button variant="secondary" size="large">
            Cancel
          </Button>
        </div>,
        <div key="2" className={styles['delete-button']}>
          <Button
            variant="primary-warning"
            size="large"
            onClick={deleteHandler}
          >
            Remove
          </Button>
        </div>,
      ]}
    />
  );
}

UnlinkLeadFormModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};
