import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function KeyDates({ content }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getList() {
    const list = (
      <ul className={styles['no-padding']}>
        {content.map(({ id, icon, date, description }) => (
          <Text
            as="li"
            key={id}
            className={classNames(styles['section-content'], styles.dates)}
          >
            {icon}
            <div className={styles['dates-info']}>
              <Text>{date}</Text>
              <Text>{description}</Text>
            </div>
          </Text>
        ))}
      </ul>
    );

    if (!isTablet) {
      return list;
    } else if (isOpen) {
      return list;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          Key Dates
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getList()}
    </section>
  );
}

export const KeyDatesShape = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
    }),
  ).isRequired,
});

KeyDates.propTypes = KeyDatesShape.isRequired;
