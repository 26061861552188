import PropTypes from 'prop-types';
import { DashCard } from 'components/DashCard';
import { Skeleton } from 'components/Skeleton';
import { CardErrorState } from 'components/CardErrorState';
import { AwardsProgress } from './AwardsProgress';
import styles from './AwardTrackingCard.module.scss';

export function AwardTrackingCard({
  product,
  vendor,
  productAwardTracks = [],
  isLoading,
  hasError,
  refreshHandler,
}) {
  if (isLoading) return <Skeleton className={styles.loader} />;

  if (!isLoading && hasError) {
    return (
      <CardErrorState
        refreshHandler={() => {
          refreshHandler(product._id);
        }}
      />
    );
  }

  if (!product || !productAwardTracks.length > 0) return null;

  return (
    <DashCard
      ctaText="Drive more reviews to win a Top Rated Award"
      ctaHref={`/vendor/${vendor._id}/reviews/generate-reviews`}
      product={product}
      isTargetBlank={false}
    >
      <AwardsProgress product={product} awardTracks={productAwardTracks} />
    </DashCard>
  );
}

AwardTrackingCard.propTypes = {
  isLoading: PropTypes.bool,
  vendor: PropTypes.object,
  hasError: PropTypes.bool,
  product: PropTypes.object,
  productAwardTracks: PropTypes.arrayOf(PropTypes.object),
  refreshHandler: PropTypes.func,
};
