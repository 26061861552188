export const EXPAND_SIDEBAR = 'OPEN_SIDEBAR';
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const CHANGE_TIMEFRAME = 'CHANGE_TIMEFRAME';
export const ACTIVATE_VENDOR_SWITCHER = 'ACTIVATE_VENDOR_SWITCHER';
export const DEACTIVATE_VENDOR_SWITCHER = 'DEACTIVATE_VENDOR_SWITCHER';

export const expandSideBar = () => ({
  type: EXPAND_SIDEBAR,
});

export const collapseSideBar = () => ({
  type: COLLAPSE_SIDEBAR,
});

export const activateVendorSwitcher = () => ({
  type: ACTIVATE_VENDOR_SWITCHER,
});

export const deactivateVendorSwitcher = () => ({
  type: DEACTIVATE_VENDOR_SWITCHER,
});

export const changeTimeFrame = (timeFrame) => ({
  type: CHANGE_TIMEFRAME,
  payload: {
    timeFrame,
  },
});
