import classNames from 'classnames';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import { ProductMetric } from 'components/ProductMetric';
import { Popover } from 'components/TrustradiusUICore';
import styles from './ProductMetrics.module.scss';

export function ProductMetrics({ metrics, isLoading }) {
  const isDesktop = useMedia('(min-width:900px)');
  const isMobile = useMedia('(max-width:599px)');

  const reviewPopover = () => (
    <Popover className={styles.popover}>
      <Popover.Content>
        <div className={styles['tool-tip-text']}>
          Number of new reviews <br /> published for your product
        </div>
      </Popover.Content>
    </Popover>
  );

  const pageViewsPopover = () => (
    <Popover className={styles.popover}>
      <Popover.Content>
        <div className={styles['tool-tip-text']}>
          Number of page views <br /> for your product listing
        </div>
      </Popover.Content>
    </Popover>
  );

  function CTAClicksPopover() {
    return (
      <Popover className={styles.popover}>
        <Popover.Content>
          <div className={styles['tool-tip-text']}>
            Number of CTA clicks on your <br /> product listing, linking buyers{' '}
            <br /> to your website
          </div>
        </Popover.Content>
      </Popover>
    );
  }

  const intentCompaniesPopover = () => (
    <Popover className={styles.popover}>
      <Popover.Content>
        <div className={styles['tool-tip-text']}>
          Number of unique companies <br /> showing intent for your product
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div
      className={classNames(styles.metricsContainer, {
        [styles.mobile]: isMobile,
      })}
    >
      <div
        className={classNames(styles.metricsBlock, {
          [styles.mobile]: isMobile,
        })}
      >
        <div
          className={classNames(styles.metricsGroup, {
            [styles.mobile]: isMobile,
          })}
        >
          <ProductMetric
            currentValue={metrics.byId.reviews?.current}
            title="Reviews"
            deltaPct={metrics.byId.reviews?.deltaPct}
            data-testid="reviews"
            popover={reviewPopover()}
            isLoading={isLoading}
          />
          <ProductMetric
            currentValue={metrics.byId.page_views?.current}
            title="Page Views"
            deltaPct={metrics.byId.page_views?.deltaPct}
            data-testid="page-views"
            popover={pageViewsPopover()}
            isLoading={isLoading}
          />
        </div>
        <div
          className={classNames(styles.metricsGroup, {
            [styles.mobile]: !isDesktop,
          })}
        >
          <ProductMetric
            currentValue={metrics.byId.cta_clicks?.current}
            title="CTA Clicks"
            deltaPct={metrics.byId.cta_clicks?.deltaPct}
            data-testid="cta-clicks"
            popover={CTAClicksPopover()}
            isLoading={isLoading}
          />
          <ProductMetric
            currentValue={metrics.byId.intent_opportunities?.current}
            title="Intent Companies"
            deltaPct={metrics.byId.intent_opportunities?.deltaPct}
            data-testid="intent-companies"
            popover={intentCompaniesPopover()}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

ProductMetrics.propTypes = {
  metrics: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
