import PropTypes from 'prop-types';
import { TextFieldWithCounter } from 'components/TextFieldWithCounter';
import styles from './CreateTextField.module.scss';

export function CreateTextField({
  labelFieldId,
  labelTitle = 'Field label',
  labelValue,
  maxLabelLength,
  labelChangeHandler,
  helpTextId,
  helpTextValue,
  maxHelpTextLength,
  helpTextChangeHandler,
}) {
  return (
    <div className={styles.container}>
      <TextFieldWithCounter
        fieldId={labelFieldId}
        fieldLabel={labelTitle}
        isRequired
        fieldValue={labelValue}
        maxFieldLength={maxLabelLength}
        fieldChangeHandler={labelChangeHandler}
      />
      <TextFieldWithCounter
        fieldId={helpTextId}
        fieldLabel="Help text"
        fieldValue={helpTextValue}
        maxFieldLength={maxHelpTextLength}
        fieldChangeHandler={helpTextChangeHandler}
      />
    </div>
  );
}

CreateTextField.propTypes = {
  labelFieldId: PropTypes.string,
  labelTitle: PropTypes.string,
  labelValue: PropTypes.string,
  maxLabelLength: PropTypes.number,
  labelChangeHandler: PropTypes.func,
  helpTextId: PropTypes.string,
  helpTextValue: PropTypes.string,
  maxHelpTextLength: PropTypes.number,
  helpTextChangeHandler: PropTypes.func,
};
