// @TODO [ECX-137] - Fix a11y violation L41-49 input element has no label

import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { X as CloseIcon, Search } from 'lucide-react';
import styles from './SearchField.module.scss';

export const searchTextColors = {
  WHITE: 'white',
  BLACK: '#191919',
};

const colorGray70 = '#65656F';

export function SearchField({
  id,
  placeholder,
  size = 18,
  inputColor = searchTextColors.BLACK,
  closeColor = colorGray70,
  searchColor = colorGray70,
  initialValue = '',
  onChange,
  className,
}) {
  const classes = classNames(styles.searchContainer, className);
  const [value, setValue] = useState(initialValue);

  const changeHandler = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const onClear = () => {
    setValue('');
    onChange('');
  };

  return (
    <div className={classNames(classes)}>
      <input
        className={classNames(styles.search, styles[inputColor])}
        type="text"
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={changeHandler}
        autoComplete="off"
      />
      {/* It really makes no sense to wrap icons in a label.
        We should remove the label wrapper here and add an aria-label to the input */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.label} htmlFor={id}>
        <button type="button" onClick={onClear}>
          <CloseIcon size={size} color={closeColor} />
        </button>
        <div className={styles.separator} />
        <Search size={size} color={searchColor} />
      </label>
    </div>
  );
}

SearchField.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.number,
  inputColor: PropTypes.oneOf(Object.values(searchTextColors)),
  closeColor: PropTypes.string,
  searchColor: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
