import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigation } from 'react-router-dom';
import { Users, Activity, DollarSign } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import {
  SecondaryPageHeader,
  TextBanner,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  Prerequisites,
  UseCases,
} from 'components/SecondaryPage';
import { Skeleton } from 'components/Skeleton';
import { Toast } from 'components/TrustradiusUICore';
import { LinkedinLogo } from 'assets/logos-companies';
import { AuthenticatedUsersSubtitle } from '../AuthenticatedUsersSubtitle';
import { useNavigateToLinkedIn } from '../limaAuth';
import { linkedinBanner, linkedinBanner2x } from './images';
import styles from './LimaContent.module.scss';

export function LimaContent({
  limaStatus,
  isLoading = false,
  isLoaderError = false,
}) {
  const navigation = useNavigation();
  const navigateToLinkedIn = useNavigateToLinkedIn();
  const [isLoaderErrorToastShown, setIsLoaderErrorToastShown] =
    useState(isLoaderError);

  const subtitle = useMemo(() => {
    if (!limaStatus?.isCustomer) {
      return null;
    }

    return (
      <AuthenticatedUsersSubtitle
        users={limaStatus.linkedInAudienceBuilderUsers}
      />
    );
  }, [limaStatus]);

  const actionBlock = useMemo(() => {
    if (isLoading) {
      return <Skeleton className={styles.skeleton} />;
    }

    function renderButtons() {
      if (!limaStatus?.isCustomer) {
        return (
          <Button
            url="https://go.trustradius.com/demo_2021.html"
            targetBlank
            size="large"
          >
            Request a demo
          </Button>
        );
      }

      if (!limaStatus.isAuthorized) {
        return (
          <Button size="large" onClick={navigateToLinkedIn}>
            Connect LinkedIn account
          </Button>
        );
      }

      return (
        <>
          <Link to="unauthorize" className="button button-secondary-pill">
            Remove LinkedIn Account
          </Link>
          <Link to="segments" className="button button-primary-pill">
            Continue to integration
          </Link>
        </>
      );
    }

    return <div className={styles['button-container']}>{renderButtons()}</div>;
  }, [limaStatus, isLoading, navigateToLinkedIn]);

  const pageClassName =
    navigation.state === 'loading' &&
    navigation.location?.pathname &&
    !navigation.location.pathname.endsWith('integrations/linkedin')
      ? styles.loading
      : undefined;

  const keyBenefits = [
    {
      id: 'build',
      icon: <Users />,
      text: 'Build audience segments of companies showing purchase intent for your products and categories.',
    },
    {
      id: 'sync',
      icon: <Activity />,
      text: 'Sync your segments to LinkedIn Campaign Manager to target the most relevant accounts.',
    },
    {
      id: 'optimize',
      icon: <DollarSign />,
      text: 'Optimize your ad spend by targeting in-market companies in your ICP',
    },
  ];

  const installation = [
    {
      id: 'activate',
      componentContent:
        'To activate this integration, click "Connect LinkedIn Account" (top right).',
    },
    {
      id: 'user-guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/en/support/solutions/folders/43000581494"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const prerequisites = [
    {
      id: 'pricing',
      componentContent: (
        <>
          An intent data license is required to unlock this feature.&nbsp;
          <a
            href="https://www.trustradius.com/vendor/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      ),
    },
    {
      id: 'access',
      componentContent: (
        <>
          Requires access to your organization&apos;s Ad Account for LinkedIn
          Campaign Manager.
        </>
      ),
    },
  ];

  const useCases = ['Social Media Advertising', 'Ad Serving & Retargeting'];

  return (
    <div className={pageClassName}>
      <SecondaryPageHeader
        backLinkProps={{
          to: '..',
          relative: 'path',
          'aria-label': 'Back',
        }}
        title="LinkedIn Matched Audiences"
        subtitle={subtitle}
        logo={<img src={LinkedinLogo} alt="LinkedIn" />}
        actionBlock={actionBlock}
        hasShadow
      />
      {limaStatus && !limaStatus.isCustomer && (
        <TextBanner>
          This integration requires TrustRadius intent data (included in
          Ultimate and Ultimate+ subscriptions).
        </TextBanner>
      )}
      <SecondaryPageContent
        imageBanner={
          <img
            src={linkedinBanner}
            srcSet={`${linkedinBanner} 1x, ${linkedinBanner2x} 2x`}
            alt="LinkedIn"
          />
        }
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'prerequisites',
            element: <Prerequisites content={prerequisites} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About LinkedIn Marketing Solutions</Text>
          <Text as="p">
            LinkedIn Marketing Solutions helps companies market relevant and
            timely messaging to specific audience segments, allowing them to
            land leads, build brand awareness, and drive website traffic.
            LinkedIn&apos;s Matched Audiences tool allows companies to create
            custom audience segments by leveraging account and customer lists
            that match to existing LinkedIn profiles.
          </Text>
          <Text as="h3">LinkedIn Marketing Solutions and TrustRadius</Text>
          <Text as="p">
            With TrustRadius&apos; integration with LinkedIn, you can build
            dynamic audience segments with TrustRadius downstream intent data.
            You can then use these segments to create ad campaigns that target
            buyers who are actively researching your product or category on
            TrustRadius, optimizing your ad spend on qualified companies in your
            ideal customer profile (ICP).
          </Text>
          <Text as="p">
            Audience segments built using this integration are composed of
            companies that have researched your product(s) or category on
            TrustRadius. LinkedIn then uses this data to match TrustRadius
            companies with their corresponding companies on LinkedIn. LinkedIn
            users who are employees of the successfully matched companies will
            be included in the LinkedIn audience segment and will be targeted in
            any ad. Once an audience segment is built, you can use it for
            LinkedIn ad campaigns. Audience segments are refreshed with the
            latest intent data on a daily basis, so you are always targeting the
            most relevant account.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">
            Create a multi-touch campaign that runs in parallel
          </Text>
          <Text as="p">
            Create a true multi-touch campaign for intent audiences by running
            LinkedIn ads in parallel and as a complement to display ads. By
            doing this, customers have shown more impact for mid- to
            bottom-funnel audiences.
          </Text>
        </section>
        {limaStatus && !limaStatus.isCustomer && (
          <section>
            <Text as="h2">Why subscribe to TrustRadius intent data?</Text>
            <Text as="p" className={styles['top-indent']}>
              With TrustRadius intent, you can gain more insights into qualified
              accounts who are likely to make a purchasing decision. You can
              leverage this data to acquire new leads, prioritize known accounts
              and personalize your outreach.
            </Text>
          </section>
        )}
      </SecondaryPageContent>
      <Toast
        show={isLoaderErrorToastShown}
        variant="danger"
        autohide
        delay={10000}
        closeButton
        className={styles.toast}
        onClose={() => {
          setIsLoaderErrorToastShown(false);
        }}
        heading="Something went wrong while getting LinkedIn status"
      >
        <Text as="p">
          Please try again or contact us at support@trustradius.com
        </Text>
      </Toast>
    </div>
  );
}

LimaContent.propTypes = {
  limaStatus: PropTypes.shape({
    isCustomer: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    linkedInAudienceBuilderUsers: PropTypes.arrayOf(PropTypes.object.isRequired)
      .isRequired,
  }),
  isLoading: PropTypes.bool,
  isLoaderError: PropTypes.bool,
};
