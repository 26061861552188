import React, { useState } from 'react';
import { Button } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';

export function ReviewGenerationCopyButton({
  text,
  value,
  copyText = 'Copied!',
  clickHandler,
}) {
  const [copied, setCopied] = useState(false);
  const onClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    clickHandler(value);
  };
  return (
    <Button
      variant="none"
      onClick={() => {
        onClick(value);
      }}
    >
      {copied && copyText}
      {!copied && text}
    </Button>
  );
}

ReviewGenerationCopyButton.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  copyText: PropTypes.string,
  clickHandler: PropTypes.func,
};
