import { axiosRequestWithAuthToken } from './axios_utils';

export const getCategoryIntentCardData = async (primaryCategoryId) => {
  try {
    const options = {
      url: `/api/categories/${primaryCategoryId}/watchers`,
      method: 'GET',
    };
    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductsInCategoryById = async (categoryId) => {
  try {
    const options = {
      url: `/api/categories/${categoryId}/products`,
      method: 'GET',
    };
    const data = await axiosRequestWithAuthToken(options);
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
