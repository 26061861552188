export const normalizeState = (dataArray = [], key = '_id') => {
  const result = {
    byId: {},
    allIds: [],
  };

  dataArray.forEach((element) => {
    if (!Object.prototype.hasOwnProperty.call(element, key)) return;

    result.byId[element[key]] = element;
    result.allIds.push(element[key]);
  });

  return result;
};
