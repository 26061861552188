export const SET_VENDOR_WATCHER_ERROR = 'SET_VENDOR_WATCHER_ERROR';
export const REMOVE_VENDOR_WATCHER_ERROR = 'REMOVE_VENDOR_WATCHER_ERROR';
export const SET_COMPETITORS_ERROR = 'SET_COMPETITORS_ERROR';
export const REMOVE_COMPETITORS_ERROR = 'REMOVE_COMPETITORS_ERROR';
export const SET_VENDOR_ERROR = 'SET_VENDOR_ERROR';
export const REMOVE_VENDOR_ERROR = 'REMOVE_VENDOR_ERROR';
export const SET_PRODUCT_ERROR = 'SET_PRODUCT_ERROR';
export const REMOVE_PRODUCT_ERROR = 'REMOVE_PRODUCT_ERROR';

export const setProductError = () => {
  return {
    type: SET_PRODUCT_ERROR,
  };
};

export const removeProductError = () => {
  return {
    type: REMOVE_PRODUCT_ERROR,
  };
};

export const setVendorError = () => {
  return {
    type: SET_VENDOR_ERROR,
  };
};

export const removeVendorError = () => {
  return {
    type: REMOVE_VENDOR_ERROR,
  };
};

export const setVendorWatcherError = () => {
  return {
    type: SET_VENDOR_WATCHER_ERROR,
  };
};

export const removeVendorWatcherError = () => {
  return {
    type: REMOVE_VENDOR_WATCHER_ERROR,
  };
};

export const setCompetitorsError = () => {
  return {
    type: SET_COMPETITORS_ERROR,
  };
};

export const removeCompetitorsError = () => {
  return {
    type: REMOVE_COMPETITORS_ERROR,
  };
};
