import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './container.module.scss';

/**
 * TrustRadius wrapped Container component
 * Added local styles for this component as well
 */

const defaultProps = {
  variant: 'primary',
};

export const Container = forwardRef(
  ({ children, variant, className, as, ...otherProps }, ref) => {
    // Merge any provided classNames with local component styling
    let classes = classNames(className, styles.container);

    if (variant === 'no-padding') {
      classes = classNames('no-padding', classes);
    } else if (variant === 'no-padding-bottom') {
      classes = classNames('no-padding-bottom', classes);
    } else if (variant === 'aside') {
      classes = classNames('aside', classes);
    } else if (variant === 'no-highlight') {
      classes = classNames('no-highlight', classes);
    } else if (variant === 'no-padding-highlight') {
      classes = classNames('no-padding', classes);
    }

    // None: null,
    //   'No Padding': 'no-padding',
    //   'No Padding Bottom': 'no-padding-bottom',
    //   Aside: 'aside',
    //   'No Highlight': 'no-highlight',

    if (ref) {
      otherProps.ref = ref;
    }
    const Component = as || 'section';
    return (
      <Component className={classes} {...otherProps}>
        {children}
      </Component>
    );
  },
);

Container.displayName = 'Container';
Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
};

Container.defaultProps = defaultProps;
