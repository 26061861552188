import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  RatingStars,
  TopRatedPill,
  TruePill,
} from 'components/TrustradiusUICore';
import { TRScore } from './TRScore';

export function TrustScore({
  insufficientData,
  className,
  showCount,
  ratingCount,
  ratingValue,
  topRatedList,
  showTrueBadge,
  topRatedUrl,
  starSize,
  trScoreSize,
  baseUrl,
}) {
  const classes = classNames('trust-score', className);
  return (
    <div className={classes}>
      <RatingStars
        insufficientData={insufficientData}
        trScore={ratingValue}
        size={starSize}
      />
      <TRScore
        showCount={showCount}
        ratingCount={ratingCount}
        ratingValue={ratingValue}
        insufficientData={insufficientData}
        showPopover
        size={trScoreSize}
        baseUrl={baseUrl}
      />
      {topRatedList && topRatedList.length && (
        <TopRatedPill topRatedAwards={topRatedList} topRatedUrl={topRatedUrl} />
      )}
      {showTrueBadge && <TruePill />}
    </div>
  );
}

TrustScore.propTypes = {
  /** Additional Classes */
  className: PropTypes.string,
  /** Indicates when there is not enough data to display stars or rating. */
  insufficientData: PropTypes.bool,
  /** That value (0-10) of the rating (i.e., the TrustScore). */
  ratingValue: PropTypes.number,
  /** The number of ratings. Only shown if showCount is true. */
  ratingCount: PropTypes.number,
  /** Indicates whether to show the number of ratings. */
  showCount: PropTypes.bool,
  /** Indicates whether to show the TRUE badge. */
  showTrueBadge: PropTypes.bool,
  /** List of current unique Top Rated awards in order, if applicable. */
  topRatedList: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      campaign: PropTypes.string.isRequired,
    }),
  ),
  /** Url for top rated */
  topRatedUrl: PropTypes.string,
  /** Size of stars  **/
  starSize: PropTypes.string,
  /** Size of stars  **/
  trScoreSize: PropTypes.string,
  baseUrl: PropTypes.string,
};

TrustScore.defaultProps = {
  insufficientData: false,
  ratingValue: 0,
  showCount: false,
  showTrueBadge: false,
};
