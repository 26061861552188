import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GET_LEADS_BY_VENDOR_ID } from 'graphql/leads';

export const withVendorLeadsData = (Element) => {
  function WrappedComponent(props) {
    const [searchParams] = useSearchParams();
    const vendorId = useSelector((state) => state.vendors.currentVendor._id);

    const sortParam = searchParams.get('sort-order')
      ? searchParams.get('sort-order').toLowerCase()
      : 'desc';
    const isDesc = sortParam === 'desc';

    const defaultSortField = searchParams.get('sort') || 'createdAt';

    const leads = useQuery(GET_LEADS_BY_VENDOR_ID, {
      variables: {
        vendorId,
        sortField: 'createdAt',
        sortOrder: 'ASC',
      },
    });

    if (leads.error) {
      throw new Error("Can't get lead data");
    }

    const sortHandler = (sortField, sortOrder) => {
      leads.refetch({
        vendorId,
        sortField,
        sortOrder,
      });
    };

    const formattedLeads = leads?.data?.leadsByVendorId?.map((lead) => {
      const newLead = { ...lead };
      newLead.productName = lead?.productName;
      return newLead;
    });

    return (
      <Element
        {...props}
        leads={formattedLeads}
        sortHandler={sortHandler}
        defaultSortField={defaultSortField}
        isDesc={isDesc}
      />
    );
  }

  return WrappedComponent;
};
