import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Tag, Text } from '@trustradius/radiant-ui';
import styles from './SecondaryPageContent.module.scss';

export function UseCases({ content }) {
  const [isOpen, setIsOpen] = useState(true);
  const isTablet = useMedia('(min-width:601px) and (max-width:900px)');

  function onClose() {
    if (isTablet) {
      setIsOpen(!isOpen);
    }
  }

  function getList() {
    const list = (
      <ul className={classNames(styles['no-padding'], styles.tags)}>
        {content.map((tag) => (
          <Text as="li" key={tag}>
            <Tag text={tag} />
          </Text>
        ))}
      </ul>
    );

    if (!isTablet) {
      return list;
    } else if (isOpen) {
      return list;
    }

    return <></>;
  }

  return (
    <section>
      <button type="button" onClick={onClose}>
        <Text as="h3">
          {isTablet && isOpen && <ChevronDown />}
          {isTablet && !isOpen && <ChevronRight />}
          Use Cases
        </Text>
      </button>
      {isTablet && <div className={styles.separator} />}
      {getList()}
    </section>
  );
}

export const UseCasesShape = PropTypes.shape({
  content: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

UseCases.propTypes = UseCasesShape.isRequired;
