import PropTypes from 'prop-types';
import {
  Icon,
  OverlayTrigger,
  Pill,
  Popover,
} from 'components/TrustradiusUICore';

export function TopRatedPill(props) {
  const { text, title, topRatedAwards, topRatedUrl } = props;

  // Loop over awards to determine popover content
  let awardCategoryList = [];

  if (topRatedAwards && topRatedAwards.length) {
    awardCategoryList = topRatedAwards.map((award) => {
      let awardName = award.categoryName;

      // add campaign text if available
      if (award.campaign && award.campaign.length) {
        awardName += ` - ${award.campaign}`;
      }
      return <li key={award.categoryName}>{awardName}</li>;
    });
  }

  const hasAwardCategories = awardCategoryList.length > 0;

  return (
    <OverlayTrigger
      placement="bottom"
      delay={300}
      trigger={['focus', 'hover']}
      interactive
      overlay={
        <Popover>
          <Popover.Title>
            <strong>{title || text}</strong>
          </Popover.Title>
          <Popover.Content>
            {hasAwardCategories && (
              <>
                <ul className="list-unstyled">{awardCategoryList}</ul>
                <a href={topRatedUrl} style={{ marginBottom: 0 }}>
                  Learn More
                </a>
              </>
            )}
          </Popover.Content>
        </Popover>
      }
    >
      <div style={{ display: 'inline-block' }}>
        <Pill>
          <Icon name="star" size={18} color="#116bf2" />
          <span>{text}</span>
        </Pill>
      </div>
    </OverlayTrigger>
  );
}

TopRatedPill.propTypes = {
  /** Text of the badge. */
  text: PropTypes.string,
  /** Title of the pop-over (defaults to the badge text). */
  title: PropTypes.string,
  /** List of current unique Top Rated awards in order. */
  topRatedAwards: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      campaign: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** Url for top rated */
  topRatedUrl: PropTypes.string.isRequired,
};

TopRatedPill.defaultProps = {
  text: 'Top Rated',
};
