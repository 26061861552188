import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapPopover from 'react-bootstrap/Popover';
import classNames from 'classnames';

/**
 * TrustRadius wrapped Popover component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export const Popover = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapPopover
      ref={ref}
      className={classes}
      bsPrefix="tr-popover"
      {...otherProps}
    >
      {children}
    </ReactBootstrapPopover>
  );
});

Popover.displayName = 'Popover';
Popover.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
