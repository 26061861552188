function SortAscending(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6H4ZM3 12C3 11.4477 3.44772 11 4 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H4C3.44772 13 3 12.5523 3 12ZM3 17C3 16.4477 3.44772 16 4 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H4C3.44772 18 3 17.5523 3 17Z"
        fill="#21B1FF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="18"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6H4ZM3 12C3 11.4477 3.44772 11 4 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H4C3.44772 13 3 12.5523 3 12ZM3 17C3 16.4477 3.44772 16 4 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H4C3.44772 18 3 17.5523 3 17Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
}

export default SortAscending;
