import {
  fetchWatchersByProductIdSuccess,
  fetchWatchersByProductIdStart,
  fetchWatchersByProductIdFailed,
} from '../actions/watchersActions';
import {
  removeVendorWatcherError,
  setVendorWatcherError,
} from '../actions/errorsActions';
import { axiosRequestWithAuthToken } from './axios_utils';

export const fetchWatchersByProductId =
  (productId, timeFrame) => async (dispatch) => {
    try {
      dispatch(fetchWatchersByProductIdStart());
      const params = {
        days: timeFrame,
      };
      const options = {
        url: `/api/products/${productId}/watchers`,
        method: 'GET',
        params,
      };
      const data = await axiosRequestWithAuthToken(options);

      if (data.status === 200) {
        dispatch(removeVendorWatcherError());
      } else {
        dispatch(setVendorWatcherError());
      }

      dispatch(fetchWatchersByProductIdSuccess(data.data.records));
    } catch (err) {
      handleError(dispatch, err);
    }
  };

function handleError(dispatch, err) {
  console.error('Fetching watchers failed', err);
  dispatch(fetchWatchersByProductIdFailed(err));
}
