import { useSelector } from 'react-redux';
import { MousePointer, UserCheck, Lightbulb } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { DemostackSquare } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { DemostackBanner, DemostackBanner2x } from './assets';

export function Demostack() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Create, share, and track personalized demo environments for every prospect, without R&D.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Demostack',
    logo: <img src={DemostackSquare} alt="Demostack" />,
    subtitle: subTitle,
    actionBlock: (
      <Button size="large" url="https://www.demostack.com/" targetBlank>
        Learn more
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: 'ability',
      icon: <MousePointer />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: 'tailor',
      icon: <UserCheck />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: 'insights',
      icon: <Lightbulb />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in.',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader {...headerProps} hasShadow />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={DemostackBanner}
            srcSet={`${DemostackBanner} 1x, ${DemostackBanner2x} 2x`}
            alt="Demostack: Showcase your product in the best light, without the hassle."
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Demostack</Text>
          <Text as="p">
            Demostack is the world&rsquo;s #1 demo experience platform. We help
            SaaS revenue leaders accelerate growth with better product
            storytelling. Demostack lets you spin up a Tour or demo environment,
            and tailor the story for each conversation in minutes. No code
            needed. And no more waiting weeks on R&D. Learn more at
            demostack.com.
          </Text>
          <Text as="h3">Demostack and TrustRadius</Text>
          <Text as="p">
            Create a tour on Demostack and embed it anywhere including your
            TrustRadius profile. A tour educates your buyers on your product
            before their first sales call. When you gate your Tour you capture
            key data that can you help you close deals. Like how features speak
            most to them, are there multiple stakeholders coming from the same
            company, and how invested are they in looking for a product.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Create awareness</Text>
          <Text as="p">
            Showcase your product with Tours embedded on TrustRadius, blogs,
            LPs, social and anywhere else
          </Text>
          <Text as="h3">Generate interest & desire</Text>
          <Text as="p">
            Embed tours on your website. Tailor them to each use case.
          </Text>
          <Text as="h3">Spur into action</Text>
          <Text as="p">
            Capture product engagement & send hot prospects to live demos.
          </Text>
          <Text as="h3">Drive sales momentum</Text>
          <Text as="p">
            Show product value after a call by sharing a leave behind and reach
            the buying committee.
          </Text>
          <Text as="h3">Micro demos for events</Text>
          <Text as="p">
            Empower everyone to be able to demo at events with an easy to learn
            micro demo tour. Tool tips optional.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
