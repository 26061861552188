import { ArrowDown, ArrowUp } from 'lucide-react';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';

export function Table({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  additionalStyles,
}) {
  return (
    <>
      <table
        {...getTableProps()}
        className={classNames(styles.table, additionalStyles)}
      >
        <thead className={styles.thead}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  {column.render('Header')}
                  <>
                    {column.isSorted && column.isSortedDesc && (
                      <ArrowDown size="18px" />
                    )}
                    {column.isSorted && !column.isSortedDesc && (
                      <ArrowUp size="18px" />
                    )}
                  </>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

Table.propTypes = {
  additionalStyles: PropTypes.string,
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepareRow: PropTypes.func.isRequired,
};
