import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'components/Skeleton';
import { CardErrorState } from 'components/CardErrorState';
import { getCategoryIntentCardData } from 'store/apis/categoryApi';
import { getProductsFeaturesByVendor } from 'store/apis/productApi';
import { CategoryIntentCard } from 'components/NewsFeed/cards/CategoryIntentCard/CategoryIntentCard';
import styles from 'components/NewsFeed/cards/CategoryIntentCard/CategoryIntentCard.module.scss';

export function CategoryIntentCardContainer() {
  const [categoryWatchers, setCategoryWatchers] = useState({});
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLicense, setHasLicense] = useState(false);

  const product = useSelector((state) => state.vendors.currentVendorProduct);
  const vendor = useSelector((state) => state.vendors.currentVendor);

  useEffect(() => {
    (async () => {
      if (product?._id && !hasLicense) {
        await getTopFiveCategoryWatchers(product._id);
      }
    })();
  }, [product, hasLicense]);

  useEffect(() => {
    (async () => {
      if (vendor && vendor._id) {
        setHasLicense(await checkIfVendorHasLicense(vendor._id));
      }
    })();
  }, [vendor]);

  async function checkIfVendorHasLicense(vendorId) {
    try {
      setHasError(false);
      setIsLoading(true);
      const response = await getProductsFeaturesByVendor(vendorId);
      if (response.status !== 200) {
        setHasError(true);
      }

      return response?.data?.records?.indexOf('api-vi-category-level') !== -1;
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getTopFiveCategoryWatchers(productId) {
    try {
      setHasError(false);
      setIsLoading(true);
      const response = await getCategoryIntentCardData(productId);

      if (response?.status !== 200) {
        setHasError(true);
      }
      setCategoryWatchers(response?.data);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {!isLoading && !hasError && (
        <CategoryIntentCard
          categoryWatchers={categoryWatchers}
          product={product}
          hasLicense={hasLicense}
        />
      )}
      {isLoading && <Skeleton className={styles.loader} />}
      {!isLoading && hasError && (
        <CardErrorState
          refreshHandler={async () => {
            if (product?._id && !hasLicense) {
              await getTopFiveCategoryWatchers(product._id);
            }
          }}
        />
      )}
    </>
  );
}
