import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './reducers';

export function configureStore() {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const composedEnhancers = composeEnhancers(...enhancers);

  const persistedState = {};
  const store = createStore(reducers, persistedState, composedEnhancers);

  return store;
}

export const store = configureStore();
