import { axiosPostWithAuthToken } from './axios_utils';

export const getURL = async (dashboardId, vendorId) => {
  try {
    const options = {
      url: `/api/reportingurl`,
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        SameSite: 'None; Secure',
      },
    };

    const data = await axiosPostWithAuthToken(options, {
      dashboardId,
      vendorId,
    });

    return data;
  } catch (error) {
    console.error(error);
    return '';
  }
};
