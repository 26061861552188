import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getVendorLicensesByProduct } from 'store/apis/vendorApi';

export const withEligibleVendorProducts = (Element) => {
  function WrappedComponent(props) {
    const vendorId = useSelector((state) => state?.vendors?.currentVendor?._id);
    const vendorProducts = useSelector(
      (state) => state?.vendors?.currentVendorProductsById,
    );
    const [eligibleProducts, setEligibleProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      async function fetch() {
        setIsLoading(true);
        const productsWithLicenses = await getVendorLicensesByProduct(vendorId);
        const productIds = Object.keys(productsWithLicenses?.data).filter(
          (productId) => {
            const productFeatures = productsWithLicenses?.data[
              productId
            ]?.features?.map((feature) => feature.slug);
            return productFeatures?.includes('buyer-leads');
          },
        );
        const eligible = [];
        productIds.forEach((productId) => {
          eligible.push(vendorProducts[productId]);
        });

        setEligibleProducts(eligible);
        setIsLoading(false);
      }
      fetch();
    }, [vendorId, vendorProducts]);
    return (
      <Element
        {...props}
        eligibleProducts={eligibleProducts}
        isLoading={isLoading}
      />
    );
  }

  return WrappedComponent;
};
