import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendGTMEvent } from 'utils/gtm';

export function GTMData() {
  const userId = useSelector((state) => state?.users?.user?._id);
  const vendorId = useSelector((state) => state?.vendors?.currentVendor?._id);
  const isTrAdmin = useSelector((state) =>
    state?.users?.rolesAllNames?.includes('vp:tr_admin'),
  );

  const location = useLocation();

  // Send user.updated event when user is logged in
  useEffect(() => {
    if (userId && vendorId) {
      sendGTMEvent('user.updated', { userId, vendorId, isTrAdmin });
    }
  }, [userId, vendorId, isTrAdmin]);

  // Send user pageview event when user is logged in and location changes
  useEffect(() => {
    if (userId && vendorId) {
      sendGTMEvent('user.pageview', { location });
    }
  }, [location, userId, vendorId]);
}
