import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  WEBHOOKS_BY_VENDOR_ID,
  REMOVE_WEBHOOK,
  UPDATE_WEBHOOK,
  CREATE_WEBHOOK,
  CREATE_LEAD_WEBHOOK_PROCESS_JOB_BY_WEBHOOK_ID,
  EXECUTE_WEBHOOK_PROCESS_JOB,
} from 'graphql/webhooks';

export const withWebhookData = (Element) => {
  function WrappedComponent(props) {
    const { client } = props;
    const vendor = useSelector((state) => state?.vendors?.currentVendor);
    const currentProduct = useSelector(
      (state) => state?.vendors?.currentVendorProduct,
    );
    const isLeadFormActivated = vendor?.hasBuyerLeadsActivated || false;
    const [hasError, setHasError] = useState(false);

    const [webhooks, setWebhooks] = useState(null);
    const fetchWebhooksByVendorId = useCallback(async () => {
      try {
        const res = await client.query({
          query: WEBHOOKS_BY_VENDOR_ID,
          variables: { vendorId: vendor?._id },
        });
        const webhooksResult = res?.data?.webhooksByVendorId || [];
        setWebhooks(webhooksResult || []);
      } catch (error) {
        setHasError(true);
      }
    }, [client, vendor?._id]);

    if (hasError) {
      throw new Error("Can't get webhook data");
    }

    async function removeWebhookMutation(id) {
      await client.mutate({
        mutation: REMOVE_WEBHOOK,
        variables: { id },
      });

      const updatedWebhooks = webhooks.filter((webhook) => webhook.id !== id);

      setWebhooks(updatedWebhooks);
    }

    async function sendTestLead(webhookId) {
      const responseObject = await client.query({
        query: CREATE_LEAD_WEBHOOK_PROCESS_JOB_BY_WEBHOOK_ID,
        fetchPolicy: 'no-cache',
        variables: {
          webhookId,
          testLead: {
            firstName: 'Test',
            lastName: 'User',
            email: 'Test@trustradius.com',
            company: 'TrustRadius',
            country: 'US',
            message: 'This is a test lead sent by TrustRadius.',
            interestedIn: 'DEMO',
            vendorId: vendor._id,
            productId: currentProduct._id,
            productName: currentProduct.name,
            privacyConsent: true,
          },
        },
      });

      const configObj = JSON.parse(
        JSON.stringify(
          responseObject?.data?.createLeadWebhookProcessJobByWebhookId,
        ),
      );

      delete configObj?.config?.__typename;
      delete configObj?.__typename;

      const result = await client.mutate({
        mutation: EXECUTE_WEBHOOK_PROCESS_JOB,
        variables: {
          sendWebhookInput: configObj,
        },
        errorPolicy: 'all',
      });

      return result;
    }

    async function updateWebhookMutation(updateWebhookInput) {
      try {
        const updatedWebhook = await client.mutate({
          mutation: UPDATE_WEBHOOK,
          variables: { updateWebhookInput },
        });

        const newWebhooks = webhooks.map((webhook) => {
          if (webhook?.id === updatedWebhook?.data?.updateWebhook?.id) {
            return updatedWebhook?.data?.updateWebhook;
          } else {
            return webhook;
          }
        });
        setWebhooks(newWebhooks);
        return updatedWebhook;
      } catch (err) {
        return { errors: true };
      }
    }

    async function createWebhookMutation(createWebhookInput) {
      try {
        const createdWebhook = await client.mutate({
          mutation: CREATE_WEBHOOK,
          variables: { createWebhookInput },
        });
        if (createdWebhook?.data?.createWebhook?.id) {
          const updatedWebhooks = [
            ...webhooks,
            createdWebhook.data.createWebhook,
          ];
          setWebhooks(updatedWebhooks);
        }
        return createdWebhook;
      } catch (err) {
        return { errors: true };
      }
    }

    useEffect(() => {
      fetchWebhooksByVendorId(vendor?._id);
    }, [fetchWebhooksByVendorId, vendor?._id]);

    return (
      <Element
        {...props}
        webhooks={webhooks}
        isLeadFormActivated={isLeadFormActivated}
        createWebhook={createWebhookMutation}
        updateWebhook={updateWebhookMutation}
        removeWebhook={removeWebhookMutation}
        sendTestLead={sendTestLead}
      />
    );
  }

  WrappedComponent.propTypes = {
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
      mutate: PropTypes.func.isRequired,
    }),
  };

  return WrappedComponent;
};
