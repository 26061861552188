import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { LeadDetailContent } from 'components/LeadDetailContent';
import styles from './LeadDetail.module.scss';

export function LeadDetail({ data, ...otherProps }) {
  return (
    <div {...otherProps}>
      <SecondaryPageHeader
        title="Lead Details"
        backLinkProps={{
          to: '..',
          relative: 'path',
        }}
        hasShadow
      />
      <div className={styles.content}>
        <LeadDetailContent content={data} />
      </div>
    </div>
  );
}

LeadDetail.propTypes = {
  data: PropTypes.object.isRequired,
};
