import { Text, Button, Dialog } from '@trustradius/radiant-ui';
import { ExternalLink } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { SecondaryPageHeader } from 'components/SecondaryPage';
import { TwoColumnTemplate } from 'components/TwoColumnTemplate';
import { LeadCard } from 'components/LeadCard';
import { LeadsHubEmptyState } from 'components/LeadsHubEmptyState';
import { useLeadCards } from 'pages/LeadFormV2/useLeadCards';
import { CircleIcon } from 'components/CircleIcon';
import { CreateLeadModal } from 'components/LeadsV2Modals';
import styles from './LeadFormV2.module.scss';

export function LeadFormV2() {
  const vendorProducts = useSelector(
    (state) => state?.vendors?.currentVendorProductsById,
  );
  const { search } = useLocation();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const cards = useLeadCards(queryString.get('isEmpty'), vendorProducts);

  const getRightColumn = () => {
    return (
      <div className={styles.rightColumn}>
        <Text as="h2" size="300" weight="semibold">
          How your lead form works
        </Text>
        <Text as="p">
          When enabled, buyers can submit their contact details via a lead form
          accessible through a “Contact” CTA on your TrustRadius product
          listing, pricing page, comparison page, and more.{' '}
        </Text>
        <Dialog
          content={
            <>
              <Text as="p">
                The lead form is available for licensed products. To license
                additional products, contact your TrustRadius Customer Success
                Manager.
              </Text>
              <div className={styles['product-list']}>
                {Object.keys(vendorProducts).map((key) => (
                  <div className={styles.product} key={key}>
                    <img
                      src={vendorProducts[key]?.logos?.square180}
                      alt={`${vendorProducts[key].name} logo`}
                    />
                    <Text as="p">{vendorProducts[key].name}</Text>
                  </div>
                ))}
              </div>
            </>
          }
          title="Eligible Products"
          titleProps={{
            as: 'h2',
            size: 300,
            weight: 'semibold',
          }}
          trigger="View eligible products"
          triggerProps={{
            size: 'large',
            variant: 'none',
          }}
        />
        <Text as="h2" size="300" weight="semibold">
          Creating a form
        </Text>
        <Text as="p">
          Keep the buyer experience in mind. Limit the number of fields to
          encourage buyers to complete your form.
        </Text>
        <Text as="h2" size="300" weight="semibold">
          Applying a form
        </Text>
        <Text as="p">
          Once you’ve customized the fields on your lead form to collect the
          data you’re interested in, choose which product listings the form will
          be displayed on.
        </Text>
      </div>
    );
  };

  return (
    <>
      <SecondaryPageHeader
        title="Your Lead Forms"
        subtitle="Customize and enable your lead forms to collect contact information from buyers researching your TrustRadius product listings."
        actionBlock={<CreateLeadModal />}
        hasShadow
        maxWidth="1792px"
      />
      {cards?.length === 0 && (
        <LeadsHubEmptyState
          icon={<CircleIcon iconType="emptyLead" />}
          title="You haven’t created a lead form yet"
          description="Create a lead form to start capturing valuable leads from in-market buyers."
          button={
            <Button variant="secondary">
              Learn more
              <ExternalLink />
            </Button>
          }
          button2={<CreateLeadModal />}
        />
      )}
      {cards?.length > 0 && (
        <TwoColumnTemplate
          leftColumn={
            <>
              {cards?.map((card) => (
                <LeadCard
                  key={card.id}
                  appliedProducts={card?.appliedProducts}
                  appliedWebhooks={card?.appliedWebhooks}
                  title={card?.title}
                  updated={card?.updated}
                />
              ))}
            </>
          }
          rightColumn={getRightColumn()}
        />
      )}
    </>
  );
}
