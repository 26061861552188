import {
  fetchProductMetricsSuccess,
  fetchProductMetricsFailed,
  fetchProductTeamSuccess,
  fetchProductTeamFailed,
} from '../actions/productActions';
import { axiosRequestWithAuthToken } from './axios_utils';

export const fetchProductMetricsByProductId =
  (productId, timeFrame) => async (dispatch) => {
    try {
      if (!productId) {
        throw new Error('Missed productId');
      }

      const params = {
        days: timeFrame,
      };

      const options = {
        url: `/api/products/${productId}/metrics`,
        method: 'GET',
        params,
      };
      const data = await axiosRequestWithAuthToken(options);
      dispatch(fetchProductMetricsSuccess(data.data.records));
    } catch (error) {
      console.error(error);
      dispatch(fetchProductMetricsFailed(error));
    }
  };

export const fetchProductTeam = (productId) => async (dispatch) => {
  try {
    if (!productId) {
      throw new Error('Missed productId');
    }

    const options = {
      url: `/api/products/${productId}/team`,
      method: 'GET',
    };
    const data = await axiosRequestWithAuthToken(options);
    dispatch(fetchProductTeamSuccess(data.data.records));
  } catch (error) {
    console.error(error);
    dispatch(fetchProductTeamFailed(error));
  }
};

export const getLastPublishedReviewByProductId = async (productId) => {
  try {
    const params = {
      sort: 'publishedDate',
      order: 'desc',
      limit: 1,
    };
    const options = {
      url: `/api/products/${productId}/reviews`,
      method: 'GET',
      params,
    };

    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductsFeaturesByVendor = async (vendorId) => {
  try {
    const options = {
      url: `/api/vendors/${vendorId}/features`,
      method: 'GET',
    };

    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getVendorIntentRegistration = async (vendorId, partnerName) => {
  try {
    const options = {
      url: `/api/vendors/${vendorId}/intent_registration?partnerName=${partnerName}`,
      method: 'GET',
    };

    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getTrustquotesWebSummary = async (productId) => {
  try {
    const options = {
      url: `/api/tqw/summary/${productId}`,
      method: 'GET',
    };

    const data = await axiosRequestWithAuthToken(options);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductLogos = async (productIds) => {
  try {
    const params = {
      fields: 'logos',
      where: { _id: { $in: productIds } },
    };
    const options = {
      url: `/api/products`,
      method: 'GET',
      params,
    };

    const data = await axiosRequestWithAuthToken(options);
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
