import PropTypes from 'prop-types';

export function SilentAuthIframe({ show, useNewAuthFlow, silentAuthUrl }) {
  console.log(
    `SilentAuthIframe: show=${show} useNewAuthFlow=${useNewAuthFlow}`,
  );

  const renderIframe = show && useNewAuthFlow;

  if (!renderIframe) {
    return null;
  }

  return <iframe title="silent-auth" src={silentAuthUrl} hidden />;
}

SilentAuthIframe.propTypes = {
  show: PropTypes.bool.isRequired,
  useNewAuthFlow: PropTypes.bool.isRequired,
  silentAuthUrl: PropTypes.string.isRequired,
};
