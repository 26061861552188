import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Image, Logo } from 'components/TrustradiusUICore';

/**
 * ClearbitLogo component uses Clearbit Logo service to retrieve businesses
 * logos based on their website. Will attempt specific size from the service,
 * fallback to not specifying a size to Clearbit, and lastly fallback on showing
 * a no logo png
 */
export class ClearbitLogo extends Component {
  static propTypes = {
    website: PropTypes.string,
    // array of size numbers to attempt against Clearbit logo service in priority order
    sizes: PropTypes.arrayOf(PropTypes.number),
  };

  state = {
    // failedSizes tracks all sizes that have failed to load from Clearbit so
    // we can use the next one from sizes prop
    failedSizes: [],
    defaultFailed: false,
  };

  // When the image onError occurs, handle multiple possible situations
  onError = () => {
    const { sizes } = this.props;
    const { failedSizes, defaultFailed } = this.state;

    // Tried with size and it failed apparently, so set state to note that is
    // the case
    if (sizes && _.last(failedSizes) !== _.last(sizes)) {
      this.setState((state) => {
        // Concatenating to failedSizes array
        return {
          failedSizes: [
            ...state.failedSizes,
            sizes[sizes.indexOf(_.last(failedSizes)) + 1],
          ],
        };
      });
    } else if (!defaultFailed) {
      // Tried without a size sent to Clearbit so set state to note that is the
      // case
      this.setState({ defaultFailed: true });
    }
  };

  render() {
    const { website, sizes } = this.props;
    const { failedSizes, defaultFailed } = this.state;

    // If no website specified or if the no-size option has already failed
    if (!website || defaultFailed) {
      return <Logo />;
    } else {
      let clearbitUrl = `https://logo.clearbit.com/${website}`;

      // If there is a size and the request to clearbit for that size failed,
      // put together a styles object to maintain the size that we want
      let imageStyle = {};
      // If there is a sizes array, use first size in there that hasn't failed
      if (sizes) {
        // Get last size that has failed
        const lastSizeFailed = _.last(failedSizes);
        const lastSizeFailedIndex = sizes.indexOf(lastSizeFailed);
        // If there are more sizes left to try, do so
        if (sizes.length > lastSizeFailedIndex + 1) {
          const nextSizeToTry = sizes[lastSizeFailedIndex + 1];
          clearbitUrl += `?size=${nextSizeToTry}`;
          imageStyle = {
            maxWidth: nextSizeToTry,
            maxHeight: nextSizeToTry,
          };
        }
      }

      return (
        <Image
          style={imageStyle}
          src={clearbitUrl}
          onError={this.onError}
          alt={website}
        />
      );
    }
  }
}
