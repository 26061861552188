function HomeCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <title>Homepage</title>
      <path
        d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm6.5,23.85a1,1,0,0,1-.94,1H21a1,1,0,0,1-.94-1V20.52a1,1,0,0,0-1-1H16.92a1,1,0,0,0-.94,1v3.33a1,1,0,0,1-.94,1h-2.6a1,1,0,0,1-.94-1V16.64a1,1,0,0,1,.36-.78l5.45-4.44a1.09,1.09,0,0,1,1.38,0l5.45,4.44a1,1,0,0,1,.36.78Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default HomeCircle;
