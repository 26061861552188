export const csvHeaders = [
  {
    label: 'Report Date',
    key: 'now',
  },
  {
    label: 'Vendor Name',
    key: 'vendor.name',
  },
  {
    label: 'Vendor Slug',
    key: 'vendor.slug',
  },
  {
    label: 'Product Name',
    key: 'product.name',
  },
  {
    label: 'Product Slug',
    key: 'product.slug',
  },
  {
    label: 'Category Name',
    key: 'category.name',
  },
  {
    label: 'Category Slug',
    key: 'category.slug',
  },
  {
    label: 'Meeting Criteria',
    key: 'meetingCriteria',
  },
  {
    label: 'Recency Criteria',
    key: 'award.recencyCriteria',
  },
  {
    label: 'Recency',
    key: 'recencyProgress',
  },
  {
    label: 'Rating Criteria',
    key: 'award.ratingCriteria',
  },
  {
    label: 'Rating',
    key: 'ratingProgress',
  },
  {
    label: 'Relevancy Criteria',
    key: 'award.relevancyCriteria',
  },
  {
    label: 'Relevancy',
    key: 'relevancyProgress',
  },
];
