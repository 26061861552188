import { useSelector } from 'react-redux';
import { Lightbulb, MousePointer, UserCheck } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { WalnutSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { walnutBanner, walnutBanner2x } from './assets';

export function Walnut() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle =
    'Create personalized & interactive product demos that convert leads and close more deals';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Walnut',
    logo: <img src={WalnutSquareLogo} alt="Walnut" />,
    subtitle: subTitle,
    actionBlock: (
      <Button
        size="large"
        url="https://www.walnut.io/lp/trustradius/?utm_source=referral&utm_medium=TrustRadius&utm_campaign=marketplaces_growth_initiatives_November22"
        targetBlank
      >
        Create a free demo
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: '1',
      icon: <MousePointer />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: '2',
      icon: <UserCheck />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: '3',
      icon: <Lightbulb />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in.',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={walnutBanner}
            srcSet={`${walnutBanner} 1x, ${walnutBanner2x} 2x`}
            alt="Walnut"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Walnut</Text>
          <Text as="p">
            With Walnut, B2B sales and marketing teams can create interactive
            and customized product demos without coding; sellers and marketers
            can scale their demo personalization, share interactive and guided
            demos with prospects, and collect insights to optimize their sales
            cycle; and buyers can discover the seller&apos;s product early on in
            the process and enjoy tailor-made demos that focus on value.
          </Text>
          <Text as="h3">Walnut and TrustRadius</Text>
          <Text as="p">
            Implementing interactive demos on your TrustRadius page can result
            in higher lead conversion rates compared to previous periods, as
            well as better conversion rates than competitors who do not offer
            interactive demos.
          </Text>
          <Text as="p">
            Walnut also tracks and surfaces granular analytics on demo
            engagements, enabling sellers with better intel as they receive
            inbound leads, as well as enabling product marketers to optimize
            their demos over time.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Take buyers on a memorable journey</Text>
          <Text as="p">
            Enable buyers to take a self-guided tour of your product so they can
            have a better understanding of how it works and evaluate whether
            it&apos;s the right fit for their organization.
          </Text>
          <Text as="h3">Personalize the buyer experience</Text>
          <Text as="p">
            Let buyers explore use case-based experiences of your product
            according to their needs versus showing them generic
            one-size-fits-all video and screenshots.
          </Text>
          <Text as="h3">
            Tap into sales intelligence when engaging prospects
          </Text>
          <Text as="p">
            Let buyers explore use case-based experiences of your product
            according to their needs versus showing them generic
            one-size-fits-all video and screenshots.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
