import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { X } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@trustradius/radiant-ui';
import styles from './Modal.module.scss';

export function Modal({
  trigger,
  image,
  title,
  waiting = false,
  description,
  closeText = 'Close',
  actionText = 'Action',
  onAction = () => console.log('action'),
  onOpenChange,
  warning,
  closeWarning,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const isWaiting = waiting;

  const handleAction = async () => {
    try {
      if (onAction) {
        await onAction();
        setOpen(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const closeButtonVariant = closeWarning ? 'secondary-warning' : 'secondary';
  const actionButtonVariant = warning ? 'primary-warning' : 'primary';

  const spinner = <div className={styles.spinner} />;

  const actions = (
    <div className={styles.actions}>
      <Dialog.Close asChild>
        <Button variant={closeButtonVariant} size="large">
          {closeText}
        </Button>
      </Dialog.Close>
      <Button variant={actionButtonVariant} size="large" onClick={handleAction}>
        {actionText}
      </Button>
    </div>
  );

  const renderContent = isWaiting ? spinner : actions;

  return (
    <Dialog.Root
      className={styles.root}
      open={open}
      onOpenChange={(newOpen) => {
        setOpen(newOpen);
        if (onOpenChange) {
          onOpenChange(newOpen);
        }
      }}
      {...props}
    >
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={classNames(styles.content, image && styles['has-image'])}
        >
          {image}
          <Dialog.Title className={styles.title}>{title}</Dialog.Title>
          <Dialog.Description className={styles.description}>
            {description}
          </Dialog.Description>
          {error && <p className={styles.error}>Error: {error}</p>}
          {renderContent}
          <Dialog.Close asChild>
            <button className={styles.close} aria-label="Close" type="button">
              <X />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

Modal.propTypes = {
  trigger: PropTypes.node,
  waiting: PropTypes.bool,
  image: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  defaultOpen: PropTypes.bool,
  closeText: PropTypes.string,
  actionText: PropTypes.string,
  onOpenChange: PropTypes.func,
  onAction: PropTypes.func,
  warning: PropTypes.bool,
  closeWarning: PropTypes.bool,
};
