import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '@trustradius/radiant-ui';
import { Link } from 'react-router-dom';
import styles from './IntegrationsCard.module.scss';
import 'components/TrustradiusUICore/Button/Button.scss';

function CardContent({ logo, title, text, isActive = true, children }) {
  return (
    <>
      <div className={styles['card-content']}>
        <div className={styles.header}>
          <div className={styles['image-container']}>
            <img src={logo} alt={title} />
          </div>
          {!isActive && (
            <div className={styles['coming-soon-label']}>
              <Text size="50" weight="black">
                Coming soon
              </Text>
            </div>
          )}
        </div>
        <Text as="h2" size="300" className={styles.title}>
          {title}
        </Text>
        <Text size="200" className={styles.text}>
          {text}
        </Text>
      </div>
      <div className={styles['card-footer']}>{children}</div>
    </>
  );
}

CardContent.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

export function IntegrationsCard({ logo, title, text, linkProps = {} }) {
  if (!linkProps.to && !linkProps.external) {
    return (
      <div className={classNames(styles.card, styles['coming-soon'])}>
        <CardContent logo={logo} title={title} text={text} isActive={false}>
          <div className={styles['learn-more-disabled']}>Learn more</div>
        </CardContent>
      </div>
    );
  }

  if (linkProps.external) {
    return (
      <a
        className={styles.card}
        href={linkProps.external}
        rel="noopener noreferrer"
      >
        <CardContent logo={logo} title={title} text={text}>
          {linkProps.children && (
            <div className={styles['card-learn-more']}>
              {linkProps.children}
            </div>
          )}
        </CardContent>
      </a>
    );
  }

  return (
    <Link className={styles.card} {...linkProps}>
      <CardContent logo={logo} title={title} text={text}>
        {linkProps.children && (
          <div className={styles['card-learn-more']}>{linkProps.children}</div>
        )}
      </CardContent>
    </Link>
  );
}

IntegrationsCard.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkProps: PropTypes.shape({
    to: PropTypes.string,
    external: PropTypes.string,
    children: PropTypes.node,
  }),
};
