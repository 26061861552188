import { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import classnames from 'classnames';
import styles from './ReviewGenerationCaseStudy.module.scss';
import { caseStudies } from './caseStudies';
import { ReviewGenerationCaseStudy } from './ReviewGenerationCaseStudy';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

export function ReviewGenerationCaseStudyContainer() {
  const [swiper, setSwiper] = useState(null);
  const params = {
    modules: [Navigation, Pagination],
    spaceBetween: 0,
    slidesPerView: 1,
    watchOverflow: true,
    grabCursor: true,
    pagination: {
      el: `.case-study`,
      dynamicBullets: true,
      clickable: true,
    },
  };

  const checkButtonsDisabled = (swiperInstance, prevButton, nextButton) => {
    if (prevButton) {
      prevButton.disabled = swiperInstance.isBeginning;
    }
    if (nextButton) {
      nextButton.disabled = Boolean(swiperInstance.isEnd);
    }
  };

  const goNext = () => {
    swiper?.slideNext();
  };

  const goPrev = () => {
    swiper?.slidePrev();
  };

  useEffect(() => {
    const mySwiper = document.querySelector('.case-study-carousel')?.swiper;
    const prevButton = document.querySelector(`#${styles.prevButton}`);
    const nextButton = document.querySelector(`#${styles.nextButton}`);
    if (mySwiper) {
      setSwiper(mySwiper);
      checkButtonsDisabled(mySwiper, prevButton, nextButton);

      mySwiper.on('slideChange', function () {
        checkButtonsDisabled(mySwiper, prevButton, nextButton);
      });
    }
  }, []);

  return (
    <>
      <Swiper {...params} className="case-study-carousel">
        {caseStudies?.caseStudies?.map((caseStudy) => (
          <SwiperSlide key={caseStudy.id}>
            <ReviewGenerationCaseStudy caseStudy={caseStudy} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className={classnames(
          styles['swipe-buttons'],
          styles['bottom-swipe-buttons'],
        )}
      >
        <button
          id={styles.prevButton}
          type="button"
          onClick={goPrev}
          className={styles['button-prev']}
          data-tracking="scroll-arrow-left-recent-reviews"
          aria-label="Previous"
        >
          <ChevronLeft aria-hidden="true" />
        </button>
        <div className={classnames('case-study')} id={styles.pagination} />
        <button
          id={styles.nextButton}
          type="button"
          onClick={goNext}
          className={styles['button-next']}
          data-tracking="scroll-arrow-right-recent-reviews"
          aria-label="Next"
        >
          <ChevronRight aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
