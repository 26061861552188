import { Text, Button } from '@trustradius/radiant-ui';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { AlertTriangle, ArrowRight, MoreHorizontal } from 'lucide-react';
import moment from 'moment/moment';
import { useMedia } from 'react-use';
import { useState } from 'react';
import { DropdownMenu } from 'components/DropdownMenu';
import {
  DeleteWebhookModal,
  RenameWebhookModal,
} from 'components/LeadsV2Modals';
import styles from './WebhookCard.module.scss';

export function WebhookCard({
  title,
  updatedAt,
  connectedLeadForm,
  destination,
  hasError,
  hasUpdatedFields,
}) {
  const isDesktop = useMedia('(min-width: 901px)');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const dropDownItems = [
    {
      id: 'edit',
      node: (
        <div className={styles.action}>
          <Button variant="none">Edit webhook</Button>
        </div>
      ),
    },
    {
      id: 'rename',
      node: (
        <div className={styles.action}>
          <Button variant="none" onClick={() => setShowRenameModal(true)}>
            Rename
          </Button>
        </div>
      ),
    },
    {
      id: 'delete',
      node: (
        <div className={styles.action}>
          <Button variant="none" onClick={() => setShowDeleteModal(true)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const statusText = hasError ? 'Unable to deliver leads' : 'Delivering leads';

  return (
    <div className={styles.container}>
      <div className={styles['content-container']}>
        <div
          className={classNames(styles.header, { [styles.mobile]: !isDesktop })}
        >
          <Text as="h2" size="300" weight="semibold">
            {title}
          </Text>
          <Text size="100">
            Last updated:{' '}
            <span>{moment(updatedAt).format('MMM DD, YYYY hh:mm A')}</span>
          </Text>
        </div>
        <div
          className={classNames(styles.body, { [styles.mobile]: !isDesktop })}
        >
          <div className={styles.from}>
            <Text size="100">Sending data from</Text>
            <Button variant="none" url="/">
              <Text size="200">{connectedLeadForm}</Text>
            </Button>
          </div>
          <div className={styles.to}>
            <Text size="100">Sending data to</Text>
            <Text size="200">{destination}</Text>
          </div>
          <div className={styles.status}>
            <Text size="100">Webhook status</Text>
            <div
              className={classNames(styles['status-text'], {
                [styles['status-error']]: hasError,
              })}
            >
              <Text size="100">{statusText}</Text>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.actions, {
            [styles.mobile]: !isDesktop,
          })}
        >
          <DropdownMenu
            dropdownTrigger={
              <div className={styles.icon}>
                <MoreHorizontal />
              </div>
            }
            items={dropDownItems}
          />
        </div>
      </div>
      {hasUpdatedFields && (
        <div className={styles.error}>
          <div className={styles['alert-message']}>
            <div className={styles['alert-icon']}>
              <AlertTriangle />
            </div>
            <Text size="100">
              You’ve added new custom fields to your lead form. To receive this
              data, remember to update the corresponding destination fields in
              your webhook.
            </Text>
          </div>
          <Button url="/">
            <Text size="100" weight="semibold">
              Update webhook <ArrowRight />
            </Text>
          </Button>
        </div>
      )}

      <DeleteWebhookModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
      />
      <RenameWebhookModal
        isOpen={showRenameModal}
        closeHandler={() => setShowRenameModal(false)}
      />
    </div>
  );
}

WebhookCard.propTypes = {
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  connectedLeadForm: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  hasUpdatedFields: PropTypes.bool,
};
