import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapPopover from 'react-bootstrap/Popover';
import classNames from 'classnames';

// Mimicking the access pattern that react bootstrap sets up for the inner
// components for Popover so we can specify bsPrefix prop
export const PopoverContent = forwardRef((props, ref) => {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className);
  // Provide default variant
  return (
    <ReactBootstrapPopover.Content
      ref={ref}
      className={classes}
      bsPrefix="tr-popover-body"
      {...otherProps}
    >
      {children}
    </ReactBootstrapPopover.Content>
  );
});

PopoverContent.displayName = 'PopoverContent';
PopoverContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
