export const FETCH_WATCHERS_BY_VENDOR_ID_START =
  'FETCH_WATCHERS_BY_VENDOR_ID_START';
export const FETCH_WATCHERS_BY_VENDOR_ID_FAILED =
  'FETCH_WATCHERS_BY_VENDOR_ID_FAILED';
export const FETCH_WATCHERS_BY_VENDOR_ID_SUCCESS =
  'FETCH_WATCHERS_BY_VENDOR_ID_SUCCESS';

export const fetchWatchersByProductIdStart = () => ({
  type: FETCH_WATCHERS_BY_VENDOR_ID_START,
});

export const fetchWatchersByProductIdSuccess = (watchers) => ({
  type: FETCH_WATCHERS_BY_VENDOR_ID_SUCCESS,
  payload: {
    watchers,
  },
});

export const fetchWatchersByProductIdFailed = (error) => ({
  type: FETCH_WATCHERS_BY_VENDOR_ID_FAILED,
  payload: {
    error,
  },
});
