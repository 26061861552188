import { Text, TextArea } from '@trustradius/radiant-ui';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { useState } from 'react';
import { TextFieldWithCounter } from 'components/TextFieldWithCounter';
import styles from './CreateCheckboxField.module.scss';

export function CreateCheckboxField({
  labelTitle = 'Field Type',
  labelValue,
  textAreaValue,
  maxTextAreaLength,
  labelNameFieldChangeHandler,
  textAreaChangeHandler,
  hideNameConfig,
}) {
  const hasError = textAreaValue?.length > maxTextAreaLength;
  const [internalTextareaValue, setInternalTextareaValue] =
    useState(textAreaValue);

  const debouncedTextAreaChangeHandler = _.debounce(textAreaChangeHandler, 500);
  const errorClass = hasError ? styles.error : '';
  const internalTextAreaChangeHandler = (value) => {
    setInternalTextareaValue(value);
    debouncedTextAreaChangeHandler(value);
  };

  return (
    <>
      <div className={styles.container}>
        {!hideNameConfig && (
          <div className={styles['name-container']}>
            <TextFieldWithCounter
              fieldLabel={labelTitle}
              fieldChangeHandler={labelNameFieldChangeHandler}
              isRequired
              fieldValue={labelValue}
              maxValueLength={25}
            />
          </div>
        )}
        <TextArea
          data-testid="text-area"
          label="Checkbox text"
          value={internalTextareaValue}
          required
          isInvalid={hasError}
          onChange={(event) =>
            internalTextAreaChangeHandler(event.target.value)
          }
        />
        {maxTextAreaLength && (
          <Text className={`${styles.gray70} ${errorClass}`} size="100">
            {textAreaValue?.length || 0} / {maxTextAreaLength}
          </Text>
        )}
        <div className={styles.example}>
          <Text as="p" size="100">
            <strong>To insert a link:</strong>
            &nbsp;Enclose the link text in square brackets, then followed
            immediately with the URL in parentheses.
          </Text>
          <Text as="p" size="100">
            <strong>Example:</strong>
            &nbsp;[TrustRadius](https://trustradius.com)
          </Text>
        </div>
      </div>
    </>
  );
}

CreateCheckboxField.propTypes = {
  labelTitle: PropTypes.string,
  labelValue: PropTypes.string,
  textAreaValue: PropTypes.string,
  maxTextAreaLength: PropTypes.number,
  labelNameFieldChangeHandler: PropTypes.func,
  textAreaChangeHandler: PropTypes.func,
  hideNameConfig: PropTypes.bool,
};
