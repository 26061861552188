import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import CustomerVerified from './CustomerVerified';
import Download from './Download';
import Eye from './Eye';
import FacebookCircle from './FacebookCircle';
import HelpCircle from './HelpCircle';
import HomeCircle from './HomeCircle';
import Home from './Home';
import LinkedInCircle from './LinkedInCircle';
import Plus from './Plus';
import TwitterCircle from './TwitterCircle';
import Twitter from './Twitter';
import SortAscending from './SortAscending';
import SortDescending from './SortDescending';
import TRFlag from './TRFlag';
import Star from './Star';
import Money from './Money';
import MoneyCircle from './MoneyCircle';
import CircleCheck from './CircleCheck';
import CircleX from './CircleX';
import SolidCircleCheck from './SolidCircleCheck';
import LinkedIn from './LinkedIn';
import RatingStar from './RatingStar';
import RatingStarHalf from './RatingStarHalf';
import RatingStarEmpty from './RatingStarEmpty';
import Search from './Search';
import CheckCircle2 from './CheckCircle2';
import XCircle from './XCircle';
import Key from './Key';

// Dictionary of icon names to the respective component
const icons = {
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'help-circle': HelpCircle,
  download: Download,
  twitter: Twitter,
  home: Home,
  plus: Plus,
  'home-circle': HomeCircle,
  'twitter-circle': TwitterCircle,
  'facebook-circle': FacebookCircle,
  'linkedin-circle': LinkedInCircle,
  linkedin: LinkedIn,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'sort-ascending': SortAscending,
  'sort-descending': SortDescending,
  eye: Eye,
  'customer-verified': CustomerVerified,
  'tr-flag': TRFlag,
  star: Star,
  money: Money,
  'money-circle': MoneyCircle,
  'circle-x': CircleX,
  'circle-check': CircleCheck,
  'solid-circle-check': SolidCircleCheck,
  'rating-star': RatingStar,
  'rating-star-half': RatingStarHalf,
  'rating-star-empty': RatingStarEmpty,
  search: Search,
  'check-circle-2': CheckCircle2,
  'x-circle': XCircle,
  key: Key,
};

export default icons;
