import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'components/TrustradiusUICore';
import styles from './LeadDetailContent.module.scss';

export function LeadDetailContent({
  content,
  fallbackText = 'Not provided',
  showWebhookDeliveryStatus = false,
}) {
  const {
    name,
    date,
    phone,
    email,
    location,
    company,
    product,
    interest,
    message,
    webhookDeliveryStatus,
  } = content;
  const hasContact = phone || email;

  return (
    <section className={styles.section}>
      <header>
        <h2 className={styles.heading}>{name}</h2>
        <p className={styles['date-time']}>{date && <span>{date}</span>}</p>
      </header>
      <dl className={styles.dl}>
        {hasContact && (
          <>
            <dt>Contact</dt>
            {phone && <dd>{phone}</dd>}
            {email && <dd>{email}</dd>}
          </>
        )}

        <dt>Location</dt>
        <dd>{location || fallbackText}</dd>

        <dt>Company</dt>
        <dd>{company || fallbackText}</dd>

        <dt>Product</dt>
        <dd>{product || fallbackText}</dd>

        {interest && (
          <>
            <dt>Interested In</dt>
            <dd>
              <ul className={styles.tags}>
                {interest.map((tag) => (
                  <li key={tag}>
                    <Tag>{tag}</Tag>
                  </li>
                ))}
              </ul>
            </dd>
          </>
        )}

        {message && (
          <>
            <dt>Message</dt>
            <dd>{message}</dd>
          </>
        )}

        {showWebhookDeliveryStatus && (
          <>
            <dt>Webhook Delivery Status</dt>
            <dd>{webhookDeliveryStatus || fallbackText}</dd>
          </>
        )}
      </dl>
    </section>
  );
}

LeadDetailContent.propTypes = {
  fallbackText: PropTypes.string,
  showWebhookDeliveryStatus: PropTypes.bool,
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    company: PropTypes.string,
    product: PropTypes.string,
    date: PropTypes.string.isRequired,
    phone: PropTypes.string,
    location: PropTypes.string,
    interest: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string,
    webhookDeliveryStatus: PropTypes.string,
  }).isRequired,
};
