/* eslint-disable @trustradius/jsx-prefer-fragment-wrappers */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Popover from '@radix-ui/react-popover';
import { Search } from 'lucide-react';
import { Checkbox } from 'components/TrustradiusUICore';
import { Arrow } from './Arrow';
import styles from './Switcher.module.scss';

export function Switcher({
  data,
  triggerText = 'Products',
  listName = 'products',
}) {
  const [checkedItems, setCheckedItems] = useState(() => {
    const initialCheckedItems = data
      .filter((item) => item.checked)
      .map((item) => item.name);
    return initialCheckedItems;
  });

  const [filterText, setFilterText] = useState('');

  // Checkbox change event handler
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  // "Select All" checkbox change event handler
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems(data.map((item) => item.name));
    } else {
      setCheckedItems([]);
    }
  };

  // Filter the checkbox items by label
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  // Function to return the selected items summary
  const getSelectedItemsSummary = () => {
    if (checkedItems.length === 0) {
      return 'No Products Selected';
    } else if (checkedItems.length === 1) {
      return checkedItems[0];
    } else if (checkedItems.length === data.length) {
      return 'All Products';
    } else {
      return `${checkedItems.length} Products Selected`;
    }
  };

  return (
    <>
      <Popover.Root>
        <Popover.Trigger className={styles.trigger}>
          <span>
            <b>{triggerText}:</b> {getSelectedItemsSummary()}
          </span>
          <Arrow />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className={styles['popover-content']}
            sideOffset={8}
            align="start"
          >
            <div className={styles.search}>
              <input
                className={styles['search-input']}
                aria-label="Search"
                type="input"
                placeholder="Search products"
                value={filterText}
                onChange={(event) => setFilterText(event.target.value)}
              />
              <div className={styles['search-icon']} aria-hidden>
                <Search />
              </div>
            </div>
            <div className={styles['scroll-area']}>
              <div className={styles.content}>
                <div className={styles['all-products']}>
                  <Checkbox
                    label="All products"
                    id="all-products"
                    name={listName}
                    value="All Products"
                    checked={checkedItems.length === data.length}
                    onChange={handleSelectAll}
                  />
                </div>
                <fieldset className={styles.fieldset}>
                  <legend className={styles.legend}>Licensed products</legend>
                  <ul className={styles.list}>
                    {filteredData.map((item) => {
                      return (
                        <li key={item._id}>
                          <Checkbox
                            label={item.name}
                            value={item.name}
                            id={`product-${item._id}`}
                            name={listName}
                            checked={checkedItems.includes(item.name)}
                            onChange={handleCheckboxChange}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </fieldset>
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
}

const dataShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
});

Switcher.propTypes = {
  triggerText: PropTypes.string,
  data: PropTypes.arrayOf(dataShape),
  listName: PropTypes.string,
};
