import PropTypes from 'prop-types';
import { Button } from '@trustradius/radiant-ui';
import styles from './HeroButton.module.scss';

export function HeroButton({ text, href }) {
  return (
    <p className={styles.wrapper}>
      <Button size="large" url={href}>
        {text}
      </Button>
    </p>
  );
}

HeroButton.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
