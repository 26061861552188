import PropTypes from 'prop-types';
import { useSettings } from 'settings';
import { TRLogoImage } from './assets';

export function TRLogo({ className }) {
  const settings = useSettings();

  return (
    <a
      className={className}
      href={settings?.buyerSiteUrl}
      target="_blank"
      rel="noreferrer"
      aria-label="TrustRadius"
    >
      <TRLogoImage alt="TrustRadius" />
    </a>
  );
}

TRLogo.propTypes = {
  className: PropTypes.string,
};
