import 'focus-visible';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';
import '@trustradius/radiant-ui/build/style.css';
import { useMemo, StrictMode } from 'react';
import { useSelector } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  createRoutesFromElements,
} from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { TopRatedDetails } from 'pages/TopRatedDetails';
import { TechCaresDetails } from 'pages/TechCaresDetails';
import { VendorHome } from 'pages/VendorHome';
import { RequestAccess } from 'pages/RequestAccess';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AuthenticatedPageWrapper } from 'components/AuthenticatedPageWrapper';
import { Spinner } from 'components/Spinner';
import { Dashboard } from 'pages/Dashboard';
import { Welcome } from 'pages/Welcome';
import { Awards, awardsLoader } from 'pages/Awards';
import { Metrics } from 'pages/Metrics';
import { Integrations } from 'pages/Integrations';
import {
  LinkedinMatchedAudiencesPage,
  linkedinMatchedAudiencesLoader,
} from 'pages/LinkedinMatchedAudiencesPage';
import {
  LinkedinSegmentsPage,
  linkedinSegmentsPageLoader,
} from 'pages/LinkedinSegmentsPage';
import {
  LinkedinCreateSegmentPage,
  linkedinCreateSegmentPageLoader,
} from 'pages/LinkedinCreateSegmentPage';
import {
  RemoveLinkedinIntegrationModal,
  removeLinkedinIntegrationAction,
} from 'pages/RemoveLinkedinIntegrationModal';
import '_styles/global.scss';
import { DemandBase } from 'pages/DemandBase';
import { SixSense } from 'pages/SixSense';
import { Salesforce, salesforceLoader } from 'pages/Salesforce';
import { Walnut } from 'pages/Walnut';
import { Whistic } from 'pages/Whistic';
import { ScreenSpace } from 'pages/Screenspace';
import { LeadFormWithData } from 'pages/LeadForm';
import { Navattic } from 'pages/Navattic';
import { Storylane } from 'pages/Storylane';
import { Tourial } from 'pages/Tourial';
import { Testbox } from 'pages/Testbox';
import { Demostack } from 'pages/Demostack';
import { ZoominfoContainer } from 'pages/Zoominfo';
import { ZoominfoConnectContainer } from 'pages/ZoominfoConnect';
import { LeadDetailContainer } from 'pages/LeadDetail';
import { LeadActivitiesWithData } from 'pages/LeadActivities';
import { leadsWebhookLoader, LeadsWebhookWithData } from 'pages/LeadsWebhook';
import { LeadsOverviewWithData } from 'pages/LeadsOverview';
import { Intentsify } from 'pages/Intentsify';
import { ReviewGeneration } from 'pages/ReviewGeneration';
import { LeadFormV2 } from 'pages/LeadFormV2';
import { ProtectedLayout } from 'components/ProtectedLayout/ProtectedLayout';
import { LeadsV2Webhooks } from 'pages/LeadsV2Webhooks';
import { WebhookConfigurationPage } from 'pages/WebhookConfigurationPage';
import { LeadFormConfiguration } from 'pages/LeadFormConfiguration';
import styles from './App.module.scss';

function App() {
  const { hasErrors } = useSelector((state) => state.globalState);
  const isDesktop = useMedia('(min-width:901px)');
  const isMenuOpen = useSelector(
    (state) => state.globalState.isMenuContainerExpanded,
  );
  const isNewLeadsEnabled = useFeatureIsOn('leads-v2');

  const router = useMemo(() => {
    return createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" errorElement={<ErrorBoundary hasError />}>
          <Route index element={<Navigate to="/vendor" />} />
          <Route path="request-access" element={<RequestAccess />} />
          <Route element={<AuthenticatedPageWrapper />}>
            <Route path="vendor" element={<VendorHome />}>
              <Route
                path=":vendorId/dashboard/:productId"
                element={<Dashboard />}
              />
              <Route
                path=":vendorId/buyers/leads-activity"
                element={
                  <StrictMode>
                    <LeadActivitiesWithData />
                  </StrictMode>
                }
              />
              <Route path=":vendorId/welcome" element={<Welcome />} />
              <Route
                path=":vendorId/awards"
                loader={awardsLoader}
                element={
                  <StrictMode>
                    <Awards />
                  </StrictMode>
                }
              />
              <Route
                element={
                  <ProtectedLayout
                    allowedForCustomers
                    unAuthorizedRedirectTo="/"
                  />
                }
              >
                <Route
                  path=":vendorId/metrics/:report"
                  element={
                    <StrictMode>
                      <Metrics />
                    </StrictMode>
                  }
                />
              </Route>
              <Route
                path=":vendorId/awards/top-rated"
                element={<TopRatedDetails />}
              />
              <Route
                path=":vendorId/awards/tech-cares"
                element={<TechCaresDetails />}
              />
              <Route path=":vendorId/integrations" element={<Integrations />} />
              <Route
                path=":vendorId/integrations/6sense"
                element={<SixSense />}
              />
              <Route
                path=":vendorId/integrations/linkedin"
                loader={linkedinMatchedAudiencesLoader}
                element={
                  <StrictMode>
                    <LinkedinMatchedAudiencesPage />
                  </StrictMode>
                }
              >
                <Route
                  path="unauthorize"
                  action={removeLinkedinIntegrationAction}
                  element={<RemoveLinkedinIntegrationModal />}
                />
              </Route>
              <Route
                path=":vendorId/integrations/linkedin/segments"
                loader={linkedinSegmentsPageLoader}
                element={<LinkedinSegmentsPage />}
              >
                <Route
                  path="unauthorize"
                  action={removeLinkedinIntegrationAction}
                  element={<RemoveLinkedinIntegrationModal />}
                />
              </Route>
              <Route
                path=":vendorId/integrations/linkedin/segments/create"
                loader={linkedinCreateSegmentPageLoader}
                element={<LinkedinCreateSegmentPage />}
              />
              <Route
                path=":vendorId/integrations/demandbase"
                element={<DemandBase />}
              />
              <Route
                path=":vendorId/integrations/salesforce"
                loader={salesforceLoader}
                element={
                  <StrictMode>
                    <Salesforce />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/integrations/walnut"
                element={<Walnut />}
              />
              <Route
                path=":vendorId/integrations/navattic"
                element={<Navattic />}
              />
              <Route
                path=":vendorId/integrations/intentsify"
                element={<Intentsify />}
              />
              <Route
                path=":vendorId/integrations/whistic"
                element={<Whistic />}
              />
              <Route
                path=":vendorId/integrations/storylane"
                element={<Storylane />}
              />
              <Route
                path=":vendorId/integrations/screenspace"
                element={<ScreenSpace />}
              />
              <Route
                path=":vendorId/integrations/tourial"
                element={<Tourial />}
              />
              <Route
                path=":vendorId/integrations/testbox"
                element={<Testbox />}
              />
              <Route
                path=":vendorId/integrations/demostack"
                element={<Demostack />}
              />
              <Route
                path=":vendorId/integrations/zoominfo"
                element={<ZoominfoContainer />}
              />
              <Route
                path=":vendorId/integrations/zoominfo/connect"
                element={<ZoominfoConnectContainer />}
              />
              <Route
                path=":vendorId/buyers/leads-overview"
                element={
                  <StrictMode>
                    <LeadsOverviewWithData />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/buyers/lead-form"
                element={
                  <StrictMode>
                    <LeadFormWithData />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/buyers/lead-form-hub"
                element={
                  <StrictMode>
                    <LeadFormV2 />
                  </StrictMode>
                }
                loader={() => {
                  if (!isNewLeadsEnabled) {
                    throw new Error(404);
                  }
                  return {};
                }}
              />
              <Route
                path=":vendorId/buyers/lead-form-hub/:leadFormId"
                element={
                  <StrictMode>
                    <LeadFormConfiguration />
                  </StrictMode>
                }
                loader={() => {
                  if (!isNewLeadsEnabled) {
                    throw new Error(404);
                  }
                  return {};
                }}
              />
              )
              <Route
                path=":vendorId/buyers/leads-webhook"
                loader={leadsWebhookLoader}
                element={
                  <StrictMode>
                    <LeadsWebhookWithData />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/buyers/webhook-configuration/:webhookId"
                loader={() => {
                  if (!isNewLeadsEnabled) {
                    throw new Error(404);
                  }
                  return {};
                }}
                element={
                  <StrictMode>
                    <WebhookConfigurationPage />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/buyers/leads-webhook-new"
                element={
                  <StrictMode>
                    <LeadsV2Webhooks />
                  </StrictMode>
                }
                loader={() => {
                  if (!isNewLeadsEnabled) {
                    throw new Error(404);
                  }
                  return {};
                }}
              />
              <Route
                path=":vendorId/buyers/leads-activity/:leadId"
                element={
                  <StrictMode>
                    <LeadDetailContainer />
                  </StrictMode>
                }
              />
              <Route
                path=":vendorId/reviews/generate-reviews"
                element={
                  <StrictMode>
                    <ReviewGeneration />
                  </StrictMode>
                }
              />
            </Route>
          </Route>
        </Route>,
      ),
    );
  }, [isNewLeadsEnabled]);

  return (
    <Div100vh
      className={classNames(
        styles.app,
        { [styles.container]: !isDesktop && isMenuOpen },
        {
          [styles.error]: hasErrors,
        },
      )}
    >
      <ErrorBoundary hasError={hasErrors}>
        <RouterProvider router={router} fallbackElement={<Spinner />} />
      </ErrorBoundary>
    </Div100vh>
  );
}

export default App;
