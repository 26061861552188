import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withEligibleVendorProducts } from 'hoc/withEligibleVendorProducts';
import { Modal, Spinner } from 'components/TrustradiusUICore';
import styles from './EligibleProductsModal.module.scss';

export function EligibleProductsModal({
  onHide,
  eligibleProducts = [],
  isLoading = false,
}) {
  const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);

  return (
    <Modal
      show
      className={styles.modal}
      dialogClassName={styles.dialog}
      backdropClassName={styles.backdrop}
      onHide={onHide}
    >
      <Modal.Header
        className={classNames(
          styles.header,
          isScrolledFromTop && styles['with-shadow'],
        )}
        closeButton
      >
        <Modal.Title as="div" className={styles.title}>
          <h2>Eligible Products</h2>
          <div className={styles.subtitle}>
            The lead form is available for licensed products. To license
            additional products, contact your TrustRadius Customer Success
            Manager.
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        onScroll={(event) => {
          setIsScrolledFromTop(event.target.scrollTop > 0);
        }}
        className={styles.body}
      >
        {isLoading && <Spinner />}
        {!isLoading && (
          <ul className={styles['product-list']}>
            {eligibleProducts.map((product) => (
              <li className={styles['product-list-item']} key={product.id}>
                <img
                  src={product?.logos?.square180}
                  alt=""
                  width="30"
                  height="30"
                  className={styles.logo}
                />
                {product.name}
              </li>
            ))}
            {eligibleProducts?.length === 0 && (
              <p>You do not have eligible products.</p>
            )}
          </ul>
        )}
      </Modal.Body>
    </Modal>
  );
}

EligibleProductsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  eligibleProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logos: PropTypes.shape({ square180: PropTypes.string }),
    }),
  ),
  isLoading: PropTypes.bool,
};

export const EligibleProductsModalWithData = withEligibleVendorProducts(
  EligibleProductsModal,
);
