import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isNull, isUndefined } from 'underscore';
import { OverlayTrigger } from 'components/TrustradiusUICore';
import { Arrow } from 'assets/icons';
import { Skeleton } from 'components/Skeleton';
import styles from './ProductMetric.module.scss';

export function ProductMetric({
  currentValue,
  title,
  deltaPct,
  popover,
  isLoading,
  ...otherProps
}) {
  const delta = useMemo(() => {
    if (isNull(deltaPct)) {
      return {
        icon: '',
        value: '',
        style: 'neutral',
      };
    }
    if (!deltaPct) {
      return {
        icon: '',
        value: '',
        style: 'neutral',
      };
    }
    if (deltaPct > 0) {
      return {
        icon: <Arrow className={styles.arrow} />,
        value: `${deltaPct}%`,
        style: 'positive',
      };
    }
    if (deltaPct < 0) {
      return {
        icon: (
          <Arrow className={classNames(styles.arrow, styles.arrowNegative)} />
        ),
        value: `${Math.abs(deltaPct)}%`,
        style: 'negative',
      };
    }
  }, [deltaPct]);

  const isNoDataAvailable = isNull(currentValue) || isUndefined(currentValue);
  const value = isNoDataAvailable ? '-' : `${currentValue.toLocaleString()}`;
  return (
    <div className={styles.metricContainer} {...otherProps}>
      {isLoading && <Skeleton />}
      {!isLoading && popover && (
        <OverlayTrigger
          placement="bottom"
          trigger={['hover', 'focus']}
          overlay={popover}
          interactive
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: { offset: [0, 18] },
              },
              {
                name: 'arrow',
                options: {
                  padding: 9,
                },
              },
            ],
          }}
        >
          <div className={styles.overlayContent}>
            <div
              className={classNames(styles.numberContainer, {
                [styles['no-data']]: isNoDataAvailable,
              })}
            >
              <div className={styles.number} data-testid="value">
                {value}
              </div>
              {delta && (
                <span className={styles[delta.style]}>
                  {delta.icon}
                  {delta.value}
                </span>
              )}
            </div>
            <div
              className={classNames(styles.title, {
                [styles['no-data']]: isNoDataAvailable,
              })}
            >
              {title}
            </div>
          </div>
        </OverlayTrigger>
      )}
      {!isLoading && !popover && (
        <>
          <div className={styles.numberContainer}>
            <div className={styles.number} data-testid="value">
              {currentValue.toLocaleString()}
            </div>
            {delta && (
              <span className={styles[delta.style]}>
                {delta.icon}
                {delta.value}
              </span>
            )}
          </div>
          <div className={styles.title}>{title}</div>
        </>
      )}
    </div>
  );
}

ProductMetric.propTypes = {
  currentValue: PropTypes.number,
  title: PropTypes.string,
  deltaPct: PropTypes.number,
  popover: PropTypes.object,
  isLoading: PropTypes.bool,
};
