import { useMedia, useCookie } from 'react-use';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { NewsFeed } from 'components/NewsFeed';
import { RightBarContainer } from 'components/RightBarContainer';
import { CompetitorsContainer } from 'components/CompetitorsContainer';
import { VendorInfoContainer } from 'components/VendorInfoContainer';
import { TRInnerCircleContainer } from 'components/TRInnerCircleContainer';
import { ProductHeaderContainer } from 'components/ProductHeader';
import { BookDemo } from 'components/BookDemo';
import { useSettings } from 'settings';
import { PageBody } from 'components/PageBody';
import { fetchChromeExtensionApiKey } from 'store/apis/vendorApi';
import styles from './Dashboard.module.scss';

export function Dashboard() {
  const isDesktop = useMedia('(min-width:1050px)');
  const [value, , deleteCookie] = useCookie('chrome_extension');
  const pageBodyClass = isDesktop ? 'two-columns__desktop' : 'two-columns';

  const { productId } = useParams();
  const users = useSelector((state) => state.users);
  const currentVendor = useSelector((state) => state.vendors.currentVendor);

  const settings = useSettings();
  const currentProduct = useSelector(
    (state) => state.vendors.currentVendorProduct,
  );

  let competitorTitle = '';
  let editCompetitorsLink = '';

  const canUserEditProductListing = () => {
    let result = false;
    if (!Object.keys(users?.rolesByName).length || !currentVendor?._id) {
      return result;
    }

    const allowedRoles = [
      'vp:tr_admin',
      'vp:curator',
      'vp:company_owner',
      'vp:product_editor',
    ];

    const vendorRolesIds = users?.rolesByVendorId[currentVendor._id];
    const vendorRoles = vendorRolesIds?.map(
      (roleId) => users?.rolesById[roleId],
    );

    if (users?.rolesByName['vp:tr_admin']) {
      result = true;
      return result;
    }

    vendorRoles.forEach((vendorRole) => {
      if (allowedRoles.includes(vendorRole.name)) {
        result = true;
      }
    });

    return result;
  };

  if (canUserEditProductListing()) {
    competitorTitle = 'Add New';
    editCompetitorsLink = `${settings?.legacyBaseUrl}/products/${currentProduct._id}/listing`;
  }
  useEffect(() => {
    async function initializeChromeExtension() {
      console.log('HERE', value);
      const apiToken = await fetchChromeExtensionApiKey(
        currentVendor._id,
        users?.user || {},
      );
      // The ID of the extension we want to talk to.
      if (!settings.chromeExtensionId) {
        // No chrome extension id found in environment
        console.error('No chrome extension id found in environment');
        return;
      }
      const chromeRuntime = window?.chrome?.runtime;
      // const chromeApi = (typeof window.chrome === 'undefined') ? {} : window.chrome;
      // chrome is not defined the same here as it is on a page script
      if (!chromeRuntime) {
        console.error('Chrome not persent');
        // Chrome not persent
        return;
      }
      const msgCb = (res) => {
        if (res?.success) {
          deleteCookie();
          return;
        }
        console.log(
          `TrustQuotesExt did not receive message.\n${JSON.stringify(
            res,
            null,
            2,
          )}`,
        );
        console.log('Chrome Extension received an invalid message.', 'error');
      };

      const vendorContext = {
        userId: users?.user?._id,
        vendorId: currentVendor?._id,
        apiToken: apiToken?.data?.apiToken,
        vendorSlug: currentVendor?.slug,
      };
      chromeRuntime.sendMessage(
        settings.chromeExtensionId,
        { vendorContext },
        msgCb,
      );
    }
    if (value === 'true') {
      initializeChromeExtension();
    }
  }, [
    value,
    currentVendor._id,
    users?.user,
    currentVendor?.slug,
    currentProduct._id,
    settings.chromeExtensionId,
    deleteCookie,
  ]);

  return (
    <>
      <ProductHeaderContainer />
      <PageBody className={styles[pageBodyClass]}>
        <aside className={styles['right-column']}>
          {!currentVendor?.isCustomer && (
            <RightBarContainer title="Get More With TrustRadius">
              <BookDemo />
            </RightBarContainer>
          )}
          <RightBarContainer
            title="Your Competition"
            linkTitle={competitorTitle}
            linkUrl={editCompetitorsLink}
          >
            <CompetitorsContainer
              editCompetitorsLink={editCompetitorsLink}
              hasAccessToEdit={canUserEditProductListing()}
            />
          </RightBarContainer>
          <RightBarContainer
            title="Who’s Checking You Out"
            linkTitle="View All"
            linkUrl={`${settings?.legacyBaseUrl}/products/${productId}/reports/intent-data`}
          >
            <VendorInfoContainer />
          </RightBarContainer>
          <TRInnerCircleContainer />
        </aside>
        <div className={classNames(styles['left-column'])}>
          <NewsFeed />
        </div>
      </PageBody>
    </>
  );
}
