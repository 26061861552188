import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Avatar } from '@trustradius/radiant-ui';
import { useSettings } from 'settings';
import { useOutsideClickHandler, useLogout } from 'hooks';
import styles from './UserMenu.module.scss';

export function UserMenu({ color }) {
  const { user, isAuthenticated } = useAuth0();
  const pictureUrl = user?.picture || '/images/no_photo.png';
  const firstName = user?.given_name || 'Unknown';
  const lastName = user?.family_name || 'User';
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const menuRef = useOutsideClickHandler(closeMenu);

  const handleButton = () => {
    setIsOpen(!isOpen);
  };

  const settings = useSettings();

  const logoutWithRedirect = useLogout();

  const buttonTitle =
    [firstName, lastName].filter(Boolean).join(' ') || undefined;

  return (
    <div className={styles.container} ref={menuRef}>
      <button
        type="button"
        onClick={handleButton}
        className={styles.button}
        title={buttonTitle}
        aria-label="Open user menu"
      >
        <Avatar
          src={pictureUrl}
          name={`${firstName} ${lastName}`}
          size="sm"
          colored={color}
        />
      </button>
      {isAuthenticated && isOpen && (
        <div className={styles.menu} data-testid="user-menu">
          <div className={styles.nav}>
            <a
              className={styles.link}
              href={`${settings?.buyerSiteUrl}/profile`}
            >
              Profile
            </a>
            <a
              className={styles.link}
              href={`${settings?.buyerSiteUrl}/profile/reviews`}
            >
              Reviews
            </a>
          </div>
          <button
            type="button"
            className={styles.logout}
            onClick={logoutWithRedirect}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

UserMenu.propTypes = {
  color: PropTypes.bool.isRequired,
};
