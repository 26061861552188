function SolidCircleCheck() {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M16 8c0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8 8 3.58 8 8z"
          fill="#00B2A3"
        />
        <path
          stroke="#DBFDF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11 6l-4.125 4.286L5 8.338"
        />
      </g>
    </svg>
  );
}

export default SolidCircleCheck;
