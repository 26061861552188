import { useSelector } from 'react-redux';
import { User, Sliders, PieChart } from 'lucide-react';
import { Button, Text } from '@trustradius/radiant-ui';
import { NavatticSquareLogo } from 'assets/logos-companies';
import {
  SecondaryPageHeader,
  SecondaryPageContent,
  KeyBenefits,
  Installation,
  UseCases,
} from 'components/SecondaryPage';
import { NavatticBanner1x, NavatticBanner2x } from './assets';

export function Navattic() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const subTitle = 'Instantly create interactive product demos.';

  const headerProps = {
    backLinkProps: {
      to: `/vendor/${vendor._id}/integrations`,
      'aria-label': 'Integration page',
    },
    title: 'Navattic',
    logo: <img src={NavatticSquareLogo} alt="Navattic" />,
    subtitle: subTitle,
    actionBlock: (
      <Button url="https://www.navattic.com/get-demo" size="large" targetBlank>
        Create a free demo
      </Button>
    ),
  };

  const keyBenefits = [
    {
      id: '1',
      icon: <User />,
      text: 'Give buyers the ability to test-drive your product and visualize how it works',
    },
    {
      id: '2',
      icon: <Sliders />,
      text: 'Tailor interactive demos to specific use cases',
    },
    {
      id: '3',
      icon: <PieChart />,
      text: 'Gain rich insights into who is test-driving your product and what they’re interested in.',
    },
  ];

  const installation = [
    {
      id: 'guide',
      componentContent: (
        <a
          href="https://trustradius.freshdesk.com/support/solutions/folders/43000588454"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
    },
  ];

  const useCases = ['Lead Generation', 'Presales', 'Interactive demos'];

  return (
    <>
      <SecondaryPageHeader
        backLinkProps={headerProps.backLinkProps}
        title={headerProps.title}
        subtitle={headerProps.subtitle}
        logo={headerProps.logo}
        actionBlock={headerProps.actionBlock}
        hasShadow
      />
      <SecondaryPageContent
        rightRail={[
          {
            id: 'key-benefits',
            element: <KeyBenefits content={keyBenefits} />,
          },
          {
            id: 'installation',
            element: <Installation content={installation} />,
          },
          {
            id: 'use-cases',
            element: <UseCases content={useCases} />,
          },
        ]}
        imageBanner={
          <img
            src={NavatticBanner1x}
            srcSet={`${NavatticBanner1x} 1x, ${NavatticBanner2x} 2x`}
            alt="Navattic"
          />
        }
      >
        <section>
          <Text as="h2">Overview</Text>
          <Text as="h3">About Navattic</Text>
          <Text as="p">
            Navattic empowers prospects to get hands-on with your product
            earlier in the buying journey through no-code interactive demos.
            More than 250 go-to-market teams use Navattic across the funnel,
            from first touch in outbound campaigns, to website product demos and
            post-demo leave behinds.
          </Text>
          <Text as="h3">Navattic and TrustRadius</Text>
          <Text as="p">
            Embed your Navattic no-code interactive product demos into the
            Product Demos section of your TrustRadius product listing page. Let
            your prospects get immediate hands-on access during the evaluation
            process with interactive demos that look and feel like your
            software.
          </Text>
          <Text as="p">
            Show off those aha! moments so your prospects immediately understand
            your product’s value. And demonstrate different feature
            functionality or show how to build out more complicated workflows
            like integrations.
          </Text>
        </section>
        <section>
          <Text as="h2">Use cases</Text>
          <Text as="h3">Connect buyers with high-value interactive demos</Text>
          <Text as="p">
            Enable buyers to take a self-guided tour of your product. Give them
            a better understanding of how it works and evaluate whether its the
            right fit for their organization.
          </Text>
          <Text as="h3">Personalize demos for different personas</Text>
          <Text as="p">
            Let buyers explore the features or use cases they care most about.
            Use checklists so buyers can understand your full product
            functionality and how it solves their unique pain points.
          </Text>
          <Text as="h3">Highlight soon-to-come features</Text>
          <Text as="p">
            Show customers and prospects a sneak peak of beta or upcoming
            features. Use screenshots or staging environments to build out demos
            of non features that aren’t production ready.
          </Text>
        </section>
      </SecondaryPageContent>
    </>
  );
}
