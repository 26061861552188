/* @TODO [TECHX-67] - Fix a11y violations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { useMedia } from 'react-use';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchVendorById,
  fetchVendorProducts,
  findVendorsByName,
} from 'store/apis/vendorApi';
import {
  collapseSideBar,
  deactivateVendorSwitcher,
} from 'store/actions/globalActions';
import { SearchResult } from 'components/SearchResult';
import { SearchField, searchTextColors } from 'components/SearchField';
import styles from './VendorSwitcher.module.scss';
import { NoPhoto } from './assets';
import { useVendorSwitcher } from './useVendorSwitcher';

const trAdminRoleName = 'vp:tr_admin';
function isAdmin(userRoles) {
  return userRoles.includes(trAdminRoleName);
}

export function VendorSwitcher() {
  const [isVendorSwitcherActive, toggleVendorSwitcher] = useVendorSwitcher();
  const vendors = useSelector((state) => state.vendors);
  const [filter, setFilter] = useState('');
  const [internalById, setInternalById] = useState();
  const [internalAllIds, setInternalAllIds] = useState();
  const navigate = useNavigate();
  const lgTablet = useMedia('(min-width:900px)');
  const isMenuContainerExpanded = useSelector(
    (state) => state.globalState.isMenuContainerExpanded,
  );
  const userRoles = useSelector((state) => state.users.rolesAllNames);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVendorSwitcherActive && isMenuContainerExpanded) {
      return;
    }
    setFilter('');
    setInternalById();
    setInternalAllIds();
  }, [isVendorSwitcherActive, isMenuContainerExpanded]);

  // vendors  are always an object
  if (!vendors?.availableVendors) {
    return null;
  }

  const sourceById = internalById ? internalById : vendors.byId;
  const sourceAllIds = internalAllIds ? internalAllIds : vendors.allIds;

  const changeVendor = async (event) => {
    dispatch(collapseSideBar());
    dispatch(deactivateVendorSwitcher());
    const vendorResponse = await fetchVendorById(event.target.value);
    if (vendorResponse?.data?.records?.length > 0) {
      const response = await fetchVendorProducts(event.target.value);

      if (response?.data?.records?.length > 0) {
        navigate(
          `/vendor/${event.target.value}/dashboard/${response.data.records[0]._id}`,
        );
      } else {
        navigate(`/vendor/${event.target.value}/welcome`);
      }
    }
  };

  const getVendorByName = async (name) => {
    setFilter(name);
    if (name.length < 3) return;

    const vendorData = await findVendorsByName(name);
    const newById = {};
    const newAllIds = [];
    vendorData.forEach((vendor) => {
      newById[vendor._id] = vendor;
      newAllIds.push(vendor._id);
    });
    setInternalById(newById);
    setInternalAllIds(newAllIds);
  };

  const chevronIcon = isVendorSwitcherActive ? (
    <ChevronUp size={14} />
  ) : (
    <ChevronDown size={14} />
  );
  const lazyGetVendorByName = _.debounce(getVendorByName, 500);
  const searchTextColor = lgTablet
    ? searchTextColors.BLACK
    : searchTextColors.WHITE;

  const renderLogo = () => {
    const logo =
      vendors?.currentVendor?.logos?.square180 ||
      vendors?.currentVendor?.logos?.default;

    if (logo) {
      return <img src={logo} alt="vendor logo" />;
    }

    return <NoPhoto color="#191919" />;
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles['vendor-search']]: isVendorSwitcherActive,
      })}
    >
      <div className={styles.title}>
        <div className={styles.logo}>{renderLogo()}</div>
        <div className={classNames(styles.link)} onClick={toggleVendorSwitcher}>
          <span className={styles.name} title={vendors?.currentVendor?.name}>
            {vendors?.currentVendor?.name}
          </span>
          {vendors.allIds?.length > 0 && chevronIcon}
        </div>
      </div>

      {isVendorSwitcherActive && isMenuContainerExpanded && (
        <div className={styles.results}>
          {isAdmin(userRoles) && (
            <div className={styles['search-wrapper']}>
              <SearchField
                id="vendor_switcher"
                placeholder="Search Vendors"
                onChange={lazyGetVendorByName}
                className={styles.search}
                inputColor={searchTextColor}
              />
            </div>
          )}
          <div className={styles.separator} />
          <div className={styles['vendor-switcher-results']}>
            <SearchResult
              options={sourceAllIds.map((vendorId) => sourceById[vendorId])}
              onChange={changeVendor}
              selected={vendors?.currentVendor?._id}
              filter={filter}
            />
          </div>
        </div>
      )}
    </div>
  );
}
