import ReactBootstrapModalTitle from 'react-bootstrap/ModalTitle';
import classNames from 'classnames';

import './Modal.scss';

/**
 * TrustRadius wrapped ModalTitle component
 * Implemented using react-bootstrap, which makes use of bootstrap styles.
 * Added local styles for this component as well
 */
export function ModalTitle(props) {
  const { className, children, ...otherProps } = props;
  // Merge any provided classNames with local component styling
  const classes = classNames(className, 'h5');
  // Provide default variant
  return (
    <ReactBootstrapModalTitle
      as="h2"
      className={classes}
      bsPrefix="trModal-title"
      {...otherProps}
    >
      {children}
    </ReactBootstrapModalTitle>
  );
}

// eslint-disable-next-line react/forbid-foreign-prop-types
ModalTitle.propTypes = ReactBootstrapModalTitle.propTypes;
