import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import { HelpCircle } from 'lucide-react';
import { TRLogo } from 'components/TRLogo';
import { MobileMenuContainer } from 'components/MobileMenuContainer';
import { LeftMenuWithLicenses } from 'components/LeftMenu';
import { useVendorSwitcher, VendorSwitcher } from 'components/VendorSwitcher';
import { useSettings } from 'settings';
import styles from './Header.module.scss';

export function Header({ showMenu }) {
  const productId = useSelector(
    (state) => state.vendors.currentVendorProduct?._id,
  );

  const isMenuExpanded = useSelector(
    (state) => state.globalState.isMenuContainerExpanded,
  );
  const [isVendorSwitcherActive] = useVendorSwitcher();
  const vendor = useSelector((state) => state.vendors.currentVendor);
  const isDesktop = useMedia('(min-width:901px)');
  const settings = useSettings();

  const linkToLegacy = productId
    ? `${settings?.legacyBaseUrl}/products/${productId}`
    : settings?.legacyBaseUrl;

  return (
    <header className={classNames(styles.container)}>
      <div className={styles.legacy}>
        <a href={linkToLegacy}>Return to legacy vendor portal</a>
      </div>
      <div className={styles.header}>
        <TRLogo className={styles.logo} url={settings?.buyerSiteUrl} />
        {showMenu && <MobileMenuContainer />}
        {!showMenu && (
          <a
            className={styles.helpIcon}
            href="https://trustradius.freshdesk.com/support/home"
            target="_blank"
            rel="noreferrer"
          >
            <HelpCircle size="25" />
            <span>Get Support</span>
          </a>
        )}
      </div>
      {!isDesktop && vendor && (
        <nav
          className={classNames(styles['mobile-menu'], {
            [styles['mobile-menu-expanded']]: isMenuExpanded,
          })}
          data-testid="mobile-menu"
        >
          <VendorSwitcher />
          {!isVendorSwitcherActive && <LeftMenuWithLicenses />}
        </nav>
      )}
    </header>
  );
}

Header.propTypes = {
  showMenu: PropTypes.bool,
};

Header.defaultProps = {
  showMenu: false,
};
