import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Radio.module.scss';

export function Radio({ label, text, className, ...input }) {
  return (
    <div className={classNames(styles.radio, className)}>
      <label className={styles.radio__label}>
        <input {...input} type="radio" className={styles.radio__input} />
        <span className={styles.radio__label_text}>
          {label}
          {text && (
            <span className={styles['radio__label_sub-text']}>{text}</span>
          )}
        </span>
      </label>
    </div>
  );
}

Radio.propTypes = {
  label: PropTypes.node,
  text: PropTypes.string,
  className: PropTypes.string,
};
