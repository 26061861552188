import { useSelector, useDispatch } from 'react-redux';
import { changeCurrentVendor } from 'store/actions/vendorActions';
import { enableVendorLeadForm } from 'store/apis/vendorApi';

export const withLeadFormData = (Element) => {
  function WrappedComponent(props) {
    const vendor = useSelector((state) => state.vendors.currentVendor);
    const dispatch = useDispatch();

    const leadFormSwitchHandler = async (value) => {
      const response = await enableVendorLeadForm(vendor._id, {
        hasBuyerLeadsActivated: value,
      });

      dispatch(changeCurrentVendor(response?.data));
    };

    return (
      <Element
        {...props}
        isLeadFormActive={vendor?.hasBuyerLeadsActivated}
        switchHandler={leadFormSwitchHandler}
      />
    );
  }

  return WrappedComponent;
};
