import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Info } from 'lucide-react';
import {
  Button,
  Checkbox,
  Logo,
  OverlayTrigger,
  Popover,
} from 'components/TrustradiusUICore';
import styles from './LeadCaptureModalReplica.module.scss';

const LEAD_CAPTURE_INTEREST = {
  REQUEST_DEMO: {
    title: 'Request a demo',
    value: 'request_demo',
    name: 'request_demo',
  },
  REQUEST_QUOTE: {
    title: 'Request a quote',
    value: 'request_quote',
    name: 'request_quote',
  },
  OTHER: {
    title: 'Other',
    value: 'other',
    name: 'other',
  },
};

export function LeadCaptureModalReplica() {
  const vendor = useSelector((state) => state.vendors.currentVendor);

  const emailPopover = (
    <Popover className={styles['email-popover']}>
      <Popover.Content>
        A valid business email address is required. Free email accounts (like
        Gmail and Yahoo) are not accepted.
      </Popover.Content>
    </Popover>
  );

  const emailPopoverTrigger = (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={emailPopover}
    >
      <Info />
    </OverlayTrigger>
  );

  return (
    <div className={styles['form-layout']}>
      <header className={styles.header}>
        <div className={styles['modal-top']}>
          <Logo
            className={styles['product-logo']}
            logo={vendor?.logos.square180}
            lazyLoad={false}
            altText={vendor?.name}
          />
          <h2 className={styles['vendor-text']}>
            Connect with <br />
            {vendor?.name}
          </h2>
        </div>
        <h3 className={styles['help-text']}>WHAT ARE YOU INTERESTED IN?</h3>
        <div className={styles['options-container']}>
          {Object.keys(LEAD_CAPTURE_INTEREST).map((interest) => {
            return (
              <div
                key={LEAD_CAPTURE_INTEREST[interest].value}
                className={styles['option-container']}
              >
                <Checkbox
                  text={LEAD_CAPTURE_INTEREST[interest].title}
                  name={LEAD_CAPTURE_INTEREST[interest].name}
                  value={LEAD_CAPTURE_INTEREST[interest].value}
                  size="small"
                  color="blue"
                  disabled
                />
              </div>
            );
          })}
        </div>
      </header>
      <div className={styles['modal-body']}>
        <div className={styles['two-field-container']}>
          <div className={styles['field-container']}>
            <label
              id="lead-capture-firstname-label"
              htmlFor="lead-capture-firstname"
              className={styles['label-uppercase']}
            >
              First Name
            </label>
            <input
              className={styles['form-control']}
              id="lead-capture-firstname"
              type="text"
              name="firstname"
              autoComplete="given-name"
              aria-labelledby="lead-capture-firstname-label"
              disabled
            />
          </div>
          <div className={styles['field-container']}>
            <label
              id="lead-capture-lastname-label"
              htmlFor="lead-capture-lastname"
              className={styles['label-uppercase']}
            >
              Last Name
            </label>
            <input
              className={styles['form-control']}
              id="lead-capture-lastname"
              type="text"
              name="lastname"
              autoComplete="family-name"
              aria-labelledby="lead-capture-lastname-label"
              disabled
            />
          </div>
        </div>
        <div className={styles['field-container']}>
          <label
            id="lead-capture-email-label"
            htmlFor="lead-capture-email"
            className={styles['label-uppercase']}
          >
            <span>Work Email</span>
            {emailPopoverTrigger}
          </label>
          <input
            className={styles['form-control']}
            id="lead-capture-email"
            type="text"
            name="email"
            autoComplete="email"
            aria-labelledby="lead-capture-email-label"
            disabled
          />
        </div>
        <div className={styles['field-container']}>
          <label
            id="lead-capture-companyName-label"
            htmlFor="lead-capture-companyName"
            className={styles['label-uppercase']}
          >
            Company
          </label>
          <input
            className={styles['form-control']}
            id="lead-capture-companyName"
            type="text"
            name="companyName"
            autoComplete="organization"
            aria-labelledby="lead-capture-companyName-label"
            disabled
          />
        </div>
        <div className={styles['field-container']}>
          <label
            id="lead-capture-countryName-label"
            htmlFor="lead-capture-countryName"
            className={styles['label-uppercase']}
          >
            Country
          </label>
          <select
            className={styles['form-control']}
            id="lead-capture-countryName"
            name="countryName"
            autoComplete="country-name"
            aria-labelledby="lead-capture-countryName-label"
            disabled
          >
            <option value="">United States</option>
          </select>
        </div>
        <div className={styles['field-container']}>
          <label
            id="lead-capture-notes-label"
            htmlFor="lead-capture-notes"
            className={classNames(
              styles['label-uppercase'],
              styles['notes-label'],
            )}
          >
            Message &nbsp;
            <span className={styles['notes-optional']}>(optional)</span>
          </label>
          <textarea
            className={classNames(
              styles['form-control'],
              styles['lead-capture-notes'],
            )}
            id="lead-capture-notes"
            name="notes"
            placeholder="Add a message to the vendor"
            rows="3"
            aria-labelledby="lead-capture-notes-label"
            disabled
          />
        </div>
        <div className={styles['field-container']}>
          <p className={styles.disclaimer}>
            You hereby consent to have TrustRadius share the information
            supplied on this form with TrustRadius so that {vendor.name} and
            {` ${vendor.name}`} may contact you in regard to the information
            requested.
            <div className={styles['legal-container']}>
              <input
                type="checkbox"
                id="lead-capture-privacyConsent"
                disabled
                aria-labelledby="lead-capture-privacyConsent"
              />
              <label htmlFor="lead-capture-privacyConsent">
                By checking this box, I acknowledge that I have read and agree
                to the TrustRadius
                <a
                  href="https://www.trustradius.com/static/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp; Privacy Policy
                </a>{' '}
                and
                <a
                  href="https://www.trustradius.com/static/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp; Terms Of Use
                </a>
                .
              </label>
            </div>
          </p>
          <Button
            variant="primary-pill"
            disabled
            className={styles['submit-button']}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
